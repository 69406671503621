import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SideMenu from "../General/SideMenu";
import ReactTable from "../General/ReactTable";
import { userApp} from "../Admin/Utility";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import ProductSearch from "../Transaction/ProductSearch";
import ProductAttributeView from "../Master/ProductAttributeView";
import {
  AddBOMProduct,
  Modify_Product,
  ProductInput_Onchange,
  Select_Unit,
  ShowHide_Popup,
  setBOMTrans,
} from "../ReduxStore/Transactionstates";
function BillOfMaterial() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _DT = useSelector((store) => store.states.Data);
  const _Product = useSelector((store) => store.states.Product);

  const iScrId = 68;
  const [iBOMId, setiBOMId] = useState(0);
  const [strBomName, setstrBomName] = useState("");
  const [strAlias, setstrAlias] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [ListBOMModel, setListBOMModel] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  useEffect(() => {
    Load_BOM();
  }, []);
  const Load_BOM = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await userApp.post(`BOM/Load_BOM`, parems).then(async (res) => {
        if (res.data.strStatus === "Success") {
          setListBOMModel(res.data.EntBOM);
          setListFilter(res.data.EntFilterCol);
          await dispatch(
            setBOMTrans({
              iScrId: iScrId,
              strScrType: "Sales",
              strScrName: "Bill OF Material",
            })
          );
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const InsBomModel = {
        iScrId: iScrId,
        iBOMId: Number(iBOMId),
        strBOMName: strBomName,
        strAliasName: strAlias,
        strPrintName: strPrintName,
        strRemarks: strRemarks,
      };
      await userApp.post(`BOM/InsUpd_BOM/`, {
        objEntBOM: InsBomModel,
        _objEntBOM: _DT.arrEntDT,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
      }).then((res) => {
        if (res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = async () => {
    setstrBomName("");
    setstrPrintName("");
    setstrRemarks("");
    setstrAlias("");
    setstrBomName("");
    setListBOMModel([]);
    setListFilter([]);
    await dispatch(AddBOMProduct([]));
    await Load_BOM();
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.post(
        `BOM/Delete_BOM/${iScrId}/${Number(iBOMId)}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_BOM = async (BOM) => {
    try {
      await dispatch(setload_Screen(true));
      if (Number(BOM.iBOMId) === 0) {
        AlertMessage("info", "Please Select BOM");
      } else {
        await userApp.get(
          `BOM/View_BOM/${Number(BOM.iBOMId)}/${localStorage.db}`
        ).then((res) => {
          if (res.data.strStatus === "Success") {
            dispatch(AddBOMProduct(res.data.EntDT));
            setiBOMId(BOM.iBOMId);
            setstrBomName(BOM.strBOMName);
            setstrAlias(BOM.strAliasName);
            setstrPrintName(BOM.strPrintName);
            setstrRemarks(BOM.strRemarks);
          } else AlertMessage(res.data.strMessage, "error");
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Add_BOMProduct = async () => {
    try {
      if (_Product.strProName.length === 0) {
        AlertMessage("Please Select Product", "info");
      } else if (Number(_Product.dQty) === 0) {
        AlertMessage("Qty Range should not be Zero(0)", "info");
      } else {
        await dispatch(setload_Screen(true));
        const objParams = {
          bUpdateStatus: _Product.bUpdateStatus,
          iScrId: iScrId,
          iProId: _Product.iProId,
          strJobId: "",
          iSerId: 0,
          iRowIndex: _Product.iRowIndex,
          iUnitId: Number(_Product.iUnitId),
          dQty: Number(_Product.dQty),
          FQty: 0,
          dPriceRate: 0,
          dPriceRateWithTax: 0,
          strProAttDTId: _Product.strProAttDTId,
          strStoAttDTId: _Product.strStoAttDTId,
          strTraAttDTId: _Product.strTraAttDTId,
          iALBillDiscId: _Common.iALBillDiscId,
          _objEntBOM: _DT.arrEntDT,
          iYearId: Number(localStorage.FYId),
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
          iUserId: Number(localStorage.UserId),
        };
        await userApp.post(
          `Transaction/Add_BOMProduct/`,
          objParams
        ).then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await dispatch(AddBOMProduct(Res.data.EntDT));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Remove_TableProduct = async (ProInfo) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.post(`Transaction/Remove_BOMProduct/`, {
        bUpdateStatus: _Product.bUpdateStatus,
        iRowIndex: ProInfo.sNo,
        _objEntBOM: _DT.arrEntDT,
        strProAttDTId: _Product.strProAttDTId,
        strStoAttDTId: _Product.strStoAttDTId,
        strTraAttDTId: _Product.strTraAttDTId,
        strCompanyDB: localStorage.db,
      }).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await dispatch(AddBOMProduct(Res.data.EntDT));
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Bom = async (strtxt) => {
    try {
      const obsearch = {
        strColName: "All",
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      userApp.post( "BOM/Search_BOM/", obsearch).then((res) => {
        if (res.data.strStatus === "Success") {
          setListBOMModel(res.data.EntBOM);
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_ProductUnit = async (ProInfo, Index) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.get(
        `Transaction/View_ProductUnit/${ProInfo.proId}/${_Common.strScrType}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success")
          dispatch(
            Modify_Product({
              ProInfo: ProInfo,
              Unit: res.data.objUnit,
              Index: Index,
            })
          );
        else AlertMessage(res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Input_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Unit_Onchange = (UnitId) => {
    userApp.get(
      
        "Transaction/Unit_Onchange/" +
        Number(UnitId) +
        "/" +
        _Product.iProId +
        "/" +
        0 +
        "/" +
        1 +
        "/" +
        _Common.strScrType +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        dispatch(
          Select_Unit({ dPriceRate: res.data.dPriceRate, UnitId: UnitId })
        );
      } else {
        AlertMessage(res.data.message, "error");
      }
    });
  };
  const Product = [
    {
      Header: "S.No",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "proName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Unit",
      id: "unitId",
      accessor: "unitName",
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Qty",
      accessor: "qty",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Action",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={() => Remove_TableProduct(rowinfo)}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={ListFilter}
          EntList={ListBOMModel}
          GetDetails={View_BOM}
          Search={Search_Bom}
          ScreenID={iScrId}
          accessor="strBOMName"
          accessorid="iBOMId"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter BOM Name"
                  className="form-control"
                  value={strBomName}
                  onChange={(e) => setstrBomName(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Alias</label>
                <input
                  placeholder="Enter Alias Name"
                  type="text"
                  onClick={(e) => e.target.select()}
                  className="form-control"
                  value={strAlias}
                  onChange={(e) => setstrAlias(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Print Name</label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Print Name"
                  className="form-control"
                  value={strPrintName}
                  onChange={(e) => setstrPrintName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-1">
                <label>Remark</label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  className="form-control"
                  placeholder="Enter Remark"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6  mt-1">
                <label>Product Name</label>
                <div className="custom-select">
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    value={_Product.strProName}
                    placeholder="Press Enter to Select Product "
                    onClick={(e) => dispatch(ShowHide_Popup("IsProSearch"))}
                    onInput={(e) => dispatch(ShowHide_Popup("IsProSearch"))}
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        dispatch(ShowHide_Popup("IsProSearch"));
                    }}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: _Common.IsProSearch
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-sm-6 col-md-2  mt-1">
                <label>Qty</label>
                <input
                  type="number"
                  placeholder="Enter Qty"
                  name="dQty"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  value={_Product.dQty}
                  onChange={(e) => Input_Onchange(e)}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-6 col-md-2  mt-1">
                <label>Unit</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select  Unit"
                  select_value={(val) => Unit_Onchange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={_Product.iUnitId}
                  displayName="strUnitName"
                  disvalue="iUnitId"
                  arrydata={_Product.Unit}
                  EmptVal=" Unit"
                />
              </div>
              <div className="col-sm-12 col-md-2  mt-4">
                <div className="btn-sub-section">
                  <button className="btn-fabgreen" onClick={Add_BOMProduct}>
                    Add&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <ReactTable
              columns={Product}
              data={_DT.arrEntDT}
              minRows={10}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {
                View_ProductUnit(rowInfo, Index);
              }}
              background={true}
              className="full-table"
            />
            {_Common.IsProSearch && <ProductSearch />}
            {_Common.IsAttSearch && <ProductAttributeView />}
            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (strBomName === "") {
                AlertMessage("Please Enter Name", "info");
              } else if (_DT.arrEntDT.length === 0) {
                AlertMessage("Atleast One Product Required", "info");
              } else if (iBOMId > 0) {
                dispatch(
                  Show_MsgBox({
                    Button: SaveClick,
                    Question: "Do You Want to Update BOM ?",
                    isdialog: true,
                  })
                );
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: SaveClick,
                    Question: "Do You Want to Save BOM?",
                    isdialog: true,
                  })
                );
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (Number(iBOMId) === 0) {
                AlertMessage("Please Select BOM", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete BOM?",
                    isdialog: true,
                  })
                );
              }
            }}
            ScrId={iScrId}
          />
        </div>
      </div>
    </>
  );
}
export default BillOfMaterial;
