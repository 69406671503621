import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import SideMenu from "../General/SideMenu";
import ServiceRemark from "../Master/ServiceRemark";
import { userApp} from "../Admin/Utility";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
function Forecast() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [IsRemarkOpen, setIsRemarkOpen] = useState(false);
  const [objBOMList, setobjBOMList] = useState([]);
  const [objFCDTList, setobjFCDTList] = useState([]);
  const [objFCSearchList, setobjFCSearchList] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const iScrId = 83;
  const [iForecastId, setiForecastId] = useState(0);
  const [strForecastName, setstrForecastName] = useState("");
  const [iBomId, setiBomId] = useState(0);
  const [dProValue, setdProValue] = useState(0);
  const [dEstValue, setdEstValue] = useState(0);
  const [strRemark, setstrRemark] = useState("");
  const [strRemarkType, setstrRemarkType] = useState("");
  const [strServiceRemark1, setstrServiceRemark1] = useState("");
  const [strServiceRemark2, setstrServiceRemark2] = useState("");
  const [strServiceRemark3, setstrServiceRemark3] = useState("");
  const [strServiceRemark4, setstrServiceRemark4] = useState("");
  const [dProductionQty, setdProductionQty] = useState(0);
  const [dServiceCharge1, setdServiceCharge1] = useState(0);
  const [dServiceCharge2, setdServiceCharge2] = useState(0);
  const [dServiceCharge3, setdServiceCharge3] = useState(0);
  const [dServiceCharge4, setdServiceCharge4] = useState(0);
  useEffect(() => {
    Load_Forecast();
  }, []);
  const Load_Forecast = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(`Forecast/Load_Forecast`, parems).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setobjBOMList(Res.data.objEntBOM);
            setobjFCSearchList(Res.data.objEntFC);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const handleClick = (event) => {
    event.target.select();
  };
  const OnChangeBOMEvent = (BomId) => {
    setiBomId(BomId);
    userApp.get(
      
        "Forecast/LoadBOMProducts/" +
        Number(BomId) +
        "/" +
        localStorage.db
    ).then((Res) => {
      setobjFCDTList(Res.data);
    });
  };
  const ForecastClick = async () => {
    try {
      if (iBomId === 0) {
        AlertMessage("Please Select Bom List", "info");
      } else {
        await dispatch(setload_Screen(false));
        await userApp.post( "Forecast/Forecast/", {
          dProductionQty: dProductionQty,
          dServiceCharge1: dServiceCharge1,
          dServiceCharge2: dServiceCharge2,
          dServiceCharge3: dServiceCharge3,
          dServiceCharge4: dServiceCharge4,
          strServiceRemark1: strServiceRemark1,
          strServiceRemark2: strServiceRemark2,
          strServiceRemark3: strServiceRemark3,
          strServiceRemark4: strServiceRemark4,
          strBomName: "",
          strProductionQty: dProductionQty.toString(),
          strForecastName: strForecastName,
          iBomId: Number(iBomId),
          strCompanyDB: localStorage.db,
        }).then((Res) => {
          setdEstValue(Res.data.EstValue);
          setobjFCDTList(Res.data.objEntFCDT);
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveClick = () => {
    const objFCHTList = {
      iScrId: iScrId,
      iSelectedForecastId: iForecastId,
      strForecastName: strForecastName,
      strProductionQty: "",
      dProValue: Number(dProValue),
      dEstValue: Number(dEstValue),
      strServiceRemark1: strServiceRemark1,
      strServiceRemark2: strServiceRemark2,
      strServiceRemark3: strServiceRemark3,
      strServiceRemark4: strServiceRemark4,
      dProductionQty: Number(dProductionQty),
      dServiceCharge1: Number(dServiceCharge1),
      dServiceCharge2: Number(dServiceCharge2),
      dServiceCharge3: Number(dServiceCharge3),
      dServiceCharge4: Number(dServiceCharge4),
      strBomName: "",
      iBomId: Number(iBomId),
    };
    userApp.post( "Forecast/InsUpdForcast/", {
      iForecastId: iForecastId,
      objFOModel: objFCHTList,
      objFODTModel: objFCDTList,
      strCompanyDB: localStorage.db,
    }).then((Res) => {
      if (Res.data.strStatus === "Success") {
        ClearClick();
        AlertMessage(Res.data.strMessage, "success");
      } else {
        AlertMessage(Res.data.strMessage, "error");
      }
    });
  };
  const ClearClick = () => {
    setobjFCDTList([]);
    setobjFCSearchList([]);
    setiForecastId(0);
    setstrForecastName("");
    setiBomId(0);
    setdProValue(0);
    setdEstValue(0);
    setdProductionQty(0);
    setdServiceCharge1(0);
    setdServiceCharge2(0);
    setdServiceCharge3(0);
    setdServiceCharge4(0);
    setstrServiceRemark1("");
    setstrServiceRemark2("");
    setstrServiceRemark3("");
    setstrServiceRemark4("");
    Load_Forecast();
  };
  const DeleteClick = () => {
    userApp.post(
       "Forecast/Delete/" + iForecastId + "/" + localStorage.db
    ).then((Res) => {
      if (Res.data.strStatus === "Success") {
        ClearClick();
        AlertMessage(Res.data.strMessage, "success");
      } else {
        AlertMessage(Res.data.strMessage, "error");
      }
    });
  };
  const GetFCDetails = (objModel) => {
    userApp.get(
      
        "Forecast/ViewForcaste/" +
        objModel.iSelectedForecastId +
        "/" +
        localStorage.db
    ).then((Res) => {
      if (Res.data.strStatus === "Success") {
        setobjFCDTList(Res.data.objEntForcast);
        setiForecastId(objModel.iSelectedForecastId);
        setstrForecastName(objModel.strForecastName);
        setiBomId(objModel.iBomId);
        setdProValue(objModel.dProValue);
        setdEstValue(objModel.dEstValue);
        setstrServiceRemark1(objModel.strServiceRemark1);
        setstrServiceRemark2(objModel.strServiceRemark2);
        setstrServiceRemark3(objModel.strServiceRemark3);
        setstrServiceRemark4(objModel.strServiceRemark4);
        setdProductionQty(objModel.dProductionQty);
        setdServiceCharge1(objModel.dServiceCharge1);
        setdServiceCharge2(objModel.dServiceCharge2);
        setdServiceCharge3(objModel.dServiceCharge3);
        setdServiceCharge4(objModel.dServiceCharge4);
      } else {
        AlertMessage(Res.data.strMessage, "error");
      }
    });
  };
  const Search_Forcast = (strtxt) => {
    setstrSearch(strtxt);
    const objsearch = {
      strSearch: strtxt,
      strCompanyDB: localStorage.db,
    };
    userApp.post( "Forecast/SearchForcast/", objsearch).then((Res) => {
      setobjFCSearchList(Res.data);
    });
  };
  const onServiceRemarkChange = (event) => {
    setstrRemark(event);
  };
  const ServiceRemarkPopup = (Value) => {
    setstrRemarkType(Value);
    switch (Value) {
      case "Remark1":
        setstrRemark(strServiceRemark1);
        break;
      case "Remark2":
        setstrRemark(strServiceRemark2);
        break;
      case "Remark3":
        setstrRemark(strServiceRemark3);
        break;
      case "Remark4":
        setstrRemark(strServiceRemark4);
        break;
      default:
        break;
    }
    setIsRemarkOpen(!IsRemarkOpen);
  };
  const ServiceRemarkPopupCLose = (RemarkType) => {
    switch (RemarkType) {
      case "Remark1":
        setstrServiceRemark1(strRemark);
        break;
      case "Remark2":
        setstrServiceRemark2(strRemark);
        break;
      case "Remark3":
        setstrServiceRemark3(strRemark);
        break;
      case "Remark4":
        setstrServiceRemark4(strRemark);
        break;
      default:
        break;
    }
    setIsRemarkOpen(!IsRemarkOpen);
  };
  const Column = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },

    {
      Header: "Product Name",
      accessor: "proName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Unit",
      accessor: "unitName",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Bom Qty",
      accessor: "bomQty",
      DataType: "Decimal",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Estimate Qty",
      accessor: "estQty",
      DataType: "Decimal",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Available Qty",
      accessor: "availQty",
      DataType: "Decimal",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Required Qty",
      accessor: "reqQty",
      DataType: "Decimal",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Rate",
      accessor: "proRate",
      DataType: "Decimal",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Total Rate",
      accessor: "proValue",
      DataType: "Decimal",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Print Name",
      accessor: "printName",
      minWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "Description",
      accessor: "description",
      minWidth: 100,
      style: { justifyContent: "left" },
    },
  ];

  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="m-header">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Forcast"
                value={strSearch}
                onChange={(e) => Search_Forcast(e.target.value)}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_Forcast(strSearch)}
              ></i>
            </div>
          </div>
          <div className="data-list">
            {objFCSearchList.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = objFCSearchList.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.dt-content [tabindex=${Index + 1}]`).focus();
                    else $(`.dt-content [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.dt-content [tabindex=${arrlength}]`).focus();
                    else $(`.dt-content [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    GetFCDetails(dt);
                  }
                }}
              >
                <label onClick={(e) => GetFCDetails(dt)}>
                  {dt.strForecastName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <label>
                  Forecast Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  id="strForecastName"
                  placeholder="Enter Forcat Name"
                  name="strForecastName"
                  autoComplete="off"
                  spellCheck={false}
                  onClick={(e) => e.target.select()}
                  value={strForecastName}
                  onChange={(e) => setstrForecastName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Production Qty <span className="danger">*</span>
                </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dProductionQty"
                  id="dProductionQty"
                  value={dProductionQty}
                  onChange={(e) => setdProductionQty(Number(e.target.value))}
                  onClick={handleClick}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>BOM</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select BOM"
                  select_value={(val) => OnChangeBOMEvent(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iBomId}
                  displayName="strBOMName"
                  disvalue="iBOMId"
                  arrydata={objBOMList}
                  EmptVal="BOM"
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 1</label>
                <div className="d-inline-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge1"
                    id="dServiceCharge1"
                    value={dServiceCharge1}
                    onChange={(e) => setdServiceCharge1(Number(e.target.value))}
                    onClick={handleClick}
                  />{" "}
                  &nbsp;
                  <span
                    className="icon-btn"
                    onClick={(e) => ServiceRemarkPopup("Remark1")}
                  >
                    <i className="fa-solid fa-message"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 2</label>
                <div className="d-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge2"
                    id="dServiceCharge2"
                    value={dServiceCharge2}
                    onChange={(e) => setdServiceCharge2(Number(e.target.value))}
                    onClick={handleClick}
                  />
                  &nbsp;
                  <span
                    className="icon-btn"
                    onClick={(e) => ServiceRemarkPopup("Remark2")}
                  >
                    <i className="fa-solid fa-message"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 3</label>
                <div className="d-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge3"
                    id="dServiceCharge3"
                    value={dServiceCharge3}
                    onChange={(e) => setdServiceCharge3(Number(e.target.value))}
                    onClick={handleClick}
                  />{" "}
                  &nbsp;
                  <span
                    className="icon-btn"
                    onClick={(e) => ServiceRemarkPopup("Remark3")}
                  >
                    <i className="fa-solid fa-message"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Service Charge 4</label>
                <div className="d-flex ">
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="dServiceCharge4"
                    id="dServiceCharge4"
                    value={dServiceCharge4}
                    onChange={(e) => setdServiceCharge4(Number(e.target.value))}
                    onClick={handleClick}
                  />{" "}
                  &nbsp;
                  <span
                    className="icon-btn"
                    onClick={(e) => ServiceRemarkPopup("Remark4")}
                  >
                    <i className="fa-solid fa-message"></i>
                  </span>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-md-4 col-sm-12 mt-2">
                  <div className="net-amount">
                    <h1> ESTIMATE AMOUNT</h1>
                    <h2>
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "INR",
                      }).format(Number(dEstValue).toFixed(2))}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <ReactTable
                  columns={Column}
                  data={objFCDTList}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {}}
                  background={true}
                  className="full-table"
                />
              </div>
            </div>
            {IsRemarkOpen && (
              <ServiceRemark
                Close={ServiceRemarkPopupCLose}
                strRemark={strRemark}
                onChangeEvent={onServiceRemarkChange}
                strRemarkType={strRemarkType}
              />
            )}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iBomId) === 0) {
                  AlertMessage("BOM is Required", "info");
                } else if (strForecastName === "") {
                  AlertMessage("Forcast Name Rquired", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Forecast ?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ForecastClick}>
              Forecast&nbsp;<i className="bx bxs-calculator"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iForecastId) === 0) {
                  AlertMessage("Please Select Forecast", "info");
                } else
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Forecast ?",
                      isdialog: true,
                    })
                  );
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() =>
                (window.location.href =
                  "/Dashboard")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forecast;
