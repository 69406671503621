import React, { useState, useEffect } from "react";
import ReactTable from "../General/ReactTable";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { userApp } from "../Admin/Utility";

function MasterCode(props) {
  const dispatch = useDispatch();
  const [MasterCodeList, setMasterCodeList] = useState([]);

  useEffect(() => {
    Load_MasterCodeformate("");
  }, [props]);

  const Load_MasterCodeformate = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objSearch = {
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await userApp
        .post(`ConfigurationSetting/Load_MasterCodeSetting/`, objSearch)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setMasterCodeList(Res.data.EntCode);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Save_CodeSetting = async () => {
    try {
      await dispatch(setload_Screen(true));
      let UpdateVoucherNumber = MasterCodeList;
      let CheckUserFormate = UpdateVoucherNumber.filter(
        (Data) => !Data.codeFormat.includes("#N")
      );
      if (CheckUserFormate.length > 0) AlertMessage("#N is Mandatory", "info");
      else {
        await userApp
          .post("ConfigurationSetting/Update_MasterCodeSetting/", {
            _MsCodeSett: UpdateVoucherNumber,
            strCompanyDB: localStorage.db,
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              AlertMessage(Res.data.strMessage, "success");
            } else AlertMessage(Res.data.strMessage, "error");
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_CodeSetting = () => {
    setMasterCodeList([]);
    Load_MasterCodeformate("");
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const VoucherColumn = [
    {
      Header: "Screenn Name",
      accessor: "scrName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Prefix",
      accessor: "prefix",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Max Digit",
      accessor: "numSeqMaxDigit",
      minWidth: 100,
      maxWidth: 100,
      cell: ({ rowinfo }) => (
        <input
          type="number"
          defaultValue={rowinfo.numSeqMaxDigit}
          className="form-control"
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.numSeqMaxDigit = e.target.value)}
        />
      ),
    },
    {
      Header: "Next Number",
      accessor: "nextNumber",
      minWidth: 100,
      maxWidth: 100,
      cell: ({ rowinfo }) => (
        <input
          type="number"
          defaultValue={rowinfo.nextNumber}
          className="form-control"
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.nextNumber = e.target.value)}
        />
      ),
    },
    {
      Header: "Code  Formate",
      accessor: "codeFormat",
      minWidth: 100,
      maxWidth: 100,
      cell: ({ rowinfo }) => (
        <input
          type="text"
          defaultValue={rowinfo.codeFormat}
          className="form-control"
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.codeFormat = e.target.value)}
        />
      ),
    },
    {
      Header: "Code",
      accessor: "code",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Manual No",
      minWidth: 70,
      maxWidth: 70,
      accessor: "isManualNumber",
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          className="form-check-input"
          type="checkbox"
          checked={rowinfo.isManualNumber}
          onChange={() => (rowinfo.isManualNumber = !rowinfo.isManualNumber)}
        />
      ),
    },
  ];
  return (
    <div className="popup-container">
      <div className="popup-content medium">
        <div className="popup-header">
          <h5>Master Code Settings</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <ReactTable
            columns={VoucherColumn}
            data={MasterCodeList}
            minRows={5}
            row_click={(rowInfo, Index) => {}}
            row_doubleclick={(rowInfo, Index) => {}}
            background={true}
            className="full-table"
          />
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={Save_CodeSetting}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={Clear_CodeSetting}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterCode;
