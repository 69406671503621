import React, { useEffect, useState } from "react";
import { userApp, userDomain } from "../Admin/Utility";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import $ from "jquery";
function PrintTemplateCopy(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [iScreenFrom, setiScreenFrom] = useState(0);
  const [iTempFor, setiTempFor] = useState(0);
  const [iScreenTo, setiScreenTo] = useState(0);
  const [iSourceTemId, setiSourceTemId] = useState(0);
  const [strTempName, setstrTempName] = useState("");
  const [ListScreenFrom, setListScreenFrom] = useState([]);
  const [ListTempFor, setListTempFor] = useState([]);
  const [ListSourceTemp, setListSourceTemp] = useState([]);
  const [ListScreenTo, setListScreenTo] = useState([]);
  const [IsTemplate, setIsTemplate] = useState(true);
  const [File, setFile] = useState([]);

  useEffect(() => {
    Load_PrintTemplates();
  }, []);
  const Load_PrintTemplates = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .get(
          "PrintMaster/Load_OriginalTemplate/" +
            localStorage.db +
            "/" +
            localStorage.Msdb
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setListScreenFrom(Res.data.EntScreen);
            setListScreenTo(Res.data.EntScreen);
            setListTempFor(Res.data.EntPrinterType);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_SourceTemplate = async (val, valfor) => {
    try {
      let Printer = iTempFor;
      let ScrId = iScreenFrom;
      switch (valfor) {
        case "Screen":
          setiScreenFrom(val);
          ScrId = val;
          break;
        case "PrinterType":
          setiTempFor(val);
          Printer = val;
          break;
        default:
          break;
      }
      await userApp
        .get(
          "PrintMaster/View_SourceTemplate/" +
            ScrId +
            "/" +
            Printer +
            "/" +
            localStorage.db
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setListSourceTemp(Res.data.EntTemplate);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "info");
    }
  };
  const Copy_PrintTemplate = async () => {
    try {
      await dispatch(setload_Screen(true));
      try {
        const objSearch = {
          strSearch: strTempName,
          strCompanyDB: localStorage.db,
        };
        await userApp
          .post("PrintMaster/CheckTemplateExist", objSearch)
          .then(async (Res) => {
            if (Res.data.strStatus === "Success") {
              const objCopyTem = {
                iFromScrId: Number(iScreenFrom),
                iToScrId: Number(iScreenTo),
                iPrintTypeId: Number(iTempFor),
                iSourceTempId: Number(iSourceTemId),
                strNewTempName: strTempName,
                strCompanyDB: localStorage.db,
              };
              await userApp
                .post("PrintMaster/Copy_PrintTemplate/", objCopyTem)
                .then((res1) => {
                  if (res1.data.strStatus === "Success") {
                    AlertMessage(res1.data.strMessage, "success");
                    Clear_Template();
                  } else {
                    AlertMessage(res1.data.strMessage, "info");
                  }
                });
            } else {
              AlertMessage(Res.data.strMessage, "info");
            }
          });
      } catch (err) {
        AlertMessage(err.message, "info");
      }
    } catch (err) {
      AlertMessage(err.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Create_Template = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objSearch = {
        strSearch: strTempName,
        strCompanyDB: localStorage.db,
      };
      await userApp
        .post("PrintMaster/CheckTemplateExist", objSearch)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            const formdata = new FormData();
            formdata.append("FileName", strTempName);
            formdata.append("File", File);
            formdata.append("strDBName", localStorage.db);
            await userApp
              .post("PrintMaster/Create_ScripttoPrintTemlate", formdata, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(async (res) => {
                if (res.data.strStatus === "Success") {
                  AlertMessage(res.data.strMessage, "success");
                  Clear_Script();
                } else {
                  AlertMessage(res.data.strMessage, "error");
                }
              });
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Generate_Script = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        ScrId: Number(iScreenFrom),
        ToScrId: Number(iScreenTo),
        PrinterType: Number(iTempFor),
        TemplateId: Number(iSourceTemId),
        TemplateName: strTempName,
        strCompanyDB: localStorage.db,
      };
      await fetch(`${userDomain}PrintMaster/Generate_PrintTemlate_Script`, {
        method: "POST",
        body: JSON.stringify(parems),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.AuthKey}`, // Add the token here
        },
      })
        .then((res) => res.blob())
        .then((res) => {
          const url = window.URL.createObjectURL(res);
          const link = document.createElement("a");
          link.href = url;
          const FileName = `${strTempName}_.zip`;
          link.setAttribute("download", FileName);
          link.click();
          Clear_Template();
        })
        .catch(async (err) => {
          AlertMessage(err.stack, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Clear_Template = () => {
    setiScreenFrom(0);
    setiScreenTo(0);
    setstrTempName("");
    setiTempFor(0);
    setiSourceTemId(0);
    setstrTempName("");
    setFile([]);
    Load_PrintTemplates();
  };
  const Clear_Script = () => {
    var file = document.getElementById("file-upload");
    file.value = "";
    setFile([]);
    setiScreenFrom(0);
    setiScreenTo(0);
    setstrTempName("");
    setiTempFor(0);
    setiSourceTemId(0);
    setFile([]);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Activatebtn = () => {
    const defaultBtn = document.getElementById("file-upload");
    defaultBtn.click();
    defaultBtn.addEventListener("change", function (e) {
      setFile(e.target.files[0]);
      setstrTempName(e.target.files[0].name.replace(".sql", ""));
    });
  };
  const tab_click = (tabindex) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    setIsTemplate(tabindex === 0 ? true : false);
    setiScreenFrom(0);
    setiScreenTo(0);
    setstrTempName("");
    setiTempFor(0);
    setiSourceTemId(0);
    setstrTempName("");
    setFile([]);
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Print Template Copy</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row p-0">
            <div className="trans-tab mb-1">
              <div className="trans-buttons">
                <label onClick={(e) => tab_click(0)} className="active">
                  Template
                </label>
                <label onClick={(e) => tab_click(1)}>Script</label>
                <div id="tab-line"></div>
              </div>
            </div>
            {IsTemplate && (
              <>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label className="mb-1">Screen From</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow16"
                    showoption={_parem.isoptionShow16}
                    placeholder="Select Screen"
                    select_value={(val) =>
                      View_SourceTemplate(Number(val), "Screen")
                    }
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iScreenFrom}
                    displayName="strScrName"
                    disvalue="iScrId"
                    arrydata={ListScreenFrom}
                    EmptVal="Screen Name"
                  />
                </div>
                <div className="col-md-6  col-sm-12 mb-1">
                  <label className="mb-1">Screen To</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow18"
                    showoption={_parem.isoptionShow18}
                    placeholder="Select Screen"
                    select_value={(val) => setiScreenTo(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iScreenTo}
                    displayName="strScrName"
                    disvalue="iScrId"
                    arrydata={ListScreenTo}
                    EmptVal="Screen Name"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label className="mb-1">Printer Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow17"
                    showoption={_parem.isoptionShow17}
                    placeholder="Select Printer Type"
                    select_value={(val) =>
                      View_SourceTemplate(Number(val), "PrinterType")
                    }
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iTempFor}
                    displayName="strPrinterTypeName"
                    disvalue="iPrinterTypeId"
                    arrydata={ListTempFor}
                    EmptVal="Printer Type"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label className="mb-1">Source Template</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow19"
                    showoption={_parem.isoptionShow19}
                    placeholder="Select Template"
                    select_value={(val) => setiSourceTemId(Number(val))}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iSourceTemId}
                    displayName="strPrintTempName"
                    disvalue="iPrintTempId"
                    arrydata={ListSourceTemp}
                    EmptVal="Template Name"
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>Template Name</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Template Name"
                    value={strTempName}
                    onChange={(e) => setstrTempName(e.target.value)}
                  />
                </div>
              </>
            )}
            {!IsTemplate && (
              <>
                <div id="FileUpload">
                  <div className="filewrapper">
                    <div className="uploadfile">
                      <i className="bx bxs-folder"></i>
                      <span
                        className="upload__button"
                        onClick={(e) => Activatebtn()}
                      >
                        Browse file
                      </span>
                      <input
                        type="file"
                        id="file-upload"
                        accept=".sql"
                        hidden
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mt-2">
                  <label>Template Name</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Template Name"
                    value={strTempName}
                    onChange={(e) => setstrTempName(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {IsTemplate && (
              <>
                <button
                  className="btn-fabgreen"
                  onClick={(e) => {
                    if (Number(iScreenFrom) === 0) {
                      AlertMessage("Please Select Screen From", "info");
                    } else if (Number(iTempFor) === 0) {
                      AlertMessage("Please Select Printer Type", "info");
                    } else if (Number(iScreenTo) === 0) {
                      AlertMessage("Please Select Screen To", "info");
                    } else if (Number(iSourceTemId) === 0) {
                      AlertMessage("Please Select Source Template", "info");
                    } else if (strTempName.length === 0) {
                      AlertMessage("Please Enter Template Name", "info");
                    } else {
                      dispatch(
                        Show_MsgBox({
                          Button: Copy_PrintTemplate,
                          Question: "Do You Want to Copy Template?",
                          isdialog: true,
                        })
                      );
                    }
                  }}
                >
                  Save &nbsp; <i className="fas fa-save"></i>
                </button>
                <button
                  className="btn-fabgreen"
                  onClick={(e) => Clear_Template()}
                >
                  Clear&nbsp;<i className="fa-solid fa-broom"></i>
                </button>
                <button
                  className="btn-fabgreen"
                  onClick={(e) => {
                    if (Number(iScreenFrom) === 0) {
                      AlertMessage("Please Select Screen From", "info");
                    } else if (Number(iTempFor) === 0) {
                      AlertMessage("Please Select Printer Type", "info");
                    } else if (Number(iScreenTo) === 0) {
                      AlertMessage("Please Select Screen To", "info");
                    } else if (Number(iSourceTemId) === 0) {
                      AlertMessage("Please Select Source Template", "info");
                    } else if (strTempName.length === 0) {
                      AlertMessage("Please Enter Template Name", "info");
                    } else {
                      dispatch(
                        Show_MsgBox({
                          Button: Generate_Script,
                          Question: "Do You Want to Generate Script?",
                          isdialog: true,
                        })
                      );
                    }
                  }}
                >
                  Download &nbsp; <i className="bx bx-cloud-download"></i>
                </button>
              </>
            )}
            {!IsTemplate && (
              <>
                <button
                  className="btn-fabgreen"
                  onClick={(e) => {
                    if (File.length === 0)
                      AlertMessage("Please Select Ptint Script", "info");
                    if (strTempName.length === 0)
                      AlertMessage("Please Select Ptint Script", "info");
                    else
                      dispatch(
                        Show_MsgBox({
                          Button: Create_Template,
                          Question: "Do You Want to Create Template?",
                          isdialog: true,
                        })
                      );
                  }}
                >
                  Create Template &nbsp;
                  <i className="bx bx-cloud-download"></i>
                </button>
                <button
                  className="btn-fabgreen"
                  onClick={(e) => Clear_Script()}
                >
                  Clear&nbsp;<i className="fa-solid fa-broom"></i>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintTemplateCopy;
