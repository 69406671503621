import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import ProductSearch from "../Transaction/ProductSearch";
import $ from "jquery";
import { userApp } from "../Admin/Utility";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import VouchernoSetting from "../Setting/VouchernoSetting";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import ReactTable from "../General/ReactTable";
import {
  AddStockProduct,
  Clear_Transaction,
  Modify_Product,
  ProductInput_Onchange,
  Select_Unit,
  ShowHide_Popup,
  Update_BtnPermission,
  setStockTrans,
} from "../ReduxStore/Transactionstates";
import TransactionSummary from "../Transaction/TransactionSummary";
import TransactionView from "../Transaction/TransactionView";
import ProductAttributeView from "../Master/ProductAttributeView";
function StockAdjustment() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _Common = useSelector((store) => store.states.Common);
  const _Product = useSelector((store) => store.states.Product);
  const _HT = useSelector((store) => store.states.Header);
  const _DT = useSelector((store) => store.states.Data);
  const _Summary = useSelector((store) => store.states.Summary);
  const iScrId = 38;

  const [ManualCode, setManualCode] = useState(false);
  const [strTransNo, setstrTransNo] = useState("");
  const [strVouchNo, setstrVouchNo] = useState("");
  const [strVouchDate, setstrVouchDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iStoAdjId, setiStoAdjId] = useState(0);
  const [strDescription, setstrDescription] = useState("");
  const [arrNoFormat, setarrNoFormat] = useState([0]);
  const [arrEntValidation, setarrEntValidation] = useState([]);
  const [arrtabOrder, setarrtabOrder] = useState({
    txtTransNo: "",
    txtStoAdjNo: "",
    dpStoAdjDate: "",
    txtDescription: "",
    btnNew: "",
    btnModify: "",
    btnSave: "",
    btnClear: "",
    btnView: "",
    btnExportImport: "",
    btnPrint: "",
    btnCancel: "",
    btnClose: "",
    btnPrintSettings: "",
    btnHelp: "",
    TopCenterVoucher: "",
    TopRightDelivery: "",
    MiddleRightSummary: "",
    ProductList: "",
    txtProductName: "",
    txtQty: "",
    cbUnit: "",
    txtRate: "",
    txtReason: "",
    btnAdd: "",
  });
  useEffect(() => {
    Load_StockAdjustment();
  }, []);

  const Load_StockAdjustment = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .get(
          `StockAdjustment/Load_StockAdjustment/${iScrId}/${localStorage.db}`
        )
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            const NoFormat = Res.data.NoFormat[0];
            setarrNoFormat(NoFormat);
            setManualCode(NoFormat.isManualNumber);
            setstrTransNo(NoFormat.strTransNo);
            setstrVouchNo(NoFormat.strUserNo);
            setarrEntValidation(Res.data.Transtab);
            TabOrderAssign(Res.data.Transtab);
            await dispatch(Update_BtnPermission(Validate_BtnPermission()));
            await dispatch(
              setStockTrans({
                iScrId: iScrId,
                strScrType: "Purchase",
                strScrName: "Stock Adjustment",
              })
            );
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      if (_DT.arrEntDT.length === 0) {
        AlertMessage("Atleast One Item is Required per Bill", "info");
      } else {
        const StoAdjHT = {
          iTitleTag: iScrId,
          strTransNo: strTransNo,
          iNumSeqNo: arrNoFormat.iNumSeqNo,
          strPrefix: arrNoFormat.strPrefix,
          strCompCode: arrNoFormat.strCompCode,
          strFinanFrom: arrNoFormat.strFinanFrom,
          iStoAdjId: iStoAdjId,
          strVouchNo: strVouchNo,
          strVouchDate: strVouchDate,
          strDescription: strDescription,
          dTQty: Number(_Summary.Qty),
          dTGoodsValue: Number(_Summary.GoodsValue),
          dRoundOff: Number(_Summary.RoundOff),
          dNetAmt: Number(_Summary.NetAmt),
        };
        await userApp
          .post("StockAdjustment/InsUpd_Adjustment/", {
            objEntItem: StoAdjHT,
            objBaseTransDT: _DT.arrEntDT,
            strCompanyDB: localStorage.db,
            iUserId: Number(localStorage.UserId),
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              Clear_Click();
              AlertMessage(Res.data.strMessage, "success");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Voucher = async (Vouch) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .get(`StockAdjustment/View_Voucher/${Vouch.vouchId}/${localStorage.db}`)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            const EntHT = Res.data.EntHT[0];
            setiStoAdjId(Vouch.vouchId);
            setstrTransNo(EntHT.strTransNo);
            setstrVouchNo(EntHT.strVouchNo);
            setstrVouchDate(EntHT.strVouchDate);
            setstrDescription(EntHT.strDescription);
            await dispatch(AddStockProduct(Res.data));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Delete_Voucher = async () => {
    try {
      await dispatch(setload_Screen(true));
      if (iStoAdjId === 0) {
        AlertMessage("Please Select Voucher to Delete!", "error");
      } else {
        userApp
          .post(
            "StockAdjustment/Delete_Adjustment/" +
              iStoAdjId +
              "/" +
              localStorage.db
          )
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              Clear_Click();
              AlertMessage(Res.data.strMessage, "success");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Click = async () => {
    setManualCode("");
    setstrTransNo("");
    setstrVouchNo("");
    setstrVouchDate(new Date().toISOString().split("T")[0]);
    setiStoAdjId(0);
    setstrDescription("");
    setarrNoFormat([]);
    setarrEntValidation([]);
    await dispatch(Clear_Transaction());
    await Load_StockAdjustment();
  };
  const Add_Product = async () => {
    try {
      await dispatch(setload_Screen(true));
      if (Number(_Product.iProId) === 0) {
        AlertMessage("Please select Product", "info");
      } else if (Number(_Product.dQty) === 0) {
        AlertMessage("Minimum Qty is Required", "info");
      } else {
        await userApp
          .post(`Transaction/Add_StockProduct/`, {
            bUpdateStatus: _Product.bUpdateStatus,
            iRowIndex: _Product.iRowIndex,
            iProId: _Product.iProId,
            dQty: Number(_Product.dQty),
            iUnitId: Number(_Product.iUnitId),
            dPriceRate: Number(_Product.dRate),
            strProAttDTId: _Product.strProAttDTId,
            strStoAttDTId: _Product.strStoAttDTId,
            strTraAttDTId: _Product.strTraAttDTId,
            strReason: _Product.strReason,
            objItemRemark: _Product.Remarks,
            objBaseTransDT: _DT.arrEntDT,
            objRootCalc: _Product.Calculation,
            objEntAddLess: [],
            objEntConRow: [],
            objEntTax: [],
            objPenCon: [],
            strColumn: "",
            strJobId: "",
            strSearch: "",
            strSetCurrentStatus: "Yes",
            iYearId: Number(localStorage.FYId),
            strCompanyDB: localStorage.db,
            strMasterDB: localStorage.Msdb,
            iUserId: Number(localStorage.UserId),
          })
          .then(async (Res) => {
            if (Res.data.strStatus === "Success") {
              await dispatch(AddStockProduct(Res.data));
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Remove_tableProduct = async (Index) => {
    try {
      await dispatch(setload_Screen(true));
      userApp
        .post(`Transaction/Delete_StockProduct/`, {
          bUpdateStatus: _Product.bUpdateStatus,
          iRowIndex: Index,
          iProId: _Product.iProId,
          dQty: Number(_Product.dQty),
          iUnitId: Number(_Product.iUnitId),
          dPriceRate: Number(_Product.dRate),
          strProAttDTId: _Product.strProAttDTId,
          strStoAttDTId: _Product.strStoAttDTId,
          strTraAttDTId: _Product.strTraAttDTId,
          strReason: _Product.strReason,
          objItemRemark: _Product.Remarks,
          objBaseTransDT: _DT.arrEntDT,
          objRootCalc: _Product.Calculation,
          objEntAddLess: [],
          objEntConRow: [],
          objEntTax: [],
          objPenCon: [],
          strColumn: "",
          strJobId: "",
          strSearch: "",
          strSetCurrentStatus: "Yes",
          iYearId: Number(localStorage.FYId),
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
          iUserId: Number(localStorage.UserId),
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            dispatch(AddStockProduct(Res.data));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_ProductUnit = async (ProInfo, Index) => {
    try {
      dispatch(setload_Screen(true));
      userApp
        .get(
          `Transaction/View_ProductUnit/${ProInfo.proId}/${_Common.strScrType}/${localStorage.db}`
        )
        .then((res) => {
          if (res.data.strStatus === "Success")
            dispatch(
              Modify_Product({
                ProInfo: ProInfo,
                Unit: res.data.objUnit,
                Index: Index,
              })
            );
          else AlertMessage(res.data.strMessage, "error");
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Input_Onchange = (e) => {
    dispatch(
      ProductInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Unit_Onchange = (UnitId) => {
    userApp
      .get(
        "Transaction/Unit_Onchange/" +
          Number(UnitId) +
          "/" +
          _Product.iProId +
          "/" +
          _HT.iPartyId +
          "/" +
          _HT.iTaxRelId +
          "/" +
          _Common.strScrType +
          "/" +
          localStorage.db
      )
      .then((res) => {
        if (res.data.strStatus === "Success") {
          dispatch(
            Select_Unit({ dPriceRate: res.data.dPriceRate, UnitId: UnitId })
          );
        } else {
          AlertMessage(res.data.message, "error");
        }
      });
  };
  const TransColum = [
    {
      Header: "SNo",
      accessor: "sNo",
      style: { justifyContent: "center" },
      minWidth: 50,
      maxWidth: 50,
    },
    {
      Header: "Product Name",
      accessor: "proName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Qty",
      accessor: "qty",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Rate",
      accessor: "rateWithoutTax",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Goods Value",
      accessor: "goodsValue",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Amount",
      accessor: "itemAmt",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Reason",
      accessor: "reason",
      style: { justifyContent: "left" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Delete",
      minWidth: 70,
      maxWidth: 70,
      cell: ({ rowinfo }) => (
        <i
          onClick={() => Remove_tableProduct(rowinfo.sNo)}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const Validate_BtnPermission = () => {
    let Menu = JSON.parse(localStorage.Menu);
    Menu = Menu.objEntPPM.filter((Data) => Data.iScrId === iScrId)[0];

    const BtnPerm = {
      btnSave: !Menu.isNew,
      btnView: !Menu.isView,
      btnDelete: !Menu.isCancel,
      btnPrint: true,
      btnConvert: true,
    };
    if (iStoAdjId > 0) {
      BtnPerm.btnSave = !Menu.isModify;
    }
    return BtnPerm;
  };
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = arrtabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtTransNo":
          TabOrder.txtTransNo = EntProperties[i].iTabIndex;
          break;
        case "txtStoAdjNo":
          TabOrder.txtStoAdjNo = EntProperties[i].iTabIndex;
          break;
        case "dpStoAdjDate":
          TabOrder.dpStoAdjDate = EntProperties[i].iTabIndex;
          break;
        case "txtDescription":
          TabOrder.txtDescription = EntProperties[i].iTabIndex;
          break;
        case "btnNew":
          TabOrder.btnNew = EntProperties[i].iTabIndex;
          break;
        case "btnModify":
          TabOrder.btnModify = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder.btnSave = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder.btnClear = EntProperties[i].iTabIndex;
          break;
        case "btnView":
          TabOrder.btnView = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder.btnExportImport = EntProperties[i].iTabIndex;
          break;
        case "btnPrint":
          TabOrder.btnPrint = EntProperties[i].iTabIndex;
          break;
        case "btnCancel":
          TabOrder.btnCancel = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder.btnClose = EntProperties[i].iTabIndex;
          break;
        case "btnPrintSettings":
          TabOrder.btnPrintSettings = EntProperties[i].iTabIndex;
          break;
        case "btnHelp":
          TabOrder.btnHelp = EntProperties[i].iTabIndex;
          break;
        case "TopCenterVoucher":
          TabOrder.TopCenterVoucher = EntProperties[i].iTabIndex;
          break;
        case "TopRightDelivery":
          TabOrder.TopRightDelivery = EntProperties[i].iTabIndex;
          break;
        case "MiddleRightSummary":
          TabOrder.MiddleRightSummary = EntProperties[i].iTabIndex;
          break;
        case "lbProduct":
          TabOrder.ProductList = EntProperties[i].iTabIndex;
          break;
        case "txtProductName":
          TabOrder.txtProductName = EntProperties[i].iTabIndex;
          break;
        case "txtQty":
          TabOrder.txtQty = EntProperties[i].iTabIndex;
          break;
        case "cbUnit":
          TabOrder.cbUnit = EntProperties[i].iTabIndex;
          break;
        case "txtRate":
          TabOrder.txtRate = EntProperties[i].iTabIndex;
          break;
        case "txtReason":
          TabOrder.txtReason = EntProperties[i].iTabIndex;
          break;
        case "btnAdd":
          TabOrder.btnAdd = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setarrtabOrder(TabOrder);
  };
  const KeyUpDown = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === arrtabOrder.ProductList &&
          _DT.arrEntDT.length > 0
        ) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (
          ActiveIndex + 1 === arrtabOrder.ProductList &&
          _DT.arrEntDT.length > 0
        ) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (
          ActiveIndex - 1 === arrtabOrder.ProductList &&
          _DT.arrEntDT.length > 0
        ) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === arrtabOrder.ProductList && _DT.arrEntDT.length > 0) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const Validate_Properties = () => {
    var ErrorMsg = true;
    let ValidateItem = arrEntValidation.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtStoAdjNo":
          ErrorMsg = strVouchNo === "" ? false : true;
          break;
        case "txtTransNo":
          ErrorMsg = strTransNo === "" ? false : true;
          break;
        case "txtDescription":
          ErrorMsg = strDescription === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };

  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Trans-container">
        <div className="Trans-card">
          <div className="Trans-wrapper">
            <div className="fg-card shadow-base">
              <div className="row p-2">
                <div className="col-md-3 col-sm-12 mt-1">
                  <label>
                    Vocher No <span className="danger">*</span>
                  </label>
                  <div className="sinv-no">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Voucher Number"
                      value={strVouchNo}
                      id="txtVoucherNo"
                      name="strVouchNo"
                      spellCheck={false}
                      readOnly={!ManualCode}
                      onChange={(e) => setstrVouchNo(e.target.value)}
                      tabIndex={arrtabOrder.txtStoAdjNo}
                      onKeyDown={(e) => KeyUpDown(e)}
                    />
                    <span
                      onClick={(e) => dispatch(ShowHide_Popup("IsVouchNo"))}
                    >
                      <i className="bx bx-cog"></i>
                    </span>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 mt-1">
                  <label>
                    Trans No <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtTransNo"
                    name="strTransNo"
                    readOnly
                    value={strTransNo}
                    onChange={(e) => setstrTransNo(e.target.value)}
                    tabIndex={arrtabOrder.txtTransNo}
                    onKeyDown={(e) => KeyUpDown(e)}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <label>
                    Date <span className="danger">*</span>
                  </label>
                  <input
                    type="date"
                    name="strVouchDate"
                    className="form-control"
                    value={strVouchDate}
                    onChange={(e) => setstrVouchDate(e.target.value)}
                    tabIndex={arrtabOrder.dpStoAdjDate}
                    onKeyDown={(e) => KeyUpDown(e)}
                  />
                </div>
                <div className="col-md-3 col-sm-12 mt-1">
                  <label>Description</label>
                  <textarea
                    className="form-control rounded"
                    aria-label="With textarea"
                    placeholder="Description"
                    style={{ height: "90px" }}
                    id="strDescription"
                    value={strDescription}
                    onChange={(e) => setstrDescription(e.target.value)}
                    tabIndex={arrtabOrder.txtDescription}
                    onKeyDown={(e) => KeyUpDown(e)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="fg-card shadow-base">
              <div className="row pl-2 pr-2 pb-2">
                <div className="col-md-3 col-sm-12 mt-2">
                  <label>Product Name</label>
                  <div className="custom-select">
                    <input
                      type="text"
                      className="form-control"
                      id="strProName"
                      name="strProName"
                      autoComplete="off"
                      placeholder="Search Product"
                      defaultValue={_Product.strProName}
                      tabIndex={arrtabOrder.txtProductName}
                      onKeyDown={(e) => {
                        if (e.key === "Enter")
                          dispatch(ShowHide_Popup("IsProSearch"));
                      }}
                      onClick={() => dispatch(ShowHide_Popup("IsProSearch"))}
                    />
                    <i
                      className="fa-solid fa-caret-down"
                      style={{
                        position: "absolute",
                        right: "10px",
                        color: "darkgreen",
                        transform: _Common.IsProSearch
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                      }}
                    ></i>
                  </div>
                </div>
                <div className="col-md-1 col-sm-12 mt-2">
                  <label>Qty</label>
                  <input
                    type="number"
                    className="form-control"
                    name="dQty"
                    value={_Product.dQty}
                    onChange={(e) => Input_Onchange(e)}
                    onClick={(e) => e.target.select()}
                    tabIndex={arrtabOrder.txtQty}
                    onKeyDown={(e) => KeyUpDown(e)}
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-2 col-sm-12 mt-2">
                  <label>Unit</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow3"
                    showoption={_selecrparem.isoptionShow3}
                    placeholder="Select Unit"
                    select_value={(val) => Unit_Onchange(val)}
                    btnname="Unit"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={_Product.iUnitId}
                    displayName="strUnitName"
                    disvalue="iUnitId"
                    arrydata={_Product.Unit}
                    disabled={_Product.iProId > 0 ? false : true}
                    EmptVal="Unit"
                  />
                </div>
                <div className="col-md-2 col-sm-12 mt-2">
                  <label>Rate</label>
                  <input
                    type="number"
                    className="form-control"
                    name="dRate"
                    value={_Product.dRate}
                    onChange={(e) => Input_Onchange(e)}
                    onClick={(e) => e.target.select()}
                    tabIndex={arrtabOrder.txtRate}
                    onKeyDown={(e) => KeyUpDown(e)}
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-3 col-sm-12 mt-2">
                  <label>Reason</label>
                  <input
                    type="text"
                    className="form-control"
                    name="strReason"
                    value={_Product.strReason}
                    onChange={(e) => Input_Onchange(e)}
                    onClick={(e) => e.target.select()}
                    placeholder="Enter Adjustment Reason"
                    tabIndex={arrtabOrder.txtReason}
                    onKeyDown={(e) => KeyUpDown(e)}
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-1 col-sm-12 mt-4">
                  <div className="btn-sub-section">
                    <button
                      className="btn-fabgreen"
                      onClick={Add_Product}
                      tabIndex={arrtabOrder.btnAdd}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") Add_Product();
                        else KeyUpDown(e);
                      }}
                    >
                      Add&nbsp;<i className="bx bx-layer-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <ReactTable
                columns={TransColum}
                data={_DT.arrEntDT}
                minRows={5}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {
                  View_ProductUnit(rowInfo, Index);
                }}
                background={true}
                className="full-table"
              />
            </div>
            <TransactionSummary />
            {_Common.IsTransView && (
              <TransactionView View_Voucher={View_Voucher} />
            )}
            {_Common.IsProSearch && <ProductSearch />}
            {_Common.IsAttSearch && <ProductAttributeView />}
            {_Common.IsVouchNo && <VouchernoSetting />}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              disabled={_Common.btnSave}
              className="btn-fabgreen"
              tabIndex={arrtabOrder.btnSave}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  if (await Validate_Properties()) {
                    dispatch(
                      Show_MsgBox({
                        Button: SaveClick,
                        Question: `Do You Want to ${
                          iStoAdjId > 0 ? "Update" : "Save"
                        } Stock Adjustment?`,
                        isdialog: true,
                      })
                    );
                  }
                } else KeyUpDown(e);
              }}
              onClick={async () => {
                if (await Validate_Properties()) {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: `Do You Want to ${
                        iStoAdjId > 0 ? "Update" : "Save"
                      } Stock Adjustment?`,
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="fas fa-save"></i>
            </button>
            <button
              disabled={_Common.btnView}
              className="btn-fabgreen"
              onClick={() => dispatch(ShowHide_Popup("IsTransView"))}
              tabIndex={arrtabOrder.btnView}
              onKeyDown={(e) => {
                if (e.key === "Enter") dispatch(ShowHide_Popup("IsTransView"));
                else KeyUpDown(e);
              }}
            >
              View &nbsp;<i className="fas fa-eye"></i>
            </button>
            <button
              disabled={_Common.btnDelete}
              className="btn-fabgreen"
              tabIndex={arrtabOrder.btnCancel}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  if (iStoAdjId === 0) {
                    AlertMessage("Please Select Voucher to Delete!", "error");
                  } else {
                    await dispatch(
                      Show_MsgBox({
                        Button: Delete_Voucher,
                        Question: `Do You Want to Delete Stock Adjustment?`,
                        isdialog: true,
                      })
                    );
                  }
                } else KeyUpDown(e);
              }}
              onClick={async () => {
                if (iStoAdjId === 0) {
                  AlertMessage("Please Select Voucher to Delete!", "error");
                } else {
                  await dispatch(
                    Show_MsgBox({
                      Button: Delete_Voucher,
                      Question: `Do You Want to Delete Stock Adjustment?`,
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => Clear_Click()}
              tabIndex={arrtabOrder.btnClear}
              onKeyDown={(e) => {
                if (e.key === "Enter") Clear_Click();
                else KeyUpDown(e);
              }}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex={arrtabOrder.btnClose}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sessionStorage.removeItem("VoucherId");
                  sessionStorage.removeItem("VoucherType");
                  window.location.href = "/Dashboard";
                } else KeyUpDown(e);
              }}
              onClick={() => {
                sessionStorage.removeItem("VoucherId");
                sessionStorage.removeItem("VoucherType");
                window.location.href = "/Dashboard";
              }}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default StockAdjustment;
