import React, { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import ReactTable from "../General/ReactTable";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
function PriceMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [iTaxRelId, setiTaxRelId] = useState(1);
  const [iPrgId, setiPrgId] = useState(1);
  const [strSearch, setstrSearch] = useState("");
  const [colName, setcolName] = useState("Product");

  const [ListTaxRel, setListTaxRel] = useState([]);
  const [ListPrgGrp, setListPrgGrp] = useState([]);
  const [ListEntItem, setListEntItem] = useState([]);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [isProduct, setisProduct] = useState(true);

  useEffect(() => {
    tab_click("Product", 0);
  }, []);
  const tab_click = (Button, tabindex) => {
    setcolName(Button);
    Load_PriceMaster(Button);
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    setisProduct(tabindex === 0 ? true : false);
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
  };
  const Load_PriceMaster = async (TabId) => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: 0,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
        TabType: TabId,
        iTaxRelId: Number(iTaxRelId),
      };
      await userApp.post(`PriceMaster/Load_PriceMaster`, objParems).then(
        (res) => {
          if (res.data.strStatus === "success") {
            setListTaxRel(res.data.EntTax);
            setListPrgGrp(res.data.EntPricGrp);
            setListEntItem(res.data.EntItem);
            ButtonPermission(JSON.parse(localStorage.Menu));
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Update_PriceMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParem = {
        EntProduct: colName === "Product" ? ListEntItem : [],
        EntJobWork: colName === "JobWork" ? ListEntItem : [],
        iTaxRelId: iTaxRelId,
        iPGId: Number(iPrgId),
        TabItem: colName,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(
         "PriceMaster/Update_PriceMaster/",
        objParem
      ).then((res) => {
        if (res.data.strStatus === "success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    setiPrgId(1);
    setiTaxRelId(1);
    setBtnSaveDisable(false);
    Load_PriceMaster(colName);
  };
  const Search_Product = async (strtxt) => {
    try {
      await setstrSearch(strtxt);
      await userApp.post( "PriceMaster/Search_PriceMaster/", {
        strSearchText: strtxt,
        Itemtype: colName,
        iPRGId: Number(iPrgId),
        iTaxRelId: iTaxRelId,
        strCompanyDB: localStorage.db,
      }).then((res) => {
        if (res.data.strStatus === "success") {
          setListEntItem(res.data.EntItem);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const View_PriceGroupRate = (iPriceGrpId) => {
    try {
      setiPrgId(iPriceGrpId);
      userApp.get(
        `PriceMaster/View_PriceGroupRate/${Number(
          iPriceGrpId
        )}/${iTaxRelId}/${colName}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "success") {
          setListEntItem(res.data.EntItem);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxType_OnChange = (iTaxLvId) => {
    try {
      setiTaxRelId(iTaxLvId);
      userApp.get(
        `PriceMaster/View_PriceGroupRate/${iPrgId}/${iTaxLvId}/${colName}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "success") {
          setListEntItem(res.data.EntItem);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Rate_OnChange = async (Item, Rate, RateType) => {
    try {
      const objParem = {
        ColName: colName,
        RateType: RateType,
        Rate: Rate,
        iProId: Item.proId ? Item.proId : 0,
        iUnitId: Item.unitId ? Item.unitId : 0,
        iJobId: Item.jobId ? Item.jobId : 0,
        EntProduct: Item.proId ? ListEntItem : [],
        EntJobWork: Item.jobId ? ListEntItem : [],
      };
      await userApp.post( `PriceMaster/Rate_Calculation`, objParem).then(
        (res) => {
          if (res.data.strStatus === "success")
            setListEntItem(res.data.EntItem);
          else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Productcolumns = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "proName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Attributes",
      accessor: "attValues",
      style: { justifyContent: "left" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Unit",
      accessor: "unitName",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "Purchase Rate",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
      accessor: "purRateWithoutTax",
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.purRateWithoutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) => {
            Rate_OnChange(rowinfo, e.target.value, "PurWithoutTax");
          }}
        />
      ),
    },
    {
      Header: "Purchase Rate W/Tax",
      accessor: "purRateWithTax",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.purRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) => Rate_OnChange(rowinfo, e.target.value, "PurWithTax")}
        />
      ),
    },
    {
      Header: "Sales Rate",
      accessor: "salRateWithoutTax",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.salRateWithoutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "SalWithoutTax")
          }
        />
      ),
    },
    {
      Header: "Sales Rate W/Tax",
      accessor: "salRateWithTax",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.salRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) => Rate_OnChange(rowinfo, e.target.value, "SalWithTax")}
        />
      ),
    },
    {
      Header: "Tax",
      accessor: "taxPer",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
  ];
  const JobWorkcolumns = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "jobName",
      id: "jobId",
      style: { justifyContent: "left" },
    },
    {
      Header: "In Rate",
      accessor: "inRateWithOutTax",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.inRateWithOutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "InRateWithOutTax")
          }
        />
      ),
    },
    {
      Header: "In Rate W/Tax",
      accessor: "inRateWithTax",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.inRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "InRateWithTax")
          }
        />
      ),
    },
    {
      Header: "Out Rate",
      accessor: "outRateWithOutTax",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.outRateWithOutTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "OutRateWithOutTax")
          }
        />
      ),
    },
    {
      Header: "OutRate W/Tax",
      accessor: "outRateWithTax",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.outRateWithTax}
          onClick={(e) => e.target.select()}
          onBlur={(e) =>
            Rate_OnChange(rowinfo, e.target.value, "OutRateWithTax")
          }
        />
      ),
    },
    {
      Header: "Tax",
      accessor: "taxPer",
      minWidth: 70,
      maxWidth: 70,
      style: { justifyContent: "center" },
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ButtonPermission = (ListMenu) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === 62);
    if (ListMenu.length > 0) {
      if (ListMenu[0].isModify === false) {
        setBtnSaveDisable(true);
      } else {
        setBtnSaveDisable(false);
      }
    }
  };

  return (
    <>
      <SideMenu ScrId={62} />
      <div className="Master-container">
        <div className="Master-card1">
          <div className="Master-wrapper">
            <div className="fg-card shadow-base">
              <div className="trans-tab">
                <div className="trans-buttons">
                  <label
                    onClick={(e) => tab_click("Product", 0)}
                    className="active"
                  >
                    Product
                  </label>
                  <label onClick={(e) => tab_click("JobWork", 1)}>
                    JobWork
                  </label>
                  <div id="tab-line"></div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-3 col-sm-6 mt-1">
                  <label>Tax Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_parem.isoptionShow}
                    placeholder="Select Relation"
                    select_value={(val) => TaxType_OnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iTaxRelId}
                    displayName="strTaxType"
                    disvalue="iTaxRelId"
                    arrydata={ListTaxRel}
                    EmptVal="Tax Relation "
                  />
                </div>
                <div className="col-md-3 col-sm-6 mt-1">
                  <label>Price Group</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_parem.isoptionShow1}
                    placeholder="Select Price Group"
                    select_value={(val) => View_PriceGroupRate(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iPrgId}
                    displayName="strPGrpName"
                    disvalue="iPGrpId"
                    arrydata={ListPrgGrp}
                    EmptVal="Price Group"
                    disabled={isProduct ? false : true}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mt-2">
                  <br />
                  <div className="m-hsearch">
                    <input
                      type="text"
                      autoCorrect="off"
                      autoComplete="off"
                      placeholder="Search Product"
                      value={strSearch}
                      onChange={(e) => Search_Product(e.target.value)}
                      autoFocus
                    />
                    <i
                      className="fas fa-search"
                      onClick={(e) => Search_Product(strSearch)}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <ReactTable
              columns={isProduct ? Productcolumns : JobWorkcolumns}
              data={ListEntItem}
              minRows={10}
              row_click={(ProInfo, Index) => {}}
              row_doubleclick={(ProInfo, Index) => {}}
              background={true}
              className="full-table"
              tabIndex=""
            />
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              disabled={BtnSaveDisable}
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                dispatch(
                  Show_MsgBox({
                    Button: Update_PriceMaster,
                    Question: "Do You Want to Update Price ?",
                    isdialog: true,
                  })
                );
              }}
            >
              Update Price&nbsp;<i className="fas fa-save"></i>
            </button>
            <button type="reset" className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              type="button"
              className="btn-fabgreen"
              onClick={() =>
                (window.location.href =
                  "/Dashboard")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default PriceMaster;
