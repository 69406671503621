import { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";

import { userApp} from "../Admin/Utility";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
function JournalView(props) {
  const dispatch = useDispatch();
  const [ListEntJournal, setListEntJournal] = useState([]);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  useEffect(() => {
    Load_JournalEntry(strToDate);
  }, []);
  const Load_JournalEntry = async (ToDate) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.get(
         "JournalEntry/Load_Journals/" + ToDate + "/" + localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListEntJournal(Res.data.EntJournal);
          setstrFromDate(Res.data.strFromDate);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Search_JournalEntry = async (FromDate, ToDate) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.get(
        
          "JournalEntry/Search_Journals/" +
          FromDate +
          "/" +
          ToDate +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListEntJournal(Res.data.EntJournal);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const DateOnChange = (Date, DateFor) => {
    if (DateFor === "FromDate") {
      setstrFromDate(Date);
      Search_JournalEntry(Date, strToDate);
    } else {
      setstrToDate(Date);
      Search_JournalEntry(strFromDate, Date);
    }
  };
  const LoadJEBill = (RowInfo) => {
    let VoucherDate = RowInfo.jeDate.split(" ");
    var SplitDate = VoucherDate[0].split("-");
    RowInfo.jeDate = SplitDate[2] + "-" + SplitDate[1] + "-" + SplitDate[0];
    props.JournalView(RowInfo.jeId);
  };
  const JEColumn = [
    {
      Header: "S.No",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Trans No",
      accessor: "transNo",
      minWidth: 200,
    },
    {
      Header: "Journal Voucher",
      id: "jeId",
      accessor: "jeNo",
      minWidth: 200,
      maxWidth: 200,
    },
    {
      Header: "Trans Date",
      accessor: "jeDate",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
    },
    {
      Header: "Debit",
      accessor: "dr",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Credit",
      accessor: "cr",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "right" },
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>JOURNAL VIEW</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => props.Close()}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-1">
              <label>From Date</label>
              <input
                type="date"
                aria-label="First name"
                className="form-control"
                value={strFromDate}
                onChange={(e) => DateOnChange(e.target.value, "FromDate")}
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-1">
              <label>To Date</label>
              <input
                type="date"
                aria-label="First name"
                className="form-control"
                value={strToDate}
                onChange={(e) => DateOnChange(e.target.value, "ToDate")}
              />
            </div>
          </div>
          <ReactTable
            columns={JEColumn}
            data={ListEntJournal}
            minRows={7}
            row_click={() => {}}
            row_doubleclick={(rowInfo) => {
              LoadJEBill(rowInfo);
            }}
            background={true}
            className="full-table"
          />
        </div>
      </div>
    </div>
  );
}
export default JournalView;
