import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_MsgBox } from "../ReduxStore/Message";
import { userApp} from "../Admin/Utility";

function ReportFilter(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [strMaxValue, setstrMaxValue] = useState(0);
  const [strMinValue, setstrMinValue] = useState(0);
  const [strFromDate, setstrFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [strToDate, setstrToDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iRptColId, setiRptColId] = useState(0);
  const [strFieldType, setstrFieldType] = useState("");
  const [MathSymbol, setMathSymbol] = useState(1);
  const [isDate, setisDate] = useState(false);
  const [isAmt, setisAmt] = useState(false);
  const [isTable, setisTable] = useState(false);
  const [isMinMaxVal, setisMinMaxVal] = useState(false);
  const [strSearch, setstrSearch] = useState("");

  const [arrRptCol, setarrRptCol] = useState([]);
  const [arrFiltCol, setarrFiltCol] = useState([]);
  const [arrRptData, setarrRptData] = useState([]);
  const [arrMathSymbol, setarrMathSymbol] = useState([]);

  useEffect(() => {
    Load_ReportFilter();
  }, []);
  const Load_ReportFilter = () => {
    try {
      userApp.get(
        `Reports/Load_ReportFilter/${Number(props.iRptId)}/${
          localStorage.db
        }`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrRptCol(Res.data.EntFilter);
          setarrMathSymbol(Res.data.EntMathSymbol);
          var FilderList = Res.data.EntFilter;
          if (props.iRptId === 1017) {
            FilderList = FilderList.map((Data) => {
              Data.strDataType = Data.strDataType.replace("\t", "");
              return Data;
            });
          }
          Load_FilterData(FilderList[0].iRptColId, FilderList[0].strDataType);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Update_Filter = async () => {
    try {
      let drChecked = "";
      let drUnChecked = "";
      if (strFieldType === "String") {
        drChecked = [];
        drUnChecked = [];
        arrRptData.forEach((dt) => {
          if (dt.Check) drChecked.push(dt.Id);
          else drUnChecked.push(dt.Id);
        });
        drChecked = drChecked.toString();
        drUnChecked = drUnChecked.toString();
      }
      const objFilter = {
        strDataType: strFieldType,
        RptId: Number(props.iRptId),
        RptColId: Number(iRptColId),
        MSId: Number(MathSymbol),
        MinValue: Number(strMinValue),
        MaxValue: Number(strMaxValue),
        FromDate: strFromDate,
        ToDate: strToDate,
        strCompanyDB: localStorage.db,
        strSearch: strSearch,
        strCheckedIds: drChecked,
        strUnCheckedIds: drUnChecked,
      };
      await userApp.post(`Reports/Update_ReportFilter`, objFilter).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            Clear_Filter();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Delete_ReportFilter = () => {
    try {
      userApp.post(
        `Reports/Delete_ReportFilter/${Number(props.iRptId)}/${Number(
          iRptColId
        )}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          Load_ReportFilter();
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Clear_defaultvalues = () => {
    setarrRptData([]);
    setstrMaxValue(0);
    setstrMinValue(0);
    setMathSymbol(1);
  };
  const Clear_Filter = () => {
    Clear_defaultvalues();
    Load_FilterData(iRptColId, strFieldType);
  };
  const Load_FilterData = async (Colid, strColype) => {
    Clear_defaultvalues();
    setiRptColId(Number(Colid));
    setstrFieldType(strColype);
    const objSearch = {
      strColName: strColype,
      strSearch: "",
      iItemId: Number(Colid),
      strCompanyDB: localStorage.db,
    };
    await userApp.post(`Reports/Load_ReportFilterCol/`, objSearch).then(
      (Res) => {
        if (Res.data.strStatus === "Success") {
          let arrFilter = Res.data.EntFilterCol;
          switch (strColype) {
            case "decimal":
              setisAmt(true);
              setisDate(false);
              setisTable(false);
              if (Number(arrFilter[0].msId) === 6) {
                setisMinMaxVal(true);
                setstrMinValue(arrFilter[0].minValue);
                setstrMaxValue(arrFilter[0].maxValue);
              } else {
                setisMinMaxVal(false);
                setstrMinValue(arrFilter[0].minValue);
              }
              break;
            case "DateTime":
              setisDate(true);
              setisTable(false);
              setisAmt(false);
              setstrFromDate(arrFilter[0].fromDate);
              setstrToDate(arrFilter[0].toDate);
              break;
            default:
              arrFilter = JSON.parse(arrFilter);
              arrFilter.forEach((dtcol) => {
                dtcol.Check = Boolean(Number(dtcol.Check));
                dtcol.Id = Number(dtcol.Id);
                dtcol.SNo = Number(dtcol.SNo);
              });
              setisTable(true);
              setisDate(false);
              setisAmt(false);
              const filtercol = Object.keys(arrFilter[0]);
              setarrRptData(arrFilter);
              setarrFiltCol(filtercol);
              break;
          }
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      }
    );
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  const MathSymbolChange = (MathSymble) => {
    setMathSymbol(MathSymble);
    setisMinMaxVal(Number(MathSymble) === 6 ? true : false);
  };
  const table_Onchange = (rptcolid, val) => {
    let arrreport = arrRptData;
    arrreport.forEach((dtcol) => {
      if (dtcol.Id === rptcolid) {
        dtcol.Check = val;
      }
    });
    setarrRptData(arrreport);
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Report Filter</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row mt-2">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-1">
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Filter Column"
                  select_value={(val) =>
                    Load_FilterData(val.iRptColId, val.strDataType)
                  }
                  btnname="Filter"
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iRptColId}
                  displayName="strAliasName"
                  disvalue="iRptColId"
                  arrydata={arrRptCol}
                  disabled={false}
                  EmptVal="Column"
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-1">
                <div className="m-hsearch">
                  <input
                    type="search"
                    placeholder="Search Reports"
                    value={strSearch}
                    onChange={(e) => {
                      setstrSearch(e.target.value);
                    }}
                    autoFocus
                  />
                  <i
                    className="fas fa-search"
                    onClick={(e) => setstrSearch(strSearch)}
                  ></i>
                </div>
              </div>
            </div>
            {isAmt && (
              <div className="row">
                <div className="col-md-6 col-sm-6 mb-1">
                  <label>{isMinMaxVal ? "Min Amount" : "Amount"}</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Value"
                    onClick={(e) => e.target.select()}
                    value={strMinValue}
                    onChange={(e) => setstrMinValue(e.target.value)}
                  />
                </div>
                {isMinMaxVal && (
                  <div className="col-md-6 col-sm-6 mb-1">
                    <label>Max Amount</label>
                    <input
                      type="number"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Please Enter Value"
                      onClick={(e) => e.target.select()}
                      value={strMaxValue}
                      onChange={(e) => setstrMaxValue(e.target.value)}
                    />
                  </div>
                )}
                <div className="col-md-6 col-sm-12 mb-1">
                  <label>Math symbol</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_selecrparem.isoptionShow1}
                    placeholder="Math Symble"
                    select_value={(val) => MathSymbolChange(val)}
                    btnname="Math Symble"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={MathSymbol}
                    displayName="symbol"
                    disvalue="msId"
                    arrydata={arrMathSymbol}
                    disabled={false}
                    EmptVal="Math Symble"
                  />
                </div>
              </div>
            )}
            {isDate && (
              <div className="row">
                <div className="col-md-6 col-sm-6 mt-1">
                  <label>From</label>
                  <input
                    type="Date"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Value"
                    value={strFromDate}
                    onChange={(e) => setstrFromDate(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 mt-1">
                  <label>To</label>
                  <input
                    type="Date"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Value"
                    value={strToDate}
                    onChange={(e) => setstrToDate(e.target.value)}
                  />
                </div>
              </div>
            )}
            {isTable && (
              <div className="Report-filter">
                <table>
                  <thead>
                    <tr>
                      {arrFiltCol.map((colname, Index) => (
                        <th key={Index}>{colname}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {arrRptData.map((dt, trIndex) => (
                      <tr key={trIndex}>
                        {arrFiltCol.map((colname, tdIndex) =>
                          colname !== "Check" ? (
                            <td key={tdIndex}>{dt[colname]}</td>
                          ) : (
                            <td key={tdIndex}>
                              <input
                                type="checkbox"
                                className="form-check"
                                defaultChecked={dt[colname]}
                                onChange={(e) =>
                                  table_Onchange(dt.Id, e.target.checked)
                                }
                              />
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                dispatch(
                  Show_MsgBox({
                    Button: Update_Filter,
                    Question: "Do You Want to Create Filter?",
                    isdialog: true,
                  })
                )
              }
            >
              Save&nbsp;<i className="fas fa-save"></i>
            </button>
            <button
              className="btn-fabgreen "
              onClick={(e) => {
                dispatch(
                  Show_MsgBox({
                    Button: Delete_ReportFilter,
                    Question: "Do You Want to Delete All Filter?",
                    isdialog: true,
                  })
                );
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen " onClick={Clear_Filter}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReportFilter;
