import React, { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
function PrintLineCopy(props) {
  const _parem = useSelector((state) => state.SelectOption.parems);
  const dispatch = useDispatch();
  const [strLineTemplateName, setstrLineTemplateName] = useState("");
  const [iTemplateId, setiTemplateId] = useState(0);
  const [ListSourceTemp, setListSourceTemp] = useState([]);

  useEffect(() => {
    Load_LineTemplate();
  }, []);
  const Load_LineTemplate = () => {
    try {
      dispatch(setload_Screen(true));
      userApp.get(
         "PrintMaster/Load_OriginalLinetemplate/" + localStorage.db
      ).then((Res) => {
        dispatch(setload_Screen(false));
        setListSourceTemp(Res.data);
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "info");
    }
  };
  const CheckTemplate_Exist = (strtxt) => {
    try {
      setstrLineTemplateName(strtxt);
      const objSearch = {
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      userApp.post(
         "PrintMaster/CheckLineTemplateExist/",
        objSearch
      ).then((Res) => {
        if (Res.data.strStatus === "error")
          AlertMessage(Res.data.strMessage, "info");
      });
    } catch (err) {
      AlertMessage(err.message, "info");
    }
  };
  const Copy_LineTemplate = () => {
    try {
      const objTemp = {
        iItemId: iTemplateId,
        strCompanyDB: localStorage.db,
        strColName: strLineTemplateName,
      };
      userApp.post( "PrintMaster/Copy_Linetemplate/", objTemp).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            props.Close();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "info");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content" style={{ maxWidth: "300px" }}>
        <div className="popup-header">
          <h5>Line Template Copy </h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <label>Source Template</label>
              <CustomizedSelectoption
                optionparem="isoptionShow20"
                showoption={_parem.isoptionShow20}
                placeholder="Select Template"
                select_value={(val) => setiTemplateId(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iTemplateId}
                displayName="strLineTempName"
                disvalue="iLineTempId"
                arrydata={ListSourceTemp}
                EmptVal="Template Name"
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label>Template Name</label>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                placeholder="Enter Template Name"
                value={strLineTemplateName}
                onChange={(e) => CheckTemplate_Exist(e.target.value)}
              />{" "}
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              type="button"
              className="btn-fabgreen"
              onClick={(e) => {
                if (strLineTemplateName.length === 0) {
                  AlertMessage("Please Enter line Tempalte Name", "info");
                } else if (Number(iTemplateId) === 0) {
                  AlertMessage("Please Select Source Tempalte", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Copy_LineTemplate,
                      Question: "Do You Want to Copy Line Template?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save &nbsp; <i className="fas fa-save"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintLineCopy;
