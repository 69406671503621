import SideMenu from "./SideMenu";
import { useEffect, useState } from "react";
import IncomeChart from "./IncomeChart";
import { userApp, Create_FinancialYear } from "../Admin/Utility";
import FinYearKeyMessage from "../Admin/FinYearKeyMessage";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import Messagedialogbox from "./Messagedialogbox";
import AutoBackup from "../Admin/AutoBackup";
function Dashboard() {
  const dispatch = useDispatch();
  const [IsFYear, setIsFYear] = useState(false);
  const [dSalInvAmt, setdSalInvAmt] = useState(0);
  const [dPurInvAmt, setdPurInvAmt] = useState(0);
  const [dTodaySales, setdTodaySales] = useState(0);
  const [dProfit, setdProfit] = useState(0);
  const [dTDebit, setdTDebit] = useState(0);
  const [dTCredit, setdTCredit] = useState(0);
  const [dTotalStockValue, setdTotalStockValue] = useState(0);
  const [iSalInvCount, setiSalInvCount] = useState(0);
  const [iPurInvCount, setiPurInvCount] = useState(0);
  const [iCusCount, setiCusCount] = useState(0);
  const [iSupCount, setiSupCount] = useState(0);
  const [dSalValPer, setdSalValPer] = useState(0);
  const [dPurValPer, setdPurValPer] = useState(0);
  const [dProfitValPer, setdProfitValPer] = useState(0);
  const [dSaldiff, setdSaldiff] = useState(0);
  const [dPurdiff, setdPurdiff] = useState(0);
  const [dProfitdiff, setdProfitdiff] = useState(0);
  const [dpFromdate, setdpFromdate] = useState(localStorage.CurrFinancialYear);
  const [dpTodate, setdpTodate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [arrEntInv, setarrEntInv] = useState([]);
  const [arrEntCust, setarrEntCust] = useState([]);
  const [arrEntSupp, setarrEntSupp] = useState([]);
  const [arrEntVen, setarrEntVen] = useState([]);
  const [arrRecentInv, setarrRecentInv] = useState([]);
  const [IsAutoBackup, setIsAutoBackup] = useState(false);
  const [IsBackup, setIsBackup] = useState(false);
  const [IsBackupPer, setIsBackupPer] = useState(false);
  const arrMonthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  useEffect(() => {
    var currentDate = new Date().toISOString().split("T")[0];
    if (localStorage.NxtFY !== undefined) {
      const x = new Date(currentDate);
      const y = new Date(localStorage.NxtFY);
      if (y < x) {
        setIsFYear(!IsFYear);
      }
    }
    View_SalesSummary("Month");
  }, []);
  useEffect(() => {
    async function Backup() {
      try {
        if (
          IsAutoBackup === true &&
          IsBackupPer === true &&
          !Boolean(Number(localStorage.IsDirect))
        ) {
          await setIsBackup(true);
        }
      } catch (e) {
        AlertMessage(e.message, "error");
      }
    }
    Backup();
  }, [IsAutoBackup]);

  const CreateFinancialYear = async () => {
    let res = await Create_FinancialYear(
      localStorage.Msdb,
      Number(localStorage.ComId),
      Number(localStorage.UserId),
      dispatch
    );
    if (res) {
      window.location.href = `/CompanyMaster`;
    }
  };
  const View_SalesSummary = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        dpFrom: dpFromdate,
        dpTo: dpTodate,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
      };
      await userApp.post(`Home/View_SalesSummary`, parems).then((Res) => {
        if (Res.data.strStatus === "Success") {
          let EntSummary = Res.data.EntSummary;
          var arrInv = [];
          EntSummary._EntInvoice.forEach((Inv) => {
            const objEntInv = {
              Amount: Inv.dNetAmt,
              Time: Inv.strTime,
            };
            arrInv.push(objEntInv);
          });
          setarrEntInv(arrInv);
          //#region  Summary
          let Summary = EntSummary._EntSummary[0];
          setdSalInvAmt(AssignRupeeSymbol(Summary.dSales));
          setdPurInvAmt(AssignRupeeSymbol(Summary.dPurchase));
          setdProfit(AssignRupeeSymbol(Summary.dProfit));
          setdTodaySales(AssignRupeeSymbol(Summary.dTodaySal));
          setdTCredit(AssignRupeeSymbol(Summary.dTCredit));
          setdTDebit(AssignRupeeSymbol(Summary.dTDebit));
          setdTotalStockValue(AssignRupeeSymbol(Summary.dStockVal));
          setdSalValPer(Summary.dSalper);
          setdPurValPer(Summary.dPurper);
          setdProfitValPer(Summary.dProfitPer);
          setdSaldiff(Summary.dSaldiff);
          setdPurdiff(Summary.dPurdiff);
          setdProfitdiff(Summary.dProfitdiff);
          setiSalInvCount(Summary.iSalCount);
          setiPurInvCount(Summary.iPurCount);
          setiCusCount(Summary.iCusCount);
          setiSupCount(Summary.iSupCount);
          //#endregion

          //#region  Array
          setarrRecentInv(EntSummary._RecentInv);
          setarrEntCust(EntSummary._EntCust);
          setarrEntSupp(EntSummary._EntSupp);
          setarrEntVen(EntSummary._EntVen);
          //#endregion

          let Menu = JSON.parse(localStorage.Menu);

          Menu.objEntCLPM.forEach((DT) => {
            if (DT.strAccessName === "Backup") setIsBackupPer(DT.bIsVisible);
          });
          setIsAutoBackup(Boolean(Res.data.AutoBackup));
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AssignRupeeSymbol = (Amount) => {
    var TotalAmt = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "INR",
    }).format(Amount);
    return TotalAmt;
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const InvoiceClick = (InvoiceInfo) => {
    var VouchId = InvoiceInfo.vouchId;
    var VouchType = InvoiceInfo.vouchType;
    switch (VouchType) {
      case "Purchase Challan":
        InvocieScreenRedirect(VouchId, VouchType, "/Purchase/PurchaseChallan");
        break;
      case "Purchase Invoice":
        InvocieScreenRedirect(VouchId, VouchType, "/Purchase/PurchaseInvoice");
        break;
      case "Purchase Estimate":
        InvocieScreenRedirect(VouchId, VouchType, "/Purchase/PurchaseEstimate");
        break;
      case "Sales Challan":
        InvocieScreenRedirect(VouchId, VouchType, "/Sales/SalesChallan");
        break;
      case "Sales Invoice":
        InvocieScreenRedirect(VouchId, VouchType, "/Sales/SalesInvoice");
        break;
      case "Sales Estimate":
        InvocieScreenRedirect(VouchId, VouchType, "/Sales/SalesEstimate");
        break;
      case "Inward Challan":
        InvocieScreenRedirect(VouchId, VouchType, "/JobWork/InwardChallan");
        break;
      case "Outward Challan":
        InvocieScreenRedirect(VouchId, VouchType, "/JobWork/OutwardChallan");
        break;
      case "Outward Invoice":
        InvocieScreenRedirect(VouchId, VouchType, "/JobWork/OutwardInvoice");
        break;
      case "OutSource Challan":
        InvocieScreenRedirect(VouchId, VouchType, "/JobWork/OutsourceChallan");
        break;
      case "InSource Challan":
        InvocieScreenRedirect(VouchId, VouchType, "/JobWork/InsourceChallan");
        break;
      case "InSource Invoice":
        InvocieScreenRedirect(VouchId, VouchType, "/JobWork/InsourceInvoice");
        break;
      default:
        break;
    }
  };
  const InvocieScreenRedirect = (VouchId, VouchType, Url) => {
    window.location.href = Url;
    sessionStorage.setItem("VoucherId", VouchId);
    sessionStorage.setItem("VoucherType", VouchType);
  };
  const TrentArrowIcon = (Prcentage, diffAmt) => {
    let val = Number(Prcentage);
    val = val >= 0 ? true : false;
    Prcentage = Number(Prcentage).toFixed(2);
    return (
      <>
        {val && (
          <span className="sal-up">
            <i className="fa-solid fa-arrow-trend-up"></i>
            <span className="sal-cont">
              <small>+ {Number(diffAmt).toFixed(2)}</small>
              <span>{Prcentage}%</span>
            </span>
          </span>
        )}
        {!val && (
          <span className="sal-down">
            <i className="fa-solid fa-arrow-trend-down"></i>
            <span className="sal-cont">
              <small>{Number(diffAmt).toFixed(2)}</small>
              <span>{Prcentage}%</span>
            </span>
          </span>
        )}
      </>
    );
  };
  const convertdatetolongdate = (date) => {
    date = new Date(date);
    return `${
      arrMonthName[date.getMonth()]
    } ${date.getDate()} ${date.getFullYear()}`;
  };
  return (
    <>
      <SideMenu ScrId={0} IsTab={false} />
      <div className="dashb-container p-1">
        <div className="dashb-header">
          <h1>Dashboard</h1>
          <div className="dashhead-input">
            <div className="dash-date">
              <span className="datepicker-toggle">
                <span className="datepicker-toggle-button">
                  <i className="fa-solid fa-calendar"></i>
                </span>
                <input
                  type="date"
                  className="datepicker-input"
                  onChange={(e) => setdpFromdate(e.target.value)}
                />
              </span>
              <span>{convertdatetolongdate(dpFromdate)}</span>-
              <span>{convertdatetolongdate(dpTodate)}</span>
              <span className="datepicker-toggle">
                <span className="datepicker-toggle-button">
                  <i className="fa-solid fa-calendar"></i>
                </span>
                <input
                  type="date"
                  className="datepicker-input"
                  onChange={(e) => setdpTodate(e.target.value)}
                />
              </span>
              <span className="check" onClick={(e) => View_SalesSummary()}>
                Check
              </span>
            </div>
          </div>
        </div>
        <div className="dashb-content first">
          <div className="Cart Cart1">
            <div className="chart">
              <span>Sales</span>
              <i className="fa-solid fa-cart-shopping"></i>
            </div>
            <div className="chart">
              <strong>{dSalInvAmt}</strong>
              {TrentArrowIcon(dSalValPer, dSaldiff)}
            </div>
          </div>
          <div className="Cart Cart2">
            <div className="chart">
              <span>Purchase</span>
              <i className="fa-solid fa-bag-shopping"></i>
            </div>
            <div className="chart">
              <strong>{dPurInvAmt}</strong>
              {TrentArrowIcon(dPurValPer, dPurdiff)}
            </div>
          </div>
          <div className="Cart Cart3">
            <div className="chart">
              <span> Profit</span>
              <i className="fa-solid fa-arrow-up-right-dots"></i>
            </div>
            <div className="chart">
              <strong>{dProfit}</strong>
              {TrentArrowIcon(dProfitValPer, dProfitdiff)}
            </div>
          </div>
          <div className="Cart Cart4">
            <div className="chart">
              <span> Stock Value </span>
              <i className="fa-solid fa-boxes-stacked"></i>
            </div>
            <div className="chart">
              <strong>{dTotalStockValue}</strong>
            </div>
          </div>
        </div>
        <div className="dashb-content secount">
          <div className="Cart">
            <div className="Curr_Sales">
              <div className="content">
                <span>Today Sales</span>
                <strong>{dTodaySales}</strong>
              </div>
            </div>
            <div className="total_Count">
              <div className="info">
                <span>DEBIT</span>
                <span>CREDIT</span>
              </div>
              <div className="info">
                <strong>{dTDebit}</strong>
                <strong>{dTCredit}</strong>
              </div>
            </div>
            <div className="total_Count">
              <div className="info">
                <span>Sales Invoice</span> <span>Purchase Invoice</span>
              </div>
              <div className="info">
                <strong>{iSalInvCount}</strong>
                <strong>{iPurInvCount}</strong>
              </div>
            </div>
            <div className="total_Count">
              <div className="info">
                <span> Total Customers</span> <span>Total Suppliers </span>
              </div>
              <div className="info">
                <strong>{iCusCount}</strong>
                <strong>{iSupCount}</strong>
              </div>
            </div>
          </div>
          <div className="Cart">
            <IncomeChart SalesSummary={arrEntInv} />
          </div>
          <div className="Cart">
            <div className="Invoice-Container">
              <h3>Recent Invoices</h3>
              {arrRecentInv.map((Inv, Index) => (
                <div className="invoice" key={Index}>
                  <div className="Inv-Content">
                    <strong onClick={(e) => InvoiceClick(Inv)}>
                      {Inv.partyName}
                    </strong>
                    <span>{Inv.invDate}</span>
                  </div>
                  <div className="Inv-Content">
                    <div className="info">
                      <span
                        className="hover"
                        onClick={(e) => InvoiceClick(Inv)}
                      >
                        {Inv.vouchType}
                      </span>
                      ||
                      <small>{AssignRupeeSymbol(Inv.netAmt)}</small>
                    </div>
                    <div className="info" onClick={(e) => InvoiceClick(Inv)}>
                      <strong className="hover"> {Inv.vouchNo}</strong>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="dashb-content third">
          <div className="Cart sum-table">
            <h3>Customer OutStanding</h3>
            <div className="tbl-container">
              <p className="tbl-header">
                <span>Name</span>
                <span>Paid</span>
                <span>Balance</span>
              </p>
              <div className="tbl-body">
                {arrEntCust.map((CusOutSta, Index) => (
                  <p key={Index}>
                    <span>{CusOutSta.strPartyName}</span>
                    <span className="out-amt">
                      {AssignRupeeSymbol(CusOutSta.dPaidAmt)}
                    </span>
                    <span className="out-amt">
                      {AssignRupeeSymbol(CusOutSta.dBalanceAmt)}
                    </span>
                  </p>
                ))}
              </div>
              <p className="tbl-footer">
                <span>Total</span>
                <span className="out-amt">
                  {AssignRupeeSymbol(
                    arrEntCust.length > 0
                      ? arrEntCust
                          .reduce((n, { dPaidAmt }) => n + dPaidAmt, 0)
                          .toFixed(2)
                      : 0
                  )}
                </span>
                <span className="out-amt">
                  {AssignRupeeSymbol(
                    arrEntCust.length > 0
                      ? arrEntCust
                          .reduce((n, { dBalanceAmt }) => n + dBalanceAmt, 0)
                          .toFixed(2)
                      : 0
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="Cart sum-table">
            <h3>Supplier OutStanding</h3>
            <div className="tbl-container">
              <p className="tbl-header">
                <span>Name</span>
                <span>Paid</span>
                <span>Balance</span>
              </p>
              <div className="tbl-body">
                {arrEntSupp.map((SuppOutSta, Index) => (
                  <p key={Index}>
                    <span style={{ textAlign: "left" }}>
                      {SuppOutSta.strPartyName}
                    </span>
                    <span className="out-amt">
                      {AssignRupeeSymbol(SuppOutSta.dPaidAmt)}
                    </span>
                    <span className="out-amt">
                      {AssignRupeeSymbol(SuppOutSta.dBalanceAmt)}
                    </span>
                  </p>
                ))}
              </div>
              <p className="tbl-footer">
                <span>Total</span>
                <span className="out-amt">
                  {AssignRupeeSymbol(
                    arrEntSupp.length > 0
                      ? arrEntSupp
                          .reduce((n, { dPaidAmt }) => n + dPaidAmt, 0)
                          .toFixed(2)
                      : 0
                  )}
                </span>
                <span className="out-amt">
                  {AssignRupeeSymbol(
                    arrEntSupp.length > 0
                      ? arrEntSupp
                          .reduce((n, { dBalanceAmt }) => n + dBalanceAmt, 0)
                          .toFixed(2)
                      : 0
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="Cart sum-table">
            <h3>Vendor OutStanding</h3>
            <div className="tbl-container">
              <p className="tbl-header">
                <span>Name</span>
                <span>Paid</span>
                <span>Balance</span>
              </p>
              <div className="tbl-body">
                {arrEntVen.map((VenOutSta, Index) => (
                  <p key={Index}>
                    <span>{VenOutSta.strPartyName}</span>
                    <span className="out-amt">
                      {AssignRupeeSymbol(VenOutSta.dPaidAmt)}
                    </span>
                    <span className="out-amt">
                      {AssignRupeeSymbol(VenOutSta.dBalanceAmt)}
                    </span>
                  </p>
                ))}
              </div>
              <p className="tbl-footer">
                <span>Total</span>
                <span className="out-amt">
                  {AssignRupeeSymbol(
                    arrEntVen.length > 0
                      ? arrEntVen
                          .reduce((n, { dPaidAmt }) => n + dPaidAmt, 0)
                          .toFixed(2)
                      : 0
                  )}
                </span>
                <span className="out-amt">
                  {AssignRupeeSymbol(
                    arrEntVen.length > 0
                      ? arrEntVen
                          .reduce((n, { dBalanceAmt }) => n + dBalanceAmt, 0)
                          .toFixed(2)
                      : 0
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        {IsFYear && (
          <FinYearKeyMessage
            CreateFinYear={CreateFinancialYear}
            Close={(e) => setIsFYear(!IsFYear)}
          />
        )}
        {IsBackup && (
          <AutoBackup
            Close={() => {
              setIsBackup(!IsBackup);
              setIsAutoBackup(false);
            }}
          />
        )}
        <Messagedialogbox />
      </div>
    </>
  );
}

export default Dashboard;
