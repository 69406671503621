import axios from "axios";
import moment from "moment";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";

const userDevEnv = `localhost:5000`;
const adminDevEnv = `localhost:5003`;

const testEnv = `webtestserver.justbillsoftware.com`;
const admintest = `admintestserver.justbillsoftware.com`;

const userEnv = `server.justbillsoftware.com`;
const adminEnv = `adminserver.justbillsoftware.com`;

const userDomain = `https://${userEnv}/api/`;
const adminDomain = `https://${adminEnv}/api/`;

const userApp = axios.create(); // Alias for user services
const adminApp = axios.create(); // Alias for admin services

userApp.interceptors.request.use(
  function (config) {
    config.baseURL = userDomain;
    config.headers["Authorization"] = `Bearer ${localStorage.AuthKey}`;
    return config;
  },
  function (e) {
    return Promise.reject(e);
  }
);
userApp.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (e) {
    await authorizUser(e);
    return Promise.reject(e);
  }
);
adminApp.interceptors.request.use(
  function (config) {
    config.baseURL = adminDomain;
    config.headers["Authorization"] = `Bearer ${localStorage.AuthKey}`;
    return config;
  },
  function (e) {
    return Promise.reject(e);
  }
);
adminApp.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (e) {
    await authorizUser(e);
    return Promise.reject(e);
  }
);
const authorizUser = async (e) => {
  if (e.response && e.response.status === 401) {
    await Logout_User();
  }
};
const Logout_User = async () => {
  if (localStorage.length > 0) {
    const parems = {
      iScrId: 0,
      strCompanyDB: localStorage.Msdb,
      strMasterDB: localStorage.db,
      iClientId: Number(localStorage.ClientId),
      iUserId: Number(localStorage.UserId),
      iRoleId: Number(localStorage.RoleId),
    };
    await adminApp.post(`UserMaster/Logout_User`, parems);
  }
  await localStorage.clear();
  await sessionStorage.clear();
  window.location.href = "/";
};
const Backup_Data = async ({ Name, dispatch, Msdb }) => {
  let Status = false;
  try {
    await dispatch(setload_Screen(true));
    await fetch(`${adminDomain}DBMaster/Backup_Database/${Msdb}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.AuthKey}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = url;
        const now = moment();
        const curr_date = now.format("D-MMM-YYYY");
        const ClientName = Name ? Name : "JustBill";
        const FileName = `${ClientName}_${curr_date}.zip`;
        link.setAttribute("download", FileName);
        link.click();
        Status = true;
      })
      .catch(async (err) => {
        await dispatch(
          Show_MsgBox({ Message: err.stack, Type: "error", isMsg: true })
        );
        Status = false;
      });
  } catch (err) {
    await dispatch(
      Show_MsgBox({ Message: err.message, Type: "error", isMsg: true })
    );
    Status = false;
  } finally {
    await dispatch(setload_Screen(false));
    return Status;
  }
};
export const Create_FinancialYear = async (Msdb, CompId, UserId, dispatch) => {
  let Status = false;
  try {
    const BackupData = await Backup_Data({
      dispatch: dispatch,
      Msdb: Msdb,
    });
    await dispatch(setload_Screen(true));
    if (BackupData) {
      await adminApp
        .post(`CompanyMaster/Create_Financialyear/${Msdb}/${CompId}/${UserId}`)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await dispatch(
              Show_MsgBox({
                Message: Res.data.strMessage,
                Type: "success",
                isMsg: true,
              })
            );
            Status = true;
          } else {
            await dispatch(
              Show_MsgBox({
                Message: Res.data.strMessage,
                Type: "error",
                isMsg: true,
              })
            );
          }
        });
    }
  } catch (err) {
    await dispatch(
      Show_MsgBox({
        Message: err.message,
        Type: "error",
        isMsg: true,
      })
    );
  } finally {
    await dispatch(setload_Screen(false));
    return Status;
  }
};
export { userApp, adminApp, Logout_User, Backup_Data, userDomain, adminDomain };



