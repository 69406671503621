import React, { useEffect, useState } from "react";
import CustomeIndexSelectOption from "./CustomeIndexSelectOption";
import { useDispatch, useSelector } from "react-redux";

import { userApp} from "../Admin/Utility";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import CustomizedSelectoption from "./CustomizedSelectoption";
import SideMenu from "./SideMenu";
import $ from "jquery";
import ReactTable from "../General/ReactTable";
import Messagedialogbox from "./Messagedialogbox";
function EmailSetting(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [ScrId, setScrId] = useState(17);
  const [TypeId, setTypeId] = useState(1);
  const [ColId, setColId] = useState(0);
  const [strColumName, setstrColumName] = useState("");
  const [AliasName, setAliasName] = useState("");
  const [LableName, setLableName] = useState("");
  const [LineAt, setLineAt] = useState(0);
  const [StartAt, setStartAt] = useState(0);
  const [FontSize, setFontSize] = useState(15);
  const [FontWeight, setFontWeight] = useState("normal");
  const [Color, setColor] = useState("black");
  const [TextAlign, setTextAlign] = useState("left");
  const [WordWrap, setWordWrap] = useState("nowrap");
  const [iInOrder, setiInOrder] = useState(1);
  const [Font, setFont] = useState("");
  const [Prefix, setPrefix] = useState("");
  const [Suffix, setSuffix] = useState("");
  const [isConditionCol, setisConditionCol] = useState(false);
  const [FontStyle, setFontStyle] = useState("normal");
  const [arrScreenName, setarrScreenName] = useState([]);
  const [arrTemplateType, setarrTemplateType] = useState([]);
  const [arrEmailDesign, setarrEmailDesign] = useState([]);
  const [arrEntColumns, setarrEntColumns] = useState([]);

  useEffect(() => {
    Load_EmailSetting();
  }, []);
  const Load_EmailSetting = () => {
    try {
      userApp
        .get(
          `Transaction/Load_EmailSetting/${localStorage.Msdb}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrScreenName(Res.data.EntScreen);
            setarrTemplateType(Res.data.EntType);
            setarrEntColumns(Res.data.EntColumns);
            View_EmailTemplate(1);
          } else AlertMessage(Res.data.strMessage, "info");
        });
    } catch (err) {
      AlertMessage(err.strMessage, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_EmailTemplate = (typeId) => {
    setTypeId(typeId);
    dispatch(setload_Screen(true));
    try {
      setScrId(Number(ScrId));
      userApp
        .get(
          `Transaction/View_EmailTemplate/${Number(typeId)}/${Number(ScrId)}/${
            localStorage.db
          }`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEmailDesign(Res.data.EntTemplate);
          } else AlertMessage(Res.data.strMessage, "info");
        });
    } catch (err) {
      AlertMessage(err.strMessage, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const InsUpd_EmailTemplate = () => {
    dispatch(setload_Screen(true));
    try {
      const objColmn = {
        ColId: ColId,
        ColName: strColumName,
        AliasName: AliasName,
        Prefix: Prefix,
        Description: LableName,
        Suffix: Suffix,
        LineAt: LineAt,
        StartAt: StartAt,
        Font: Font,
        FontStyle: FontStyle,
        FontWeight: FontWeight,
        FontSize: FontSize,
        Color: Color,
        TextAlign: TextAlign,
        WordWrap: WordWrap,
        InOrder: iInOrder,
        TypeId: TypeId,
        ScrId: ScrId,
        ConditionCol: isConditionCol ? 1 : 0,
        strCompanyDB: localStorage.db,
      };
      userApp.post(`Transaction/InsUpd_EmailTemplate/`, objColmn).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          Clear_Columns();
        } else AlertMessage(Res.data.strMessage, "info");
      });
    } catch (err) {
      AlertMessage(err.strMessage, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Columns = () => {
    setColId(0);
    setstrColumName("");
    setAliasName("");
    setLineAt(0);
    setStartAt(0);
    setFontSize(15);
    setFontWeight("normal");
    setFont("times");
    setFontStyle("normal");
    setColor("black");
    setTextAlign("left");
    setWordWrap("nowrap");
    setiInOrder(1);
    setPrefix("");
    setSuffix("");
    setLableName("");
    setisConditionCol(false);
    Load_EmailSetting();
  };
  const View_New_Column = (EntTemp) => {
    setColId(0);
    setstrColumName(EntTemp.colName);
    setAliasName(EntTemp.aliasName);
    setLineAt(0);
    setStartAt(0);
    setFontSize(15);
    setFontWeight("normal");
    setFont("times");
    setFontStyle("normal");
    setColor("black");
    setTextAlign("left");
    setWordWrap("nowrap");
    setiInOrder(1);
    setPrefix("");
    setSuffix("");
    setLableName("");
    setisConditionCol(true);
  };
  const View_Column = (EntTemp) => {
    setColId(EntTemp.colId);
    setstrColumName(EntTemp.colName);
    setAliasName(EntTemp.aliasName);
    setLineAt(EntTemp.lineAt);
    setStartAt(EntTemp.startAt);
    setFontSize(EntTemp.fontSize);
    setFontWeight(EntTemp.fontWeight);
    setFont(EntTemp.font);
    setFontStyle(EntTemp.fontStyle);
    setColor(EntTemp.color);
    setTextAlign(EntTemp.textAlign);
    setWordWrap(EntTemp.wordwrap);
    setiInOrder(EntTemp.inOrder);
    setPrefix(EntTemp.prefix);
    setSuffix(EntTemp.suffix);
    setLableName(EntTemp.description);
    setisConditionCol(EntTemp.conditionCol === 1 ? true : false);
  };
  const Delete_ColDesign = () => {
    dispatch(setload_Screen(true));
    try {
      userApp
        .post(`Transaction/Delete_EmailColDesign/${ColId}/${localStorage.db}`)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            AlertMessage(Res.data.strMessage, "success");
            Clear_Columns();
          } else AlertMessage(Res.data.strMessage, "info");
        });
    } catch (err) {
      AlertMessage(err.strMessage, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  const DesignColumns = [
    {
      Header: "Column Name",
      accessor: "colName",
      minWidth: 200,
    },
    {
      Header: "Line At",
      accessor: "lineAt",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Start At",
      accessor: "startAt",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Order To",
      accessor: "inOrder",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Label",
      accessor: "description",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Size",
      accessor: "fontSize",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Style",
      accessor: "fontStyle",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Weight",
      accessor: "fontWeight",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Wrap",
      accessor: "wordWrap",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Alignment",
      accessor: "textAlign",
      style: { justifyContent: "center" },
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "Prefix",
      accessor: "prefix",
      style: { justifyContent: "left" },
      minWidth: 150,
    },
    {
      Header: "Suffix",
      accessor: "suffix",
      style: { justifyContent: "left" },
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: "Color",
      accessor: "color",
      minWidth: 80,
      maxWidth: 80,
      style: { justifyContent: "left" },
    },
    {
      Header: "Font",
      accessor: "font",
      maxWidth: 80,
      minWidth: 80,
      style: { justifyContent: "left" },
    },
    {
      Header: "Delete",
      id: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={(e) => {
            dispatch(
              Show_MsgBox({
                Button: Delete_ColDesign,
                Question: "Do You Want to  Delete Design?",
                isdialog: true,
                Id: rowinfo.colId,
              })
            );
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  const arrFontStyle = [
    "normal",
    "italic",
    "oblique",
    "bold",
    "regular",
    "underline",
  ];
  const arrFontFamily = [
    "courier",
    "Courier",
    "helvetica",
    "Helvetica",
    "symbol",
    "Symbol",
    "times",
    "Times",
    "zapfdingbats",
    "ZapfDingbats",
  ];
  const arrFontWeight = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "bold",
    "normal",
  ];
  const arrTextAlignment = ["center", "left", "right", "justify"];
  const arrWordWrab = ["nowrap", "wrap"];

  return (
    <>
      <SideMenu ScrId={85} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="data-list">
            {arrEntColumns.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = setarrEntColumns.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    View_New_Column(dt);
                  }
                }}
              >
                <label onClick={(e) => View_New_Column(dt)}>
                  {dt.aliasName}
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-4 col-sm-12 mb-1">
                <label>
                  Screen<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_parem.isoptionShow}
                  placeholder="Select Screen"
                  select_value={(val) => View_EmailTemplate(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={ScrId}
                  displayName="strScrName"
                  disvalue="iScrId"
                  arrydata={arrScreenName}
                  EmptVal="Screen Name"
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <label>
                  Tempate Type<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_parem.isoptionShow2}
                  placeholder="Select Tempate Type"
                  select_value={(val) => View_EmailTemplate(Number(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={TypeId}
                  displayName="typeName"
                  disvalue="typeId"
                  arrydata={arrTemplateType}
                  EmptVal="Tempate Type"
                  disabled={ScrId === 0 ? true : false}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <label>Column Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Column Name"
                  value={strColumName}
                  onChange={(e) => setstrColumName(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <label>Alias Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Alias Name"
                  value={AliasName}
                  onChange={(e) => setAliasName(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-4 mb-1">
                <label>
                  Line At<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Margin From Top"
                  onClick={(e) => e.target.select()}
                  value={LineAt}
                  onChange={(e) => setLineAt(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-4 mb-1">
                <label>
                  Start At<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Line Start"
                  onClick={(e) => e.target.select()}
                  value={StartAt}
                  onChange={(e) => setStartAt(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-6 mb-1">
                <label>In Order</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter in Order To"
                  value={iInOrder}
                  onChange={(e) => setiInOrder(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-1">
                <label>
                  Lable Name<span className="danger">*</span>
                </label>
                <input
                  id="LableName"
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Lable Name"
                  value={LableName}
                  onChange={(e) => setLableName(e.target.value)}
                  disabled={isConditionCol}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Prefix</label>
                <input
                  type="text"
                  aria-label="First name"
                  onClick={(e) => e.target.select()}
                  className="form-control"
                  placeholder="Enter Prefix Value"
                  value={Prefix}
                  onChange={(e) => setPrefix(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Suffix</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Suffix Value"
                  onClick={(e) => e.target.select()}
                  value={Suffix}
                  onChange={(e) => setSuffix(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>
                  Font Size<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Font Size"
                  value={FontSize}
                  onChange={(e) => setFontSize(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Font Style</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow3"
                  showoption={_parem.isoptionShow3}
                  placeholder="Select Font Style"
                  select_value={(val) => setFontStyle(val)}
                  defaultval={FontStyle}
                  arrydata={arrFontStyle}
                  EmptVal="Font Style"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Font Weight</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow4"
                  showoption={_parem.isoptionShow4}
                  placeholder="Select Font Weight"
                  select_value={(val) => setFontWeight(val)}
                  defaultval={FontWeight}
                  arrydata={arrFontWeight}
                  EmptVal="Font Weight"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Word Wrap</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow5"
                  showoption={_parem.isoptionShow5}
                  placeholder="Select Word Wrap"
                  select_value={(val) => setWordWrap(val)}
                  defaultval={WordWrap}
                  arrydata={arrWordWrab}
                  EmptVal="Word Wrap"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Alignment</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow6"
                  showoption={_parem.isoptionShow6}
                  placeholder="Select Text Align"
                  select_value={(val) => setTextAlign(val)}
                  defaultval={TextAlign}
                  arrydata={arrTextAlignment}
                  EmptVal="Text Align"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Font</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow7"
                  showoption={_parem.isoptionShow7}
                  placeholder="Select Font"
                  select_value={(val) => setFont(val)}
                  defaultval={Font}
                  arrydata={arrFontFamily}
                  EmptVal="Font"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>
                  Color<span className="danger"></span>
                </label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow8"
                  showoption={_parem.isoptionShow8}
                  placeholder="Select Color"
                  select_value={(val) => setColor(val)}
                  defaultval={Color}
                  arrydata={arrColor}
                  EmptVal="Color"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Show Label</label>
                <input
                  type="checkbox"
                  className="form-check"
                  checked={!isConditionCol}
                  onChange={(e) => setisConditionCol(!isConditionCol)}
                />
              </div>
              <div className="p-2">
                <ReactTable
                  columns={DesignColumns}
                  data={arrEmailDesign}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {
                    View_Column(rowInfo);
                  }}
                  background={true}
                  className="full-table"
                  containerclass="autotable"
                />
              </div>
            </div>
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (AliasName.length === 0)
                  AlertMessage("Please Enter Alias Name", "info");
                if (!isConditionCol && LableName.length === 0)
                  AlertMessage("Please Enter Lable Name", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: InsUpd_EmailTemplate,
                      Question: "Do You Want to Save Column Design?",
                      isdialog: true,
                    })
                  );
              }}
            >
              Save&nbsp;<i className="fas fa-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={(e) => Clear_Columns()}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                (window.location.href =
                  "/Dashboard")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailSetting;
