import React, { useEffect, useState } from "react";
import SearchLedger from "../Accounts/SearchLedger";
import { userApp} from "../Admin/Utility";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  ShowHide_Popup,
  Update_Service,
  Update_ServiceVendor,
  setParty,
} from "../ReduxStore/Transactionstates";
function PartyCreation() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);

  const [strName, setstrName] = useState("");
  const [strCode, setstrCode] = useState("");
  const [iStateId, setiStateId] = useState(32);
  const [iCountryId, setiCountryId] = useState(1);
  const [strAdd1, setstrAdd1] = useState("");
  const [strAdd2, setstrAdd2] = useState("");
  const [strAdd3, setstrAdd3] = useState("");
  const [strAdd4, setstrAdd4] = useState("");
  const [strPincode, setstrPincode] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strGST, setstrGST] = useState("");
  const [strRemarks, setstrRemarks] = useState("");

  const [strLedId, setstrLedId] = useState("");
  const [strLedName, setstrLedName] = useState("");
  const [strSACCode, setstrSACCode] = useState("");
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [iSGSTTaxId, setiSGSTTaxId] = useState(8);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(16);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(21);
  const [strMobile, setstrMobile] = useState("");
  const [IsManualCode, setIsManualCode] = useState(true);
  const [IsLedgerOpen, setIsLedgerOpen] = useState(false);
  const [ListCountry, setListCountry] = useState([]);
  const [ListState, setListState] = useState([]);
  const [ListCodeFormat, setListCodeFormat] = useState([]);
  useEffect(() => {
    Load_Master();
  }, []);
  const Load_Master = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iScrId: _Common.JSScrId,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`Transaction/Load_Master`, parems).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            if (_Common.JSScrId === 51 || _Common.JSScrId === 59) {
              setlistSGST(Res.data.EntSGSTTax);
              setlistCGST(Res.data.EntCGSTTax);
              setlistIGST(Res.data.EntIGSTTax);
            } else {
              setListCountry(Res.data.EntCountry);
              setListState(Res.data.EntState);
            }
            setIsManualCode(!Res.data.CodeFormat[0].isManualCode);
            setstrCode(Res.data.CodeFormat[0].strCode);
            setListCodeFormat(Res.data.CodeFormat);
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const CountryOnChange = async (CountryId) => {
    try {
      setiCountryId(CountryId);
      const parems = {
        iCountryId: Number(CountryId),
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`CountryState/Load_State`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setListState(res.data.EntState);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const SaveClick = async () => {
    try {
      var ScrType = "Trans";
      if (_Common.JSType === "Job" || _Common.JSType === "Service")
        ScrType = "";
      if (strName === "" && ScrType === "Trans")
        AlertMessage("Please Enter Party Name", "info");
      else if (strName === "" && _Common.JSType === "Job")
        AlertMessage("Please Enter Job Name", "info");
      else if (strName === "" && _Common.JSType === "Service")
        AlertMessage("Please Enter Service Name", "info");
      else {
        await dispatch(setload_Screen(true));
        if (_Common.JSType === "Sales") {
          const CusSave = {
            iCustId: 0,
            strCustCode: strCode,
            strCustName: strName,
            strAliasName: "",
            strAdd1: strAdd1,
            strAdd2: strAdd2,
            strAdd3: strAdd3,
            strAdd4: strAdd4,
            strPincode: strPincode,
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: strEmail,
            strGST: strGST,
            strRemarks: strRemarks,
            iPGId: 1,
            iStatusId: 1,
            dOpBal: 0,
            dCreLimit: 0,
            CreditTerm: "30 Days",
            iBalTypeId: 3,
            iTitleTag: 2,
            iNumSeqNo: ListCodeFormat[0].iNumSeqNo,
            strPrefix: ListCodeFormat[0].strPrefix,
            strCustCodeTag: "Enter Customer Code",
            strFilterColumn: "All",
            strFilter: "",
            strSearch: "",
            GetLastInsertId: 1,
            strCompanyDB: localStorage.db,
          };
          await userApp.post(
            `CustomerMaster/InsUpd_Customer`,
            CusSave
          ).then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              View_Party(Res.data.iPartyId);
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
        } else if (_Common.JSType === "Purchase") {
          const SupSave = {
            iSuppId: 0,
            iNumSeqNo: ListCodeFormat[0].iNumSeqNo,
            strPrefix: ListCodeFormat[0].strPrefix,
            strSuppCode: strCode,
            strSuppName: strName,
            strAliasName: "",
            strAdd1: strAdd1,
            strAdd2: strAdd2,
            strAdd3: strAdd3,
            strAdd4: strAdd4,
            strPincode: strPincode,
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: strEmail,
            strRemarks: strRemarks,
            strGST: strGST,
            strTinNo: "",
            iStatusId: 1,
            dOpBal: 0,
            iBalTypeId: 3,
            iVenTypeId: 1,
            CreditTerm: "30 Days",
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            iTitleTag: 3,
            strSuppCodeTag: "Enter Supplier Code",
            GetLastInsertId: 1,
            strCompanyDB: localStorage.db,
          };
          await userApp.post(
            `SupplierMaster/InsUpd_Supplier`,
            SupSave
          ).then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              View_Party(Res.data.iPartyId);
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
        } else if (_Common.JSType === "SerVen") {
          const ServiceVen = {
            iSuppId: 0,
            iNumSeqNo: ListCodeFormat[0].iNumSeqNo,
            strPrefix: ListCodeFormat[0].strPrefix,
            strSuppCode: strCode,
            strSuppName: strName,
            strAliasName: "",
            strAdd1: strAdd1,
            strAdd2: strAdd2,
            strAdd3: strAdd3,
            strAdd4: strAdd4,
            strPincode: strPincode,
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: strEmail,
            strRemarks: strRemarks,
            strGST: strGST,
            strTinNo: "",
            iStatusId: 1,
            dOpBal: 0,
            iBalTypeId: 3,
            iVenTypeId: 2,
            CreditTerm: "30 Days",
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            iTitleTag: 3,
            strSuppCodeTag: "Enter Supplier Code",
            GetLastInsertId: 1,
            strCompanyDB: localStorage.db,
          };
          await userApp.post(
            `SupplierMaster/InsUpd_Supplier`,
            ServiceVen
          ).then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              View_ServiceVendore(Res.data.iPartyId);
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
        } else if (_Common.JSType === "Jobwork") {
          const VenSave = {
            iVenId: 0,
            strVenCode: strCode,
            strVenTag: "",
            strVenName: strName,
            strAliasName: "",
            strAdd1: strAdd1,
            strAdd2: strAdd2,
            strAdd3: strAdd3,
            strAdd4: strAdd4,
            strPincode: strPincode,
            iCountry: Number(iCountryId),
            iState: Number(iStateId),
            strPhone1: "",
            strPhone2: "",
            strMobile1: strMobile,
            strMobile2: "",
            strEmail: strEmail,
            strRemarks: strRemarks,
            strGST: strGST,
            TinNo: "",
            iStatusId: 1,
            dOpBal: 0,
            CreditTerm: "30 Days",
            iBalTypeId: 3,
            iVenCastId: 1,
            iTitleTag: 50,
            iNumSeqNo: ListCodeFormat[0].iNumSeqNo,
            strPrefix: ListCodeFormat[0].strPrefix,
            strVenCodeTag: "",
            strFilterColumn: "All",
            strFilter: "",
            strSearch: "",
            GetLastInsertId: 1,
            strCompanyDB: localStorage.db,
          };
          await userApp.post(
            `VendoreMaster/InsUpd_Vendor`,
            VenSave
          ).then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              View_Party(Res.data.iPartyId);
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
        } else if (_Common.JSType === "Job") {
          const ObjInsUpdJob = {
            strJobCode: strCode,
            strJobName: strName,
            strSACCode: strSACCode,
            strPrintName: "",
            strRemark: strRemarks,
            iStatusId: 1,
            strJobCodeTag: "Enter Job Code",
            iTitleTag: 51,
            strJobId: "0",
            iNumSeqNo: ListCodeFormat[0].iNumSeqNo,
            strPrefix: ListCodeFormat[0].strPrefix,
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            iTaxTypeId: 1,
            iSGSTTaxId: Number(iSGSTTaxId),
            iCGSTTaxId: Number(iCGSTTaxId),
            iIGSTTaxId: Number(iIGSTTaxId),
            strCompanyDB: localStorage.db,
          };
          await userApp.post(
            `JobWorkMaster/InsUpd_JobWork`,
            ObjInsUpdJob
          ).then((Res) => {
            if (Res.data.strStatus === "Success") {
              AlertMessage(Res.data.strMessage, "success");
              ClearClick();
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
        } else if (_Common.JSType === "Service") {
          const ObjInsUpdService = {
            strSerCode: strCode,
            strSerName: strName,
            strSACCode: strSACCode,
            strPrintName: "",
            strRemarks: strRemarks,
            strSerCodeTag: "Enter Service Code",
            iTitleTag: 59,
            iSerId: 0,
            iNumSeqNo: ListCodeFormat[0].iNumSeqNo,
            strPrefix: ListCodeFormat[0].strPrefix,
            strSearch: "",
            strFilter: "",
            strFilterColumn: "All",
            strLedId: strLedId.toString(),
            strTransTagLedName: "TagLedgerName",
            strLedName: strLedName,
            iTaxTypeId: 1,
            iSGSTTaxId: Number(iSGSTTaxId),
            iCGSTTaxId: Number(iCGSTTaxId),
            iIGSTTaxId: Number(iIGSTTaxId),
            strCompanyDB: localStorage.db,
          };
          await userApp.post(
            `ServiceMaster/InsUpd_Service`,
            ObjInsUpdService
          ).then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              Load_Service(Res.data.ServId);
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
        }
      }
    } catch (err) {
      AlertMessage(err.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Party = async (PartyId) => {
    try {
      const objTrans = {
        Controller: "",
        strScrType: _Common.strScrType,
        iScrId: _Common.iScrId,
        iPartyScrId: _Common.PartyScrId,
        iPartyId: PartyId,
        iVenType: 0,
        strSearch: "",
        strColName: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(`Transaction/View_Party/`, objTrans).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            dispatch(
              setParty({
                iPartyId: PartyId,
                Address:
                  Res.data.PartyInfo[1] +
                  Res.data.PartyInfo[2] +
                  Res.data.PartyInfo[3],
                strPartyName: Res.data.PartyInfo[0],
                ShippAdd: Res.data.strShippAdd,
                iTaxRelId: Res.data.iTaxRelId,
                arrShipAdd: Res.data.EntShipAdd,
              })
            );
            Popup_Close();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_ServiceVendore = async (PartyId) => {
    try {
      const objTrans = {
        Controller: "",
        strScrType: _Common.strScrType,
        iScrId: _Common.iScrId,
        iPartyScrId: _Common.PartyScrId,
        iPartyId: PartyId,
        iVenType: 0,
        strSearch: "",
        strColName: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(
        `Transaction/View_ServiceVendore/`,
        objTrans
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          dispatch(
            Update_ServiceVendor({
              EntVendore: Res.data.Vendore,
              VenId: PartyId,
            })
          );
          Popup_Close();
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Load_Service = async (ServId) => {
    try {
      const objTrans = {
        Controller: "",
        strScrType: _Common.strScrType,
        iScrId: _Common.iScrId,
        iPartyScrId: _Common.PartyScrId,
        iPartyId: 0,
        iVenType: 0,
        strSearch: "",
        strColName: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(`Transaction/Load_Service/`, objTrans).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            dispatch(
              Update_Service({ EntService: Res.data.Service, ServId: ServId })
            );
            Popup_Close();
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const ClearClick = () => {
    setstrName("");
    setstrCode("");
    setiStateId(32);
    setiCountryId(1);
    setstrAdd1("");
    setstrAdd2("");
    setstrAdd3("");
    setstrAdd4("");
    setstrPincode("");
    setstrEmail("");
    setstrGST("");
    setstrRemarks("");
    setstrLedId(0);
    setstrLedName("");
    setstrSACCode("");
    setIsManualCode(true);
    setiSGSTTaxId(8);
    setiCGSTTaxId(16);
    setiIGSTTaxId(21);
    setListCountry([]);
    setListState([]);
    setListCodeFormat([]);
    Load_Master();
  };
  const SelectLedger = (Ledger) => {
    try {
      setIsLedgerOpen(!IsLedgerOpen);
      setstrLedId(Ledger.ledId);
      setstrLedName(Ledger.ledName);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Popup_Close = () => {
    dispatch(ShowHide_Popup("IsVenCreation"));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{_Common.PartyScrName}</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={Popup_Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2">
          {!_Common.IsHideParty && (
            <div className="row p-2">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Name</label>
                <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  value={strName}
                  onChange={(e) => setstrName(e.target.value)}
                  placeholder={_Common.PartyPlaceHolder}
                  autoFocus
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  value={strCode}
                  disabled={IsManualCode}
                  onChange={(e) => setstrCode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Door/Office No</label> <span className="danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  value={strAdd1}
                  onChange={(e) => setstrAdd1(e.target.value)}
                  placeholder="Enter Door / Office No"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Street/Area</label>
                <input
                  type="text"
                  className="form-control"
                  value={strAdd2}
                  onChange={(e) => setstrAdd2(e.target.value)}
                  placeholder="Enter  Street/Area"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Town/Village</label>
                <input
                  type="text"
                  className="form-control"
                  value={strAdd3}
                  onChange={(e) => setstrAdd3(e.target.value)}
                  placeholder="Enter Town/Village"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={strAdd4}
                  onChange={(e) => setstrAdd4(e.target.value)}
                  placeholder="Enter City"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Pincode</label>
                <input
                  type="text"
                  className="form-control"
                  value={strPincode}
                  onChange={(e) => setstrPincode(e.target.value)}
                  placeholder="Enter Pincode"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Country</label>
                <span className="danger">*</span>
                <CustomizedSelectoption
                  optionparem="isoptionShow12"
                  showoption={_selecrparem.isoptionShow12}
                  placeholder="Select Country"
                  select_value={(val) => CountryOnChange(val)}
                  btnname="Country"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iCountryId}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={ListCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>State</label>
                <span className="danger">*</span>
                <CustomizedSelectoption
                  optionparem="isoptionShow13"
                  showoption={_selecrparem.isoptionShow13}
                  placeholder="Select State"
                  select_value={(val) => setiStateId(val)}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStateId}
                  displayName="strSName"
                  disvalue="iStateId"
                  arrydata={ListState}
                  disabled={false}
                  EmptVal="State "
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={strMobile}
                  onChange={(e) => setstrMobile(e.target.value)}
                  placeholder="Enter Mobile Number"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={strEmail}
                  onChange={(e) => setstrEmail(e.target.value)}
                  placeholder="Enter Email"
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>GSTN</label>
                <input
                  type="text"
                  className="form-control"
                  value={strGST}
                  onChange={(e) => setstrGST(e.target.value)}
                  placeholder="Enter GSTN"
                  spellCheck={false}
                  maxLength={15}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  className="form-control"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                  placeholder="Enter Remark"
                  spellCheck={false}
                />
              </div>
            </div>
          )}
          {_Common.IsHideParty && (
            <div className="row p-2">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={strName}
                  onChange={(e) => setstrName(e.target.value)}
                  placeholder={_Common.PartyPlaceHolder}
                  autoFocus
                />
              </div>
              {_Common.JSType === "Service" && (
                <div className="col-md-6 col-sm-12 mt-1">
                  <label>Ledger</label>
                  <div className="custom-select">
                    <input
                      type="text"
                      aria-label="First name"
                      className="form-control"
                      placeholder="Press Enter to Select Ledger"
                      value={strLedName}
                      onInput={(e) => setIsLedgerOpen(!IsLedgerOpen)}
                      onClick={(e) => setIsLedgerOpen(!IsLedgerOpen)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") setIsLedgerOpen(!IsLedgerOpen);
                      }}
                    />
                    <i
                      className="fa-solid fa-caret-down"
                      style={{
                        position: "absolute",
                        right: "10px",
                        color: "darkgreen",
                        transform: IsLedgerOpen
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                      }}
                    ></i>
                  </div>
                </div>
              )}
              <div className="col-md-3 col-sm-12 mt-1">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  value={strCode}
                  disabled={IsManualCode}
                  onChange={(e) => setstrCode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>SAC Code</label>
                <input
                  type="text"
                  className="form-control"
                  value={strSACCode}
                  onChange={(e) => setstrSACCode(e.target.value)}
                  placeholder="Enter SAC Code"
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-1">
                <label>GST %</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow12"
                  showoption={_selecrparem.isoptionShow12}
                  placeholder="Select Tax"
                  select_value={(val) => TaxOnChange(val)}
                  btnname="Tax"
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iIGSTTaxId}
                  displayName="strTaxDesc"
                  disvalue="iTaxId"
                  arrydata={listIGST}
                  disabled={false}
                  EmptVal="Tax"
                  replace={["IGST", "GST"]}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  className="form-control"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                  placeholder="Enter Remark"
                  spellCheck={false}
                />
              </div>
            </div>
          )}
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
        {IsLedgerOpen && (
          <SearchLedger
            Close={(e) => setIsLedgerOpen(!IsLedgerOpen)}
            SelectLedger={SelectLedger}
            ColName="AddLess"
          />
        )}
      </div>
    </div>
  );
}

export default PartyCreation;
