import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SideMenu from "../General/SideMenu";

import ReactTable from "../General/ReactTable";
import { userApp} from "../Admin/Utility";
import Messagedialogbox from "../General/Messagedialogbox";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
function AttributeGroup() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 61;
  const [iAttGrpId, setiAttGrpId] = useState(0);
  const [iDimensionId, setiDimensionId] = useState(1);
  const [strAttGroupName, setstrAttGroupName] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [strRemarks, setstrRemarks] = useState("");

  const [iAttId, setiAttId] = useState(0);
  const [strAttCode, setstrAttCode] = useState("");
  const [strAttName, setstrAttName] = useState("");

  const [iLevelId, setiLevelId] = useState("");
  const [LevelType, setLevelType] = useState("");
  const [iAttValId, setiAttValId] = useState(0);
  const [strAttVal, setstrAttVal] = useState("");
  const [iLvlIndex, setiLvlIndex] = useState("");

  const [iAttGrpCount, setiAttGrpCount] = useState(0);

  const [ListFilter, setListFilter] = useState([]);
  const [ListDimension, setListDimension] = useState([]);
  const [ListAttGrp, setListAttGrp] = useState([]);

  const [arrEntAtt, setarrEntAtt] = useState([]);
  const [arrEntAttDT, setarrEntAttDT] = useState([]);
  const [LevelColumns, setLevelColumns] = useState([]);
  const [arrEntColumns, setarrEntColumns] = useState([]);
  const [arrAttLevel, setarrAttLevel] = useState([]);
  const [arrEntAttLvlDT, setarrEntAttLvlDT] = useState([]);
  const [arrAttVal, setarrAttVal] = useState([]);
  const [IsAtt, setIsAtt] = useState(true);
  const [IsAttLvl, setIsAttLvl] = useState(false);
  const [IsAttBackround, setIsAttBackround] = useState(true);
  const [IsLvlBackround, setIsLvlBackround] = useState(true);

  useEffect(() => {
    Load_AttributeGroup();
  }, []);
  const Load_AttributeGroup = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(
        `AttributeGroup/Load_AttributeGroup`,
        parems
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListFilter(Res.data.Filter);
          setListDimension(Res.data.Dimension);
          setListAttGrp(Res.data.AttGroup);
          setarrEntAtt(Res.data.EntAttribute);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const InsAttGrp = {
        strAttGroupName: strAttGroupName,
        strDescription: strDescription,
        iAttDimId: Number(iDimensionId),
        strRemark: strRemarks,
        iTitleTag: iScrId,
        iAttGrpId: Number(iAttGrpId),
        strAttDimName: "",
        strFilter: "",
        strFilterColumn: "",
        strSearch: "",
      };
      const objParem = {
        objAttGrp: InsAttGrp,
        EntAttDT: arrEntAttDT,
        EntLevel: arrEntAttLvlDT,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(
         "AttributeGroup/InsUpd_AttGroup/",
        objParem
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_AttributeGroup = async (strtxt) => {
    try {
      const objSearch = {
        strColName: "All",
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await userApp.get(
        `AttributeGroup/Search_AttributeGroup/`,
        objSearch
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListAttGrp(Res.data.AttGroup);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const View_AttributGroup = async (AttGrp) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.get(
        `AttributeGroup/View_AttributeGroup/${Number(
          AttGrp.iAttGrpId
        )}/${AttGrp.iAttDimId}/${iScrId}/${localStorage.db}`
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await Load_Attribute(AttGrp.iAttDimId);
          await setstrAttGroupName(AttGrp.strAttGroupName);
          await setstrDescription(AttGrp.strDescription);
          await setstrRemarks(AttGrp.strRemark);
          await setiAttGrpId(AttGrp.iAttGrpId);
          await setiDimensionId(AttGrp.iAttDimId);
          await setarrEntAttDT(Res.data.EntAttribute);
          await setarrEntAttLvlDT(Res.data.EntLevel);
          if (Number(AttGrp.iAttDimId === 2))
            await setarrEntColumns(Res.data.EntColumns);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Delete_AttributeGroup = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.post(
        `AttributeGroup/Delete_AttributeGroup/${iScrId}/${iAttGrpId}/ ${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    setiAttGrpId(0);
    setiAttId(0);
    setstrAttGroupName("");
    setstrDescription("");
    setstrRemarks("");
    setiDimensionId(1);
    setiAttGrpCount(0);
    setIsAttLvl(false);
    setarrEntAtt([]);
    setarrAttVal([]);
    setarrEntAttDT([]);
    setarrEntAttLvlDT([]);
    setarrAttLevel([]);
    setLevelColumns([]);
    Load_AttributeGroup();
  };
  const Load_Attribute = async (iDimId) => {
    try {
      await dispatch(setload_Screen(true));
      setiDimensionId(Number(iDimId));
      await userApp.get(
        
          "AttributeGroup/Load_Attribute/" +
          Number(iDimId) +
          "/" +
          Number(localStorage.UserId) +
          "/" +
          localStorage.db +
          "/" +
          localStorage.Msdb
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntAtt(Res.data.EntAttribute);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Load_AttValues = async (Att) => {
    try {
      await dispatch(setload_Screen(true));
      await setiLevelId(Att.attLevelId);
      await setLevelType(Att.attLevelType);
      await setiAttId(Att.attId.toString());
      await setstrAttCode(Att.attCode);
      await setstrAttName(Att.attName);
      await setIsAtt(false);
      await setIsAttLvl(true);
      await userApp.get(
        `AttributeGroup/Load_AttValues/${Number(Att.attId)}/${Number(
          localStorage.UserId
        )}/${localStorage.db}/${localStorage.Msdb}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrAttVal(Res.data.AttValues);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Attrbute = async (Att) => {
    setiAttId(Att.iId);
    setstrAttCode(Att.strCode);
    setstrAttName(Att.strName);
  };
  const View_AttValues = (att) => {
    setiAttValId(att.iId);
    setstrAttVal(att.strName);
  };
  const Add_Attribute = async () => {
    try {
      if (Number(iAttId) === 0) AlertMessage("Please Select Attribute", "info");
      else {
        const EntAtt = arrEntAttDT.filter(
          (dt) => dt.attId === Number(iAttId)
        ).length;
        if (EntAtt > 0) AlertMessage("Attribute Already Exists!", "info");
        else {
          await dispatch(setload_Screen(true));
          const objParems = {
            iDimId: Number(iDimensionId),
            AttId: Number(iAttId),
            AttCode: strAttCode,
            AttName: strAttName,
            EntAttribute: arrEntAttDT,
            EntLevel: arrAttLevel,
            strCompanyDB: localStorage.db,
          };
          await userApp.post(
            `Transaction/Add_Attribute/`,
            objParems
          ).then(async (Res) => {
            if (Res.data.strStatus === "Success") {
              setarrEntAttDT(Res.data.EntAttribute);
              if (Number(iDimensionId === 2))
                await setarrEntColumns(Res.data.EntColumns);
              await Clear_Attribute();
              if (Res.data.EntAttribute.length === 1) setIsAttBackround(true);
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
        }
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  useEffect(() => {
    if (Number(iDimensionId === 2)) {
      let AttlvlColum = arrEntColumns.map((item) => {
        const { header: value, ...rest } = item;
        item = { ...rest, Header: value };
        return item;
      });
      AttlvlColum = AttlvlColum.concat({
        Header: "Delete",
        style: { justifyContent: "center" },
        minWidth: 50,
        maxWidth: 50,
        cell: ({ rowinfo }) => (
          <i
            onClick={() => Remove_AttLvlRow(rowinfo)}
            className="far fa-trash-alt"
          ></i>
        ),
      });
      setLevelColumns(AttlvlColum);
    }
  }, [arrEntAttLvlDT, arrEntColumns]);
  const Remove_Attribute = async (att) => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iDimId: Number(iDimensionId),
        AttId: Number(iAttId),
        AttCode: strAttCode,
        AttName: strAttName,
        EntAttribute: arrEntAttDT,
        EntLevel: arrAttLevel,
        iRowIndex: Number(att.sNo),
        strCompanyDB: localStorage.db,
      };
      await userApp.post(
        `Transaction/Remove_Attribute/`,
        objParems
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntAttDT(Res.data.EntAttribute);
          if (Number(iDimensionId === 2))
            await setarrEntColumns(Res.data.EntColumns);
          await Clear_Attribute();
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Add_EmptyLevel = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        EntLevel: arrEntAttLvlDT,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`Transaction/Add_EmptyLevel/`, objParems).then(
        async (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEntAttLvlDT(Res.data.EntLevel);
            await Clear_AttLvl();
            if (Res.data.EntLevel.length === 1) setIsLvlBackround(true);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Add_AttributeLevel = async () => {
    try {
      if (Number(iAttValId) === 0)
        AlertMessage("Please Select Attribute Value", "info");
      else if (iLvlIndex === "")
        AlertMessage("Please Select Attribute Level Row", "info");
      else {
        await dispatch(setload_Screen(true));
        const objParems = {
          iRowIndex: iLvlIndex,
          objLevel: {
            iId: Number(iAttValId),
            strName: strAttVal,
            AttLevelId: iLevelId,
            AttLevelType: LevelType,
          },
          EntLevel: arrEntAttLvlDT,
          strCompanyDB: localStorage.db,
        };
        await userApp.post(
          `Transaction/Add_AttributeLevel/`,
          objParems
        ).then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrEntAttLvlDT(Res.data.EntLevel);
            await Clear_Attribute();
            if (Res.data.EntLevel.length === 1) setIsLvlBackround(true);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Remove_AttLvlRow = async (rowinfo) => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iRowIndex: rowinfo.sNo,
        EntLevel: arrEntAttLvlDT,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(
        `Transaction/Remove_AttributeLevel/`,
        objParems
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntAttLvlDT(Res.data.EntLevel);
          if (Res.data.EntLevel.length === 1) setIsLvlBackround(true);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Attribute = async () => {
    setIsAtt(true);
    setIsAttLvl(false);
    setiLevelId(0);
    setLevelType("");
    setiAttId(0);
    setstrAttCode("");
    setstrAttName("");
    setarrEntAtt([]);
    setIsAttBackround(false);
    await Clear_AttLvl();
    await Load_Attribute(Number(iDimensionId));
  };
  const Clear_AttLvl = () => {
    setiAttValId(0);
    setstrAttVal("");
    setiLvlIndex("");
    setIsLvlBackround(false);
  };
  const Clear_Dimention = () => {
    setarrEntAtt([]);
    setarrEntAttDT([]);
    setLevelColumns([]);
    setarrEntColumns([]);
    setarrAttLevel([]);
    setarrEntAttLvlDT([]);
    setarrAttVal([]);
    setIsAtt(true);
    setIsAttLvl(false);
  };
  const Modify_Levels = async (Index) => {
    if (Number(iLevelId) === 0) AlertMessage("Please Select Attribute", "info");
    else {
      setIsLvlBackround(true);
      setiLvlIndex(Index);
      setIsAtt(false);
      setIsAttLvl(true);
    }
  };
  const AttColumn = [
    {
      Header: "S.No",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Level",
      accessor: "attLevelType",
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "AttCode",
      accessor: "attCode",
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Attribute",
      accessor: "attName",
      style: { justifyContent: "center" },
    },
    {
      Header: "Delete",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={() => Remove_Attribute(rowinfo)}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={ListFilter}
          EntList={ListAttGrp}
          GetDetails={View_AttributGroup}
          Count={iAttGrpCount}
          Search={Search_AttributeGroup}
          ScreenID={iScrId}
          accessor="strAttGroupName"
          accessorid="iAttGrpId"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-1">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  placeholder="Enter Attribute Group Name"
                  className="form-control"
                  value={strAttGroupName}
                  onChange={(e) => setstrAttGroupName(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-1">
                <label>
                  Dimension<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select Dimension"
                  select_value={async (val) => {
                    await Clear_Dimention();
                    await Load_Attribute(val);
                  }}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iDimensionId}
                  displayName="strAttDimName"
                  disvalue="iAttDimId"
                  arrydata={ListDimension}
                  EmptVal="Dimension"
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-1">
                <label>Remark</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Remarks"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-1">
                <label>Description</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Description"
                  value={strDescription}
                  onChange={(e) => setstrDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12 mb-1">
                <label>
                  Attribute<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select Attribute"
                  select_value={(val) => View_Attrbute(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iAttId}
                  displayName="strName"
                  disvalue="iId"
                  arrydata={arrEntAtt}
                  EmptVal="Attribute"
                  disabled={!IsAtt}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <label>
                  Attribute Value<span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select Attribute Value"
                  select_value={(val) => View_AttValues(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={false}
                  defaultval={iAttValId}
                  displayName="strName"
                  disvalue="iId"
                  arrydata={arrAttVal}
                  EmptVal="Attribute Value"
                  disabled={!IsAttLvl}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-1">
                <div className="btn-sub-section mt-3">
                  <button
                    onClick={() => {
                      if (IsAtt) Add_Attribute();
                      else Add_AttributeLevel();
                    }}
                    className="btn-fabgreen"
                  >
                    Add&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                  <button
                    onClick={() => Add_EmptyLevel()}
                    className="btn-fabgreen"
                    disabled={Number(iDimensionId) !== 2 ? true : false}
                  >
                    New Level&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                  <button
                    className="btn-fabgreen"
                    onClick={() => Clear_Attribute()}
                  >
                    Clear&nbsp;<i className="fas fa-eraser"></i>
                  </button>
                </div>
              </div>
              <div className=" p-2">
                <ReactTable
                  columns={AttColumn}
                  data={arrEntAttDT}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {
                    if (Number(iDimensionId) === 2) {
                      setIsAttBackround(true);
                      Load_AttValues(rowInfo);
                    }
                  }}
                  background={IsAttBackround}
                  className="full-table"
                />
              </div>
              {Number(iDimensionId) === 2 && (
                <div className=" p-2">
                  <ReactTable
                    columns={LevelColumns}
                    data={arrEntAttLvlDT}
                    minRows={5}
                    row_click={(rowInfo, Index) => {}}
                    row_doubleclick={(rowInfo, Index) => {
                      Modify_Levels(Index);
                    }}
                    background={IsLvlBackround}
                    className="full-table"
                  />
                </div>
              )}
            </div>

            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (strAttGroupName === "") {
                AlertMessage("Please Enter Name", "info");
              } else if (Number(arrEntAttDT.length) === 0) {
                AlertMessage("Atleast One Attribute Required", "info");
              } else {
                if (iAttGrpId > 0) {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Update Attribute Group?",
                      isdialog: true,
                    })
                  );
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Attribute Group?",
                      isdialog: true,
                    })
                  );
                }
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (iAttGrpId === 0) {
                AlertMessage("Please Select Attribute Group", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: Delete_AttributeGroup,
                    Question: "Do You Want to Delete Attribute Group?",
                    isdialog: true,
                  })
                );
              }
            }}
            ScrId={iScrId}
          />
        </div>
      </div>
    </>
  );
}
export default AttributeGroup;
