import React, { useEffect, useState } from "react";

import { userApp} from "../Admin/Utility";
import { Show_MsgBox } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import UnitMaster from "./UnitMaster";
import $ from "jquery";
function UOMMaster(props) {
  const dispatch = useDispatch();
  const [strUnitName, setstrUnitName] = useState("");
  const [DefaultUnit, setDefaultUnit] = useState([]);
  const [SelectedDefault, setSelectedDefault] = useState([]);
  const [listUnits, setlistUnits] = useState([]);
  const [IsUnitMasterOpen, setIsUnitMasterOpen] = useState(false);

  useEffect(() => {
    try {
      Load_UnitMaster("Existing");
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  }, []);
  const Load_UnitMaster = (UnitFor) => {
    try {
      userApp.get( "UOMMaster/GetDefaultData/" + localStorage.db).then(
        (Res) => {
          if (props.ListUnit.length > 0 && UnitFor === "Existing") {
            for (var i = 0; i < props.ListUnit.length; i++) {
              for (var D = 0; D < Res.data.length; D++) {
                if (Res.data[D].iUnitId === props.ListUnit[i].iUnitId) {
                  Res.data[D].bIsChecked = true;
                }
              }
            }
            setstrUnitName(props.UnitName);
            setSelectedDefault(props.ListUnit);
            setlistUnits(Res.data);
            setDefaultUnit(props.ListUnit);
          } else {
            setlistUnits(Res.data);
          }
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const CheckBox_Check = (checked, UnitViewCheck, ListUnit) => {
    try {
      let tempList = ListUnit === undefined ? listUnits : ListUnit;
      tempList.map((Unitview, Index) => {
        if (Unitview.iUnitId === UnitViewCheck.iUnitId) {
          Unitview.bIsChecked = checked;
        }
        return Unitview;
      });
      let SelectedUnit = tempList.filter((Data) => Data.bIsChecked === true);
      // Update State
      if (SelectedUnit.length > 0 && SelectedUnit.length <= 4) {
        setlistUnits(tempList);
        userApp.post( "UOMMaster/GenerateUnit/", {
          objViewModel: tempList,
          strCompanyDB: localStorage.db,
        }).then((Res) => {
          if (Res.data.strStatus === "Error") {
            AlertMessage(Res.data.strMessage, "info");
          } else if (Res.data.strStatus === "Validation") {
            setDefaultUnit([]);
            setstrUnitName("");
            setSelectedDefault([]);
          } else {
            setDefaultUnit(Res.data.objUnitModel);
            setstrUnitName(Res.data.strUnitName);
            setSelectedDefault(Res.data.objUnitModel);
          }
        });
      } else if (SelectedUnit.length > 4) {
        setlistUnits(listUnits);
        AlertMessage("More Then Four Unit Not Allowed", "info");
      } else {
        setDefaultUnit([]);
        setSelectedDefault([]);
      }
    } catch (err) {
      setDefaultUnit([]);
      setSelectedDefault([]);
      AlertMessage(err.message, "error");
    }
  };
  const OnChangebPurchaseDefault = (checked, PurchaseDefault) => {
    try {
      const AssignSelectedUnit = {
        iUnitId: PurchaseDefault.iUnitId,
        strUnitFor: "Purchase",
        objViewUnit: DefaultUnit,
        bIsChecked: checked,
      };
      userApp.post( "UOMMaster/SelectedUnit/", AssignSelectedUnit).then(
        (Res) => {
          setSelectedDefault(Res.data);
          setDefaultUnit(Res.data);
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const OnChangebSalesDefault = (checked, SalesDefault) => {
    try {
      const AssignSelectedUnit = {
        iUnitId: SalesDefault.iUnitId,
        strUnitFor: "Sales",
        objViewUnit: DefaultUnit,
        bIsChecked: checked,
      };
      userApp.post( "UOMMaster/SelectedUnit/", AssignSelectedUnit).then(
        (Res) => {
          setSelectedDefault(Res.data);
          setDefaultUnit(Res.data);
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const OnChangebReportDefault = (checked, ReportDefault) => {
    try {
      const AssignSelectedUnit = {
        iUnitId: ReportDefault.iUnitId,
        strUnitFor: "Report",
        objViewUnit: DefaultUnit,
        bIsChecked: checked,
      };
      userApp.post( "UOMMaster/SelectedUnit/", AssignSelectedUnit).then(
        (Res) => {
          setSelectedDefault(Res.data);
          setDefaultUnit(Res.data);
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const OnChangebStockDefault = (checked, StockDefault) => {
    try {
      const AssignSelectedUnit = {
        iUnitId: StockDefault.iUnitId,
        strUnitFor: "Stock",
        objViewUnit: DefaultUnit,
        bIsChecked: checked,
      };
      userApp.post( "UOMMaster/SelectedUnit/", AssignSelectedUnit).then(
        (Res) => {
          setSelectedDefault(Res.data);
          setDefaultUnit(Res.data);
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const ClearClick = (e) => {
    try {
      setlistUnits([]);
      setSelectedDefault([]);
      setDefaultUnit([]);
      setstrUnitName("");
      Load_UnitMaster("New");
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = () => {
    try {
      if (SelectedDefault.length === 0)
        AlertMessage("Please Select Unit", "error");
      else props.GetDefautUnit(SelectedDefault, strUnitName);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Unit Master</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="unitmaster p-0">
            <div className="unitlist">
              <div className="dt-header">
                <label>Unit Name</label>
              </div>
              <div className="unit-wrapper">
                {listUnits.map((unit, Index) => (
                  <button
                    className="dt-unit"
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = listUnits.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                    }}
                  >
                    <label>{unit.strUnitName}</label>
                    <input
                      type="checkbox"
                      checked={unit.bIsChecked}
                      className="form-check-input"
                      onChange={(e) => CheckBox_Check(e.target.checked, unit)}
                    />
                  </button>
                ))}
              </div>
            </div>
            <div className="unitlist">
              <div className="dt-header">
                <label>Unit Name</label>
                <label>Purchase</label>
                <label>Sales</label>
                <label>Stock</label>
                <label>Report</label>
              </div>
              {DefaultUnit.map((unit, Index) => (
                <button
                  className="dt-unit"
                  key={Index}
                  tabIndex={Index}
                  onKeyDown={(e) => {
                    const arrlength = DefaultUnit.length - 1;
                    if (e.key === "ArrowDown") {
                      if (Index === arrlength)
                        $(`.data-list [tabindex=${Index + 1}]`).focus();
                      else $(`.data-list [tabindex=${0}]`).focus();
                    }
                    if (e.key === "ArrowUp") {
                      if (Index === 0)
                        $(`.data-list [tabindex=${arrlength}]`).focus();
                      else $(`.data-list [tabindex=${Index - 1}]`).focus();
                    }
                  }}
                >
                  <label>{unit.strUnitName}</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bPurchaseDefault}
                    onChange={(e) =>
                      OnChangebPurchaseDefault(e.target.checked, unit)
                    }
                  />
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bSalesDefault}
                    onChange={(e) =>
                      OnChangebSalesDefault(e.target.checked, unit)
                    }
                  />
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bReportDefault}
                    onChange={(e) =>
                      OnChangebReportDefault(e.target.checked, unit)
                    }
                  />
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={unit.bStockDefault}
                    onChange={(e) =>
                      OnChangebStockDefault(e.target.checked, unit)
                    }
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => SaveClick(SelectedDefault)}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setIsUnitMasterOpen(!IsUnitMasterOpen)}
            >
              Create Unit&nbsp;<i className="bx bx-plus-circle"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              onClick={(e) => ClearClick(e)}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
      {IsUnitMasterOpen && (
        <UnitMaster
          Close={(e) => {
            setIsUnitMasterOpen(!IsUnitMasterOpen);
            Load_UnitMaster("New");
          }}
        />
      )}
    </div>
  );
}
export default UOMMaster;
