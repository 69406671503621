import { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import $ from "jquery";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import Print_DesignCaller from "../General/Print_DesignCaller";
import {
  Modify_ExpenseIncome,
  Modify_Vouchers,
  Selecte_Voucher,
  ShowHide_Popup,
  ShowHide_PrintSett,
  Update_BtnPermission,
} from "../ReduxStore/Transactionstates";
import { Validate_BtnPermission } from "./Transaction";
function TransactionView(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);

  const [strFromDate, setstrFromDate] = useState("");
  const [strToDate, setstrToDate] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [arrEntVoucher, setarrEntVoucher] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_Vouchers();
  }, []);

  const Load_Vouchers = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objSearch = {
        strColName: "Vouchers",
        iScrId: _Common.iScrId,
        iPartyId: 0,
        strCompanyDB: localStorage.db,
        strScrName: _Common.strScrName,
        iUserId: Number(localStorage.UserId),
      };
       await userApp 
        .post(`Transaction/Load_Vouchers`, objSearch)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            setstrFromDate(Res.data.strFromDate);
            setstrToDate(Res.data.strToDate);
            setarrEntVoucher(Res.data.objVouchers);
            setarrEntTableCol(Res.data.EntTablDesign);
            if (Res.data.objVouchers.length > 0)
              await dispatch(Selecte_Voucher(Res.data.objVouchers[0].vouchId));
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        });
    } catch (e) {
      await AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Search_Vouchers = async (Value, SearchFor) => {
    try {
      const objSearch = {
        iScrId: _Common.iScrId,
        dpFrom: strFromDate,
        dpTo: strToDate,
        strSearch: strSearch,
        iPartyId: 0,
        strCompanyDB: localStorage.db,
        strScrName: _Common.strScrName,
        iUserId: Number(localStorage.UserId),
      };
      switch (SearchFor) {
        case "From":
          objSearch.dpFrom = Value;
          await dispatch(setload_Screen(true));
          break;
        case "To":
          objSearch.dpTo = Value;
          await dispatch(setload_Screen(true));
          break;
        case "Search":
          setstrSearch(Value);
          objSearch.strSearch = Value;
          break;
        default:
          break;
      }
      await userApp.post("Transaction/Search_Vouchers/", objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntVoucher(Res.data.EntVouchers);
          setstrFromDate(Res.data.strFromDate);
          setstrToDate(Res.data.strToDate);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const PrintPdfClick = async (PrintFor) => {
    try {
      if (_HT.SelectedVouchId === 0) {
        AlertMessage("Please Select Screen", "info");
      } else
        await Print_DesignCaller(
          _Common.iScrId,
          _Common.strScrName,
          "Transaction",
          _HT.SelectedVouchId,
          0,
          PrintFor,
          dispatch,
          false
        );
    } catch (err) {
      AlertMessage(err.strMessage, "info");
    }
  };
  const View_Voucher = async (voucher) => {
    try {
      await dispatch(setload_Screen(true));
      if (_Common.iScrId === 38) {
        await dispatch(ShowHide_Popup("IsTransView"));
        props.View_Voucher(voucher);
      } else {
         await userApp 
          .get(
            `${_Common.Controller}/View_Voucher/${voucher.vouchId}/${localStorage.db}`
          )
          .then(async (Res) => {
            if (Res.data.strStatus === "Success") {
              await dispatch(ShowHide_Popup("IsTransView"));
              let BtnDisable = false;
              if (_Common.iScrId === 66) {
                await dispatch(Modify_ExpenseIncome(Res.data));
                BtnDisable = false;
              } else {
                await dispatch(
                  Modify_Vouchers({ Data: Res.data, VouchId: voucher.vouchId })
                );
                BtnDisable = Res.data.IsDisable;
              }

              await dispatch(
                Update_BtnPermission(
                  await Validate_BtnPermission(
                    _Common.strScrType,
                    _Common.iScrId,
                    voucher.vouchId,
                    BtnDisable
                  )
                )
              );
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{_Common.strScrName} View</h5>
          <div className="icon-popup-btn-grp">
            {!_Common.btnPrint && (
              <span
                className="icon-btn"
                onClick={(e) => dispatch(ShowHide_PrintSett(true))}
              >
                <i className="fa-solid fa-gear"></i>
              </span>
            )}
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsTransView"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row ">
            <div className="col-md-6 col-sm-12 mt-1">
              <div className="m-hsearch ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  autoFocus
                  autoComplete="off"
                  id="Invoice_Search"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp") $("#from_date").focus();
                    else if (e.key === "ArrowDown")
                      $(".option-list [tabindex=0]").focus();
                    else if (e.key === "Escape")
                      dispatch(ShowHide_Popup("IsTransView"));
                  }}
                  tabIndex={52}
                  name="strSearch"
                  value={strSearch}
                  onChange={(e) => Search_Vouchers(e.target.value, "Search")}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Vouchers(strSearch, "Search")}
                ></i>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mt-1">
              <input
                type="date"
                className="form-control"
                value={strFromDate}
                name="strFromDate"
                id="from_date"
                onKeyDown={(e) => {
                  if (e.key === "ArrowLeft") $("#Invoice_Search").focus();
                  else if (e.key === "ArrowRight") $("#to_date").focus();
                  else if (e.key === "Escape")
                    dispatch(ShowHide_Popup("IsTransView"));
                }}
                onChange={(e) => Search_Vouchers(e.target.value, "From")}
              />
            </div>
            <div className="col-md-3 col-sm-6 mt-1">
              <input
                type="date"
                id="to_date"
                onKeyDown={(e) => {
                  if (e.key === "ArrowLeft") $("#from_date").focus();
                  else if (e.key === "ArrowRight")
                    $(".option-list [tabindex=0]").focus();
                  else if (e.key === "Escape")
                    dispatch(ShowHide_Popup("IsTransView"));
                }}
                className="form-control"
                value={strToDate}
                name="strToDate"
                onChange={(e) => Search_Vouchers(e.target.value, "To")}
              />
            </div>
          </div>
          <div className="table-card mt-1">
            <table className="option-list">
              <thead>
                <tr>
                  {arrEntTableCol.map(
                    (ht, Index) =>
                      ht.isShow && <th key={Index}>{ht.strAliasName}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {arrEntVoucher.map((voucher, Index) => (
                  <tr
                    key={Index}
                    tabIndex={Index}
                    onKeyDown={(e) => {
                      const arrlength = arrEntVoucher.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.option-list [tabindex=${0}]`).focus();
                        else $(`.option-list [tabindex=${Index + 1}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.option-list [tabindex=${arrlength}]`).focus();
                        else $(`.option-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "ArrowLeft") $("#to_date").focus();
                      if (e.key === "ArrowRight") $("#Invoice_Search").focus();
                      if (e.key === "Enter") View_Voucher(voucher);
                    }}
                    onClick={(e) => dispatch(Selecte_Voucher(voucher.vouchId))}
                    onDoubleClick={(e) => View_Voucher(voucher)}
                  >
                    {arrEntTableCol.map(
                      (dt, Index) =>
                        dt.isShow && (
                          <td
                            key={Index}
                            style={{
                              textAlign: dt.strTextAlign,
                              width: dt.strWidth.includes("%")
                                ? dt.strWidth
                                : dt.strWidth + "px",
                              maxWidth: dt.strWidth.includes("%")
                                ? dt.strWidth
                                : dt.strWidth + "px",
                              color: "black",
                            }}
                          >
                            {dt.strDataType === "number"
                              ? Number(voucher[dt.strColName]).toFixed(2)
                              : voucher[dt.strColName]}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="popup-footer">
          {!_Common.btnPrint && (
            <div className="popup-btn-group">
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  if (_HT.SelectedVouchId === 0)
                    AlertMessage("Please Select Voucher", "info");
                  PrintPdfClick("Print");
                }}
              >
                Print&nbsp;<i className="fa-solid fa-print"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  if (_HT.SelectedVouchId === 0)
                    AlertMessage("Please Select Voucher", "info");
                  else PrintPdfClick("PDF");
                }}
              >
                PDF&nbsp;<i className="far fa-file-pdf"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  if (_HT.SelectedVouchId === 0)
                    AlertMessage("Please Select Voucher", "info");
                  else dispatch(ShowHide_Popup("IsEmail"));
                }}
              >
                Email&nbsp;<i className="fa-solid fa-envelope"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={(e) => {
                  if (_HT.SelectedVouchId === 0)
                    AlertMessage("Please Select Voucher", "info");
                  else dispatch(ShowHide_Popup("IsEInvoice"));
                }}
                disabled={
                  _Common.iScrId === 12 || _Common.iScrId === 18 ? true : false
                }
              >
                E-Invoice&nbsp;<i className="bx bx-receipt"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default TransactionView;
