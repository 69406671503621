import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import { userApp } from "../Admin/Utility";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
function Configuration(props) {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [strSearch, setstrSearch] = useState("");
  const [iFiltColId, setiFiltColId] = useState(0);
  const [arrUpdateSettings, setarrUpdateSettings] = useState([]);
  const [arrEntSetting, setarrEntSetting] = useState([]);
  const [arrEntOption, setarrEntOption] = useState([]);
  const [arrFilterCol, setarrFilterCol] = useState([]);
  useEffect(() => {
    Load_ConfigurationSetting(0, "Filter");
  }, [props]);

  const Load_ConfigurationSetting = async (Value, Searchfor) => {
    try {
      await dispatch(setload_Screen(true));
      let FiltColId = iFiltColId;
      let Search = strSearch;
      switch (Searchfor) {
        case "Filter":
          setiFiltColId(Value);
          FiltColId = Value;
          break;
        default:
          setstrSearch(Value);
          Search = Value;
          break;
      }
      const parems = {
        iIndex: Number(FiltColId),
        strSearch: Search,
        strCompanyDB: localStorage.db,
      };
      await userApp
        .post(`ConfigurationSetting/Load_ConfigurationSetting`, parems)
        .then((res) => {
          res.data.EntSource.push(
            {
              ctrl: "cmbT13",
              isShow: false,
              arrVal: res.data.cmbT13,
            },
            {
              ctrl: "cmbT22",
              isShow: false,
              arrVal: res.data.cmbT22,
            },
            {
              ctrl: "cmbX2",
              isShow: false,
              arrVal: res.data.cmbX2,
            },
            {
              ctrl: "cmbT38",
              isShow: false,
              arrVal: res.data.cmbT38,
            }
          );
          setarrEntOption(res.data.EntSource);
          setarrEntSetting(
            res.data.objEntSetting.sort((a, b) => a.sNo - b.sNo)
          );
          setarrFilterCol(res.data.EntFilterCol);
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Save_ConfigurationSetting = async () => {
    try {
      if (arrUpdateSettings.length === 0)
        AlertMessage("Atleast One Change Required", "info");
      else {
        await dispatch(setload_Screen(true));
        await userApp
          .post("ConfigurationSetting/Update_Configuration/", {
            _objUpdSetting: arrUpdateSettings,
            strCompanyDB: localStorage.db,
          })
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              Clear_ConfigurationSetting();
              AlertMessage(Res.data.strMessage, "success");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Clear_ConfigurationSetting = () => {
    setstrSearch("");
    setarrUpdateSettings([]);
    setarrEntSetting([]);
    Load_ConfigurationSetting(iFiltColId, "Filter");
  };
  const Input_Onchange = (value, sno, ctrl) => {
    let EntSetting = arrEntSetting.map((dt) => {
      if (dt.sNo === sno) {
        dt.value = value;
        Add_UpdatedValues(dt);
      }
      return dt;
    });
    setarrEntSetting(EntSetting);
    isShowOption(false, ctrl);
  };
  const isShowOption = (value, ctl) => {
    let EntOption = arrEntOption.map((dt) => {
      if (dt.ctrl === ctl) {
        dt.isShow = value;
      }
      return dt;
    });
    setarrEntOption(EntOption);
  };
  const isOptionShow = (ctl) => {
    return arrEntOption.filter((dt) => dt.ctrl === ctl)[0].isShow;
  };
  const getarroption = (ctl) => {
    return arrEntOption.filter((dt) => dt.ctrl === ctl)[0].arrVal;
  };
  const isShowselect = (ctrl) => {
    switch (ctrl) {
      case "cmbT13":
      case "cmbT22":
      case "cmbX2":
      case "cmbT38":
        return true;
      default:
        return false;
    }
  };
  const OptionCol = (ctrl) => {
    switch (ctrl) {
      case "cmbT13":
        return "strBillType";
      case "cmbT22":
        return "strTaxType";
      case "cmbX2":
        return "optionName";
      case "cmbT38":
        return "optionName";
      default:
        break;
    }
  };
  const OptionVal = (ctrl) => {
    switch (ctrl) {
      case "cmbT13":
        return "iBT_Id";
      case "cmbT22":
        return "iTaxRelId";
      case "cmbX2":
        return "taxCalcOnId";
      case "cmbT38":
        return "addLessBillDiscId";
      default:
        break;
    }
  };
  const GetOptionVal = (ctrl, value) => {
    let strValues = "";
    let Entarroption = getarroption(ctrl);
    value = Number(value);
    Entarroption.forEach((dt) => {
      if (dt[OptionVal(ctrl)] === value) strValues = dt[OptionCol(ctrl)];
    });
    return strValues;
  };
  const Add_UpdatedValues = (objSett) => {
    let EntUpdSett = arrUpdateSettings;
    EntUpdSett = EntUpdSett.filter((dt) => dt.sNo !== objSett.sNo);
    EntUpdSett.push(objSett);
    setarrUpdateSettings(EntUpdSett);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content medium">
        <div className="popup-header">
          <h5>Configuration Settings</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="search-card">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search Voucher Screen"
                value={strSearch}
                onChange={(e) => {
                  Load_ConfigurationSetting(e.target.value, "Search");
                }}
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Load_ConfigurationSetting(strSearch, "Search")}
              ></i>
            </div>
            <CustomizedSelectoption
              optionparem="isoptionShow10"
              showoption={_selecrparem.isoptionShow10}
              placeholder="Filter Setting"
              select_value={(val) => Load_ConfigurationSetting(val, "Filter")}
              btnname="Setting"
              btnshow={false}
              show_popup=""
              valueonly={true}
              defaultval={iFiltColId}
              displayName="strDisplayName"
              disvalue="iFilterId"
              arrydata={arrFilterCol}
              disabled={false}
              EmptVal="Setting"
            />
          </div>
          <div className="table-card">
            <table className="option-list">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Setting</th>
                  <th>Values</th>
                </tr>
              </thead>
              <tbody>
                {arrEntSetting.map((setting, Index) => (
                  <tr key={Index}>
                    <td>{setting.sNo}</td>
                    <td>{setting.name}</td>
                    <td>
                      {setting.ctrlType.startsWith("cmb") &&
                        setting.ctrlType !== "cmbT13" &&
                        setting.ctrlType !== "cmbT22" &&
                        setting.ctrlType !== "cmbX2" &&
                        setting.ctrlType !== "cmbT38" && (
                          <div
                            className="custom-select"
                            onMouseLeave={(e) =>
                              isShowOption(false, setting.ctrlType)
                            }
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Select Default Value"
                              value={setting.value}
                              readOnly
                              onClick={(e) =>
                                isShowOption(true, setting.ctrlType)
                              }
                            />
                            <i
                              className="fa-solid fa-caret-down"
                              style={{
                                position: "absolute",
                                right: "10px",
                                color: "darkgreen",
                                transform: isOptionShow(setting.ctrlType)
                                  ? "rotate(-180deg)"
                                  : "rotate(0deg)",
                              }}
                            ></i>
                            {isOptionShow(setting.ctrlType) && (
                              <div className="custom-option">
                                <div className="option-list">
                                  {getarroption(setting.ctrlType).map(
                                    (value, Index) => (
                                      <button
                                        key={Index}
                                        tabIndex={Index}
                                        style={{
                                          height: "32px",
                                          borderBottom: "none",
                                        }}
                                        onClick={(e) =>
                                          Input_Onchange(
                                            value,
                                            setting.sNo,
                                            setting.ctrlType
                                          )
                                        }
                                      >
                                        {value}
                                      </button>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      {isShowselect(setting.ctrlType) && (
                        <div
                          className="custom-select"
                          onMouseLeave={(e) =>
                            isShowOption(false, setting.ctrlType)
                          }
                        >
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Select Default Value"
                            value={GetOptionVal(
                              setting.ctrlType,
                              setting.value
                            )}
                            readOnly
                            onClick={(e) =>
                              isShowOption(true, setting.ctrlType)
                            }
                          />
                          <i
                            className="fa-solid fa-caret-down"
                            style={{
                              position: "absolute",
                              right: "10px",
                              color: "darkgreen",
                              transform: isOptionShow(setting.ctrlType)
                                ? "rotate(-180deg)"
                                : "rotate(0deg)",
                            }}
                          ></i>
                          {isOptionShow(setting.ctrlType) && (
                            <div className="custom-option">
                              <div className="option-list">
                                {getarroption(setting.ctrlType).map(
                                  (dt, Index) => (
                                    <button
                                      key={Index}
                                      tabIndex={Index}
                                      style={{
                                        height: "32px",
                                        borderBottom: "none",
                                      }}
                                      onClick={(e) =>
                                        Input_Onchange(
                                          dt[OptionVal(setting.ctrlType)],
                                          setting.sNo,
                                          setting.ctrlType
                                        )
                                      }
                                    >
                                      {dt[OptionCol(setting.ctrlType)]}
                                    </button>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {setting.ctrlType.startsWith("txt") && (
                        <input
                          type="text"
                          className="form-control"
                          onClick={(e) => e.target.select()}
                          value={setting.value}
                          onChange={(e) =>
                            Input_Onchange(e.target.value, setting.sNo)
                          }
                        />
                      )}
                      {setting.ctrlType.startsWith("dtp") && (
                        <input
                          type="date"
                          className="form-control"
                          onClick={(e) => e.target.select()}
                          value={setting.value}
                          onChange={(e) =>
                            Input_Onchange(e.target.value, setting.sNo)
                          }
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={Save_ConfigurationSetting}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={Clear_ConfigurationSetting}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configuration;
