import React from "react";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import "antd/dist/reset.css";
function IncomeChart(props) {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0) {
      var Amount = payload === null ? 0 : payload[0].value;
      return (
        <div className="graptooltip">
          {label} <span>Rs.{Amount}.00</span>
        </div>
      );
    }
    return "";
  };
  return (
    <div className="salesgrap-cont">
      <ResponsiveContainer className="salesgrap">
        <AreaChart data={props.SalesSummary}>
          <defs>
            <linearGradient id="colorSales" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2451B7" stopOpacity={0.4} />
              <stop offset="75%" stopColor="#2451B7" stopOpacity={0.05} />
            </linearGradient>
          </defs>
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid opacity={0.1} vertical={false} />
          <XAxis
            dataKey={props.SalesSummary?.Time || "Time"} // Providing fallback if no SalesSummary
            axisLine={false}
            tickLine={false}
            tickCount={10}
          />
          <YAxis
            dataKey={props.SalesSummary?.Amount || "Amount"} // Same for Amount
            axisLine={false}
            tickLine={false}
            tickCount={5}
            tickFormatter={(number) => number.toFixed()}
          />
          <Area dataKey="Amount" stroke="#2451B7" fill="url(#colorSales)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
export default IncomeChart;
