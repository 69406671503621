import React, { useState, useEffect } from "react";
import { userApp} from "../Admin/Utility";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  Select_Service,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import PopupSearch from "./PopupSearch";

function ServiceSearch(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);

  const [arrService, setarrService] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [arrEntTableCol, setarrEntTableCol] = useState([]);

  useEffect(() => {
    Load_Service();
  }, []);
  const Load_Service = async () => {
    try {
      const objTrans = {
        Controller: "",
        strScrType: _Common.strScrType,
        iScrId: _Common.iScrId,
        iPartyScrId: _Common.PartyScrId,
        iPartyId: 0,
        iVenType: _Common.iScrId === 66 ? _Common.VenTypeId : 1,
        strSearch: "",
        strColName: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(`Transaction/Load_Service/`, objTrans).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrService(Res.data.Service);
            setarrEntTableCol(Res.data.EntTablDesign);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Service = async (strtxt) => {
    try {
      const objTrans = {
        Controller: "",
        strScrType: _Common.strScrType,
        iScrId: _Common.iScrId,
        iPartyScrId: _Common.PartyScrId,
        iPartyId: 0,
        iVenType: _Common.iScrId === 66 ? _Common.VenTypeId : 1,
        strSearch: strtxt,
        strColName: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
      };
      await userApp.post(`Transaction/Search_Service/`, objTrans).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrService(Res.data.Service);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const View_Service = async (serinfo) => {
    await dispatch(ShowHide_Popup("IsSerSearch"));
    await dispatch(Select_Service(serinfo));
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <PopupSearch
      ScrName="Service Search"
      FilterCol={[]}
      FilterVal={""}
      FilterShow={false}
      strSearch={strSearch}
      Columns={arrEntTableCol}
      Data={arrService}
      UpdateFilterCol={(col) => {}}
      Search_Data={(txt) => {
        setstrSearch(txt);
        Search_Service(txt);
      }}
      View_Data={(e) => View_Service(e)}
      Close={() => dispatch(ShowHide_Popup("IsSerSearch"))}
      IsService={true}
    />
  );
}

export default ServiceSearch;
