import React, { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import Messagedialogbox from "../General/Messagedialogbox";
import ReactTable from "../General/ReactTable";
import SideMenu from "../General/SideMenu";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
function BarcodeSetting(props) {
  const dispatch = useDispatch();
  const _selectparem = useSelector((state) => state.SelectOption.parems);

  const [iPrintArea, setiPrintArea] = useState(2);
  const [iPrintAreaColumId, setiPrintAreaColumId] = useState(0);
  const [PrintAreaSelected, setPrintAreaSelected] = useState(0);
  const [RemarkEnable, setRemarkEnable] = useState(true);
  const [strColumName, setstrColumName] = useState("");
  const [iColumId, setiColumId] = useState(0);
  const [ColumType, setColumType] = useState(0);

  const [strLineColor, setstrLineColor] = useState("black");
  const [strfont, setstrfont] = useState("Times");
  const [strfontstyle, setstrfontstyle] = useState("normal");
  const [strFontWeight, setstrFontWeight] = useState("500");
  const [strTextAlign, setstrTextAlign] = useState("center");
  const [FontSize, setFontSize] = useState(10);
  const [strwordwrap, setstrwordwrap] = useState("nowrap");

  const [X1Position, setX1Position] = useState(0);
  const [Y1Position, setY1Position] = useState(0);
  const [ColumWidth, setColumWidth] = useState(0);
  const [iInOrder, setiInOrder] = useState(1);
  const [strPrefix, setstrPrefix] = useState("");
  const [strSuffix, setstrSuffix] = useState("");
  const [ListBarcodePrintArea, setListBarcodePrintArea] = useState([]);
  const [ListBarcodePrintAreaDesign, setListBarcodePrintAreaDesign] = useState(
    []
  );
  useEffect(() => {
    SearchPrintArea(2);
  }, []);
  const SearchPrintArea = async (Value) => {
    try {
      dispatch(setload_Screen(true));
      setiPrintArea(Value);
      setListBarcodePrintArea([]);
       await userApp 
        .get("Barcode/SearchPrintArea/" + Number(Value) + "/" + localStorage.db)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            let printArea = [];
            setListBarcodePrintAreaDesign(Res.data.objEntPrintDesign);
            Res.data.objEntPrintArea.map((Data, Index) => {
              const AreaObject = {
                BSettId: Data.bSettId,
                ColumName: Data.columName,
                DisplayName: Data.displayName,
                ColumType: Data.columType,
                PrintStatus: Data.printStatus === 1 ? true : false,
              };
              printArea.push(AreaObject);
              return Data;
            });
            setListBarcodePrintArea(printArea);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    setiPrintArea(2);
    setiPrintAreaColumId(0);
    setPrintAreaSelected(0);
    setstrColumName("");
    setstrLineColor("black");
    setstrfont("Times");
    setFontSize(12);
    setstrTextAlign("left");
    setstrfontstyle("normal");
    setstrwordwrap("nowrap");
    setstrFontWeight("500");
    setiColumId(0);
    setColumType(0);
    setX1Position("");
    setY1Position("");
    setColumWidth("");
    setiInOrder(1);
    setstrPrefix("");
    setstrSuffix("");
    setListBarcodePrintArea([]);
    setListBarcodePrintAreaDesign([]);
    SearchPrintArea(2);
  };
  const PrintColumn_Checked = (Value, RownInfo) => {
    let Product = ListBarcodePrintArea;
    Product.map((Data) => {
      if (RownInfo.iProId === Data.iProId) Data.SelectProduct = Value;
      return Data;
    });
    setListBarcodePrintArea(Product);
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const InsUpdSetting = {
        PrintAreaId: Number(iPrintAreaColumId),
        iColumnId: Number(iColumId),
        strColumName: strColumName,
        InOrder: Number(iInOrder),
        dWidth: Number(ColumWidth),
        dYAxis: Number(Y1Position),
        dXAxis: Number(X1Position),
        iFontSize: Number(FontSize),
        strFontWeight: strFontWeight,
        strFontStyle: strfontstyle,
        strColor: strLineColor,
        ColumType: ColumType,
        strPrefix: strPrefix,
        strSuffix: strSuffix,
        strTextAlign: strTextAlign,
        strfont: strfont,
        strwordwrap: strwordwrap,
        strCompanyDB: localStorage.db,
      };
      await userApp.post("Barcode/InserPrintArea/", InsUpdSetting).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
       await userApp 
        .post(
          "Barcode/DeletePrintArea/" +
            Number(iPrintAreaColumId) +
            "/" +
            localStorage.db
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AssignPrintDesign = (rowinfo) => {
    setColumWidth(rowinfo.dWidth);
    setX1Position(rowinfo.dXAxis);
    setY1Position(rowinfo.dYAxis);
    setiColumId(rowinfo.iColumnId);
    setColumType(rowinfo.columType);
    setFontSize(rowinfo.iFontSize);
    setiInOrder(rowinfo.inOrder);
    setiPrintAreaColumId(rowinfo.printAreaId);
    setstrLineColor(rowinfo.strColor);
    setstrFontWeight(rowinfo.strFontWeight);
    setstrPrefix(rowinfo.strPrefix);
    setstrSuffix(rowinfo.strSuffix);
    setstrTextAlign(rowinfo.strTextAlign);
    setstrColumName(rowinfo.strColumName);
    setstrfont(rowinfo.strfont);
    setstrwordwrap(rowinfo.strwordwrap);
    setstrfontstyle(rowinfo.strFontStyle);
  };
  const PrintAreaDesignColum = [
    {
      Header: "Design Name",
      id: "printAreaId",
      accessor: "strColumName",
      style: {
        justifyContent: "start",
      },
    },
    {
      Header: "XPosition",
      accessor: "dXAxis",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "YPosition",
      accessor: "dYAxis",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Width",
      accessor: "dWidth",
      style: {
        justifyContent: "start",
      },
    },
    {
      Header: "InOrder",
      accessor: "inOrder",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Font Size",
      accessor: "iFontSize",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Color",
      accessor: "strColor",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Font Style",
      accessor: "strFontWeight",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Prefix",
      accessor: "strPrefix",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Suffix",
      accessor: "strSuffix",
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Text Align",
      accessor: "strTextAlign",
      style: {
        justifyContent: "center",
      },
    },
  ];
  const ListPrintArea = [
    { Value: 2, AreaName: "Product" },
    { Value: 1, AreaName: "Company" },
  ];
  const KeyDown = (e) => {
    try {
      var RowCount = ListBarcodePrintArea.length - 1;
      if (e.key === "ArrowDown") {
        if (RowCount === PrintAreaSelected) setPrintAreaSelected(0);
        else setPrintAreaSelected(PrintAreaSelected + 1);
      } else if (e.key === "ArrowUp") {
        if (PrintAreaSelected === 0) setPrintAreaSelected(RowCount);
        else setPrintAreaSelected(PrintAreaSelected - 1);
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  const arrTextAlignment = ["center", "left", "right", "justify"];
  const arrFontWeight = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "bold",
    "normal",
  ];
  const arrWordWrab = ["nowrap", "wrap"];
  return (
    <>
      <SideMenu ScrId={121} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="p-2">
            <CustomizedSelectoption
              optionparem="isoptionShow"
              showoption={_selectparem.isoptionShow}
              placeholder="Select Print Area"
              select_value={(val) => SearchPrintArea(val)}
              btnname=""
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={iPrintArea}
              displayName="AreaName"
              disvalue="Value"
              arrydata={ListPrintArea}
              EmptVal="Print Area"
            />
          </div>
          <div className="data-list">
            {ListBarcodePrintArea.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = ListBarcodePrintArea.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    setiColumId(dt.BSettId);
                    setColumType(dt.ColumType);
                    if (dt.BSettId === 12 || dt.BSettId === 21) {
                      setRemarkEnable(false);
                      setstrColumName("");
                    } else {
                      setRemarkEnable(true);
                      setstrColumName(dt.ColumName);
                    }
                  }
                }}
              >
                <label
                  onClick={(e) => {
                    setiColumId(dt.BSettId);
                    setColumType(dt.ColumType);
                    if (dt.BSettId === 12 || dt.BSettId === 21) {
                      setRemarkEnable(false);
                      setstrColumName("");
                    } else {
                      setRemarkEnable(true);
                      setstrColumName(dt.ColumName);
                    }
                  }}
                >
                  {dt.DisplayName}
                </label>
                <input
                  type="checkbox"
                  defaultChecked={dt.PrintStatus}
                  className="form-check"
                  onChange={(e) => PrintColumn_Checked(e.target.checked, dt)}
                />
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row mb-2">
              <div className="col-sm-12 col-md-3 mb-1">
                <label>Colum Name</label>
                <input
                  type="text"
                  value={strColumName}
                  onChange={(e) => setstrColumName(e.target.value)}
                  className="form-control rounded"
                  onClick={(e) => e.target.select()}
                  readOnly={RemarkEnable}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Prefix</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Prefix"
                  onClick={(e) => e.target.select()}
                  value={strPrefix}
                  onChange={(e) => setstrPrefix(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Suffix</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Suffix"
                  onClick={(e) => e.target.select()}
                  value={strSuffix}
                  onChange={(e) => setstrSuffix(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>X Axis</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Line Start Point"
                  value={X1Position}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setX1Position(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Y Axis</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Height Start Point"
                  value={Y1Position}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setY1Position(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-3 mt-1">
                <label>Width</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Colum Width"
                  onClick={(e) => e.target.select()}
                  value={ColumWidth}
                  onChange={(e) => setColumWidth(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Font Size</label>
                <input
                  type="text"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  value={FontSize}
                  onChange={(e) => setFontSize(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-md-2 p-2">
                <label>In Order</label>
                <input
                  type="text"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  value={iInOrder}
                  onChange={(e) => setiInOrder(Number(e.target.value))}
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Font Weight</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow2"
                  showoption={_selectparem.isoptionShow2}
                  placeholder="Select Font Weight"
                  select_value={(val) => setstrFontWeight(val)}
                  defaultval={strFontWeight}
                  arrydata={arrFontWeight}
                  EmptVal="Font Weight"
                />
              </div>
              <div className="col-sm-6 col-md-2 mt-1">
                <label>Alignment</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow3"
                  showoption={_selectparem.isoptionShow3}
                  placeholder="Select Text Align"
                  select_value={(val) => setstrTextAlign(val)}
                  defaultval={strTextAlign}
                  arrydata={arrTextAlignment}
                  EmptVal="Text Align"
                />
              </div>
              <div className="col-sm-6 col-md-3 mt-1">
                <label>
                  Color<span className="danger"></span>
                </label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow4"
                  showoption={_selectparem.isoptionShow4}
                  placeholder="Select Color"
                  select_value={(val) => setstrLineColor(val)}
                  defaultval={strLineColor}
                  arrydata={arrColor}
                  EmptVal="Color"
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-1">
                <label>Word Wrap</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow5"
                  showoption={_selectparem.isoptionShow5}
                  placeholder="Select Word Wrap"
                  select_value={(val) => setstrwordwrap(val)}
                  defaultval={strwordwrap}
                  arrydata={arrWordWrab}
                  EmptVal="Word Wrap"
                />
              </div>
            </div>
            <ReactTable
              columns={PrintAreaDesignColum}
              data={ListBarcodePrintAreaDesign}
              minRows={5}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {
                AssignPrintDesign(rowInfo);
              }}
              background={true}
              className="full-table"
            />
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={() => {
                if (iPrintAreaColumId === 0)
                  AlertMessage("Please Select Column Design", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Design ?",
                      isdialog: true,
                    })
                  );
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) =>
                (window.location.href = "/Others/BarcodeGenerator")
              }
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BarcodeSetting;
