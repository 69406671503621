
import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";

import { userApp} from "../Admin/Utility";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function ReNameScreen() {
  const dispatch = useDispatch();
  const [strSearch, setstrSearch] = useState("");
  const [ReNameList, setReNameList] = useState([]);

  useEffect(() => {
    GetDefaultVoucherNumber();
  }, []);

  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const GetDefaultVoucherNumber = () => {
    try {
      setReNameList([]);
      var Search = strSearch === "" ? "null" : strSearch;
      userApp.get(
        
          "RenameScreen/GetDefaultScreenName/" +
          Search +
          "/" +
          localStorage.db
      ).then((Res) => {
        setReNameList(Res.data);
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const SearchSetting = (SearchText) => {
    try {
      setstrSearch(SearchText);
      SearchText = SearchText === "" ? "null" : SearchText;
      userApp.get(
        
          "RenameScreen/GetDefaultScreenName/" +
          SearchText +
          "/" +
          localStorage.db
      ).then((Res) => {
        setReNameList(Res.data);
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const UpdatClick = () => {
    try {
      let UpdateVoucherNumber = ReNameList;
      let ValidateRename = UpdateVoucherNumber.filter(
        (Data) => Data.strDefaultNameAs === ""
      );
      if (ValidateRename.length > 0) AlertMessage("Name Is Reqiured", "info");
      else {
        userApp.post( "RenameScreen/UpdateScreenName/", {
          objEntRename: UpdateVoucherNumber,
          strCompanyDB: localStorage.db,
        }).then((Res) => {
          if (Res.data.strStatus === "Success") {
            GetDefaultVoucherNumber();
            AlertMessage(Res.data.strMessage, "success");
          } else AlertMessage(Res.data.strMessage, "error");
        });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Input_OnChange = (Value, RowInfo) => {
    let UpdateVoucherList = ReNameList;
    UpdateVoucherList = UpdateVoucherList.map((Data) => {
      if (Data.iRenameId === RowInfo.iRenameId) {
        Data.strDefaultNameAs = Value;
      }
      return Data;
    });
    setReNameList(UpdateVoucherList);
  };
  const CloseClick = () => {
    window.location.href = "/Dashboard";
  };

  const RenameColum = [
    {
      Header: "Screen Name",
      accessor: "strScrName",
    },
    {
      Header: "Default Name",
      accessor: "strDefaultName",
    },
    {
      Header: "Change Name",
      accessor: "strDefaultNameAs",
      cell: ({ rowinfo }) => (
        <input
          type="text"
          defaultValue={rowinfo.strDefaultNameAs}
          onClick={(e) => {
            e.target.select();
          }}
          onBlur={(e) => {
            Input_OnChange(e.target.value, rowinfo);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <SideMenu ScrId={106} />
      <div className="Master-view">
        <div className="container-fluid">
          <div className="fg-card shadow-base p-2">
            <div className=" d-flex justify-content-center">
              <div className="d-flex input-group w-auto">
                <input
                  type="search"
                  className="form-control rounded"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  style={{
                    minWidth: "250px",
                    borderRadius: " 3px 0 0 3px !important",
                  }}
                  name="strSearch"
                  value={strSearch}
                  onChange={(e) => SearchSetting(e.target.value)}
                />
                <span className="input-group-text border-0" id="search-addon">
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
            <ReactTable
              columns={RenameColum}
              data={ReNameList}
              minRows={5}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {}}
              background={true}
              className="full-table"
            />
            <div className=" mt-1 d-flex justify-content-end">
              <button className="btn-fabgreen" onClick={UpdatClick}>
                Save&nbsp;<i className="bx bxs-save"></i>
              </button>
              <button
                className="btn-fabgreen"
                onClick={GetDefaultVoucherNumber}
              >
                Clear&nbsp;<i className="fa-solid fa-broom"></i>
              </button>
              <button className="btn-fabgreen" onClick={CloseClick}>
                Close&nbsp;<i className="fas fa-times"></i>
              </button>
            </div>
          </div>
          <Messagedialogbox />
        </div>
      </div>
    </div>
  );
}
export default ReNameScreen;
