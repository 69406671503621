
import React, { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
function CategoryMaster(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 4;
  const strCatCodeTag = "Enter Category Code";
  const [iCatId, setiCatId] = useState(0);
  const [strCatCode, setstrCatCode] = useState("");
  const [strCatName, setstrCatName] = useState("");
  const [strDescription, setstrDescription] = useState("");
  const [iCatTypeId, setiCatTypeId] = useState("1");
  const [strFilterColumn, setstrFilterColumn] = useState("All");
  const [strSearch, setstrSearch] = useState("");
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntFillter, setlistEntFillter] = useState([]);
  const [listEntCat, setlistEntCat] = useState([]);
  const [listEntCatType, setlistEntCatType] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);

  useEffect(() => {
    Load_CategoreMaster();
  }, []);
  const Load_CategoreMaster = () => {
    try {
      userApp.get(
         "Category/GetDefaultData/" + iScrId + "/" + localStorage.db
      ).then((Res) => {
        setlistEntFillter(Res.data.objEntComBoxVa);
        setlistEntCat(Res.data.objEntCatName);
        setlistEntCatType(Res.data.objEntCatType);
        setiCatTypeId(Number(props.Index));
        setlistCodeFormat(Res.data.objCodeFormat);
        setstrCatCode(Res.data.objCodeFormat[0].strCode);
        setManualCode(!Res.data.objCodeFormat[0].isManualCode);
        SearchFilter(props.Index);
        dispatch(setload_Screen(false));
        ButtonPermission(JSON.parse(localStorage.Menu), iCatId);
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = () => {
    dispatch(setload_Screen(true));
    const ObjInsUpdCat = {
      iCatId: iCatId,
      strCatCode: strCatCode,
      strCatName: strCatName,
      strDescription: strDescription,
      iCatTypeId: Number(iCatTypeId),
      strCatCodeTag: strCatCodeTag,
      iNumSeqNo: listCodeFormat[0].iNumSeqNo,
      strPrefix: listCodeFormat[0].strPrefix,
      strFilterColumn: strFilterColumn,
      iTitleTag: iScrId,
      strCompanyDB: localStorage.db,
    };
    userApp.post( "Category/InsertUpd/", ObjInsUpdCat).then((Res) => {
      if (Res.data.strStatus === "Success") {
        Load_CategoreMaster();
        AlertMessage(Res.data.strMessage, "success");
      } else {
        AlertMessage(Res.data.strMessage, "error");
      }
      dispatch(setload_Screen(false));
    });
  };
  const ClearClick = () => {
    setiCatId(0);
    setstrCatCode("");
    setstrCatName("");
    setstrDescription("");
    setiCatTypeId(1);
    setBtnDeleteDisable(false);
    setBtnSaveDisable(false);
    setstrFilterColumn("All");
    Load_CategoreMaster();
  };
  const DeleteClick = () => {
    try {
      dispatch(setload_Screen(true));
      userApp.post(
        
          "Category/Delete/" +
          iCatId +
          "/" +
          iScrId +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          Load_CategoreMaster();
          AlertMessage(Res.data.strMessage, "success");
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const GlobalSearch = (searchtext) => {
    setstrSearch(searchtext);
    try {
      searchtext = searchtext === "" ? "null" : searchtext;
      userApp.get(
        
          "Category/Search/" +
          strFilterColumn +
          "/" +
          searchtext +
          "/" +
          localStorage.db
      ).then((Res) => {
        setlistEntCat(Res.data);
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const SearchFilter = (FilterColumn) => {
    setstrFilterColumn(FilterColumn);
    try {
      var Search = strSearch === "" ? "null" : strSearch;
      userApp.get(
        
          "Category/Search/" +
          FilterColumn +
          "/" +
          Search +
          "/" +
          localStorage.db
      ).then((Res) => {
        setlistEntCat(Res.data);
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const View_Category = (iCatId) => {
    try {
      dispatch(setload_Screen(true));
      userApp.get(
        
          "Category/GetCatData/" +
          iCatId +
          "/" +
          iScrId +
          "/" +
          localStorage.db
      ).then((Res) => {
        if (Res.data.strStatus === undefined) {
          setiCatId(Res.data[0].iCatId);
          setstrCatCode(Res.data[0].strCatCode);
          setstrCatName(Res.data[0].strCatName);
          setstrDescription(Res.data[0].strDescription);
          setiCatTypeId(Res.data[0].iCatTypeId);
          ButtonPermission(JSON.parse(localStorage.Menu), Res.data[0].iCatId);
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const TabIndex = (e) => {
    var maxTab = 60;
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 50 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 60) {
          $(".Cat .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 60) {
          $(".Cat .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 60) {
          $(".Cat .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $(".Cat .react-table .rt-tbody .rt-tr:eq(0)").focus();
      }
    }
  };
  const ButtonPermission = (ListMenu, CatId) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === iScrId);
    if (ListMenu.length > 0) {
      if (Number(CatId) > 0) {
        if (ListMenu[0].isModify === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
        if (ListMenu[0].isDelete === false) {
          setBtnDeleteDisable(true);
        } else {
          setBtnDeleteDisable(false);
        }
      }
      if (Number(CatId) === 0)
        if (ListMenu[0].isNew === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
    }
  };

  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>CATEGORY</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <CustomizedSelectoption
                optionparem="isoptionShow14"
                showoption={_parem.isoptionShow14}
                placeholder="Select Cetegory "
                taborder={58}
                keydown={(e) => TabIndex(e)}
                select_value={(val) => SearchFilter(val)}
                btnname="Category "
                btnshow={false}
                show_popup=""
                valueonly={true}
                defaultval={strFilterColumn}
                displayName="strDisplayName"
                disvalue="strValueName"
                arrydata={listEntFillter}
                EmptVal="Category "
              />
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search"
                  className="form-control"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      $(".dt-content [tabindex=0]").focus();
                    } else TabIndex(e);
                  }}
                  value={strSearch}
                  tabIndex={59}
                  onChange={(e) => GlobalSearch(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => GlobalSearch(strSearch)}
                ></i>
              </div>
              <div className="data-list">
                {listEntCat.map((dt, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onClick={(e) => View_Category(dt.iCatId)}
                    onKeyDown={(e) => {
                      const arrlength = listEntCat.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Category(dt.iCatId);
                      }
                    }}
                  >
                    <label>{dt.strCatName}</label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-8 col-sm-12 mb-1">
                  <label>Category Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow13"
                    showoption={_parem.isoptionShow13}
                    placeholder="Select Cetegory Type"
                    taborder={50}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCatTypeId(val)}
                    btnname="Category 2"
                    btnshow={false}
                    show_popup=""
                    valueonly={true}
                    defaultval={iCatTypeId}
                    displayName="catType"
                    disvalue="catTypeId"
                    arrydata={listEntCatType}
                    EmptVal="Category Type "
                  />
                </div>
                <div className="col-md-4 col-sm-12 mb-1">
                  <label>
                    Code<span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    tabIndex="51"
                    onKeyDown={(e) => TabIndex(e)}
                    readOnly={ManualCode}
                    value={strCatCode}
                    onChange={(e) => setstrCatCode(e.target.value)}
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>
                    Name <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    value={strCatName}
                    tabIndex="52"
                    onKeyDown={(e) => TabIndex(e)}
                    onChange={(e) => setstrCatName(e.target.value)}
                    autoFocus
                    placeholder="Category Name"
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>Description</label>
                  <textarea
                    type="text"
                    aria-label="First name"
                    tabIndex="53"
                    onKeyDown={(e) => TabIndex(e)}
                    className="form-control"
                    value={strDescription}
                    onChange={(e) => setstrDescription(e.target.value)}
                    placeholder="Description"
                    style={{ minHeight: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              tabIndex="54"
              onKeyDown={(e) => TabIndex(e)}
              disabled={BtnSaveDisable}
              onClick={(e) => {
                if (iCatTypeId === "") {
                  AlertMessage("Category Type is Required", "info");
                } else if (strCatName === "") {
                  AlertMessage("Category Name is Required", "info");
                } else if (strCatCode === "") {
                  AlertMessage("Category Code is Required", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Category?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex="55"
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => {
                if (iCatId === 0) {
                  AlertMessage("Validation", "Please Select Category", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Category ?",
                      isdialog: true,
                    })
                  );
                }
              }}
              disabled={BtnDeleteDisable}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              tabIndex="56"
              onKeyDown={(e) => TabIndex(e)}
              onClick={ClearClick}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex="57"
              onKeyDown={(e) => TabIndex(e)}
              onClick={props.Close}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CategoryMaster;
