import React, { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";

import { userApp} from "../Admin/Utility";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import ReactTable from "../General/ReactTable";
function GSTTaxMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [strFilterColumn, setstrFilterColumn] = useState("ProName");
  const [strSearch, setstrSearch] = useState("");
  const [iItemTypeId, setiItemTypeId] = useState(27);
  const [iTaxLvlId, setiTaxLvlId] = useState(1);

  const [SGSTTaxId, setSGSTTaxId] = useState(8);
  const [CGSTTaxId, setCGSTTaxId] = useState(16);
  const [IGSTTaxId, setIGSTTaxId] = useState(21);
  const [TcsTaxId, setTcsTaxId] = useState(41);
  const [isTcsShow, setisTcsShow] = useState(false);
  const [isAllItem, setisAllItem] = useState(false);

  const [ListEntFillter, setListEntFillter] = useState([]);
  const [ListEntItemType, setListEntItemType] = useState([]);
  const [ListEntTaxLevel, setListEntTaxLevel] = useState([]);
  const [ListEntCgst, setListEntCgst] = useState([]);
  const [ListEntsgst, setListEntsgst] = useState([]);
  const [ListEntigst, setListEntigst] = useState([]);
  const [ListEntTCS, setListEntTCS] = useState([]);
  const [ListEntList, setListEntList] = useState([]);

  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);

  useEffect(() => {
    Load_TaxMaster();
  }, []);

  const Load_TaxMaster = async () => {
    try {
      const objParems = {
        iScrId: 0,
        strSearch: "",
        iItemId: Number(iItemTypeId),
        iTypeId: Number(iTaxLvlId),
        strColName: strFilterColumn,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(`TaxMaster/Load_TaxMaster`, objParems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setListEntFillter(res.data.objEntCmbFilter);
            setListEntItemType(res.data.objEntItemTyp);
            setListEntTaxLevel(res.data.objEntTaxType);
            setListEntCgst(res.data.objEntCGSTTax);
            setListEntsgst(res.data.objEntSGSTTax);
            setListEntigst(res.data.objEntIGSTTax);
            setListEntTCS(res.data.objEntTCSTax);
            setListEntList(res.data.objEntItem.sort((a, b) => a.sNo - b.sNo));
            ButtonPermission(JSON.parse(localStorage.Menu));
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Update_TaxMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objEntTax = {
        strSearchColumn: strFilterColumn,
        strSearch: strSearch,
        iTaxLvl: Number(iTaxLvlId),
        iItemTypeId: Number(iItemTypeId),
        iCGSTTaxId: Number(CGSTTaxId),
        iSGSTTaxId: Number(SGSTTaxId),
        iIGSTTaxId: Number(IGSTTaxId),
        iTCSTaxId: Number(TcsTaxId),
      };
      let Row = ListEntList.filter((e) => e.checkBox === true);
      const objTaxUpdate = {
        objBulkTaxModel: objEntTax,
        objTaxDT: isAllItem === true ? [] : Row,
        TaxAll: isAllItem,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(
         "TaxMaster/Update_TaxMaster/",
        objTaxUpdate
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
          ClearClick();
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    try {
      dispatch(setload_Screen(false));
      setstrSearch("");
      setSGSTTaxId(8);
      setCGSTTaxId(16);
      setIGSTTaxId(21);
      setTcsTaxId(41);
      setisAllItem(false);
      setBtnSaveDisable(false);
      setListEntList([]);
      Load_TaxMaster();
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Search_Product = async (strtxt, colname) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        iItemId: iItemTypeId,
        strSearch: strtxt,
        iTypeId: iTaxLvlId,
        strColName: colname,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`TaxMaster/Search_Product/`, objSearch).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setListEntList(res.data.EntItems);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const ItemTypeChange = async (Res) => {
    try {
      await setisAllItem(false);
      await setiItemTypeId(Res);
      await userApp.get(
        `TaxMaster/ItemType/${Res}/${iTaxLvlId}/${strFilterColumn}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          setListEntList(res.data.EntItems);
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxTypeChange = async (iTaxLvl) => {
    try {
      await dispatch(setload_Screen(true));
      setiTaxLvlId(Number(iTaxLvl));
      setisAllItem(false);
      await userApp.get(
        `TaxMaster/ItemType/${iItemTypeId}/${Number(
          iTaxLvl
        )}/${strFilterColumn}/${localStorage.db}`
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          setListEntList(res.data.EntItems);
          if (Number(iTaxLvl) === 1) setisTcsShow(false);
          else setisTcsShow(true);
        } else AlertMessage(res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const CheckBoxChange = (rowInfo) => {
    try {
      let EntList = ListEntList;
      EntList.map((e) => {
        if (e.itemId === rowInfo.itemId) {
          e.checkBox = !e.checkBox;
        }
        return e;
      });
      setListEntList(EntList);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setSGSTTaxId(objtax.iTaxId);
        setCGSTTaxId(
          ListEntCgst.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setIGSTTaxId(
          ListEntigst.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setSGSTTaxId(
          ListEntsgst.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setCGSTTaxId(objtax.iTaxId);
        setIGSTTaxId(
          ListEntigst.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setIGSTTaxId(objtax.iTaxId);
        setSGSTTaxId(
          ListEntsgst.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setCGSTTaxId(
          ListEntCgst.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const AllBoxOnChange = (isallSelect) => {
    try {
      setisAllItem(isallSelect);
      let listEntItem = ListEntList;
      listEntItem = listEntItem.map((Data) => {
        Data.checkBox = isallSelect;
        return Data;
      });
      setListEntList(listEntItem);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ButtonPermission = (ListMenu) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === 62);
    if (ListMenu.length > 0) {
      if (ListMenu[0].isModify === false) {
        setBtnSaveDisable(true);
      } else {
        setBtnSaveDisable(false);
      }
    }
  };
  const GSTTaxcolumns = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Select",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      accessor: "checkBox",
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.checkBox}
          onClick={(e) => e.target.select()}
          onChange={(e) => {
            CheckBoxChange(rowinfo);
          }}
        />
      ),
    },
    {
      Header: "Name",
      accessor: "itemName",
      style: { justifyContent: "left" },
    },
    {
      Header: "SGST",
      accessor: "sgstTaxDesc",
      style: { justifyContent: "left" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "CGST",
      accessor: "cgstTaxDesc",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "left" },
    },
    {
      Header: "IGST",
      accessor: "igstTaxDesc",
      maxWidth: 100,
      minWidth: 100,
      style: { justifyContent: "left" },
    },
  ];
  const TCSTaxcolumns = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 50,
      minWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Select",
      maxWidth: 70,
      minWidth: 70,
      style: { justifyContent: "center" },
      accessor: "checkBox",
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.checkBox}
          onClick={(e) => e.target.select()}
          onChange={(e) => {
            CheckBoxChange(rowinfo);
          }}
        />
      ),
    },
    {
      Header: "Name",
      accessor: "itemName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Tax",
      accessor: "tcsTaxDesc",
      style: { justifyContent: "left" },
      maxWidth: 100,
      minWidth: 100,
    },
  ];
  return (
    <>
      <SideMenu ScrId={110} />
      <div className="Master-view p-2">
        <div className="row">
          <div className="col-md-4 col-sm-12 mt-2 ">
            <div className="m-hsearch">
              <input
                type="text"
                autoCorrect="off"
                autoComplete="off"
                placeholder="Search"
                value={strSearch}
                onChange={(e) =>
                  Search_Product(e.target.value, strFilterColumn)
                }
                autoFocus
              />
              <i
                className="fas fa-search"
                onClick={(e) => Search_Product(strSearch, strFilterColumn)}
              ></i>
            </div>
          </div>
          <div className="col-md-2 col-sm-12 mt-2">
            <CustomizedSelectoption
              optionparem="isoptionShow6"
              showoption={_parem.isoptionShow6}
              placeholder="Select Filter"
              select_value={(val) => {
                setstrFilterColumn(val);
                Search_Product("", val);
              }}
              btnname=""
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={strFilterColumn}
              displayName="strDisplayName"
              disvalue="strValueName"
              arrydata={ListEntFillter}
              EmptVal="Filter Column "
            />
          </div>
          <div className="col-md-3 col-sm-6 mt-2">
            <CustomizedSelectoption
              optionparem="isoptionShow"
              showoption={_parem.isoptionShow}
              placeholder="Select Item Type"
              select_value={(val) => ItemTypeChange(val)}
              btnname=""
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={iItemTypeId}
              displayName="strItemType"
              disvalue="iItemTypeId"
              arrydata={ListEntItemType}
              EmptVal="Item Type "
            />
          </div>
          <div className="col-md-3 col-sm-6 mt-2">
            <CustomizedSelectoption
              optionparem="isoptionShow1"
              showoption={_parem.isoptionShow1}
              placeholder="Select Tax Type"
              select_value={(val) => TaxTypeChange(val)}
              btnname=""
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={iTaxLvlId}
              displayName="strTaxLvlType"
              disvalue="iTaxLvlId"
              arrydata={ListEntTaxLevel}
              EmptVal="Tax Type "
            />
          </div>
          {!isTcsShow && (
            <div className="col-md-3 col-sm-6 mt-2">
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_parem.isoptionShow2}
                placeholder="Select SGST Tax"
                select_value={(val) => TaxOnChange(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={SGSTTaxId}
                displayName="strTaxDesc"
                disvalue="iTaxId"
                arrydata={ListEntsgst}
                EmptVal="SGST Tax "
              />
            </div>
          )}
          {!isTcsShow && (
            <div className="col-md-3 col-sm-6 mt-2">
              <CustomizedSelectoption
                optionparem="isoptionShow3"
                showoption={_parem.isoptionShow3}
                placeholder="Select CGST Tax"
                select_value={(val) => TaxOnChange(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={CGSTTaxId}
                displayName="strTaxDesc"
                disvalue="iTaxId"
                arrydata={ListEntCgst}
                EmptVal="CGST Tax "
              />
            </div>
          )}
          {!isTcsShow && (
            <div className="col-md-3 col-sm-6 mt-2">
              <CustomizedSelectoption
                optionparem="isoptionShow4"
                showoption={_parem.isoptionShow4}
                placeholder="Select IGST Tax"
                select_value={(val) => TaxOnChange(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={false}
                defaultval={IGSTTaxId}
                displayName="strTaxDesc"
                disvalue="iTaxId"
                arrydata={ListEntigst}
                EmptVal="IGST Tax "
              />
            </div>
          )}
          {isTcsShow && (
            <div className="col-md-3 col-sm-6 mt-2">
              <CustomizedSelectoption
                optionparem="isoptionShow5"
                showoption={_parem.isoptionShow5}
                placeholder="Select TCS Tax"
                select_value={(val) => setTcsTaxId(val)}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={TcsTaxId}
                displayName="strTaxDesc"
                disvalue="iTaxId"
                arrydata={ListEntTCS}
                EmptVal="TCS Tax "
              />
            </div>
          )}
          <div
            className="col-md-3 col-sm-6 mt-2 d-flex align-items-center"
            style={{ columnGap: "5px" }}
          >
            <input
              className="form-check"
              type="checkbox"
              checked={isAllItem}
              onChange={(e) => AllBoxOnChange(!isAllItem)}
            />{" "}
            <span style={{ color: "black" }}>Tax For All Item</span>
          </div>
        </div>
        <ReactTable
          columns={Number(iTaxLvlId) === 1 ? GSTTaxcolumns : TCSTaxcolumns}
          data={ListEntList}
          minRows={10}
          row_click={(ProInfo, Index) => {}}
          row_doubleclick={(ProInfo, Index) => {}}
          background={true}
          className="full-table"
          tabIndex=""
        />

        <div className="mt-1 btn-section">
          <button
            disabled={BtnSaveDisable}
            className="btn-fabgreen"
            onClick={(e) => {
              dispatch(
                Show_MsgBox({
                  Button: Update_TaxMaster,
                  Question: "Do You Want to Update Tax ?",
                  isdialog: true,
                })
              );
            }}
          >
            Save&nbsp;<i className="bx bxs-save"></i>
          </button>
          <button type="reset" className="btn-fabgreen" onClick={ClearClick}>
            Clear&nbsp;<i className="fa-solid fa-broom"></i>
          </button>
          <button
            className="btn-fabgreen"
            onClick={() =>
              (window.location.href =
                "/Dashboard")
            }
          >
            Close&nbsp;<i className="fas fa-times"></i>
          </button>
        </div>
      </div>
      <Messagedialogbox />
    </>
  );
}
export default GSTTaxMaster;
