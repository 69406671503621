import React, { useEffect, useState } from "react";
import { userApp } from "../Admin/Utility";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import Print_DesignCaller from "./Print_DesignCaller";
import { ShowHide_Popup } from "../ReduxStore/Transactionstates";
import JustBill from "../img/JustBill.png";
function EmailPopup() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const [strpassWord, setstrpassWord] = useState("");
  const [strHost, setstrHost] = useState("");
  const [iPort, setiPort] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strToEmail, setstrToEmail] = useState("");
  const [strSubject, setstrSubject] = useState("");
  const [arrInfo, setarrInfo] = useState([]);
  const [arrEmailData, setarrEmailData] = useState([]);
  const [arrEmailTemp, setarrEmailTemp] = useState([]);
  const [compName, setcompName] = useState("");
  const [partyName, setpartyName] = useState("");

  useEffect(() => {
    Load_parytyinfo();
  }, []);

  const Load_parytyinfo = async () => {
    try {
      await dispatch(setload_Screen(true));
      var objEntPdf = await Print_DesignCaller(
        _Common.iScrId,
        _Common.strScrName,
        "Transaction",
        _HT.iVouchId,
        0,
        "PDF",
        dispatch,
        true
      );
      setarrInfo([objEntPdf]);
      let Email = objEntPdf.PartyInfo[0].email.replace("E-Mail: ", "");
      let company = objEntPdf.CompanyInfo[0];
      let arrData = {};
      for (let key in objEntPdf.PartyInfo[0]) {
        arrData["party" + key] = objEntPdf.PartyInfo[0][key];
      }
      for (let key in objEntPdf.CompanyInfo[0]) {
        arrData["comp" + key] = objEntPdf.CompanyInfo[0][key];
      }
      for (let key in objEntPdf.Summary[0]) {
        arrData["sum" + key] = objEntPdf.Summary[0][key];
      }
      setarrEmailData(arrData);
      setstrToEmail(Email);
      setstrEmail(company.compEMail);
      setstrpassWord(company.password);
      setiPort(company.smtpPort);
      setstrHost(company.smtpHost);
      setcompName(company.compName);
      setpartyName(await getpartyname(objEntPdf.PartyInfo[0]));
      await Load_EmailTemplate();
      await setstrSubject(View_Message());
    } catch (err) {
      await AlertMessage(err.strMessage, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Load_EmailTemplate = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .get(
          `Transaction/View_EmailTemplate/1/${_Common.iScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            if (Res.data.EntTemplate.length > 0) {
              setarrEmailTemp(Res.data.EntTemplate);
            }
          } else AlertMessage(Res.data.strMessage, "info");
        });
    } catch (err) {
      await AlertMessage(err.strMessage, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const getpartyname = (party) => {
    if (party.custName) {
      return party.custName;
    } else if (party.suppName) {
      return party.suppName;
    } else if (party.venName) {
      return party.venName;
    }
  };
  const SendEmailAsync = async () => {
    try {
      await dispatch(setload_Screen(true));
      const formdata = new FormData();
      let Subject = strSubject + " - #" + arrInfo[0].FileName;
      const Message = document.getElementById("emailtemplate").innerHTML;
      formdata.append("strHost", strHost);
      formdata.append("iPort", iPort);
      formdata.append("strFromEmail", strEmail);
      formdata.append("strPassword", strpassWord);
      formdata.append("strToEmail", strToEmail);

      formdata.append("Sender", compName);
      formdata.append("Receiver", partyName);

      formdata.append("strSubject", Subject);
      formdata.append("strBody", Message);
      formdata.append(
        "Attachment",
        UrlToFile(arrInfo[0].PdfURL, arrInfo[0].FileName)
      );
      formdata.append("FileName", arrInfo[0].FileName);
      await userApp
        .post(`Transaction/SendEmailAsync`, formdata)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await AlertMessage(Res.data.strMessage, "success");
          } else await AlertMessage(Res.data.strMessage, "info");
        })
        .catch(async (err) => {
          console.error(err);
          await AlertMessage(err.message, "error");
        });
    } catch (err) {
      await AlertMessage(err.message, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const UrlToFile = (dataUrl, fileName) => {
    // Split the Data URL to get the content type and data
    const data = dataUrl.split(",");

    // Convert base64 to a Blob
    const blob = new Blob([atob(data[1])], { type: data[0] });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: data[0] });

    return file;
  };
  const View_Message = () => {
    let Msg = "";
    switch (_Common.strScrName) {
      case "Sales Challan":
        Msg =
          "This is to acknowledge the receipt of your order for [quantity] !";
        break;
      case "Sales Enquiry":
        Msg =
          "Thank you for your interest in our products. We appreciate you taking the time to inquire about our product !";
        break;
      case "Sales Estimate":
        Msg =
          "Thank you for your recent purchase. We appreciate your business and hope you're enjoying it !";
        break;
      case "Sales Invoice":
        Msg = "Acknowledgement of Recent Purchase Invoice";
        break;
      case "Sales Order":
        Msg = "Acknowledgement of Recent Purchase Order";
        break;
      case "Sales Quotation":
        Msg = "Acknowledgement of Recent Purchase Quotation";
        break;
      case "Sales Return":
        Msg = "Acknowledgement of Recent Purchase Return";
        break;
      case "Purchase Challan":
        Msg = "Acknowledgement of Recent Purchase Challan";
        break;
      case "Purchase Enquiry":
        Msg = "Acknowledgement of Recent Purchase Enquiry";
        break;
      case "Purchase Estimate":
        Msg = "Acknowledgement of Recent Purchase Estimate";
        break;
      case "Purchase Invoice":
        Msg = "Acknowledgement of Recent Purchase Invoice";
        break;
      case "Purchase Order":
        Msg = "Acknowledgement of Recent Purchase Order";
        break;
      case "Purchase Quotation":
        Msg = "Acknowledgement of Recent Purchase Quotation";
        break;
      case "Purchase Return":
        Msg = "Acknowledgement of Recent Purchase Return";
        break;
      case "Outsource Dc":
        Msg = "Acknowledgement of Recent Outsource Challan";
        break;
      case "Insource Dc":
        Msg = "Acknowledgement of Recent Insource Challan";
        break;
      case "Insource Invoice":
        Msg = "Acknowledgement of Recent Insource Invoice";
        break;
      case "Inward Dc":
        Msg = "Acknowledgement of Recent Inward Challan";
        break;
      case "Outward Dc":
        Msg = "Acknowledgement of Recent Outward Challan";
        break;
      case "Outward Invoice":
        Msg = "Acknowledgement of Recent Outward Invoice";
        break;
      default:
        break;
    }
    return Msg;
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <div className="popup-container">
      <div className="popup-content medium">
        <div className="popup-header">
          <h5>Sent E-mail</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsEmail"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-2">
              <label>From</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email Address"
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
              <label>To</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email Address"
                value={strToEmail}
                onChange={(e) => setstrToEmail(e.target.value)}
              />
            </div>
            <div className="col-md-12 col-sm-12 mb-2">
              <label>Subject</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Subject"
                value={strSubject}
                onChange={(e) => setstrSubject(e.target.value)}
              />
            </div>
            <div className="emailtemplate">
              <h1>Email Template</h1>
              <div id="emailtemplate">
                <div
                  style={{
                    width: "100%",
                    color: "black",
                    display: "block",
                    lineHeight: "15px",
                    fontFamily: "Mukta Malar, Arial",
                    position: "relative",
                    minHeight: arrEmailTemp.length
                      ? arrEmailTemp[arrEmailTemp.length - 1].lineAt +
                        100 +
                        "px"
                      : 0,
                  }}
                >
                  {arrEmailTemp.map((info, Index) => (
                    <>
                      {info.conditionCol === 1 && (
                        <p
                          style={{
                            fontSize: info.fontSize,
                            fontWeight: info.fontWeight,
                            color: info.color,
                            width: "100%",
                            position: "absolute",
                            top: info.lineAt + "px",
                            left: info.startAt + "px",
                            textAlign: info.textAlign,
                            wordWrap: info.wordWrap,
                          }}
                        >
                          <span
                            style={{
                              fontSize: info.fontSize,
                              fontWeight: "normal",
                              color: "black",
                            }}
                          >
                            {info.prefix}
                          </span>
                          <strong> {arrEmailData[info.colName]} </strong>
                          <span
                            style={{
                              fontSize: info.fontSize,
                              fontWeight: "normal",
                              color: "black",
                            }}
                          >
                            {info.suffix}
                          </span>
                        </p>
                      )}
                      {info.conditionCol === 0 && (
                        <p
                          style={{
                            fontSize: info.fontSize,
                            fontWeight: info.fontWeight,
                            color: info.color,
                            width: "100%",
                            position: "absolute",
                            top: info.lineAt + "px",
                            left: info.startAt + "px",
                            textAlign: info.textAlign,
                            wordWrap: info.wordWrap,
                          }}
                        >
                          <span
                            style={{
                              fontSize: info.fontSize,
                              fontWeight: "normal",
                              color: "black",
                            }}
                          >
                            {info.prefix}
                          </span>
                          <span> {info.description} </span>
                          <span
                            style={{
                              fontSize: info.fontSize,
                              fontWeight: "normal",
                              color: "black",
                            }}
                          >
                            {info.suffix}
                          </span>
                        </p>
                      )}
                    </>
                  ))}
                  <a
                    href="https://justbillsoftware.com/"
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: "100%",
                      height: "70px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      columnGap: "10px",
                      textAlign: "left",
                      color: "black",
                      fontWeight: "500",
                      fontSize: "12px",
                      textDecoration: "none",
                    }}
                  >
                    <span>Powerd By </span>
                    <img
                      id="JbE-Img"
                      style={{ width: "85px", height: "25px" }}
                      src={JustBill}
                      alt="Just Bill"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (strEmail.length <= 1) {
                  AlertMessage("Please Enter From E-Mail", "info");
                } else if (strToEmail.length <= 1) {
                  AlertMessage("Please Enter To E-Mail", "info");
                } else if (iPort.length < 1 || strHost.length < 5) {
                  AlertMessage(
                    "Please Check mail Provider Configuration",
                    "info"
                  );
                } else {
                  SendEmailAsync();
                }
              }}
            >
              Sent Email&nbsp;<i className="bx bx-envelope"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailPopup;
