import ExportImport from "./ExportImport";
import $ from "jquery";
import { userApp } from "../Admin/Utility";
import { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import MasterList from "./MasterListItem";
import ShippingAddressPopup from "../Transaction/ShippingAddressPopup";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import MasterCodeSetting from "./MasterCodeSetting";
import CountryMaster from "./CountryMaster";
import StateMaster from "./StateMaster";
function VendorMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);

  const _ScrId = 50;
  const [iVenId, setiVenId] = useState(0);
  const [strVenCode, setstrVenCode] = useState("");
  const [strVenName, setstrVenName] = useState("");
  const [strAliasName, setstrAliasName] = useState("");
  const [strOfficeNo, setstrOfficeNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strCity, setstrCity] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strPinCode, setstrPinCode] = useState("");
  const [strCountryId, setstrCountryId] = useState(1);
  const [strStateId, setstrStateId] = useState(32);
  const [strPhone, setstrPhone] = useState("");
  const [strPhone1, setstrPhone1] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strMobile1, setstrMobile1] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strTinNo, setstrTinNo] = useState("");
  const [strGst, setstrGst] = useState("");
  const [ExpImpOpen, setExpImpOpen] = useState(false);
  const [iStatusId, setiStatusId] = useState(1);
  const [dOpBal, setdOpBal] = useState((0.0).toFixed());
  const [CreditTerm, setCreditTerm] = useState("30 Days");
  const [iVenCastId, setiVenCastId] = useState(1);
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [strFilterColumn, setstrFilterColumn] = useState("All");
  const [strFilter, setstrFilter] = useState("");
  const [strSearch, setstrSearch] = useState("");
  const [iVendorCount, setiVendorCount] = useState(0);
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntFillter, setlistEntFillter] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listEntCountry, setlistEntCountry] = useState([]);
  const [listEntState, setlistEntState] = useState([]);
  const [listEntVentCast, setlistEntVentCast] = useState([]);
  const [listEntBalTyp, setlistEntBalTyp] = useState([]);
  const [listEntVenList, setlistEntVenList] = useState([]);
  const [ManualCode, setManualCode] = useState(true);
  const [ListValidateProperties, setListValidateProperties] = useState([]);
  const [BtnExport, setBtnExport] = useState(false);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);
  const [bIsNewAddress, setbIsNewAddress] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [IsCountry, setIsCountry] = useState(false);
  const [IsState, setIsState] = useState(false);
  useEffect(() => {
    Load_VendorMaster();
  }, []);

  const Load_VendorMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: _ScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await userApp
        .post(`VendoreMaster/Load_VendorMaster`, objParems)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setlistEntFillter(Res.data.objEntFilter);
            setlistEntStatus(Res.data.objEntStatus);
            setlistEntCountry(Res.data.objEntCountry);
            setlistEntState(Res.data.objEntState);
            setlistEntBalTyp(Res.data.objEntBalTyp);
            setlistEntVentCast(Res.data.objVenCast);
            setlistEntVenList(Res.data.objEntVenList);
            setlistCodeFormat(Res.data.objCodeFormat);
            setManualCode(!Res.data.objCodeFormat[0].isManualCode);
            setstrVenCode(Res.data.objCodeFormat[0].strCode);
            setListValidateProperties(Res.data.Transtab);
            TabOrderAssign(Res.data.Transtab);
            ButtonPermission(JSON.parse(localStorage.Menu));
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objVen = {
        iVenId: iVenId,
        strVenCode: strVenCode,
        strVenTag: "",
        strVenName: strVenName,
        strAliasName: strAliasName,
        strAdd1: strOfficeNo,
        strAdd2: strStreet,
        strAdd3: strArea,
        strAdd4: strCity,
        strPincode: strPinCode,
        iCountry: Number(strCountryId),
        iState: Number(strStateId),
        strPhone1: strPhone,
        strPhone2: strPhone1,
        strMobile1: strMobile,
        strMobile2: strMobile1,
        strEmail: strEmail,
        strGST: strGst,
        strRemarks: strRemarks,
        TinNo: strTinNo,
        iStatusId: Number(iStatusId),
        dOpBal: Number(dOpBal),
        CreditTerm: CreditTerm,
        iBalTypeId: Number(iBalTypeId),
        iVenCastId: Number(iVenCastId),
        iTitleTag: _ScrId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strVenCodeTag: "",
        strFilterColumn: strFilterColumn,
        strFilter: strFilter,
        strSearch: strSearch,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`VendoreMaster/InsUpd_Vendor`, objVen).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          ClearClick();
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .post(
          `VendoreMaster/Delete_Vendor/${iVenId}/${_ScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    setiVenId(0);
    setBtnDeleteDisable(false);
    setBtnSaveDisable(false);
    setstrVenCode("");
    setstrVenName("");
    setstrAliasName("");
    setstrOfficeNo("");
    setstrStreet("");
    setstrCity("");
    setstrArea("");
    setstrPinCode("");
    setstrCountryId(1);
    setstrStateId(32);
    setstrPhone("");
    setstrPhone1("");
    setstrMobile("");
    setstrMobile1("");
    setstrEmail("");
    setstrRemarks("");
    setstrTinNo("");
    setstrGst("");
    setiStatusId(1);
    setdOpBal((0.0).toFixed());
    setCreditTerm("30 Days");
    setiVenCastId(1);
    setiBalTypeId(3);
    setstrFilterColumn("All");
    setstrFilter("");
    setstrSearch("");
    setiVendorCount(0);
    Load_VendorMaster();
  };
  const View_Vendor = async (iVenId) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .get(`VendoreMaster/View_Vendor/${iVenId}/${_ScrId}/${localStorage.db}`)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setiVenId(Res.data.objEntVen[0].iVenId);
            setstrVenCode(Res.data.objEntVen[0].strVenCode);
            setstrVenName(Res.data.objEntVen[0].strVenName);
            setstrAliasName(Res.data.objEntVen[0].strAliasName);
            setstrOfficeNo(Res.data.objEntVen[0].strAdd1);
            setstrStreet(Res.data.objEntVen[0].strAdd2);
            setstrArea(Res.data.objEntVen[0].strAdd3);
            setstrCity(Res.data.objEntVen[0].strAdd4);
            setstrPinCode(Res.data.objEntVen[0].strPincode);
            setstrPhone(Res.data.objEntVen[0].strPhone1);
            setstrPhone1(Res.data.objEntVen[0].strPhone2);
            setstrMobile(Res.data.objEntVen[0].strMobile1);
            setstrMobile1(Res.data.objEntVen[0].strMobile2);
            setstrEmail(Res.data.objEntVen[0].strEmail);
            setstrGst(Res.data.objEntVen[0].strGST);
            setstrTinNo(Res.data.objEntVen[0].tinNo);
            setiStatusId(Res.data.objEntVen[0].iStatusId);
            setstrRemarks(Res.data.objEntVen[0].strRemarks);
            setiBalTypeId(Res.data.objEntVen[0].iBalTypeId);
            setdOpBal(Res.data.objEntVen[0].dOpBal);
            setiVenCastId(Res.data.objEntVen[0].iVenCastId);
            setCreditTerm(Res.data.objEntVen[0].creditTerm);
            setlistEntState(Res.data.objEntState);
            setstrCountryId(Res.data.objEntVen[0].iCountry);
            setstrStateId(Res.data.objEntVen[0].iState);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const CountryOnChange = async (CountryId) => {
    try {
      setstrCountryId(CountryId);
      const parems = {
        iCountryId: Number(CountryId),
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`CountryState/Load_State`, parems).then((res) => {
        if (res.data.strStatus === "Success") {
          setlistEntState(res.data.EntState);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Search_Vendor = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strColName: "All",
        iScrId: _ScrId,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await userApp
        .post("VendoreMaster/Search_Vendor/", objSearch)
        .then((Res) => {
          if (Res.data.strStatus === "Success")
            setlistEntVenList(Res.data.EntVendor);
          else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  const [ListTabOrder, setListTabOrder] = useState([
    {
      VenCodeTab: "",
      AliasTab: "",
      NameTab: "",
      OfficeNoTab: "",
      StreetTab: "",
      AreaTab: "",
      CityTab: "",
      PinCodeTab: "",
      CountryTab: "",
      StateTab: "",
      Phone1Tab: "",
      Phone2Tab: "",
      Mobile1Tab: "",
      Mobile2Tab: "",
      Emailtab: "",
      GstTab: "",
      TinNoTab: "",
      RemarksTab: "",
      CreditLimitTab: "",
      CreditTermTab: "",
      StatusTab: "",
      OpeningBalTab: "",
      BalTypeTab: "",
      VenCastTab: "",
      VendoreListTab: "",
      VendoreFIlterTab: "",
      VendoreSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtCode":
          TabOrder[0].VenCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtName":
          TabOrder[0].NameTab = EntProperties[i].iTabIndex;
          break;
        case "txtAlias":
          TabOrder[0].AliasTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd1":
          TabOrder[0].OfficeNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd2":
          TabOrder[0].StreetTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd3":
          TabOrder[0].AreaTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd4":
          TabOrder[0].CityTab = EntProperties[i].iTabIndex;
          break;
        case "cbCountry":
          TabOrder[0].CountryTab = EntProperties[i].iTabIndex;
          break;
        case "txtPinCode":
          TabOrder[0].PinCodeTab = EntProperties[i].iTabIndex;
          break;
        case "cbState":
          TabOrder[0].StateTab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone1":
          TabOrder[0].Phone1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone2":
          TabOrder[0].Phone2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile1":
          TabOrder[0].Mobile1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile2":
          TabOrder[0].Mobile2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtEmail":
          TabOrder[0].Emailtab = EntProperties[i].iTabIndex;
          break;
        case "txtTin":
          TabOrder[0].TinNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtGst":
          TabOrder[0].GstTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarksTab = EntProperties[i].iTabIndex;
          break;
        case "txtOpBalance":
          TabOrder[0].OpeningBalTab = EntProperties[i].iTabIndex;
          break;
        case "cbBalType":
          TabOrder[0].BalTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditLimit":
          TabOrder[0].CreditLimitTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditTerm":
          TabOrder[0].CreditTermTab = EntProperties[i].iTabIndex;
          break;
        case "cbVenCast":
          TabOrder[0].VenCastTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].VendoreFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].VendoreSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbVendorName":
          TabOrder[0].VendoreListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    );
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].VendoreListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].VendoreListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].VendoreListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].VendoreListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const ButtonPermission = (ListMenu) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === _ScrId);
    if (ListMenu.length > 0) {
      if (Number(iVenId) > 0) {
        if (ListMenu[0].isModify === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
        if (ListMenu[0].isDelete === false) {
          setBtnDeleteDisable(true);
        } else {
          setBtnDeleteDisable(false);
        }
      }
      if (ListMenu[0].isExport === false) {
        setBtnExport(true);
      } else {
        setBtnExport(false);
      }
      if (ListMenu[0].isNew === false) {
        setBtnSaveDisable(true);
      } else {
        setBtnSaveDisable(false);
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtAlias":
          ErrorMsg = strAliasName === "" ? false : true;
          break;
        case "txtName":
          ErrorMsg = strVenName === "" ? false : true;
          break;
        case "txtAdd1":
          ErrorMsg = strOfficeNo === "" ? false : true;
          break;
        case "txtAdd2":
          ErrorMsg = strStreet === "" ? false : true;
          break;
        case "txtAdd3":
          ErrorMsg = strArea === "" ? false : true;
          break;
        case "txtAdd4":
          ErrorMsg = strCity === "" ? false : true;
          break;
        case "txtPinCode":
          ErrorMsg = strPinCode === "" ? false : true;
          break;
        case "txtPhone1":
          ErrorMsg = strPhone === "" ? false : true;
          break;
        case "txtPhone2":
          ErrorMsg = strPhone1 === "" ? false : true;
          break;
        case "txtMobile1":
          ErrorMsg = strMobile === "" ? false : true;
          break;
        case "txtMobile2":
          ErrorMsg = strMobile1 === "" ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = strEmail === "" ? false : true;
          break;
        case "txtTin":
          ErrorMsg = strTinNo === "" ? false : true;
          break;
        case "txtGst":
          ErrorMsg = strGst === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "txtOpBalance":
          ErrorMsg = dOpBal === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  return (
    <>
      <SideMenu ScrId={_ScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listEntFillter}
          EntList={listEntVenList}
          GetDetails={View_Vendor}
          Count={iVendorCount}
          Search={Search_Vendor}
          ScreenID={_ScrId}
          accessor="strVenName"
          accessorid="iVenId"
          ListTab={ListTabOrder[0].VendoreListTab}
          FilterTab={ListTabOrder[0].VendoreFIlterTab}
          SearchTab={ListTabOrder[0].VendoreSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  spellCheck={false}
                  className="form-control"
                  placeholder="Enter Vendor Name"
                  tabIndex={ListTabOrder[0].NameTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strVenName}
                  autoComplete="off"
                  onChange={(e) => setstrVenName(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    id="txtProductCode"
                    readOnly={ManualCode}
                    value={strVenCode}
                    autoComplete="off"
                    tabIndex={ListTabOrder[0].VenCodeTab}
                    onKeyDown={(e) => TabIndex(e)}
                    onChange={(e) => setstrVenCode(e.target.value)}
                    name="strProCode"
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Alias Name</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Alias Name"
                  value={strAliasName}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].AliasTab}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setstrAliasName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Office /Door No</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Office /Door No"
                  tabIndex={ListTabOrder[0].OfficeNoTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strOfficeNo}
                  autoComplete="off"
                  onChange={(e) => setstrOfficeNo(e.target.value)}
                />
                {iVenId > 0 && (
                  <span
                    className="btn-shipadd"
                    onClick={(e) => setbIsNewAddress(!bIsNewAddress)}
                  >
                    <i className="fa-solid fa-location-dot ml-2"></i>
                    Add Secondary Address
                  </span>
                )}
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Street/Village</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Street/Village"
                  tabIndex={ListTabOrder[0].StreetTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strStreet}
                  autoComplete="off"
                  onChange={(e) => setstrStreet(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Area/Town</label>
                <input
                  type="text"
                  aria-label="First name"
                  placeholder="Enter Area/Town"
                  className="form-control"
                  value={strArea}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].AreaTab}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setstrArea(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>City</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter City Name"
                  tabIndex={ListTabOrder[0].CityTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strCity}
                  autoComplete="off"
                  onChange={(e) => setstrCity(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Pincode</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Pincode"
                  autoComplete="off"
                  value={strPinCode}
                  tabIndex={ListTabOrder[0].PinCodeTab}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setstrPinCode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  Country <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select Country"
                  select_value={(val) => CountryOnChange(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].CountryTab}
                  btnname="Country"
                  btnshow={true}
                  show_popup={() => setIsCountry(!IsCountry)}
                  valueonly={true}
                  defaultval={strCountryId}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={listEntCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>
                  State <span className="danger">*</span>
                </label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select State"
                  select_value={(val) => setstrStateId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StateTab}
                  btnname="State"
                  btnshow={true}
                  show_popup={() => setIsState(!IsState)}
                  valueonly={true}
                  defaultval={strStateId}
                  displayName="strSName"
                  disvalue="iStateId"
                  arrydata={listEntState}
                  disabled={false}
                  EmptVal="State "
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>GST</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  tabIndex={ListTabOrder[0].GstTab}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Enter GST No"
                  value={strGst}
                  autoComplete="off"
                  onChange={(e) => setstrGst(e.target.value)}
                  maxLength={15}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Phone </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Phone Number"
                  tabIndex={ListTabOrder[0].Phone1Tab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strPhone}
                  autoComplete="off"
                  onChange={(e) => setstrPhone(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Phone 1</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Phone Number"
                  tabIndex={ListTabOrder[0].Phone2Tab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strPhone1}
                  autoComplete="off"
                  onChange={(e) => setstrPhone1(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Mobile</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  value={strMobile}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].Mobile1Tab}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setstrMobile(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Mobile 1</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  tabIndex={ListTabOrder[0].Mobile2Tab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strMobile1}
                  autoComplete="off"
                  onChange={(e) => setstrMobile1(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>E-Mail </label>
                <input
                  type="email"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Email"
                  tabIndex={ListTabOrder[0].Emailtab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={strEmail}
                  autoComplete="off"
                  onChange={(e) => setstrEmail(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>TIN</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter TIN Number"
                  value={strTinNo}
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].TinNoTab}
                  onKeyDown={(e) => TabIndex(e)}
                  onChange={(e) => setstrTinNo(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow2"
                  showoption={_selecrparem.isoptionShow2}
                  placeholder="Select Status"
                  select_value={(val) => setiStatusId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StatusTab}
                  btnname="State"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iStatusId}
                  displayName="strStatusName"
                  disvalue="iStatusId"
                  arrydata={listEntStatus}
                  disabled={false}
                  EmptVal="Status "
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Vendor Cast</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selecrparem.isoptionShow3}
                  placeholder="Select Vendor Cast"
                  select_value={(val) => setiVenCastId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StatusTab}
                  btnname="Vendor Cast"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iVenCastId}
                  displayName="strVenCastName"
                  disvalue="iVenCastId"
                  arrydata={listEntVentCast}
                  disabled={false}
                  EmptVal="Vendor Cast "
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Opening Balance</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Opening Balance"
                  tabIndex={ListTabOrder[0].OpeningBalTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={dOpBal}
                  autoComplete="off"
                  onChange={(e) => setdOpBal(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-2">
                <label>Balance Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow4"
                  showoption={_selecrparem.isoptionShow4}
                  placeholder="Select Balance Type"
                  select_value={(val) => setiBalTypeId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].BalTypeTab}
                  btnname="Balance Type"
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iBalTypeId}
                  displayName="strBalType"
                  disvalue="iBalTypeId"
                  arrydata={listEntBalTyp}
                  disabled={false}
                  EmptVal="Balance Type "
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Credit Term</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  tabIndex={ListTabOrder[0].CreditTermTab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={CreditTerm}
                  autoComplete="off"
                  onChange={(e) => setCreditTerm(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Remarks</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  tabIndex={ListTabOrder[0].RemarksTab}
                  onKeyDown={(e) => TabIndex(e)}
                  placeholder="Enter Remarks"
                  value={strRemarks}
                  autoComplete="off"
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
            </div>
            {ExpImpOpen && (
              <ExportImport
                Close={(IsImported) => {
                  setExpImpOpen(!ExpImpOpen);
                  if (IsImported) ClearClick();
                }}
                iScrId={_ScrId}
              />
            )}
            {bIsNewAddress && (
              <ShippingAddressPopup
                PartyId={iVenId}
                PartyName={strVenName}
                Close={(e) => setbIsNewAddress(!bIsNewAddress)}
                ScreentType="JobWork"
                iScrId={_ScrId}
                Update={(add) => setbIsNewAddress(!bIsNewAddress)}
              />
            )}
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={_ScrId}
              />
            )}
            {IsCountry && (
              <CountryMaster Close={() => setIsCountry(!IsCountry)} />
            )}
            {IsState && <StateMaster Close={() => setIsState(!IsState)} />}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button
              disabled={BtnSaveDisable}
              className="btn-fabgreen"
              onClick={(e) => {
                if (PropertiesValidation()) {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Vendor?",
                      isdialog: true,
                    })
                  );
                }
              }}
              tabIndex={ListTabOrder[0].BtnSavtab}
              onKeyDown={(e) => TabIndex(e)}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              disabled={BtnExport}
              className="btn-fabgreen"
              onClick={(e) => setExpImpOpen(!ExpImpOpen)}
              tabIndex={ListTabOrder[0].BtnExporttab}
              onKeyDown={(e) => TabIndex(e)}
            >
              Export&nbsp;<i className="bx bxs-file-export"></i>
            </button>
            <button
              disabled={BtnDeleteDisable}
              className="btn-fabgreen"
              tabIndex={ListTabOrder[0].BtnDeletetab}
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => {
                if (iVenId === 0) {
                  AlertMessage("Please Select Vendor", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Vendor?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={ClearClick}
              tabIndex={ListTabOrder[0].BtnCleartab}
              onKeyDown={(e) => TabIndex(e)}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex={ListTabOrder[0].BtnClosetab}
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => (window.location.href = "/Dashboard")}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default VendorMaster;
