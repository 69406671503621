import { useEffect, useState } from "react";
import { adminApp, userApp } from "../Admin/Utility";

import SideMenu from "./SideMenu";
import ReactTable from "../General/ReactTable";
import Messagedialogbox from "./Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function EWayBIlling(props) {
  const dispatch = useDispatch();
  const [isNewInvoiceCreate, setisNewInvoiceCreate] = useState(false);
  const [selected, setselected] = useState("");
  const [iVouchId, setiVouchId] = useState(0);
  const [iPartyId, setiPartyId] = useState(0);
  // E-way Parameters
  const [iScrId, setiScrId] = useState(17);
  const [supplyType, setsupplyType] = useState("");
  const [subSupplyType, setsubSupplyType] = useState("");
  const [subSupplyDesc, setsubSupplyDesc] = useState("");
  const [docType, setdocType] = useState("");
  const [docNo, setdocNo] = useState("");
  const [docDate, setdocDate] = useState("");
  const [fromGstin, setfromGstin] = useState("");
  const [fromTrdName, setfromTrdName] = useState("");
  const [fromAddr1, setfromAddr1] = useState("");
  const [fromAddr2, setfromAddr2] = useState("");
  const [fromPlace, setfromPlace] = useState("");
  const [fromPincode, setfromPincode] = useState("");
  const [actFromStateCode, setactFromStateCode] = useState("");
  const [fromStateCode, setfromStateCode] = useState("");
  const [toGstin, settoGstin] = useState("");
  const [toTrdName, settoTrdName] = useState("");
  const [toAddr1, settoAddr1] = useState("");
  const [toAddr2, settoAddr2] = useState("");
  const [toPlace, settoPlace] = useState("");
  const [toPincode, settoPincode] = useState("");
  const [actToStateCode, setactToStateCode] = useState("");
  const [toStateCode, settoStateCode] = useState("");
  const [transactionType, settransactionType] = useState("");
  const [otherValue, setotherValue] = useState("");
  const [totalValue, settotalValue] = useState("");
  const [cgstValue, setcgstValue] = useState("");
  const [sgstValue, setsgstValue] = useState("");
  const [igstValue, setigstValue] = useState("");
  const [cessValue, setcessValue] = useState("");
  const [cessNonAdvolValue, setcessNonAdvolValue] = useState("");
  const [totInvValue, settotInvValue] = useState("");
  const [transporterId, settransporterId] = useState("");
  const [transporterName, settransporterName] = useState("");
  const [transDocNo, settransDocNo] = useState("");
  const [transMode, settransMode] = useState("");
  const [transDistance, settransDistance] = useState("");
  const [transDocDate, settransDocDate] = useState("");
  const [vehicleNo, setvehicleNo] = useState("");
  const [vehicleType, setvehicleType] = useState("");

  const [ListEntInvoice, setListEntInvoice] = useState([]);
  const [ListEntItem, setListEntItem] = useState([]);

  useEffect(() => {
    GetInvoiceData(iScrId);
  }, []);
  const GetInvoiceData = async (ScrId) => {
    try {
      setiScrId(ScrId);
      await dispatch(setload_Screen(true));
      const objSearch = {
        strColName: "Convertion",
        iScrId: ScrId,
        iPartyId: 0,
        strCompanyDB: localStorage.db,
        strScrName: "",
        iUserId: Number(localStorage.UserId),
      };
      await userApp.post(`Transaction/Load_Vouchers`, objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListEntInvoice(Res.data.objVouchers);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const View_Voucher = (VouchId) => {
    try {
      let ControllerUrl = "";
      switch (iScrId) {
        case 11:
          setsupplyType("I");
          ControllerUrl = "PurchaseInvoice/GetVoucherDetails/";
          break;
        case 73:
          setsupplyType("O");
          ControllerUrl = "OutwardInvoice/GetVoucherDetails/";
          break;
        case 76:
          setsupplyType("I");
          ControllerUrl = "InsourceInvoice/GetVoucherDetails/";
          break;
        default:
          setsupplyType("O");
          ControllerUrl = "SalesInvoice/GetVoucherDetails/";
          break;
      }
      dispatch(setload_Screen(true));
      userApp
        .all([
          userApp.get(ControllerUrl + VouchId + "/" + localStorage.db),
          adminApp.get(
            "CompanyMaster/View_Company/" +
              Number(localStorage.ComId) +
              "/" +
              localStorage.Msdb
          ),
        ])
        .then((Res) => {
          if (
            Res[0].data.strStatus === "Success" &&
            Res[1].data.strStatus === "Success"
          ) {
            userApp
              .get(
                "Transaction/View_SateCode/" +
                  Res[0].data.objEntHT[0].iPartyId +
                  "/" +
                  iScrId +
                  "/" +
                  localStorage.db
              )
              .then((Response) => {
                if (Res[0].data.strStatus === "Success") {
                  AssignVoucher_Data(
                    Res[0].data,
                    Res[1].data.CompanyInfo[0],
                    Response.data.StateCode
                  );
                } else {
                  AlertMessage(Res.data.strMessage, "error");
                }
              });
          } else {
            AlertMessage(
              Res[0].data.strMessage + Res[1].data.strMessage,
              "error"
            );
          }
          dispatch(setload_Screen(false));
        });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const AssignVoucher_Data = (VoucherInfo, CompanyInfo, StateCode) => {
    // Company Details
    setfromTrdName(CompanyInfo.strCompName);
    setfromAddr1(CompanyInfo.strAdd1 + "," + CompanyInfo.strAdd2);
    setfromAddr2(CompanyInfo.strAdd3);
    setfromGstin(CompanyInfo.strGST);
    setfromPincode(CompanyInfo.strPincode);
    setfromPlace(CompanyInfo.strAdd4);
    setfromStateCode(CompanyInfo.state);
    setactFromStateCode(CompanyInfo.state);

    // To Party or Trade Details
    settoTrdName(VoucherInfo.objPartyList[0]);

    settoStateCode(StateCode);
    setactToStateCode(StateCode);
    settoGstin(VoucherInfo.objPartyList[3]);
    setdocNo(VoucherInfo.objEntHT[0].strVouchNo);
    setdocDate(VoucherInfo.objEntHT[0].strVouchDate);
    setvehicleNo(VoucherInfo.objEntHT[0].strVehicle);
    settransDocDate(VoucherInfo.objEntHT[0].dpDespatchDate);
    settransDocNo(VoucherInfo.objEntHT[0].strDespatch);

    // Total Invocie Discound,taxValue,Taxable Value
    settotalValue(VoucherInfo.objEntSummary[0].goodsValue.toFixed(2));
    settotInvValue(VoucherInfo.objEntSummary[0].netAmt.toFixed(2));
    setcessValue((0).toFixed(2));
    setcessNonAdvolValue((0).toFixed(2));
    let otheVal =
      VoucherInfo.objEntSummary[0].totalDiscount -
      VoucherInfo.objEntSummary[0].totalAddLess +
      VoucherInfo.objEntSummary[0].roundOff;

    setotherValue(otheVal.toFixed(2));
    let Taxypeid = VoucherInfo.objEntHT[0].iTaxTypeId;
    switch (Taxypeid) {
      case 2:
        setigstValue(VoucherInfo.objEntSummary[0].totalTax.toFixed(2));
        setcgstValue((0).toFixed(2));
        setsgstValue((0).toFixed(2));
        break;
      default:
        setigstValue((0).toFixed(2));
        setcgstValue((VoucherInfo.objEntSummary[0].totalTax / 2).toFixed(2));
        setsgstValue((VoucherInfo.objEntSummary[0].totalTax / 2).toFixed(2));
        break;
    }
    // Item Detailes Assign
    let EntItem = [];
    VoucherInfo.objEntDT.map((Data) => {
      const objItem = {
        productName: Data.proName,
        productDesc: Data.remark1 + Data.remark2 + Data.remark3 + Data.remark4,
        hsnCode: Data.commodityCode,
        quantity: Data.qty,
        qtyUnit: Data.unitName,
        cgstRate:
          Taxypeid === 1 ? (Data.taxPer / 2).toFixed(2) : (0).toFixed(2),
        sgstRate:
          Taxypeid === 1 ? (Data.taxPer / 2).toFixed(2) : (0).toFixed(2),
        igstRate: Taxypeid === 1 ? (0).toFixed(2) : Data.taxPer.toFixed(2),
        cessRate: 0,
        cessNonadvol: 0,
        taxableAmount: Data.taxableAmt,
      };
      EntItem.push(objItem);
      return Data;
    });
    setListEntItem(EntItem);
  };

  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  const GenerateEwaBillClick = () => {
    try {
      userApp.post().then((Res) => {
        if (Res.status === "200") {
          AlertMessage("E-way Bill Successfully Created", "Success");
        } else {
          AlertMessage(Res.statusText, "info");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "info");
    }
  };
  const ListScreenType = [
    { ScrType: "Sales Invocie", ScrId: 17 },
    { ScrType: "Outward Invocie", ScrId: 73 },
    { ScrType: "Purchase Invocie", ScrId: 11 },
    { ScrType: "InSource Invocie", ScrId: 76 },
  ];
  const ListSupplyType = [{ SupTyp: "O" }, { SupTyp: "I" }];

  const ListdocType = [
    { docType: "INV" },
    { docType: "CHL" },
    { docType: "BIL" },
    { docType: "BOE" },
    { docType: "OTH" },
  ];
  const ListTransportMode = [
    { TransType: 1, TransTypeName: "Road" },
    { TransType: 2, TransTypeName: "Rail" },
    { TransType: 3, TransTypeName: "Air" },
    { TransType: 4, TransTypeName: "Ship" },
  ];
  const EwayJosnObject = {
    $schema: "https://json-schema.org/draft-04/schema#",
    type: "object",
    properties: {
      supplyType: {
        type: "string",
        maxLength: 1,
        minLength: 1,
        enum: ["O", "I"],
        description: "Supply Type",
      },
      subSupplyType: {
        type: "string",
        description: "Sub Supply Type",
      },
      subSupplyDesc: {
        type: "string",
        maxLength: 20,
        description: "Other Sub Supply Description",
      },
      docType: {
        type: "string",
        enum: ["INV", "CHL", "BIL", "BOE", "OTH"],
        description: "Document Type",
      },
      docNo: {
        type: "string",
        maxLength: 16,
        description: "Document Number (Alphanumeric with / and - are allowed)",
      },
      docDate: {
        type: "string",
        pattern: "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
        description: "Document Date",
      },
      fromGstin: {
        type: "string",
        maxLength: 15,
        minLength: 15,
        pattern: "[0-9]{2}[0-9|A-Z]{13}",
        description: "From GSTIN (Supplier or Consignor)",
      },
      fromTrdName: {
        type: "string",
        maxLength: 100,
        description: "From Trade Name (Consignor Trade name)",
      },
      fromAddr1: {
        type: "string",
        maxLength: 120,
        description: "From Address Line 1 (Valid Special Chars #,-,/)",
      },
      fromAddr2: {
        type: "string",
        maxLength: 120,
        description: "From Address Line 2(Valid Special Chars # , - ,/)",
      },
      fromPlace: {
        type: "string",
        maxLength: 50,
        description: "From Place",
      },
      actFromStateCode: {
        type: "integer",
        maximum: 99,
        description: "Actual From State Code",
      },
      fromPincode: {
        type: "integer",
        maximum: 999999,
        minimum: 100000,
        description: "From Pincode",
      },
      fromStateCode: {
        type: "integer",
        maximum: 99,
        description: "From State Code",
      },
      toGstin: {
        type: "string",
        maxLength: 15,
        minLength: 15,
        pattern: "[0-9]{2}[0-9|A-Z]{13}",
        description: "To GSTIN (Consignee or Recipient)",
      },
      toTrdName: {
        type: "string",
        maxLength: 100,
        description:
          "To Trade Name (Consignee Trade name or Recipient Trade name)",
      },
      toAddr1: {
        type: "string",
        maxLength: 120,
        description: "To Address Line 1 (Valid Special Chars #,-,/)",
      },
      toAddr2: {
        type: "string",
        maxLength: 120,
        description: "To Address Line 2 (Valid Special Chars #,-,/)",
      },
      toPlace: {
        type: "string",
        maxLength: 50,
        description: "To Place",
      },
      toPincode: {
        type: "integer",
        description: "To Pincode",
      },
      actToStateCode: {
        type: "integer",
        maximum: 99,
        description: "Actual To State Code",
      },
      toStateCode: {
        type: "integer",
        maximum: 99,
        description: "To State Code",
      },
      transactionType: {
        type: "integer",
        maximum: 4,
        description: "Transaction type",
      },
      totalValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Sum of Taxable value ",
      },
      cgstValue: {
        type: "number",
        multipleOf: 0.01,
        description: "CGST value",
      },
      sgstValue: {
        type: "number",
        multipleOf: 0.01,
        description: "SGST value",
      },
      igstValue: {
        type: "number",
        multipleOf: 0.01,
        description: "IGST value",
      },
      cessValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Cess value",
      },
      cessNonAdvolValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Cess Non Advol value",
      },
      otherValue: {
        type: "number",
        multipleOf: 0.01,
        description: "Other charges, if any ",
      },
      totInvValue: {
        type: "number",
        multipleOf: 0.01,
        description:
          "Total Invoice Value (Including taxable value, tax value,and other charges if any)",
      },
      transMode: {
        type: "string",
        enum: ["1", "2", "3", "4"],
        description: "Mode of transport (Road-1, Rail-2, Air-3, Ship-4) ",
      },
      transDistance: {
        type: "string",
        description: "Distance (<4000 km) ",
      },
      transporterName: {
        type: "string",
        maxLength: 100,
        description: "Name of the transporter",
      },
      transporterId: {
        type: "string",
        pattern: "[0-9]{2}[0-9|A-Z]{13}",
        description: "15 Digit Transporter GSTIN/TRANSIN",
      },
      transDocNo: {
        type: "string",
        maxLength: 15,
        description:
          "Transport Document Number (Alphanumeric with / and – are allowed)",
      },
      transDocDate: {
        type: "string",
        description: "Transport Document Date",
      },
      vehicleNo: {
        type: "string",
        minLength: 7,
        maxLength: 15,
        description: "Vehicle Number",
      },
      vehicleType: {
        type: "string",
        description: "Vehicle Type",
      },
      itemList: {
        type: "array",
        items: [
          {
            type: "object",
            properties: {
              productName: {
                type: "string",
                maxLength: 100,
                description: "Product / Item Name",
              },
              productDesc: {
                type: "string",
                maxLength: 100,
                description: "Product / Item description",
              },
              hsnCode: {
                type: "number",
                description: "HSN Code",
              },
              quantity: {
                type: "number",
                description: "Quantity",
              },
              qtyUnit: {
                type: "string",
                maxLength: 3,
                minLength: 3,
                description: "Unit",
              },
              taxableAmount: {
                type: "number",
                multipleOf: 0.01,
                description: "Taxable Amount",
              },
              sgstRate: {
                type: "number",
                multipleOf: 0.001,
                description: "SGST Rate of Tax",
              },
              cgstRate: {
                type: "number",
                multipleOf: 0.001,
                description: "CGST Rate of Tax",
              },
              igstRate: {
                type: "number",
                multipleOf: 0.001,
                description: "IGST Rate of Tax",
              },
              cessRate: {
                type: "number",
                multipleOf: 0.001,
                description: "Cess Rate of Tax",
              },
              cessNonadvol: {
                type: "number",
                description: "Cess Non-Advolerum",
              },
            },
            required: ["hsnCode", "taxableAmount"],
          },
        ],
      },
    },
    required: [
      "supplyType",
      "subSupplyType",
      "docType",
      "docNo",
      "docDate",
      "fromGstin",
      "fromPincode",
      "fromStateCode",
      "toGstin",
      "toPincode",
      "toStateCode",
      "transDistance",
      "itemList",
      "actToStateCode",
      "actFromStateCode",
      "totInvValue",
      "transactionType",
    ],
  };
  const UserRequest = {
    supplyType: "O",
    subSupplyType: "1",
    subSupplyDesc: "",
    docType: "INV",
    docNo: "7001-8",
    docDate: "15/12/2017",
    fromGstin: "29AKLPM8755F1Z2",
    fromTrdName: "welton",
    fromAddr1: "2ND CROSS NO 59  19  A",
    fromAddr2: "GROUND FLOOR OSBORNE ROAD",
    fromPlace: "FRAZER TOWN",
    fromPincode: 560090,
    actFromStateCode: 29,
    fromStateCode: 29,
    toGstin: "02EHFPS5910D2Z0",
    toTrdName: "sthuthya",
    toAddr1: "Shree Nilaya",
    toAddr2: "Dasarahosahalli",
    toPlace: "Beml Nagar",
    toPincode: 560090,
    actToStateCode: 29,
    toStateCode: 27,
    transactionType: 4,
    otherValue: "-100",
    totalValue: 56099,
    cgstValue: 0,
    sgstValue: 0,
    igstValue: 300.67,
    cessValue: 400.56,
    cessNonAdvolValue: 400,
    totInvValue: 68358,
    transporterId: "",
    transporterName: "",
    transDocNo: "",
    transMode: "1",
    transDistance: "100",
    transDocDate: "",
    vehicleNo: "PVC1234",
    vehicleType: "R",
    itemList: [
      {
        productName: "Wheat",
        productDesc: "Wheat",
        hsnCode: 1001,
        quantity: 4,
        qtyUnit: "BOX",
        cgstRate: 0,
        sgstRate: 0,
        igstRate: 3,
        cessRate: 3,
        cessNonadvol: 0,
        taxableAmount: 5609889,
      },
    ],
  };
  const ItemColumn = [
    {
      Header: "ProductName",
      accessor: "productName",
      maxWidth: 200,
      minWidth: 200,
    },
    { Header: "Product Desc", accessor: "productDesc" },
    {
      Header: "HSN Code",
      accessor: "hsnCode",
      style: { justifyContent: "center" },
    },
    { Header: "Qty", accessor: "quantity", style: { justifyContent: "right" } },
    {
      Header: "Unit",
      accessor: "qtyUnit",
      style: { justifyContent: "center" },
    },
    {
      Header: "CGST Rate",
      accessor: "cgstRate",
      style: { justifyContent: "right" },
    },
    {
      Header: "SGST Rate",
      accessor: "sgstRate",
      style: { justifyContent: "right" },
    },
    {
      Header: "IGST Rate",
      accessor: "igstRate",
      style: { justifyContent: "right" },
    },
    {
      Header: "Cess Rate",
      accessor: "cessRate",
      style: { justifyContent: "right" },
    },
    {
      Header: "Cess Non-Advolerum",
      accessor: "cessNonadvol",
      style: { justifyContent: "right" },
    },
    {
      Header: "Taxable Amount",
      accessor: "taxableAmount",
      style: { justifyContent: "right" },
    },
  ];
  const InvoiceColumn = [
    {
      Header: "SNo",
      accessor: "sNo",
      maxWidth: 100,
    },
    {
      Header: "VouchId",
      accessor: "vouchId",
      show: false,
    },
    {
      Header: "Voucher Number",
      accessor: "vouchNo",
      minWidth: 200,
      maxWidth: 200,
    },
    {
      Header: "Date",
      accessor: "vouchDate",
    },
    {
      Header: "Party Name",
      accessor: "partyName",
    },
    {
      Header: "Total Qty",
      accessor: "tQty",
      DataType: "Decimal",
    },
    {
      Header: "Total Tax",
      accessor: "tTaxAmt",
      DataType: "Decimal",
    },
    {
      Header: "Net Amount",
      accessor: "netAmt",
      DataType: "Decimal",
    },
  ];
  return (
    <>
      <SideMenu ScrId={151} />
      <div className="EB-Container">
        <div className="btn-eb">
          <button
            onClick={(e) => {
              if (iVouchId === 0) {
                AlertMessage("Please Select Invoice", "info");
              } else {
                View_Voucher(iVouchId);
                setisNewInvoiceCreate(!isNewInvoiceCreate);
              }
            }}
          >
            Create E-Invoice <i className="fa-solid fa-circle-plus"></i>
          </button>
          {!isNewInvoiceCreate && (
            <select
              style={{ maxWidth: "200px" }}
              className="form-select"
              value={iScrId}
              onChange={(e) => GetInvoiceData(Number(e.target.value), iPartyId)}
            >
              {ListScreenType.map((ScrType, Index) => (
                <option key={Index} value={ScrType.ScrId}>
                  {ScrType.ScrType}
                </option>
              ))}
            </select>
          )}
          {isNewInvoiceCreate && (
            <button
              onClick={(e) => {
                setiVouchId(0);
                setisNewInvoiceCreate(!isNewInvoiceCreate);
              }}
            >
              <i className="fa-solid fa-arrow-left"></i> Go Back
            </button>
          )}
        </div>
        {isNewInvoiceCreate && (
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <label>
                Supply Type<span className="danger">*</span>
              </label>
              <select
                className="form-select"
                value={supplyType}
                onChange={(e) => setsupplyType(e.target.value)}
              >
                {ListSupplyType.map((SupType, Index) => (
                  <option key={Index} value={SupType.SupTyp}>
                    {SupType.SupTyp}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Sub Supply Type<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={subSupplyType}
                onChange={(e) => setsubSupplyType(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Other Sub Supply Description<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={subSupplyDesc}
                onChange={(e) => setsubSupplyDesc(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Document Type<span className="danger">*</span>
              </label>
              <select
                className="form-select"
                value={docType}
                onChange={(e) => setdocType(e.target.value)}
              >
                {ListdocType.map((docType, Index) => (
                  <option key={Index} value={docType.docType}>
                    {docType.docType}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Document Number<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={docNo}
                onChange={(e) => setdocNo(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Document Date<span className="danger">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                pattern="[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]"
                value={docDate}
                onChange={(e) => setdocDate(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                From GSTIN (Supplier or Consignor)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={fromGstin}
                onChange={(e) => setfromGstin(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                From Trade Name (Consignor Trade name)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={fromTrdName}
                onChange={(e) => setfromTrdName(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                From Address Line 1<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={fromAddr1}
                onChange={(e) => setfromAddr1(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                From Address Line 2<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={fromAddr2}
                onChange={(e) => setfromAddr2(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                From Place<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={fromPlace}
                onChange={(e) => setfromPlace(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Actual From State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={actFromStateCode}
                onChange={(e) => setactFromStateCode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                From Pincode<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={fromPincode}
                onChange={(e) => setfromPincode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                From State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={fromStateCode}
                onChange={(e) => setfromStateCode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                To GSTIN (Consignee or Recipient)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={toGstin}
                onChange={(e) => settoGstin(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                To Trade Name<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={toTrdName}
                onChange={(e) => settoTrdName(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                To Address Line 1<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={toAddr1}
                onChange={(e) => settoAddr1(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                To Address Line 2<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={toAddr2}
                onChange={(e) => settoAddr2(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                To Place<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={toPlace}
                onChange={(e) => settoPlace(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                To Pincode<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={toPincode}
                onChange={(e) => settoPincode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Actual To State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={actToStateCode}
                onChange={(e) => setactToStateCode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                To State Code<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={toStateCode}
                onChange={(e) => settoStateCode(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Transaction type<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={transactionType}
                onChange={(e) => settransactionType(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Sum of Taxable value <span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={totalValue}
                onChange={(e) => settotalValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                CGST value<span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={cgstValue}
                onChange={(e) => setcgstValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                SGST value<span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={sgstValue}
                onChange={(e) => setsgstValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                IGST value<span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={igstValue}
                onChange={(e) => setigstValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Cess value<span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={cessValue}
                onChange={(e) => setcessValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Cess Non Advol value<span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={cessNonAdvolValue}
                onChange={(e) => setcessNonAdvolValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Other charges, if any <span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={otherValue}
                onChange={(e) => setotherValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Total Invoice value<span className="danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={totInvValue}
                onChange={(e) => settotInvValue(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Transport Mode <span className="danger">*</span>
              </label>
              <select
                className="form-select"
                value={transMode}
                onChange={(e) => settransMode(e.target.value)}
              >
                {ListTransportMode.map((TransMode, Index) => (
                  <option key={Index} value={TransMode.TransType}>
                    {TransMode.TransTypeName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Transport Distance (below 4000km)
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={transDistance}
                onChange={(e) => settransDistance(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Name of the transporter<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={transporterName}
                onChange={(e) => settransporterName(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                15 Digit Transporter GSTIN/TRANSIN
                <span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={transporterId}
                onChange={(e) => settransporterId(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Transport Document Number<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={transDocNo}
                onChange={(e) => settransDocNo(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Transport Document Date<span className="danger">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                value={transDocDate}
                onChange={(e) => settransDocDate(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Vehicle Number<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={vehicleNo}
                onChange={(e) => setvehicleNo(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <label>
                Vehicle Type<span className="danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={vehicleType}
                onChange={(e) => setvehicleType(e.target.value)}
              />
            </div>
            <ReactTable
              columns={ItemColumn}
              data={ListEntItem}
              minRows={5}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {}}
              background={true}
              className="full-table"
            />
          </div>
        )}
        {!isNewInvoiceCreate && (
          <div className="TransactionTable mt-2">
            <ReactTable
              sortable={true}
              data={ListEntInvoice}
              columns={InvoiceColumn}
              minRows={5}
              defaultPageSize={500}
              showPaginationBottom={false}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (e) => {
                      setselected(rowInfo.index);
                      setiVouchId(rowInfo.original.vouchId);
                      setiPartyId(rowInfo.original.partyId);
                    },
                    style: {
                      background:
                        rowInfo.index === selected ? "darkgreen" : "white",
                      color: rowInfo.index === selected ? "white" : "black",
                    },
                    onKeyDown: (e) => {
                      var RowCount = ListEntInvoice.length - 1;
                      if (e.key === "ArrowDown") {
                        if (RowCount === selected) {
                          setselected(0);
                          setiVouchId(ListEntInvoice[0].vouchId);
                        } else {
                          setselected(selected + 1);
                          setiVouchId(ListEntInvoice[selected + 1].vouchId);
                        }
                      } else if (e.key === "ArrowUp") {
                        if (selected === 0) {
                          setselected(RowCount);
                          setiVouchId(ListEntInvoice[RowCount].vouchId);
                        } else {
                          setselected(selected - 1);
                          setiVouchId(ListEntInvoice[selected - 1].vouchId);
                        }
                      } else if (e.key === "Enter") {
                        setselected(selected);
                        setiVouchId(ListEntInvoice[selected].vouchId);
                      }
                    },
                    tabIndex: selected,
                  };
                } else {
                  return {};
                }
              }}
            />
          </div>
        )}
        <Messagedialogbox />
      </div>
    </>
  );
}

export default EWayBIlling;
