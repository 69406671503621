import React, { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";

function BankMaster(props) {
  const dispatch = useDispatch();
  const [Bank, setBank] = useState({
    iBankId: 0,
    strBankName: "",
    arrEntBank: [],
    strSearch: "",
  });
  const updateBankField = (field, value) => {
    setBank((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  useEffect(() => {
    Load_BankMaster();
  }, []);
  const Load_BankMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        strSearch: "",
        iBankNameId: 0,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`BankMaster/Load_BankMaster`, parems).then(
        async (res) => {
          if (res.data.strStatus === "Success") {
            await updateBankField("arrEntBank", res.data.EntBank);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Bank = async (strtxt) => {
    try {
      const parems = {
        strSearch: strtxt,
        iBankNameId: 0,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`BankMaster/Search_BankMaster`, parems).then(
        async (res) => {
          if (res.data.strStatus === "Success") {
            await updateBankField("arrEntBank", res.data.EntBank);
            await updateBankField("strSearch", strtxt);
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Save_Bank = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        BNId: Bank.iBankId,
        BankNames: Bank.strBankName,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`BankMaster/InsUpd_Bank`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            Clear_Bank();
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Bank = async (objBank) => {
    await updateBankField("iBankId", objBank.bnId);
    await updateBankField("strBankName", objBank.bankNames);
  };
  const Delete_Bank = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        BNId: Bank.iBankId,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`BankMaster/Delete_Bank`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            Clear_Bank();
          } else AlertMessage(res.data.strMessage, "error");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_Bank = async () => {
    await setBank({
      iBankId: 0,
      strBankName: "",
      arrEntBank: [],
      strSearch: "",
    });
    await Load_BankMaster();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Bank Master</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-5 col-sm-12 popup-card">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search"
                  className="form-control"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      $(".dt-content [tabindex=0]").focus();
                    }
                  }}
                  value={Bank.strSearch}
                  tabIndex={59}
                  onChange={(e) => Search_Bank(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Bank(Bank.strSearch)}
                ></i>
              </div>
              <div className="data-list">
                {Bank.arrEntBank.map((dt, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onClick={(e) => View_Bank(dt)}
                    onKeyDown={(e) => {
                      const arrlength = Bank.arrEntBank.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Bank(dt);
                      }
                    }}
                  >
                    <label>{dt.bankNames}</label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 mb-1">
                  <label>
                    Bank Name <span className="danger">*</span>
                  </label>
                  <input
                    type="text"
                    aria-label="First name"
                    placeholder="Enter Bank Name"
                    className="form-control"
                    value={Bank.strBankName}
                    onChange={(e) =>
                      updateBankField("strBankName", e.target.value)
                    }
                    autoFocus
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              tabIndex="54"
              onClick={(e) => {
                if (Bank.strBankName === "") {
                  AlertMessage("Bank Name is Rquired", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Save_Bank,
                      Question: "Do You Want to Save Bank?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex="55"
              onClick={(e) => {
                if (Number(Bank.iBankId) === 0) {
                  AlertMessage("Validation", "Please Select Category", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Delete_Bank,
                      Question: "Do You Want to Delete Bank ?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="reset"
              className="btn-fabgreen"
              tabIndex="56"
              onClick={Clear_Bank}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={async (e) => props.Bank_Search()}
            >
              <i className="bx bx-arrow-back"></i>&nbsp;Back to Bank
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BankMaster;
