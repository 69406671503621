import { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import SideMenu from "../General/SideMenu";
import JobSearch from "../JobWork/JobSearch";
import ReactTable from "../General/ReactTable";
import { userApp} from "../Admin/Utility";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import PartyCreation from "../Transaction/PartyCreation";
import {
  Add_JobWork,
  JobWorkInput_Onchange,
  Modify_JobWork,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
function RouteGroup() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _DT = useSelector((store) => store.states.Data);
  const _JobWork = useSelector((store) => store.states.JobWork);

  const iScrId = 69;
  const [iRGId, setiRGId] = useState(0);
  const [strRouteGrpName, setstrRouteGrpName] = useState("");
  const [strAlias, setstrAlias] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [ListRouteGroup, setListRouteGroup] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  useEffect(() => {
    Load_RouteGroup();
  }, []);
  const Load_RouteGroup = async () => {
    try {
      const parems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await dispatch(setload_Screen(true));
      await userApp.post(`RouteGroup/Load_RouteGroup`, parems).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setListFilter(res.data.EntFilterCol);
            setListRouteGroup(res.data.EntRouteGroup);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const InsUpdRG = {
        iScrId: iScrId,
        iRGId: Number(iRGId),
        strRGName: strRouteGrpName,
        strAliasName: strAlias,
        strPrintName: strPrintName,
        strRemarks: strRemarks,
      };
      const objInsUpdRG = {
        _objEntRG: _DT.arrEntDT,
        objEntRG: InsUpdRG,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
      };
      await userApp.post(
        `RouteGroup/InsUpd_RouteGroup/`,
        objInsUpdRG
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = async () => {
    setstrRouteGrpName("");
    setstrAlias("");
    setstrRemarks("");
    setstrPrintName("");
    setListFilter([]);
    setListRouteGroup([]);
    await dispatch(Add_JobWork([]));
    await Load_RouteGroup();
  };
  const Add_Job = async () => {
    try {
      if (Number(_JobWork.JobId) === 0) {
        AlertMessage("Select Job Work", "info");
      } else {
        await dispatch(setload_Screen(true));
        const objParams = {
          bUpdateStatus: _JobWork.bUpdateStatus,
          iScrId: iScrId,
          iRowIndex: _JobWork.iRowIndex,
          JobId: _JobWork.JobId,
          JobName: _JobWork.strJobName,
          Amount: Number(_JobWork.dRate),
          Remark1: _JobWork.strRGRemark1,
          Remark2: _JobWork.strRGRemark2,
          Remark3: _JobWork.strRGRemark3,
          Remark4: _JobWork.strRGRemark4,
          Hours: _JobWork.strRGHours,
          _objEntRG: _DT.arrEntDT,
          iYearId: Number(localStorage.FYId),
          strCompanyDB: localStorage.db,
          strMasterDB: localStorage.Msdb,
          iUserId: Number(localStorage.UserId),
        };
        await userApp.post(
          `Transaction/Add_RouteGroupJob/`,
          objParams
        ).then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await dispatch(Add_JobWork(Res.data.EntDT));
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.post(
        `RouteGroup/Delete_Routegroup/${iScrId}/${Number(iRGId)}/${
          localStorage.db
        }`
      ).then((res) => {
        if (res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_RouteGroup = async (RG) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.get(
        `RouteGroup/View_RouteGroup/${Number(RG.iRGId)}/
        ${localStorage.db}`
      ).then(async (res) => {
        if (res.data.strStatus === "Success") {
          setiRGId(Number(RG.iRGId));
          setstrRouteGrpName(RG.strRGName);
          setstrAlias(RG.strAliasName);
          setstrPrintName(RG.strPrintName);
          setstrRemarks(RG.strRemarks);
          await dispatch(Add_JobWork(res.data.EntDT));
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Remove_TableRow = async (rowinfo) => {
    try {
      await dispatch(setload_Screen(true));
      const objParams = {
        bUpdateStatus: _JobWork.bUpdateStatus,
        iScrId: iScrId,
        iRowIndex: rowinfo.orderNo,
        JobId: _JobWork.JobId,
        JobName: _JobWork.strJobName,
        JobCode: _JobWork.strJobCode,
        Amount: Number(_JobWork.dRate),
        _objEntRG: _DT.arrEntDT,
        iYearId: Number(localStorage.FYId),
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
      };
      await userApp.post(
        `Transaction/Remove_RouteGroupJob/`,
        objParams
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await dispatch(Add_JobWork(Res.data.EntDT));
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Routegroup = async (strtxt) => {
    const obsearch = {
      strColName: "All",
      strSearch: strtxt,
      strCompanyDB: localStorage.db,
    };
    await userApp.post(`RouteGroup/Search_Routegroup/`, obsearch).then(
      (res) => {
        if (res.data.strStatus === "Success")
          setListRouteGroup(res.data.EntRouteGroup);
        else AlertMessage(res.data.strMessage, "error");
      }
    );
  };
  const Input_Onchange = (e) => {
    dispatch(
      JobWorkInput_Onchange({
        Name: e.target.name,
        Value: e.target.value,
      })
    );
  };
  const Job = [
    {
      Header: "SNO",
      accessor: "orderNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Name",
      accessor: "jobName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Amount",
      accessor: "amount",
      style: { justifyContent: "right" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Remark 1",
      accessor: "remark1",
      style: { justifyContent: "left" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Remark 2",
      accessor: "remark2",
      style: { justifyContent: "left" },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: "Action",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={() => Remove_TableRow(rowinfo)}
          className="far fa-trash-alt"
        ></i>
      ),
    },
  ];
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <div>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={ListFilter}
          EntList={ListRouteGroup}
          GetDetails={View_RouteGroup}
          Search={Search_Routegroup}
          ScreenID={iScrId}
          accessor="strRGName"
          accessorid="iRGId"
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-1">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  autoFocus
                  value={strRouteGrpName}
                  onChange={(e) => setstrRouteGrpName(e.target.value)}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter RoutGroup Name"
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Alias Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={strAlias}
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Alias Name"
                  onChange={(e) => setstrAlias(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Print Name</label>
                <input
                  type="text"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Print Name"
                  value={strPrintName}
                  onChange={(e) => setstrPrintName(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-1">
                <label>Remark</label>
                <input
                  type="text"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Remark"
                  value={strRemarks}
                  onChange={(e) => setstrRemarks(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-1">
                <label>Job</label>
                <div className="custom-select">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Press Enter to Select JobWork"
                    defaultValue={_JobWork.strJobName}
                    onClick={(e) => dispatch(ShowHide_Popup("IsJobSearch"))}
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        dispatch(ShowHide_Popup("IsJobSearch"));
                    }}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: _Common.IsJobSearch
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-2 col-sm-12 mt-1">
                <label>Amount</label>
                <input
                  type="number"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Amount"
                  className="form-control"
                  name="dRate"
                  value={_JobWork.dRate}
                  onChange={(e) => Input_Onchange(e)}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>Remark 1</label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Remark"
                  className="form-control"
                  name="strRGRemark1"
                  autoComplete="off"
                  spellCheck={false}
                  value={_JobWork.strRGRemark1}
                  onChange={(e) => Input_Onchange(e)}
                />
              </div>
              <div className="col-md-2 col-sm-6 mt-1">
                <label>Remark 2</label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Remark"
                  className="form-control"
                  autoComplete="off"
                  spellCheck={false}
                  name="strRGRemark2"
                  value={_JobWork.strRGRemark2}
                  onChange={(e) => Input_Onchange(e)}
                />
              </div>
              <div className="col-md-2 col-sm-12 mt-4">
                <div className="btn-sub-section">
                  <button className="btn-fabgreen" onClick={Add_Job}>
                    Add&nbsp;<i className="bx bx-layer-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <ReactTable
              columns={Job}
              data={_DT.arrEntDT}
              minRows={5}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {
                dispatch(Modify_JobWork({ JobInfo: rowInfo, Index: Index }));
              }}
              background={true}
              className="full-table"
            />
            {_Common.IsJobSearch && <JobSearch />}
            {_Common.IsVenCreation && <PartyCreation />}
            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (strRouteGrpName === "") {
                AlertMessage("Please Enter Name", "info");
              } else if (_DT.arrEntDT.length === 0) {
                AlertMessage("Atleast One Job Required", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: SaveClick,
                    Question: "Do You Want to Save Route Group?",
                    isdialog: true,
                  })
                );
              }
            }}
            Clear={ClearClick}
            Delete={(e) => {
              if (Number(iRGId) === 0) {
                AlertMessage("Please Select Route Group", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Route Group?",
                    isdialog: true,
                  })
                );
              }
            }}
            ScrId={iScrId}
          />
        </div>
      </div>
    </div>
  );
}
export default RouteGroup;
