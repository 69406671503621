import React, { useEffect } from "react";
import { useState } from "react";

import { userApp} from "../Admin/Utility";
import ReactTable from "../General/ReactTable";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import CustomizedSelectoption from "./CustomizedSelectoption";
import { ShowHide_Popup } from "../ReduxStore/Transactionstates";
function EInvoice() {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const [iScrId, setiScrId] = useState(0);
  const [strColName, setstrColName] = useState("");
  const [strAliasName, setstrAliasName] = useState("");
  const [strDatatype, setstrDatatype] = useState("");
  const [strTransName, setstrTransName] = useState("");
  const [strdefaultval, setstrdefaultval] = useState("");
  const [strval, setstrval] = useState("");
  const [isShow, setisShow] = useState(true);
  const [iAreaId, setiAreaId] = useState(0);
  const [iColId, setiColId] = useState(0);
  const [isSetting, setisSetting] = useState(false);

  const [ListEntItem, setListEntItem] = useState([]);
  const [arrEntCol, setarrEntCol] = useState([]);
  const [arrEntArea, setarrEntArea] = useState([]);
  const [EntJsonInv, setEntJsonInv] = useState([]);
  useEffect(() => {
    Load_EInvoice(_Common.iScrId, _HT.SelectedVouchId);
  }, []);
  const Load_EInvoice = async (ScrId, VouchId) => {
    try {
      setiScrId(ScrId);
      dispatch(setload_Screen(true));
      await userApp.get(
        `GST/Load_EInvoice/${ScrId}/${VouchId}/${Number(
          localStorage.ComId
        )}/${Number(localStorage.FYId)}/${localStorage.db}/${localStorage.Msdb}`
      ).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await AssignVoucher_Data(ScrId, Res.data);
        } else {
          await dispatch(ShowHide_Popup("IsEInvoice"));
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AssignVoucher_Data = async (ScrId, InvtDT) => {
    let EntArea = InvtDT.EntArea;
    let EntCol = InvtDT.EntCol;
    let EntTax = InvtDT.objEntTax;
    let EntSummary = InvtDT.objEntSummary;
    let Summary = EntSummary[0];
    let EntHT = InvtDT.objEntHT;
    let EntParty = InvtDT.Party;
    let EntShipAdd = InvtDT.EntShippingAdd;
    let EntCompany = InvtDT.Company;
    let EntItem = InvtDT.objEntDT;
    let TaxRelId = EntHT[0].iTaxTypeId;
    let iTCSApplicable = EntHT[0].iTCSApplicable;
    let dSGST = 0;
    let dCGST = 0;
    let dIGST = 0;
    let dTCS = 0;
    const checkbuyerseller = (arrParty, arrcompany, type) => {
      switch (ScrId) {
        case 11:
          return type === "Sell" ? arrParty : arrcompany;
        case 17:
          return type === "Sell" ? arrcompany : arrParty;
        case 67:
          return type === "Sell" ? arrcompany : arrParty;
        case 73:
          return type === "Sell" ? arrcompany : arrParty;
        case 76:
          return type === "Sell" ? arrParty : arrcompany;
        case 78:
          return type === "Sell" ? arrParty : arrcompany;
        default:
          break;
      }
    };
    EntTax.forEach((dt) => {
      if (dt.taxDesc.startsWith("SGST") && TaxRelId === 1) {
        dSGST += dt.taxAmt;
      } else if (dt.taxDesc.startsWith("CGST") && TaxRelId === 1) {
        dCGST += dt.taxAmt;
      } else if (dt.taxDesc.startsWith("IGST") && TaxRelId === 2) {
        dIGST += dt.taxAmt;
      } else if (dt.taxDesc.startsWith("TCS") && iTCSApplicable === 1) {
        dTCS += dt.taxAmt;
      }
    });
    Summary = { ...Summary, sgst: dSGST, cgst: dCGST, igst: dIGST, tcs: dTCS };
    let objData = [
      {
        iAreaId: 1,
        data: EntHT[0],
      },
      {
        iAreaId: 2,
        data: EntHT[0],
      },
      {
        iAreaId: 3,
        data: checkbuyerseller(EntParty[0], EntCompany[0], "Sell"),
      },
      {
        iAreaId: 4,
        data: checkbuyerseller(EntParty[0], EntCompany[0], "Buy"),
      },
      {
        iAreaId: 5,
        data: checkbuyerseller(EntParty[0], EntCompany[0], "Sell"),
      },
      {
        iAreaId: 6,
        data: EntShipAdd,
      },
      {
        iAreaId: 7,
        data: Summary,
      },
      {
        iAreaId: 8,
        data: EntHT[0],
      },
      {
        iAreaId: 9,
        data: EntItem,
      },
    ];
    let objInvoice = { Version: "1.1" };
    EntArea.forEach((area) => {
      let AreaCol = EntCol.filter((col) => col.iAreaId === area.iAreaId);
      let Entdt = objData.filter((col) => col.iAreaId === area.iAreaId)[0].data;
      if (area.iAreaId !== 9) {
        let objDT = {};
        AreaCol.forEach((col) => {
          if (col.strConditionCol === "") {
            if (col.strdatatype === "cmb")
              objDT = {
                ...objDT,
                [col.strGstcolName]: col.strValue,
              };
            else if (col.strdatatype === "decimal")
              objDT = {
                ...objDT,
                [col.strGstcolName]: Number(col.strdefaultvalue),
              };
            else
              objDT = {
                ...objDT,
                [col.strGstcolName]: col.strdefaultvalue,
              };
          } else {
            if (col.strdatatype === "decimal")
              objDT = {
                ...objDT,
                [col.strGstcolName]: Number(Entdt[col.strConditionCol]),
              };
            else {
              if (
                (Entdt[col.strConditionCol] &&
                  Entdt[col.strConditionCol].length > 0) ||
                col.strGstcolName === "Pin"
              ) {
                objDT = {
                  ...objDT,
                  [col.strGstcolName]: Entdt[col.strConditionCol],
                };
              }
            }
          }
        });
        objInvoice = { ...objInvoice, [area.strAreaCol]: objDT };
      } else {
        let arrItem = [];
        Entdt.forEach((dt) => {
          let objDT = {};
          AreaCol.forEach((col) => {
            if (col.strConditionCol === "") {
              if (col.strdatatype === "decimal") {
                switch (col.strGstcolName) {
                  case "igstAmt":
                    if (TaxRelId === 2) {
                      objDT = {
                        ...objDT,
                        [col.strGstcolName]: Number(dt.taxAmt),
                      };
                    }
                    break;
                  case "CgstAmt":
                  case "SgstAmt":
                    if (TaxRelId === 1) {
                      objDT = {
                        ...objDT,
                        [col.strGstcolName]: Number(dt.taxAmt / 2),
                      };
                    }
                    break;
                  default:
                    objDT = {
                      ...objDT,
                      [col.strGstcolName]: Number(col.strdefaultvalue),
                    };
                    break;
                }
              } else
                objDT = {
                  ...objDT,
                  [col.strGstcolName]: col.strdefaultvalue,
                };
            } else {
              if (col.strdatatype === "decimal")
                objDT = {
                  ...objDT,
                  [col.strGstcolName]: Number(dt[col.strConditionCol]),
                };
              else if (col.strGstcolName === "IsServc")
                objDT = {
                  ...objDT,
                  [col.strGstcolName]: "N",
                };
              else if (col.strGstcolName === "SlNo")
                objDT = {
                  ...objDT,
                  [col.strGstcolName]: dt[col.strConditionCol].toString(),
                };
              else
                objDT = {
                  ...objDT,
                  [col.strGstcolName]: dt[col.strConditionCol],
                };
            }
          });
          arrItem.push(objDT);
        });
        setListEntItem(arrItem);
        objInvoice = { ...objInvoice, ItemList: arrItem };
      }
    });
    await View_Distance(objInvoice);
    setarrEntArea(EntArea);
    setarrEntCol(EntCol);
  };
  const View_Distance = async (objInvoice) => {
    try {
      let pincode1 = objInvoice["SellerDtls"]["Pin"];
      let pincode2 = objInvoice["ShipDtls"]["Pin"];
      if (!pincode1 || !pincode2) {
        objInvoice["EwbDtls"]["Distance"] = 0;
      } else {
        // Fetch coordinates for pincode1
        const coord1 = await fetchCoordinates(pincode1);
        if (!coord1) return;

        // Fetch coordinates for pincode2
        const coord2 = await fetchCoordinates(pincode2);
        if (!coord2) return;
        // Calculate distance using Haversine formula
        const R = 6371e3; // Earth's radius in meters
        const φ1 = (coord1.lat * Math.PI) / 180;
        const φ2 = (coord2.lat * Math.PI) / 180;
        const Δφ = ((coord2.lat - coord1.lat) * Math.PI) / 180;
        const Δλ = ((coord2.lon - coord1.lon) * Math.PI) / 180;

        const a =
          Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // Distance in meters const d = R * c;

        objInvoice["EwbDtls"]["Distance"] = Number((d / 1000).toFixed(2));
      }
      setEntJsonInv([objInvoice]);
    } catch (error) {
      console.error("Error fetching distance:", error);
      AlertMessage(error.message, "error");
    }
  };
  const fetchCoordinates = async (pincode) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${pincode}&format=json`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        const { lat, lon } = data[0];
        return { lat: lat, lon: lon };
      } else {
        AlertMessage("Invalid Pincode", "error");
      }
    } catch (error) {
      AlertMessage(error.message, "error");
      console.error("Error fetching distance:", error);
    }
  };
  const Input_Onchange = (val, element, area) => {
    let arrJson = EntJsonInv;
    arrJson = arrJson.map((json) => {
      switch (element.strdatatype) {
        case "decimal":
          json[area.strAreaCol][element.strGstcolName] = Number(val);
          break;
        default:
          json[area.strAreaCol][element.strGstcolName] = val;
          break;
      }
      return json;
    });
    setEntJsonInv(arrJson);
  };
  const Create_EInvoice = () => {
    let EntData = EntJsonInv;
    EntData[0].DocDtls.Dt = EntData[0].DocDtls.Dt.split("-")
      .reverse()
      .join("/");
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(EntData));
    var link = document.createElement("a");
    link.setAttribute("href", dataStr);
    link.setAttribute("download", "E-Invoice.json");
    link.click();
  };
  const Clear_Invoice = () => {
    Load_EInvoice(_Common.iScrId, _HT.SelectedVouchId);
  };
  const Area_Onchange = async (AreaId) => {
    try {
      setiAreaId(AreaId);
      await dispatch(setload_Screen(true));
      await userApp.get(
        `GST/Area_Onchange/${Number(AreaId)}/${Number(iScrId)}/${
          localStorage.db
        }`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrEntCol(Res.data.EntCol);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_GSTColumns = (col) => {
    setstrColName(col.strGstcolName);
    setstrAliasName(col.strAliasName);
    setstrDatatype(col.strdatatype);
    setstrTransName(col.strConditionCol);
    setstrdefaultval(col.strdefaultvalue);
    setstrval(col.strValue);
    setiAreaId(col.iAreaId);
    setiColId(col.iGstcolId);
    setisShow(col.iShwinScr === 1 ? true : false);
  };
  const Clear_Colums = () => {
    setstrColName("");
    setstrAliasName("");
    setstrDatatype("string");
    setstrTransName("");
    setstrdefaultval("");
    setstrval("");
    setiAreaId(0);
    setiColId(0);
    setisShow(true);
  };
  const Updat_GSTColumn = async () => {
    try {
      const InsUpdCol = {
        strCompanyDB: localStorage.db,
        EntArea: [],
        EntCol: [
          {
            iGstcolId: iColId,
            strGstcolName: strColName,
            strAliasName: strAliasName,
            strdatatype: strDatatype,
            strConditionCol: strTransName,
            strdefaultvalue: strdefaultval,
            strValue: strval,
            iShwinScr: isShow ? 1 : 0,
            iAreaId: iAreaId,
            iScrId: iScrId,
          },
        ],
      };
      await dispatch(setload_Screen(true));
      await userApp.post(`GST/InsUpd_GSTColumns`, InsUpdCol).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            Area_Onchange(iAreaId);
            Clear_Colums();
          } else AlertMessage(Res.data.strMessage, "error");
        }
      );
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const TransMode = [
    { val: "1", Name: "Road" },
    { val: "2", Name: "Rail" },
    { val: "3", Name: "Air" },
    { val: "4", Name: "Ship" },
  ];
  const VechicleType = [
    { val: "R", Name: "Regular" },
    { val: "O", Name: "Rail" },
  ];
  const Option_list = (col, EntDT) => {
    switch (col) {
      case "VehType":
        EntDT = VechicleType;
        break;
      case "TransMode":
        EntDT = TransMode;
        break;
      default:
        break;
    }
    return EntDT;
  };
  const Option_Value = (Val, col) => {
    switch (col) {
      case "VehType":
      case "TransMode":
        Val = Val.val;
        break;
      default:
        break;
    }
    return Val;
  };
  const Option_display = (Val, col) => {
    switch (col) {
      case "VehType":
      case "TransMode":
        Val = Val.Name;
        break;
      default:
        break;
    }
    return Val;
  };
  const display_Value = (Val, col) => {
    switch (col) {
      case "VehType":
        Val = VechicleType.filter((dt) => dt.val === Val)[0].Name;
        break;
      case "TransMode":
        Val = TransMode.filter((dt) => dt.val === Val)[0].Name;
        break;
      default:
        break;
    }
    return Val;
  };
  const Hide_Option = (val, element) => {
    let arrcol = arrEntCol;
    arrcol = arrcol.map((col) => {
      if (col.iGstcolId === element.iGstcolId) {
        col.isShowOption = val;
      }
      return col;
    });
    setarrEntCol(arrcol);
  };
  const CreateHTMLElemet = (element, area, Index) => {
    if (element.iAreaId === area.iAreaId && area.iAreaId !== 9) {
      let json = EntJsonInv[0];
      let val = json ? json[area.strAreaCol][element.strGstcolName] : "";

      switch (element.strdatatype) {
        case "string":
          return (
            <div className="col-sm-6 col-md-3" key={Index}>
              <label>{element.strAliasName}</label>
              <input
                type="text"
                className="form-control"
                value={val}
                onChange={(e) => Input_Onchange(e.target.value, element, area)}
              />
            </div>
          );
        case "cmb":
          return (
            <div className="col-sm-6 col-md-3" key={Index}>
              <label>{element.strAliasName}</label>
              <div
                className="custom-select"
                onMouseLeave={(e) => Hide_Option(false, element)}
              >
                <input
                  type="text"
                  className="form-control"
                  disabled={false}
                  placeholder="Select Value"
                  value={display_Value(val, element.strGstcolName)}
                  onClick={(e) => Hide_Option(true, element)}
                />
                <i className="fa-solid fa-caret-down"></i>
                {element.isShowOption && (
                  <div className="custom-option">
                    <div className="option-list">
                      {Option_list(
                        element.strGstcolName,
                        element.arrOption
                      ).map((item, Index) => (
                        <button
                          key={Index}
                          tabIndex={Index}
                          onClick={(e) => {
                            Input_Onchange(
                              Option_Value(item, element.strGstcolName),
                              element,
                              area
                            );
                            Hide_Option(false, element);
                          }}
                          onKeyDown={(e) => {
                            let arrlength = element.arrOption.length - 1;
                            if (e.key === "ArrowDown") {
                              if (arrlength === Index)
                                $(`.option-list [tabindex=0]`).focus();
                              else
                                $(
                                  `.option-list [tabindex=${Index + 1}]`
                                ).focus();
                            }
                            if (e.key === "ArrowUp") {
                              if (Index === 0)
                                $(
                                  `.option-list [tabindex=${arrlength}]`
                                ).focus();
                              else
                                $(
                                  `.option-list [tabindex=${Index - 1}]`
                                ).focus();
                            }
                            if (e.key === "Enter") {
                              Input_Onchange(
                                Option_Value(item, element.strGstcolName),
                                element,
                                area
                              );
                              Hide_Option(false, element);
                            }
                          }}
                        >
                          {Option_display(item, element.strGstcolName)}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        case "decimal":
          return (
            <div className="col-sm-6 col-md-3" key={Index}>
              <label>{element.strAliasName}</label>
              <input
                type="number"
                className="form-control"
                value={val}
                onChange={(e) => Input_Onchange(e.target.value, element, area)}
              />
            </div>
          );
        case "date":
          return (
            <div className="col-sm-6 col-md-3" key={Index}>
              <label>{element.strAliasName}</label>
              <input
                type="date"
                className="form-control"
                value={val}
                onChange={(e) => Input_Onchange(e.target.value, element, area)}
              />
            </div>
          );
        default:
          break;
      }
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ItemColumn = [
    {
      Header: "ProductName",
      accessor: "PrdDesc",
      minWidth: 200,
    },
    {
      Header: "HSN Code",
      accessor: "HsnCd",
      minWidth: 100,
    },
    {
      Header: "Qty",
      accessor: "Qty",
      style: { justifyContent: "right" },
      minWidth: 100,
    },
    {
      Header: "Unit",
      accessor: "Unit",
      style: { justifyContent: "center" },
      minWidth: 100,
    },
    {
      Header: "Rate",
      accessor: "UnitPrice:",
      minWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "SGST Amount",
      accessor: "SgstAmt",
      minWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "CGST Amount",
      accessor: "CgstAmt",
      minWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "IGST Amount",
      accessor: "IgstAmt",
      minWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Tax Per",
      accessor: "GstRt",
      minWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Taxable Amount",
      accessor: "taxableAmt",
      minWidth: 100,
      style: { justifyContent: "right" },
    },
    {
      Header: "Item Amount",
      accessor: "TotItemVal",
      minWidth: 100,
      style: { justifyContent: "right" },
    },
  ];
  const GstColumn = [
    {
      Header: "GST Column",
      accessor: "strGstcolName",
    },
    {
      Header: "Alias Name",
      accessor: "strAliasName",
      minWidth: 200,
    },
    {
      Header: "Data Type",
      accessor: "strdatatype",
      minWidth: 100,
    },
    {
      Header: "Trans Name",
      accessor: "strConditionCol",
      minWidth: 100,
    },
    {
      Header: "Defult Value",
      accessor: "strdefaultvalue",
      minWidth: 200,
    },
    {
      Header: "Value",
      accessor: "strValue",
      minWidth: 100,
      style: {
        justifyContent: "center",
      },
    },
    {
      Header: "Show",
      accessor: "iShwinScr",
      minWidth: 70,
      style: {
        justifyContent: "center",
      },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          className="form-check"
          checked={rowinfo.iShwinScr}
          readOnly
        />
      ),
    },
  ];
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>E-Invoice</h5>
          <div className="icon-popup-btn-grp">
            {!isSetting && (
              <span
                className="icon-btn"
                onClick={(e) => {
                  setisSetting(!isSetting);
                  Area_Onchange(1);
                }}
              >
                <i className="fa-solid fa-gear"></i>
              </span>
            )}
            <span
              className="icon-btn"
              onClick={() => dispatch(ShowHide_Popup("IsEInvoice"))}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          {!isSetting && (
            <div className="row">
              {arrEntArea.map((area, Index) => (
                <div className="row" key={Index}>
                  <h6 className="gstcol-h">{area.strAliasName}</h6>
                  {arrEntCol.map((col, Index) => {
                    return CreateHTMLElemet(col, area, Index);
                  })}
                </div>
              ))}
              <div className="TransactionTable">
                <ReactTable
                  sortable={true}
                  data={ListEntItem}
                  columns={ItemColumn}
                  minRows={5}
                  defaultPageSize={500}
                  showPaginationBottom={false}
                />
              </div>
            </div>
          )}
          {isSetting && (
            <div className="row p-2">
              <div className="col-sm-12 col-md-3">
                <label>GST Column Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow10"
                  showoption={_selecrparem.isoptionShow10}
                  placeholder="Select Column Type"
                  select_value={(val) => Area_Onchange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iAreaId}
                  displayName="strAreaCol"
                  disvalue="iAreaId"
                  arrydata={arrEntArea}
                  EmptVal="Column Type"
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>GST Column Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="GST Column"
                  value={strColName}
                  onChange={(e) => setstrColName(e.target.value)}
                  spellCheck={false}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Alias Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Alias Name"
                  value={strAliasName}
                  onChange={(e) => setstrAliasName(e.target.value)}
                  spellCheck={false}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Trans Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Trans Name"
                  value={strTransName}
                  onChange={(e) => setstrTransName(e.target.value)}
                  spellCheck={false}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Default Value</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Default Value"
                  value={strdefaultval}
                  onChange={(e) => setstrdefaultval(e.target.value)}
                  spellCheck={false}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Value</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Value"
                  value={strval}
                  onChange={(e) => setstrval(e.target.value)}
                  spellCheck={false}
                  autoComplete="off"
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Show Screen</label>
                <input
                  type="checkbox"
                  className="form-check"
                  checked={isShow}
                  onChange={(e) => setisShow(!isShow)}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Data Type</label>
                <div className="row">
                  <div className="col-sm-4 col-md-4">
                    <label>String</label>
                    <input
                      type="checkbox"
                      className="form-check"
                      checked={strDatatype === "string" ? true : false}
                      onChange={(e) => setstrDatatype("string")}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <label>date</label>
                    <input
                      type="checkbox"
                      className="form-check"
                      checked={strDatatype === "date" ? true : false}
                      onChange={(e) => setstrDatatype("date")}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <label>decimal</label>
                    <input
                      type="checkbox"
                      className="form-check"
                      checked={strDatatype === "decimal" ? true : false}
                      onChange={(e) => setstrDatatype("decimal")}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <label>Options</label>
                    <input
                      type="checkbox"
                      className="form-check"
                      checked={strDatatype === "cmb" ? true : false}
                      onChange={(e) => setstrDatatype("cmb")}
                    />
                  </div>
                </div>
              </div>
              <ReactTable
                columns={GstColumn}
                data={arrEntCol}
                minRows={5}
                row_click={(rowInfo, Index) => {}}
                row_doubleclick={(rowInfo, Index) => {
                  View_GSTColumns(rowInfo);
                }}
                background={true}
                className="full-table"
              />
            </div>
          )}
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {!isSetting && (
              <button
                onClick={(e) => Create_EInvoice()}
                className="btn-fabgreen"
              >
                Generate E-Invoice <i className="fa-solid fa-circle-plus"></i>
              </button>
            )}
            {!isSetting && (
              <button onClick={(e) => Clear_Invoice()} className="btn-fabgreen">
                Clear&nbsp; <i className="fas fa-eraser"></i>
              </button>
            )}
            {isSetting && (
              <button
                onClick={(e) => Updat_GSTColumn()}
                className="btn-fabgreen"
              >
                Update&nbsp;<i className="fas fa-save"></i>
              </button>
            )}
            {isSetting && (
              <button
                onClick={(e) => Updat_GSTColumn()}
                className="btn-fabgreen"
              >
                Delete&nbsp;<i className="far fa-trash-alt"></i>
              </button>
            )}
            {isSetting && (
              <button onClick={(e) => Clear_Colums()} className="btn-fabgreen">
                Clear&nbsp; <i className="fas fa-eraser"></i>
              </button>
            )}
            {isSetting && (
              <button
                onClick={(e) => setisSetting(!isSetting)}
                className="btn-fabgreen"
              >
                <i className="bx bx-arrow-back"></i>&nbsp;Back to E-Invoice
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EInvoice;
