import React, { useEffect, useState } from "react";
import $ from "jquery";
import Excel from "../img/excel.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Show_MsgBox } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import Messagedialogbox from "../General/Messagedialogbox";
import ReportSetting from "./ReportSetting";
import ReportFilter from "./ReportFilter";
import { adminApp, userApp } from "../Admin/Utility";

function ReportTable(props) {
  const dispatch = useDispatch();
  const [strRptSearch, setstrRptSearch] = useState("");
  const [strColSearch, setstrColSearch] = useState("");
  const [strCustSearch, setstrCustSearch] = useState("");
  const [strtableSearch, setstrtableSearch] = useState("");
  const [IsSetting, setIsSetting] = useState(false);
  const [IsFilter, setIsFilter] = useState(false);
  const [IsColOption, setIsColOption] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [RptColumn, setRptColumn] = useState([]);
  const [strLedSearch, setstrLedSearch] = useState("");

  useEffect(() => {}, [props]);

  const Save_SearchColumn = async (ColValue) => {
    try {
      props.setstrFilterColName(ColValue);
      setIsColOption(!IsColOption);
      await userApp
        .post(
          "Reports/Insert_SearchCol/" +
            props.iRptId +
            "/" +
            ColValue +
            "/" +
            localStorage.db
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Search_FilterCol = async (val) => {
    setstrColSearch(val);
    const EntOption = await props.ReportDesing.filter((dt) =>
      dt.strRptColName.toLowerCase().includes(val.toLowerCase())
    );
    setRptColumn(EntOption);
  };
  const Search_Ledger = (val) => {
    const EntOption = props.EntLedger.filter((dt) =>
      dt.strName.toLowerCase().includes(val.toLowerCase())
    );
    props.setarrLedgerOption(EntOption);
    setstrLedSearch(val);
  };
  const View_Ledger = async (strLedName) => {
    props.setIsLedOption(false);
    await props.setstrLedger(strLedName);
    await props.View_Report(
      props.iRptId,
      props.strReportName,
      strLedName,
      props.strFromDate,
      props.strToDate
    );
  };
  const Date_Onchange = (Data, DateFor) => {
    switch (DateFor) {
      case "FromDate":
        props.setstrFromDate(Data);
        props.View_Report(props.iRptId, props.strLedger, Data, props.strToDate);
        break;
      case "ToDate":
        props.setstrToDate(Data);
        props.View_Report(
          props.iRptId,
          props.strLedger,
          props.strFromDate,
          Data
        );
        break;
      default:
        break;
    }
  };
  const isDate = (RptId) => {
    let ishideDate = true;
    switch (Number(RptId)) {
      case 1015:
      case 1016:
      case 1017:
      case 1018:
      case 133:
        ishideDate = false;
        break;
      default:
        ishideDate = true;
        break;
    }
    return ishideDate;
  };
  const converttoColor = (Value, type) => {
    return type === "decimal" ? (Number(Value) < 0 ? "red" : "black") : "black";
  };
  const converttodecimal = (Value, type) => {
    return type === "decimal" ? Number(Number(Value).toFixed(2)) : Value;
  };
  const DownLoad_Excel = async () => {
    if (Number(props.iRptId) === 0) {
      AlertMessage("Please Select Report", "info");
    } else {
      var ExportDiv = document.createElement("div");
      ExportDiv.setAttribute("class", "ExportExcel");
      await adminApp
        .get(
          `CompanyMaster/View_Company/${Number(localStorage.ComId)}/${
            localStorage.Msdb
          }`
        )
        .then((res) => {
          //#region Header
          var Company = document.createElement("p");
          const compInfo = res.data.CompanyInfo[0];
          Company.innerHTML = compInfo.strCompName.toUpperCase();
          var Address1 = document.createElement("p");
          Address1.innerHTML = compInfo.strAdd1 + "," + compInfo.strAdd2;
          var Address2 = document.createElement("p");
          Address2.innerHTML = compInfo.strAdd3 + "," + compInfo.strAdd4;
          var Para = document.createElement("p");
          Para.innerHTML = props.strReportName.toUpperCase();
          //#endregion

          var table = document.createElement("table");
          table.innerHTML = document.getElementById("Reporttable").innerHTML;

          //#region  Table
          var ThElemt = table.getElementsByTagName("th");
          for (var H = 0; H < ThElemt.length; H++) {
            ThElemt[H].style.color = "#1b5e20";
            ThElemt[H].style.textAlign = "center";
            ThElemt[H].style.fontSize = "13px";
            ThElemt[H].style.fontWeight = "bold";
            ThElemt[H].style.border = "1px solid black";
            ThElemt[H].style.fontFamily = "Times New Roman";
          }
          var TdElemt = table.getElementsByTagName("td");
          for (var B = 0; B < TdElemt.length; B++) {
            TdElemt[B].style.border = "1px solid black";
            TdElemt[B].style.fontSize = "13px";
            TdElemt[B].style.background = "#fff";
            TdElemt[B].style.fontFamily = "Times New Roman";
          }
          var TFElemt = table.querySelectorAll("tfoot td");
          for (var F = 0; F < TFElemt.length; F++) {
            TFElemt[F].style.color = "#1b5e20";
            TFElemt[F].style.fontSize = "13px";
            TFElemt[F].style.fontFamily = "Times New Roman";
            TFElemt[F].style.fontWeight = "bold";
            TFElemt[F].style.textAlign = "right";
          }
          //#endregion
          //#region  Style
          Company.setAttribute(
            "style",
            "color:darkgreen:font-weight:bold;" +
              "mso-number-format:@; text-align: center"
          );
          Address1.setAttribute(
            "style",
            "color:darkgreen:font-weight:bold;" +
              "mso-number-format:@; text-align: center"
          );
          Address2.setAttribute(
            "style",
            "color:darkgreen:font-weight:bold;" +
              "mso-number-format:@; text-align: center"
          );
          Para.setAttribute(
            "style",
            "color:darkgreen:font-weight:bold;" +
              "mso-number-format:@; text-align: center"
          );
          //#endregion

          //#region ExcelllAssign
          ExportDiv.appendChild(Company);
          ExportDiv.appendChild(Address1);
          ExportDiv.appendChild(Address2);
          ExportDiv.appendChild(Para);
          ExportDiv.appendChild(table);

          //#endregion

          //#region  ExcelDownload
          let file = new Blob([$(ExportDiv).html()], {
            type: "application/vnd.ms-excel",
          });
          let url = URL.createObjectURL(file);
          var Link = document.createElement("a");
          Link.href = url;
          Link.download = props.strReportName + ".xls";
          Link.click();
          //#endregion
        });
    }
  };
  const DownLoad_Pdf = async () => {
    var doc = new jsPDF("p", "pt");
    const HtmlElement = document.getElementById("Reporttable");
    await adminApp
      .get(
        `CompanyMaster/View_Company/${Number(localStorage.ComId)}/${
          localStorage.Msdb
        }`
      )
      .then((res) => {
        const compInfo = res.data.CompanyInfo[0];
        var Company =  compInfo.strCompName.toUpperCase();
        var Address1 = compInfo.strAdd1 + "," + compInfo.strAdd2;
        var Address2 =   compInfo.strAdd3 + "," + compInfo.strAdd4;

        const RptName = props.strReportName.toUpperCase();
        const style = {
          align: "center",
          baseline: "middle",
        };
        const currdate =
          "Date : " +
          new Date().toISOString().split("T")[0].split("-").reverse().join("-");
        const currtime = "Time : " + new Date().toLocaleTimeString();
        doc.setTextColor("black");
        doc.setFontSize(15);
        doc.setFont("Poppins, sans-serif", "normal", "bold");
        doc.text(Company, 270, 20, style);
        doc.setFontSize(10);
        doc.setFont("Poppins, sans-serif", "normal", "normal");
        doc.text(Address1, 270, 40, style);
        doc.text(Address2, 270, 60, style);
        doc.setTextColor("darkgreen");
        doc.setFont("Poppins, sans-serif", "normal", "bold");
        doc.text(RptName, 270, 80, style);
        doc.setTextColor("black");
        doc.setFont("Poppins, sans-serif", "normal", "normal");
        doc.text(currdate, 510, 60, style);
        doc.text(currtime, 510, 80, style);
        autoTable(doc, {
          margin: { top: 10, left: 10, bottom: 10 },
          startY: 100,
          theme: "grid",
          showFoot: "everyPage",
          showHead: "everyPage",
          rowPageBreak: "auto",
          tableWidth: "auto",
          pageBreak: "auto",
          horizontalPageBreak: true,
          horizontalPageBreakRepeat: 0,
          horizontalPageBreakBehaviour: "afterAllRows",
          headStyles: {
            textColor: "darkgreen",
            fontStyle: "bold",
            cellWidth: "auto",
            halign: "center",
          },
          bodyStyles: {
            textColor: "black",
            fontStyle: "normal",
            cellWidth: "wrap",
            overflow: "linebreak",
          },
          footStyles: {
            textColor: "black",
            fontStyle: "bold",
            cellWidth: "auto",
            halign: "center",
          },
          styles: {
            lineColor: "black",
            lineWidth: 0.1,
            fontSize: 8,
            fillColor: "white",
            minCellHeight: 15,
          },
          html: HtmlElement,
        });
        doc.save(RptName + ".pdf");
      });
  };
  const DownLoad_Json = () => {
    let EntData = [];
    Sorted_Report.forEach((report) => {
      let ObjRow = {};
      props.ReportDesing.forEach((design) => {
        ObjRow[design.strRptColName] = converttodecimal(
          report[design.strRptColName],
          design.strDatatype
        );
      });
      EntData.push(ObjRow);
    });
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(EntData));
    var link = document.createElement("a");
    link.setAttribute("href", dataStr);
    link.setAttribute("download", `${props.strReportName}.json`);
    link.click();
  };
  const textAlign = ["left", "center", "right"];
  const fontWeight = ["normal", "500", "bold"];

  const Sort_Report = (key) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };
  const Sorted_Report = sortBy
    ? props.ReportData.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      })
    : props.ReportData;
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  const Hide_Popup = async () => {
    await setIsColOption(false);
    if (props.IsAccounts) await props.setIsLedOption(false);
    else await props.Party_OptionShow(false);
  };
  const Table_Style = (design, report) => {
    const Style = {
      textAlign: textAlign[design.iFontAlign - 1],
      fontWeight: fontWeight[design.iFontStyle - 1],
      whiteSpace: "wrap",
      color: converttoColor(report[design.strRptColName], design.strDatatype),
    };

    if (design.iRptColLen.includes("%") || design.iRptColLen.includes("auto")) {
      Style.width = design.iRptColLen;
      Style.minWidth = "100px";
    } else {
      Style.width = design.iRptColLen + "px";
      Style.minWidth = design.iRptColLen + "px";
      Style.maxWidth = design.iRptColLen + "px";
    }
    return Style;
  };
  return (
    <div className="Master-container">
      <div className="Master-card">
        <div className="m-header ">
          <div className="m-hsearch">
            <input
              type="search"
              placeholder="Search Reports"
              value={strRptSearch}
              onChange={(e) => {
                setstrRptSearch(e.target.value);
                props.Load_Reports(e.target.value);
              }}
              autoFocus
            />
            <i
              className="fas fa-search"
              onClick={(e) => props.Load_Reports(strRptSearch)}
            ></i>
          </div>
        </div>
        <div className="data-list rpt">
          {props.EntReports.map((report, Index) => (
            <button
              key={Index}
              tabIndex={Index}
              onKeyDown={async (e) => {
                const arrlength = props.EntReports.length - 1;
                if (e.key === "ArrowDown") {
                  if (Index === arrlength)
                    $(`.data-list [tabindex=${Index + 1}]`).focus();
                  else $(`.data-list [tabindex=${0}]`).focus();
                }
                if (e.key === "ArrowUp") {
                  if (Index === 0)
                    $(`.data-list [tabindex=${arrlength}]`).focus();
                  else $(`.data-list [tabindex=${Index - 1}]`).focus();
                }
                if (e.key === "Enter") {
                  await setstrColSearch("");
                  await setstrCustSearch("");
                  if (!props.IsAccounts) await props.Party_OptionShow(false);
                  if (props.IsAccounts) await props.Clear_Report();
                  await props.View_Report(
                    report.iId,
                    report.strName,
                    props.strLedger,
                    props.strFromDate,
                    props.strToDate
                  );
                  if (Number(report.iId) === 1015 && props.IsAccounts)
                    await props.Load_Ledger();
                }
              }}
            >
              <label
                onClick={async (e) => {
                  await setstrColSearch("");
                  await setstrCustSearch("");
                  if (!props.IsAccounts) await props.Party_OptionShow(false);
                  if (props.IsAccounts) await props.Clear_Report();
                  await props.View_Report(
                    report.iId,
                    report.strName,
                    props.strLedger,
                    props.strFromDate,
                    props.strToDate
                  );
                  if (Number(report.iId) === 1015 && props.IsAccounts)
                    await props.Load_Ledger();
                }}
              >
                {report.strName}
              </label>
            </button>
          ))}
        </div>
      </div>
      <div className="Master-card">
        <div className="Master-wrapper report-wrapper">
          <div className="row report-header">
            <div className="rport-title mt-1">
              <strong>{props.strReportName.toLocaleUpperCase()}</strong>
              {Number(props.iRptId) > 0 && (
                <div className="icon-btn-grp">
                  <div className="lbltooltip" onClick={(e) => DownLoad_Excel()}>
                    <a className="icon-btn " href="##">
                      <img src={Excel} alt="Excel Icon" />
                    </a>
                    <span className="tooltiptext">Excel</span>
                  </div>
                  <div className="lbltooltip" onClick={(e) => DownLoad_Pdf()}>
                    <a className="icon-btn " href="##">
                      <i className="far fa-file-pdf"></i>
                    </a>
                    <span className="tooltiptext">Pdf</span>
                  </div>
                  <div className="lbltooltip" onClick={(e) => DownLoad_Json()}>
                    <a className="icon-btn" href="##">
                      <i className="bx bxs-file-json"></i>
                    </a>
                    <span className="tooltiptext">Json Format</span>
                  </div>
                  <div
                    className="lbltooltip"
                    onClick={(e) => {
                      setIsFilter(!IsFilter);
                      Hide_Popup();
                    }}
                  >
                    <a className="icon-btn" href="##">
                      <i className="fa-solid fa-arrow-down-short-wide"></i>
                    </a>
                    <span className="tooltiptext">Filter</span>
                  </div>
                  <div
                    className="lbltooltip"
                    onClick={(e) => {
                      setIsSetting(!IsSetting);
                      Hide_Popup();
                    }}
                  >
                    <a className="icon-btn" href="##">
                      <i className="fa-solid fa-sliders"></i>
                    </a>
                    <span className="tooltiptext">Setting</span>
                  </div>
                  <div className="lbltooltip">
                    <a className="icon-btn " href="##">
                      <i
                        className="fa-regular fa-rectangle-xmark"
                        onClick={(e) => (window.location.href = "/Dashboard")}
                      ></i>
                    </a>
                    <span className="tooltiptext">Close</span>
                  </div>
                </div>
              )}
            </div>
            {!props.IsAccounts && (
              <div className="col-sm-6 col-md-6 mt-2 pl-0">
                <div
                  className="custom-select"
                  onMouseLeave={(e) => props.Party_OptionShow(false)}
                >
                  <input
                    className="form-control"
                    disabled={!props.IsParty}
                    type="text"
                    placeholder="Select  Party Name"
                    defaultValue={props.PartyName}
                    onClick={(e) => {
                      props.Party_OptionShow(!props.IsPartyOption);
                      props.Search_Party("");
                    }}
                  />
                  <i className="fa-solid fa-caret-down"></i>
                  {props.IsPartyOption && (
                    <div className="custom-option">
                      <div className="search-option">
                        <input
                          className="form-control"
                          type="text"
                          autoFocus
                          placeholder="Search Party"
                          value={strCustSearch}
                          onChange={(e) => {
                            setstrCustSearch(e.target.value);
                            props.Search_Party(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "ArrowDown") {
                              $(".optionbtn [tabindex=0]").focus();
                            } else if (e.key === "Enter") {
                              setstrCustSearch("");
                              props.View_VendoreOutstanding(props.EntParty[0]);
                            }
                          }}
                        />
                        {props.EntParty.length === 0 && (
                          <span> Party Not Found</span>
                        )}
                      </div>
                      <div className="option-list">
                        {props.EntParty.map((Party, Index) => (
                          <button
                            key={Index}
                            tabIndex={Index}
                            onClick={(e) => {
                              setstrCustSearch("");
                              props.View_VendoreOutstanding(Party);
                            }}
                            onKeyDown={(e) => {
                              let arrlength = props.EntParty.length - 1;
                              if (e.key === "ArrowDown") {
                                if (arrlength === Index)
                                  $(`.option-list [tabindex=0]`).focus();
                                else
                                  $(
                                    `.option-list [tabindex=${Index + 1}]`
                                  ).focus();
                              }
                              if (e.key === "ArrowUp") {
                                if (Index === 0)
                                  $(
                                    `.option-list [tabindex=${arrlength}]`
                                  ).focus();
                                else
                                  $(
                                    `.option-list [tabindex=${Index - 1}]`
                                  ).focus();
                              }
                              if (e.key === "Enter") {
                                setstrCustSearch("");
                                props.View_VendoreOutstanding(Party);
                              }
                            }}
                            className="con-btn"
                          >
                            {Party.partyName}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {props.IsAccounts && (
              <div className="col-sm-6 col-md-3 mt-2 pl-0">
                <div
                  className="custom-select"
                  onMouseLeave={(e) => props.setIsLedOption(false)}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Select Ledger"
                    defaultValue={props.strLedger}
                    onClick={(e) => {
                      props.setIsLedOption(true);
                      Search_Ledger("");
                    }}
                    disabled={props.iRptId === 1015 ? false : true}
                  />
                  <i className="fa-solid fa-caret-down"></i>
                  {props.IsLedOption && (
                    <div className="custom-option">
                      <div className="search-option">
                        <input
                          className="form-control"
                          type="text"
                          autoFocus
                          placeholder="Search Filter Column"
                          value={strLedSearch}
                          onChange={(e) => Search_Ledger(e.target.value)}
                        />
                        {props.EntLedgerOption.length === 0 && (
                          <span>Ledger Not Found</span>
                        )}
                      </div>
                      <div className="option-list">
                        {props.EntLedgerOption.map((ledger, Index) => (
                          <button
                            key={Index}
                            tabIndex={Index}
                            onClick={(e) => View_Ledger(ledger.strName)}
                            onKeyDown={(e) => {
                              let arrlength = props.EntLedgerOption.length - 1;
                              if (e.key === "ArrowDown") {
                                if (arrlength === Index)
                                  $(`.option-list [tabindex=0]`).focus();
                                else
                                  $(
                                    `.option-list [tabindex=${Index + 1}]`
                                  ).focus();
                              }
                              if (e.key === "ArrowUp") {
                                if (Index === 0)
                                  $(
                                    `.option-list [tabindex=${arrlength}]`
                                  ).focus();
                                else
                                  $(
                                    `.option-list [tabindex=${Index - 1}]`
                                  ).focus();
                              }
                              if (e.key === "Enter")
                                View_Ledger(ledger.strName);
                            }}
                            className="con-btn"
                          >
                            {ledger.strName}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {props.IsAccounts && (
              <div className="col-sm-6 col-md-3 mt-2 pl-0">
                <input
                  className="form-control rounded"
                  type="date"
                  value={props.strFromDate}
                  onChange={(e) => Date_Onchange(e.target.value, "FromDate")}
                  disabled={isDate(Number(props.iRptId))}
                />
              </div>
            )}
            {props.IsAccounts && (
              <div className="col-sm-6 col-md-3 mt-2 pl-0">
                <input
                  className="form-control rounded"
                  type="date"
                  value={props.strToDate}
                  onChange={(e) => Date_Onchange(e.target.value, "ToDate")}
                  disabled={isDate(Number(props.iRptId))}
                />
              </div>
            )}
            <div className="col-sm-6 col-md-3 mt-2 pl-0">
              <div
                className="custom-select"
                onMouseLeave={(e) => setIsColOption(false)}
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search Column"
                  defaultValue={props.FilterColName}
                  onClick={(e) => {
                    setIsColOption(!IsColOption);
                    Search_FilterCol("");
                  }}
                  disabled={props.iRptId === 0 ? true : false}
                />
                <i
                  className="fa-solid fa-caret-down"
                  style={{
                    position: "absolute",
                    right: "10px",
                    color: "darkgreen",
                    transform: IsColOption ? "rotate(-180deg)" : "rotate(0deg)",
                  }}
                ></i>
                {IsColOption && (
                  <div className="custom-option">
                    <div className="search-option">
                      <input
                        className="form-control"
                        type="text"
                        autoFocus
                        placeholder="Search Filter Column"
                        value={strColSearch}
                        onChange={(e) => Search_FilterCol(e.target.value)}
                      />
                      {RptColumn.length === 0 && <span> Column Not Found</span>}
                    </div>
                    <div className="option-list">
                      {RptColumn.map((col, Index) => (
                        <button
                          key={Index}
                          tabIndex={Index}
                          onClick={(e) => Save_SearchColumn(col.strRptColName)}
                          onKeyDown={(e) => {
                            let arrlength = RptColumn.length - 1;
                            if (e.key === "ArrowDown") {
                              if (arrlength === Index)
                                $(`.option-list [tabindex=0]`).focus();
                              else
                                $(
                                  `.option-list [tabindex=${Index + 1}]`
                                ).focus();
                            }
                            if (e.key === "ArrowUp") {
                              if (Index === 0)
                                $(
                                  `.option-list [tabindex=${arrlength}]`
                                ).focus();
                              else
                                $(
                                  `.option-list [tabindex=${Index - 1}]`
                                ).focus();
                            }
                            if (e.key === "Enter")
                              Save_SearchColumn(col.strRptColName);
                          }}
                          className="con-btn"
                        >
                          {col.strAliasName}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                props.IsAccounts
                  ? "col-sm-6 col-md-6 mt-2 pl-0"
                  : "col-sm-6 col-md-3 mt-2 pl-0"
              }
            >
              <div className="m-hsearch">
                <input
                  type="search"
                  placeholder="Search Reports"
                  value={strtableSearch}
                  onChange={(e) => {
                    setstrtableSearch(e.target.value);
                    props.Search_Report(e.target.value, props.FilterColName);
                  }}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) =>
                    props.Search_Report(strtableSearch, props.FilterColName)
                  }
                ></i>
              </div>
            </div>
          </div>
          <div className="table-card">
            <table className="option-list" id="Reporttable">
              <thead>
                <tr>
                  {props.ReportDesing.map((design, Index) => (
                    <th
                      key={Index}
                      onClick={(e) => {
                        Sort_Report(design.strRptColName);
                        $(`#Reporttable th:eq(${Index})`)
                          .toggleClass("active")
                          .siblings()
                          .removeClass("active");
                      }}
                    >
                      {design.strAliasName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Sorted_Report.map((report, trIndex) => (
                  <tr key={trIndex}>
                    {props.ReportDesing.map((design, tdindex) => (
                      <td key={tdindex} style={Table_Style(design, report)}>
                        {converttodecimal(
                          report[design.strRptColName],
                          design.strDatatype
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  {props.ReportDesing.map((design, Index) => (
                    <td key={Index}>
                      {props.EntSum.length > 0
                        ? props.EntSum[0][design.strAliasName] === undefined
                          ? ""
                          : Number(
                              props.EntSum[0][design.strAliasName]
                            ).toFixed(2)
                        : ""}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="rpt-section">
          <p>Total Records : {props.TotalRows}</p>
          {props.EntSum.length > 0 &&
            props.ReportDesing.map(
              (design, Index) =>
                props.EntSum[0][design.strAliasName] && (
                  <p key={Index}>
                    <span>
                      {design.strAliasName} : &nbsp;
                      {Number(props.EntSum[0][design.strAliasName]).toFixed(2)}
                    </span>
                  </p>
                )
            )}
        </div>

        {IsSetting && (
          <ReportSetting
            Close={(e) => {
              setIsSetting(!IsSetting);
              props.View_Report(
                props.iRptId,
                props.strReportName,
                props.strLedger,
                props.strFromDate,
                props.strToDate
              );
            }}
            iRptId={props.iRptId}
          />
        )}
        {IsFilter && (
          <ReportFilter
            Close={(e) => {
              setIsFilter(!IsFilter);
              props.View_Report(
                props.iRptId,
                props.strReportName,
                props.strLedger,
                props.strFromDate,
                props.strToDate
              );
            }}
            iRptId={props.iRptId}
          />
        )}
        <Messagedialogbox />
      </div>
    </div>
  );
}

export default ReportTable;
