import React, { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import $ from "jquery";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
function UnitMaster(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 64;
  const [iUnitId, setiUnitId] = useState(0);
  const [strUnitName, setstrUnitName] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [iLeastUnit, setiLeastUnit] = useState(1);
  const [iUQC, setiUQC] = useState(1);
  const [dConvertValue, setdConvertValue] = useState(1);
  const [bChkLeastUnit, setbChkLeastUnit] = useState(false);
  const [bChkNonLeastUnit, setbChkNonLeastUnit] = useState(true);
  const [strSearch, setstrSearch] = useState("");

  const [ListEntUnit, setListEntUnit] = useState([]);
  const [ListEntLeast, setListEntLeast] = useState([]);
  const [ListEntUqc, setListEntUqc] = useState([]);
  const [BtnSaveDisable, setBtnSaveDisable] = useState(false);
  const [BtnDeleteDisable, setBtnDeleteDisable] = useState(false);

  useEffect(() => {
    GetDefaultUnit();
  }, []);
  const GetDefaultUnit = (e) => {
    try {
      userApp.get( "UnitMaster/GetDefaultData/" + localStorage.db).then(
        (Res) => {
          setListEntUnit(Res.data.objEntUnit);
          setListEntLeast(Res.data.objLeastUnit);
          setListEntUqc(Res.data.objLoadUQC);
          dispatch(setload_Screen(false));
          ButtonPermission(JSON.parse(localStorage.Menu), iUnitId);
        }
      );
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = () => {
    try {
      dispatch(setload_Screen(true));
      switch (bChkLeastUnit) {
        case true:
          const InsUpdUnit = {
            iTitleTag: iScrId,
            iUnitId: iUnitId,
            strUnitName: strUnitName,
            strPrintName: strPrintName,
            iLeastUnit: Number(50),
            iUQC: Number(iUQC),
            dConvertValue: Number(1),
            bChkLeastUnit: bChkLeastUnit,
            strLeastUnitName: "",
            strCompanyDB: localStorage.db,
          };
          userApp.post( "UnitMaster/InsertUpd/", InsUpdUnit).then(
            (json) => {
              if (json.data.strStatus === "Success") {
                AlertMessage(json.data.strMessage, "success");
                ClearClick();
              } else {
                dispatch(setload_Screen(false));
                AlertMessage(json.data.strMessage, "error");
              }
            }
          );

          break;
        case false:
          const InsUpdfalseUnit = {
            iTitleTag: iScrId,
            iUnitId: iUnitId,
            strUnitName: strUnitName,
            strPrintName: strPrintName,
            iLeastUnit: Number(iLeastUnit),
            iUQC: Number(iUQC),
            dConvertValue: Number(dConvertValue),
            bChkLeastUnit: bChkLeastUnit,
            strLeastUnitName: "strLeastUnitName",
            strCompanyDB: localStorage.db,
          };
          userApp.post( "UnitMaster/InsertUpd/", InsUpdfalseUnit).then(
            (json) => {
              if (json.data.strStatus === "Success") {
                ClearClick();
                AlertMessage(json.data.strMessage, "success");
              } else {
                dispatch(setload_Screen(false));
                AlertMessage(json.data.strMessage, "error");
              }
            }
          );

          break;
        default:
          break;
      }
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const ClearClick = () => {
    try {
      dispatch(setload_Screen(false));
      setstrUnitName("");
      setstrPrintName("");
      setiLeastUnit(1);
      setiUQC(1);
      setdConvertValue(1);
      setbChkLeastUnit(false);
      setbChkNonLeastUnit(true);
      setstrSearch("");
      setiUnitId(0);
      setBtnDeleteDisable(false);
      setBtnSaveDisable(false);
      GetDefaultUnit();
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const View_Unit = (iUnitId) => {
    try {
      dispatch(setload_Screen(true));
      userApp.get(
         "UnitMaster/GetUnit/" + iUnitId + "/" + localStorage.db
      ).then((Res) => {
        setstrUnitName(Res.data.strUnitName);
        setstrPrintName(Res.data.strPrintName);
        setiLeastUnit(Res.data.iLeastUnit);
        setiUQC(Res.data.iUQC);
        setdConvertValue(Res.data.dConvertValue);
        if (Res.data.bChkLeastUnit === true) {
          setbChkLeastUnit(Res.data.bChkLeastUnit);
          setbChkNonLeastUnit(false);
        } else {
          setbChkLeastUnit(false);
          setbChkNonLeastUnit(true);
        }
        setiUnitId(Res.data.iUnitId);
        ButtonPermission(JSON.parse(localStorage.Menu), Res.data.iUnitId);
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const DeleteClick = () => {
    try {
      dispatch(setload_Screen(true));
      userApp.post(
         "UnitMaster/Delete/" + iUnitId + "/" + localStorage.db
      ).then((json) => {
        if (json.data.strStatus === "Success") {
          AlertMessage(json.data.strMessage, "success");
          ClearClick();
        } else {
          dispatch(setload_Screen(false));
          AlertMessage(json.data.strMessage, "error");
        }
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const Search_Unit = (strtext) => {
    try {
      setstrSearch(strtext);
      const objUnit = {
        strSearch: strtext,
        strCompanyDB: localStorage.db,
      };
      userApp.post( "UnitMaster/Search_Unit/", objUnit).then((Res) => {
        setListEntUnit(Res.data);
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const TabIndex = (e) => {
    var maxTab = 61;
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowLeft") {
      if (ActiveIndex > 50 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + maxTab + "']").focus();
      }
    }
    if (e.key === "ArrowRight") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 61) {
          $(".Unit .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 61) {
          $(".Unit .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 50 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === 61) {
          $(".Unit .react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 50 + "']").focus();
        $("[tabindex='" + 50 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $(".Unit .react-table .rt-tbody .rt-tr:eq(0)").focus();
      }
    }
  };
  const ButtonPermission = (ListMenu, UnitId) => {
    ListMenu = ListMenu.objEntMPM.filter((Data) => Data.iScrId === 64);
    if (ListMenu.length > 0) {
      if (Number(UnitId) > 0) {
        if (ListMenu[0].isModify === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
        if (ListMenu[0].isDelete === false) {
          setBtnDeleteDisable(true);
        } else {
          setBtnDeleteDisable(false);
        }
      }
      if (Number(UnitId) === 0) {
        if (ListMenu[0].isNew === false) {
          setBtnSaveDisable(true);
        } else {
          setBtnSaveDisable(false);
        }
      }
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Unit Master</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-4 col-sm-12 popup-card">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      $(".dt-content [tabindex=0]").focus();
                    } else TabIndex(e);
                  }}
                  value={strSearch}
                  tabIndex={59}
                  onChange={(e) => Search_Unit(e.target.value)}
                  autoFocus
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => Search_Unit(strSearch)}
                ></i>
              </div>
              <div className="data-list">
                {ListEntUnit.map((dt, Index) => (
                  <button
                    key={Index}
                    tabIndex={Index}
                    onClick={(e) => View_Unit(dt.iUnitId)}
                    onKeyDown={(e) => {
                      const arrlength = ListEntUnit.length - 1;
                      if (e.key === "ArrowDown") {
                        if (Index === arrlength)
                          $(`.data-list [tabindex=${Index + 1}]`).focus();
                        else $(`.data-list [tabindex=${0}]`).focus();
                      }
                      if (e.key === "ArrowUp") {
                        if (Index === 0)
                          $(`.data-list [tabindex=${arrlength}]`).focus();
                        else $(`.data-list [tabindex=${Index - 1}]`).focus();
                      }
                      if (e.key === "Enter") {
                        View_Unit(dt.iUnitId);
                      }
                    }}
                  >
                    <label>{dt.strUnitName}</label>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row p-0">
                <div className="col-md-6 col-sm-6 ">
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    tabIndex="50"
                    onKeyDown={(e) => TabIndex(e)}
                    checked={bChkLeastUnit}
                    onChange={(e) => {
                      setbChkLeastUnit(!bChkLeastUnit);
                      setbChkNonLeastUnit(false);
                    }}
                  />
                  <label>Least Unit</label>
                </div>
                <div className="col-md-6 col-sm-6">
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    tabIndex="51"
                    onKeyDown={(e) => TabIndex(e)}
                    checked={bChkNonLeastUnit}
                    onChange={(e) => {
                      setbChkLeastUnit(false);
                      setbChkNonLeastUnit(!bChkNonLeastUnit);
                    }}
                  />
                  <label>Non-Least Unit</label>
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Least Unit</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow15"
                    showoption={_parem.isoptionShow15}
                    placeholder="Select Least Unit"
                    select_value={(val) => setiLeastUnit(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iLeastUnit}
                    displayName="strLeastUnitName"
                    disvalue="iLeastUnitId"
                    arrydata={ListEntLeast}
                    EmptVal="Least Unit"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>UQC</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow16"
                    showoption={_parem.isoptionShow16}
                    placeholder="Select Unit"
                    select_value={(val) => setiUQC(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iUQC}
                    displayName="strUQCName"
                    disvalue="iUQCId"
                    arrydata={ListEntUqc}
                    EmptVal="Unit"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Unit Name</label>
                  <input
                    type="text"
                    className="form-control"
                    tabIndex="52"
                    spellCheck={false}
                    onKeyDown={(e) => TabIndex(e)}
                    autoFocus
                    value={strUnitName}
                    onChange={(e) => setstrUnitName(e.target.value)}
                    placeholder="Unit Name"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Print Name</label>
                  <input
                    type="text"
                    aria-label="First name"
                    tabIndex="53"
                    onKeyDown={(e) => TabIndex(e)}
                    spellCheck={false}
                    className="form-control"
                    value={strPrintName}
                    onChange={(e) => setstrPrintName(e.target.value)}
                    placeholder="Print Name"
                  />
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>Convert Value</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    tabIndex="54"
                    onKeyDown={(e) => TabIndex(e)}
                    onClick={(e) => e.target.select()}
                    value={dConvertValue}
                    onChange={(e) => setdConvertValue(e.target.value)}
                    onBlur={(e) => {
                      if (e.target.value === "") setdConvertValue(0);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button
              className="btn-fabgreen"
              tabIndex="57"
              disabled={BtnSaveDisable}
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => {
                switch (bChkLeastUnit) {
                  case true:
                    if (strUnitName === "") {
                      AlertMessage("Please Enter UnitName", "error");
                    } else if (iUQC === 0) {
                      AlertMessage("Please Select UQC", "error");
                    } else {
                      dispatch(
                        Show_MsgBox({
                          Button: SaveClick,
                          Question: "Do You Want to Save Least Unit?",
                          isdialog: true,
                        })
                      );
                    }
                    break;
                  case false:
                    if (strUnitName === "") {
                      AlertMessage("Please Enter UnitName", "error");
                    } else if (iLeastUnit === 0) {
                      AlertMessage("Please Select LeastUnit", "error");
                    } else if (iUQC === 0) {
                      AlertMessage("Please Select UQC", "error");
                    } else if (dConvertValue === 0) {
                      AlertMessage("Please Enter Convertion Value", "error");
                    } else {
                      dispatch(
                        Show_MsgBox({
                          Button: SaveClick,
                          Question: "Do You Want to Save Unit?",
                          isdialog: true,
                        })
                      );
                    }
                    break;
                  default:
                    break;
                }
              }}
            >
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              tabIndex="58"
              onKeyDown={(e) => TabIndex(e)}
              disabled={BtnDeleteDisable}
              onClick={(e) => {
                if (iUnitId === 0) {
                  AlertMessage("Please Select Unit", "error");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteClick,
                      Question: "Do You Want to Delete Unit ?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button
              type="Reset"
              className="btn-fabgreen"
              tabIndex="59"
              onKeyDown={(e) => TabIndex(e)}
              onClick={ClearClick}
            >
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UnitMaster;
