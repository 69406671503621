import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import { userApp } from "../Admin/Utility";

function CustomizeScreen(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const [isUpdateStatus, setisUpdateStatus] = useState(false);
  const [TabName, setTabName] = useState("Order");
  const [arrEntData, setarrEntData] = useState([]);
  const [arrTabBtnshow, setarrTabBtnshow] = useState([true, false, false]);
  const [iIndex, setiIndex] = useState(0);

  useEffect(() => {
    tab_click(0, "Field");
  }, [props]);
  const ClearClick = () => {
    setarrEntData([]);
    setarrEntData([]);
    setarrEntData([]);
    Tab_OnChange(TabName);
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      if (TabName === "Field") {
        SaveFieldSetting();
      } else {
        const UpdateSetting = {
          objEntBTDT: [],
          objEntTSDT: [],
          strTag: TabName,
          iScrId: _Common.iScrId,
          strCompanyDB: localStorage.db,
        };
        if (TabName === "Order") {
          let ListUpdateControl = arrEntData;
          ListUpdateControl.map((Data) => {
            Data.strDisplayName =
              Data.strDisplayName === null ? "" : Data.strDisplayName;
            Data.strErrorMsg =
              Data.strErrorMsg === null ? "" : Data.strErrorMsg;
            Data.strParentTabItemName =
              Data.strParentTabItemName === null
                ? ""
                : Data.strParentTabItemName;
            return Data;
          });
          UpdateSetting.objEntBTDT = ListUpdateControl;
        } else {
          UpdateSetting.objEntTSDT = arrEntData;
        }
        await userApp
          .post("Home/Update_DefaultProperties", UpdateSetting)
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              ClearClick();
              AlertMessage(Res.data.strMessage, "success");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const SaveFieldSetting = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .post("Home/Update_CustomFieldSetting", {
          objEntPerm: arrEntData,
          strCompanyDB: localStorage.db,
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setisUpdateStatus(true);
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Tab_OnChange = async (TabName) => {
    setTabName(TabName);
    try {
      await dispatch(setload_Screen(true));
      if (Number(_Common.iScrId) > 0)
        await userApp
          .get(
            `Home/Load_Transtabsettings/${_Common.iScrId}/${TabName}/${localStorage.db}`
          )
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              setarrEntData(Res.data.EntProperties);
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Field = [
    {
      Header: "Field Name",
      style: { justifyContent: "center" },
      accessor: "strFieldName",
      cell: ({ rowinfo }) => (
        <input
          type="text"
          defaultValue={rowinfo.strFieldName}
          className="form-control"
          spellCheck={false}
          autoComplete="off"
          onChange={(e) => (rowinfo.strFieldName = e.target.value)}
        />
      ),
    },

    {
      Header: "Show",
      accessor: "bIsVissible",
      style: { justifyContent: "center" },
      minWidth: 50,
      maxWidth: 50,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.bIsVissible}
          className="form-check"
          onChange={(e) => (rowinfo.bIsVissible = !rowinfo.bIsVissible)}
        />
      ),
    },
  ];
  const Column = [
    {
      Header: "SNo",
      accessor: "sNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Alias Name",
      accessor: "aliasName ",
      style: { justifyContent: "left" },
      cell: ({ rowinfo }) => (
        <input
          type="text"
          defaultValue={rowinfo.aliasName}
          className="form-control"
          spellCheck={false}
          autoComplete="off"
          onChange={(e) => (rowinfo.aliasName = e.target.value)}
        />
      ),
    },
    {
      Header: "Column Name",
      accessor: "columnName",
      id: "tScrId",
      style: { justifyContent: "left" },
    },

    {
      Header: "Show",
      accessor: "isShowInScr",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isShowInScr}
          className="form-check"
          onChange={(e) => (rowinfo.isShowInScr = !rowinfo.isShowInScr)}
        />
      ),
    },
    {
      Header: "Read Only",
      accessor: "readOnly",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.readOnly}
          className="form-check"
          onChange={(e) => (rowinfo.readOnly = !rowinfo.readOnly)}
        />
      ),
    },
    {
      Header: "In Order",
      accessor: "inOrderTO",
      minWidth: 100,
      maxWidth: 100,
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.inOrderTO}
          onClick={(e) => e.target.select()}
          onChange={(e) => (rowinfo.inOrderTO = e.target.value)}
        />
      ),
    },
  ];
  const Order = [
    {
      Header: "SNo",
      accessor: "iSNo",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
    },
    {
      Header: "Field Name",
      id: "iCtrlId",
      accessor: "strCtrlName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Show",
      accessor: "isTabStop",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isTabStop}
          className="form-check"
          onChange={(e) => (rowinfo.isTabStop = !rowinfo.isTabStop)}
        />
      ),
    },
    {
      Header: "Validation",
      accessor: "isValidate",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.isValidate}
          className="form-check"
          onChange={(e) => (rowinfo.isValidate = !rowinfo.isValidate)}
        />
      ),
    },
    {
      Header: "In Order",
      accessor: "iTabIndex",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          defaultValue={rowinfo.iTabIndex}
          onClick={(e) => e.target.select()}
          onBlur={(e) => (rowinfo.iTabIndex = e.target.value)}
        />
      ),
    },
  ];
  const tab_click = (tabindex, tabname) => {
    let line = document.getElementById("tab-line");
    let lable = $(`.trans-buttons label`);
    let fromleft = lable[tabindex].offsetLeft;
    let width = lable[tabindex].clientWidth;
    line.style.left = fromleft + "px";
    line.style.width = width + "px";
    $(`.trans-buttons label:eq(${tabindex})`)
      .addClass("active")
      .siblings()
      .removeClass("active");
    let EntTab = arrTabBtnshow.map((Dt, Index) => {
      if (Index === tabindex) {
        Dt = true;
      } else {
        Dt = false;
      }
      return Dt;
    });
    setarrTabBtnshow(EntTab);
    setiIndex(tabindex);
    Tab_OnChange(tabname);
  };
  const View_tableColumns = (Index) => {
    let DT = [];
    switch (Index) {
      case 0:
        DT = Field;
        break;
      case 1:
        DT = Column;
        break;
      case 2:
        DT = Order;
        break;
      default:
        break;
    }
    return DT;
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content medium">
        <div className="popup-header">
          <h5>Customize Screens</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={() => {
                if (isUpdateStatus) window.location.reload();
                else props.Close();
              }}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="trans-tab">
            <div className="trans-buttons">
              <label onClick={(e) => tab_click(0, "Field")} className="active">
                Customise Field
              </label>
              <label onClick={(e) => tab_click(1, "Column")}>
                Table Columns
              </label>
              <label onClick={(e) => tab_click(2, "Order")}>Tab Order</label>
              <div id="tab-line"></div>
            </div>
          </div>
          <div className="table-card">
            <ReactTable
              columns={View_tableColumns(iIndex)}
              data={arrEntData}
              minRows={10}
              row_click={() => {}}
              background={false}
              className="full-table"
            />
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CustomizeScreen;
