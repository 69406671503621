import React, { useEffect, useState } from "react";
import ExportImport from "./ExportImport";
import Button from "./MasterButton";
import MasterList from "./MasterListItem";
import { userApp } from "../Admin/Utility";
import PriceGroupMaster from "./PriceGroupMaster";
import SideMenu from "../General/SideMenu";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import $ from "jquery";
import ShippingAddressPopup from "../Transaction/ShippingAddressPopup";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import Messagedialogbox from "../General/Messagedialogbox";
import MasterCodeSetting from "./MasterCodeSetting";
import CountryMaster from "./CountryMaster";
import StateMaster from "./StateMaster";
function CustomerMaster() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _ScrId = 2;
  const [strSearch, setstrSearch] = useState("");
  const strFilter = "";
  const strFilterColumn = "All";
  const [ManualCode, setManualCode] = useState(true);
  const strCustCodeTag = "Enter Customer Code";
  const [iCustId, setiCustId] = useState(0);
  const [strCustCode, setstrCustCode] = useState("");
  const [strAliesName, setstrAliesName] = useState("");
  const [strCustomerName, setstrCustomerName] = useState("");
  const [strOfficeNo, setstrOfficeNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strCity, setstrCity] = useState("");
  const [iPinCode, setiPinCode] = useState("");
  const [strCountryId, setstrCountryId] = useState(1);
  const [strStateId, setstrStateId] = useState(32);
  const [isShowPriceGroup, setisShowPriceGroup] = useState(false);
  const [iPhone, setiPhone] = useState("");
  const [iPhone1, setiPhone1] = useState("");
  const [iMobile, setiMobile] = useState("");
  const [iMobile1, setiMobile1] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strGst, setstrGst] = useState("");
  const [iPriceGrpId, setiPriceGrpId] = useState(1);
  const [iStatusId, setiStatusId] = useState(1);
  const [strRemarks, setstrRemarks] = useState("");
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [iCreditLimit, setiCreditLimit] = useState((0.0).toFixed());
  const [iOpeningBal, setiOpeningBal] = useState((0.0).toFixed());
  const [strCreditTerm, setstrCreditTerm] = useState("30 Days");
  const [iCustCount, setiCustCount] = useState(0);
  const [ExpImpOpen, setExpImpOpen] = useState(false);

  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listEntComBoxVa, setlistEntComBoxVa] = useState([]);
  const [listEntStatus, setlistEntStatus] = useState([]);
  const [listEntCountry, setlistEntCountry] = useState([]);
  const [listEntState, setlistEntState] = useState([]);
  const [listEntPriGrp, setlistEntPriGrp] = useState([]);
  const [listEntBalTyp, setlistEntBalTyp] = useState([]);
  const [listEntCusList, setlistEntCusList] = useState([]);
  const [ListValidateProperties, setListValidateProperties] = useState([]);

  const [bIsPhone1, setbIsPhone1] = useState(false);
  const [bIsMobile1, setbIsMobile1] = useState(false);
  const [bIsRemark, setbIsRemark] = useState(false);
  const [bIsStatus, setbIsStatus] = useState(false);
  const [bIsBalType, setbIsBalType] = useState(false);
  const [bIsOpenBal, setbIsOpenBal] = useState(false);
  const [bIsCreditLim, setbIsCreditLim] = useState(false);
  const [bIsCreditTerm, setbIsCreditTerm] = useState(false);
  const [bIsPriceGroup, setbIsPriceGroup] = useState(false);
  const [bIsNewAddress, setbIsNewAddress] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);
  const [IsCountry, setIsCountry] = useState(false);
  const [IsState, setIsState] = useState(false);

  useEffect(() => {
    Load_CustomerMaster();
  }, []);
  const Load_CustomerMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: _ScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await userApp
        .post(`CustomerMaster/Load_CustomerMaster`, objParems)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setlistEntComBoxVa(Res.data.objEntComBoxVa);
            setlistEntStatus(Res.data.objEntStatus);
            setlistEntCountry(Res.data.objEntCountry);
            setlistEntState(Res.data.objEntState);
            setlistEntPriGrp(Res.data.objEntPriGrp);
            setlistEntBalTyp(Res.data.objEntBalTyp);
            setlistEntCusList(Res.data.objEntCusList);
            setlistCodeFormat(Res.data.objCodeFormat);
            setstrCustCode(Res.data.objCodeFormat[0].strCode);
            setManualCode(!Res.data.objCodeFormat[0].isManualCode);
            var objEntField = Res.data.FieldPer;
            setbIsPhone1(objEntField[0].bIsVissible);
            setbIsMobile1(objEntField[1].bIsVissible);
            setbIsStatus(objEntField[2].bIsVissible);
            setbIsRemark(objEntField[3].bIsVissible);
            setbIsBalType(objEntField[4].bIsVissible);
            setbIsOpenBal(objEntField[5].bIsVissible);
            setbIsCreditLim(objEntField[6].bIsVissible);
            setbIsCreditTerm(objEntField[7].bIsVissible);
            setbIsPriceGroup(objEntField[8].bIsVissible);
            setListValidateProperties(Res.data.Transtab);
            TabOrderAssign(Res.data.Transtab);
          } else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const CountryOnChange = async (CountryId) => {
    try {
      setstrCountryId(CountryId);
      const parems = {
        iCountryId: Number(CountryId),
        strSearch: "",
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`CountryState/Load_State`, parems).then((res) => {
        if (res.data.strStatus === "Success") {
          setlistEntState(res.data.EntState);
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Search_Customer = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strColName: "All",
        iScrId: _ScrId,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      await userApp
        .post("CustomerMaster/Search_Customer/", objSearch)
        .then((Res) => {
          if (Res.data.strStatus === "Success")
            setlistEntCusList(Res.data.EntCustomer);
          else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const listEntCusInsUpd = {
        iCustId: iCustId,
        strCustCode: strCustCode,
        strCustName: strCustomerName,
        strAliasName: strAliesName,
        strAdd1: strOfficeNo,
        strAdd2: strStreet,
        strAdd3: strArea,
        strAdd4: strCity,
        strPincode: iPinCode,
        iCountry: Number(strCountryId),
        iState: Number(strStateId),
        strPhone1: iPhone,
        strPhone2: iPhone1,
        strMobile1: iMobile,
        strMobile2: iMobile1,
        strEmail: strEmail,
        strGST: strGst,
        strRemarks: strRemarks,
        iPGId: Number(iPriceGrpId),
        iStatusId: Number(iStatusId),
        dOpBal: Number(iOpeningBal),
        dCreLimit: Number(iCreditLimit),
        CreditTerm: strCreditTerm,
        iBalTypeId: Number(iBalTypeId),
        iTitleTag: _ScrId,
        iNumSeqNo: listCodeFormat[0].iNumSeqNo,
        strPrefix: listCodeFormat[0].strPrefix,
        strCustCodeTag: strCustCodeTag,
        strFilterColumn: strFilterColumn,
        strFilter: strFilter,
        strSearch: strSearch,
        strCompanyDB: localStorage.db,
      };
      await userApp
        .post(`CustomerMaster/InsUpd_Customer`, listEntCusInsUpd)
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await ClearClick();
            await AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .post(
          `CustomerMaster/Delete_Customer/${iCustId}/${_ScrId}/${localStorage.db}`
        )
        .then(async (Res) => {
          if (Res.data.strStatus === "Success") {
            await ClearClick();
            await AlertMessage(Res.data.strMessage, "success");
          } else {
            await AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    try {
      setiCustId(0);
      setstrCustCode("");
      setstrAliesName("");
      setstrCustomerName("");
      setstrOfficeNo("");
      setstrStreet("");
      setstrArea("");
      setstrCity("");
      setstrArea("");
      setstrArea("");
      setiPinCode("");
      setstrCountryId(1);
      setstrStateId(32);
      setiPhone("");
      setiPhone1("");
      setiMobile("");
      setiMobile1("");
      setstrEmail("");
      setstrGst("");
      setiPriceGrpId(0);
      setiStatusId(1);
      setstrRemarks("");
      setiBalTypeId(3);
      setiCreditLimit(0);
      setiOpeningBal(0);
      setstrCreditTerm("30 Days");
      setiCustCount(0);
      Load_CustomerMaster();
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtName":
          ErrorMsg = strCustomerName === "" ? false : true;
          break;
        case "txtAlias":
          ErrorMsg = strAliesName === "" ? false : true;
          break;
        case "txtAdd1":
          ErrorMsg = strOfficeNo === "" ? false : true;
          break;
        case "txtAdd2":
          ErrorMsg = strStreet === "" ? false : true;
          break;
        case "txtAdd3":
          ErrorMsg = strArea === "" ? false : true;
          break;
        case "txtAdd4":
          ErrorMsg = strCity === "" ? false : true;
          break;
        case "txtPinCode":
          ErrorMsg = iPinCode === "" ? false : true;
          break;
        case "txtPhone1":
          ErrorMsg = iPhone === "" ? false : true;
          break;
        case "txtPhone2":
          ErrorMsg = iPhone1 === "" ? false : true;
          break;
        case "txtMobile1":
          ErrorMsg = iMobile === "" ? false : true;
          break;
        case "txtMobile2":
          ErrorMsg = iMobile1 === "" ? false : true;
          break;
        case "txtEmail":
          ErrorMsg = strEmail === "" ? false : true;
          break;
        case "txtTin":
          ErrorMsg = true;
          break;
        case "txtGst":
          ErrorMsg = strGst === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "txtOpBalance":
          ErrorMsg = iOpeningBal === "" ? false : true;
          break;
        case "txtCreditLimit":
          ErrorMsg = iCreditLimit === "" ? false : true;
          break;
        case "txtCreditTerm ":
          ErrorMsg = strCreditTerm === "" ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const View_Customer = async (iCusId) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .get(
          `CustomerMaster/View_Customer/${iCusId}/${_ScrId}/${localStorage.db}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setiCustId(Res.data.objEntCust[0].iCustId);
            setstrCustCode(Res.data.objEntCust[0].strCustCode);
            setstrCustomerName(Res.data.objEntCust[0].strCustName);
            setstrAliesName(Res.data.objEntCust[0].strAliasName);
            setstrOfficeNo(Res.data.objEntCust[0].strAdd1);
            setstrStreet(Res.data.objEntCust[0].strAdd2);
            setstrArea(Res.data.objEntCust[0].strAdd3);
            setstrCity(Res.data.objEntCust[0].strAdd4);
            setiPinCode(Res.data.objEntCust[0].strPincode);
            setiPhone(Res.data.objEntCust[0].strPhone1);
            setiPhone1(Res.data.objEntCust[0].strPhone2);
            setiMobile(Res.data.objEntCust[0].strMobile1);
            setiMobile1(Res.data.objEntCust[0].strMobile2);
            setstrEmail(Res.data.objEntCust[0].strEmail);
            setstrGst(Res.data.objEntCust[0].strGST);
            setiPriceGrpId(Res.data.objEntCust[0].iPGId);
            setiStatusId(Res.data.objEntCust[0].iStatusId);
            setstrRemarks(Res.data.objEntCust[0].strRemarks);
            setiBalTypeId(Res.data.objEntCust[0].iBalTypeId);
            setiCreditLimit(Res.data.objEntCust[0].dCreLimit);
            setiOpeningBal(Res.data.objEntCust[0].dOpBal);
            var CretTerm = Res.data.objEntCust[0].creditTerm + " Days";
            setstrCreditTerm(CretTerm);
            setlistEntState(Res.data.objEntState);
            setstrStateId(Res.data.objEntCust[0].iState);
            setstrCountryId(Res.data.objEntCust[0].iCountry);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Load_PriceGroup = async () => {
    try {
      await dispatch(setload_Screen(true));
      setisShowPriceGroup(!isShowPriceGroup);
      await userApp
        .get(
          "CustomerMaster/GetDefaultData/" +
            _ScrId +
            "/" +
            localStorage.db +
            "/" +
            localStorage.Msdb
        )
        .then((Res) => {
          setlistEntPriGrp(Res.data.objEntPriGrp);
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  const [ListTabOrder, setListTabOrder] = useState([
    {
      CusCodeTab: "",
      AliasTab: "",
      NameTab: "",
      OfficeNoTab: "",
      StreetTab: "",
      AreaTab: "",
      CityTab: "",
      PinCodeTab: "",
      CountryTab: "",
      StateTab: "",
      Phone1Tab: "",
      Phone2Tab: "",
      Mobile1Tab: "",
      Mobile2Tab: "",
      Emailtab: "",
      GstTab: "",
      TinNoTab: "",
      RemarksTab: "",
      CreditLimitTab: "",
      CreditTermTab: "",
      StatusTab: "",
      OpeningBalTab: "",
      BalTypeTab: "",
      PriceGrpTab: "",
      CustomerListTab: "",
      CustomerFIlterTab: "",
      CustomerSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
      btnCountry: "",
      btnPriceGrp: "",
      btnState: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtCode":
          TabOrder[0].CusCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtName":
          TabOrder[0].NameTab = EntProperties[i].iTabIndex;
          break;
        case "txtAlias":
          TabOrder[0].AliasTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd1":
          TabOrder[0].OfficeNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd2":
          TabOrder[0].StreetTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd3":
          TabOrder[0].AreaTab = EntProperties[i].iTabIndex;
          break;
        case "txtAdd4":
          TabOrder[0].CityTab = EntProperties[i].iTabIndex;
          break;
        case "cbCountry":
          TabOrder[0].CountryTab = EntProperties[i].iTabIndex;
          break;
        case "txtPinCode":
          TabOrder[0].PinCodeTab = EntProperties[i].iTabIndex;
          break;
        case "cbState":
          TabOrder[0].StateTab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone1":
          TabOrder[0].Phone1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtPhone2":
          TabOrder[0].Phone2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile1":
          TabOrder[0].Mobile1Tab = EntProperties[i].iTabIndex;
          break;
        case "txtMobile2":
          TabOrder[0].Mobile2Tab = EntProperties[i].iTabIndex;
          break;
        case "txtEmail":
          TabOrder[0].Emailtab = EntProperties[i].iTabIndex;
          break;
        case "txtTin":
          TabOrder[0].TinNoTab = EntProperties[i].iTabIndex;
          break;
        case "txtGst":
          TabOrder[0].GstTab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].StatusTab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].RemarksTab = EntProperties[i].iTabIndex;
          break;
        case "txtOpBalance":
          TabOrder[0].OpeningBalTab = EntProperties[i].iTabIndex;
          break;
        case "cbBalType":
          TabOrder[0].BalTypeTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditLimit":
          TabOrder[0].CreditLimitTab = EntProperties[i].iTabIndex;
          break;
        case "txtCreditTerm":
          TabOrder[0].CreditTermTab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "btnCountry":
          TabOrder[0].btnCountry = EntProperties[i].iTabIndex;
          break;
        case "btnPriceGrp":
          TabOrder[0].btnPriceGrp = EntProperties[i].iTabIndex;
          break;
        case "btnState":
          TabOrder[0].btnState = EntProperties[i].iTabIndex;
          break;
        case "cbPriceGroup":
          TabOrder[0].PriceGrpTab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].CustomerFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].CustomerSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbCustomerName":
          TabOrder[0].CustomerListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].CustomerListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].CustomerListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].CustomerListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].CustomerListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  return (
    <div>
      <SideMenu ScrId={_ScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listEntComBoxVa}
          EntList={listEntCusList}
          GetDetails={View_Customer}
          Count={iCustCount}
          Search={Search_Customer}
          ScreenID={_ScrId}
          accessor="strCustName"
          accessorid="iCustId"
          ListTab={ListTabOrder[0].CustomerListTab}
          FilterTab={ListTabOrder[0].CustomerFIlterTab}
          SearchTab={ListTabOrder[0].CustomerSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strCustomerName}
                  spellCheck={false}
                  autoComplete="off"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].NameTab}
                  autoFocus
                  placeholder="Please Enter Customer Name"
                  onChange={(e) => setstrCustomerName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    id="txtProductCode"
                    value={strCustCode}
                    autoComplete="off"
                    readOnly={ManualCode}
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].CusCodeTab}
                    onChange={(e) => setstrCustCode(e.target.value)}
                    name="strProCode"
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Alias Name</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strAliesName}
                  autoComplete="off"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].AliasTab}
                  placeholder="Please Enter Alias Name"
                  onChange={(e) => setstrAliesName(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Office /Door No</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].OfficeNoTab}
                  value={strOfficeNo}
                  placeholder="Please Enter Office /Door No"
                  onChange={(e) => setstrOfficeNo(e.target.value)}
                />
                {iCustId > 0 && (
                  <span
                    className="btn-shipadd"
                    onClick={(e) => setbIsNewAddress(!bIsNewAddress)}
                  >
                    <i className="fa-solid fa-location-dot ml-2"></i>
                    Add Secondary Address
                  </span>
                )}
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Street/Village</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].StreetTab}
                  autoComplete="off"
                  value={strStreet}
                  placeholder="Please Enter Street/Village"
                  onChange={(e) => setstrStreet(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Area/Town</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].AreaTab}
                  placeholder="Please Enter Area/Town"
                  autoComplete="off"
                  value={strArea}
                  onChange={(e) => setstrArea(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>City</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  value={strCity}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].CityTab}
                  autoComplete="off"
                  placeholder="Please Enter City Name"
                  onChange={(e) => setstrCity(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Pincode</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].PinCodeTab}
                  placeholder="Please Enter PinCode"
                  autoComplete="off"
                  value={iPinCode}
                  onChange={(e) => setiPinCode(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Country</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_selecrparem.isoptionShow}
                  placeholder="Select Country"
                  select_value={(val) => CountryOnChange(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].CountryTab}
                  btnname="Country"
                  btnshow={true}
                  show_popup={() => setIsCountry(!IsCountry)}
                  valueonly={true}
                  defaultval={strCountryId}
                  displayName="strCountryName"
                  disvalue="iCountryId"
                  arrydata={listEntCountry}
                  disabled={false}
                  EmptVal="Country "
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>State</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_selecrparem.isoptionShow1}
                  placeholder="Select State"
                  select_value={(val) => setstrStateId(val)}
                  keydown={(e) => TabIndex(e)}
                  taborder={ListTabOrder[0].StateTab}
                  btnname="State"
                  btnshow={true}
                  show_popup={() => setIsState(!IsState)}
                  valueonly={true}
                  defaultval={strStateId}
                  displayName="strSName"
                  disvalue="iStateId"
                  arrydata={listEntState}
                  disabled={false}
                  EmptVal="State "
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Phone </label>
                <input
                  type="number"
                  aria-label="First name"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Phone1Tab}
                  className="form-control"
                  placeholder="Please Enter Phone No"
                  value={iPhone}
                  autoComplete="off"
                  onChange={(e) => setiPhone(e.target.value)}
                />
              </div>
              {bIsPhone1 && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Phone 1</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Phone No"
                    value={iPhone1}
                    autoComplete="off"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Phone2Tab}
                    onChange={(e) => setiPhone1(e.target.value)}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>Mobile</label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Please Enter Mobile No"
                  value={iMobile}
                  autoComplete="off"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Mobile1Tab}
                  onChange={(e) => setiMobile(e.target.value)}
                />
              </div>
              {bIsMobile1 && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Mobile 1</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Please Enter Mobile No"
                    value={iMobile1}
                    autoComplete="off"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Mobile2Tab}
                    onChange={(e) => setiMobile1(e.target.value)}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-12 mb-2">
                <label>E-Mail </label>
                <input
                  type="email"
                  aria-label="First name"
                  className="form-control"
                  autoComplete="off"
                  placeholder="Please Enter Email"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Emailtab}
                  value={strEmail}
                  onChange={(e) => setstrEmail(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12 mb-2">
                <label>GST</label>
                <input
                  type="text"
                  aria-label="First name"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].GstTab}
                  className="form-control"
                  autoComplete="off"
                  placeholder="Please Enter GST No"
                  value={strGst}
                  onChange={(e) => setstrGst(e.target.value)}
                  maxLength={15}
                />
              </div>
              {bIsStatus && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>Status</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_selecrparem.isoptionShow2}
                    placeholder="Select Status"
                    select_value={(val) => setiStatusId(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].StatusTab}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStatusId}
                    displayName="strStatusName"
                    disvalue="iStatusId"
                    arrydata={listEntStatus}
                    disabled={false}
                    EmptVal="Status "
                  />
                </div>
              )}
              {bIsRemark && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Remarks</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].RemarksTab}
                    placeholder="Please Enter Remark"
                    value={strRemarks}
                    autoComplete="off"
                    onChange={(e) => setstrRemarks(e.target.value)}
                  />
                </div>
              )}
              {bIsBalType && (
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>Balance Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow3"
                    showoption={_selecrparem.isoptionShow3}
                    placeholder="Select Balance Type"
                    select_value={(val) => setiBalTypeId(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].BalTypeTab}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iBalTypeId}
                    displayName="strBalType"
                    disvalue="iBalTypeId"
                    arrydata={listEntBalTyp}
                    disabled={false}
                    EmptVal="Balance Type "
                  />
                </div>
              )}
              {bIsOpenBal && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Opening Balance</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].OpeningBalTab}
                    placeholder="Please Enter Opening Balance"
                    value={iOpeningBal}
                    autoComplete="off"
                    onChange={(e) => setiOpeningBal(e.target.value)}
                  />
                </div>
              )}
              {bIsCreditLim && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Credit Limit</label>
                  <input
                    type="number"
                    aria-label="First name"
                    className="form-control"
                    name="iCreditLimit"
                    placeholder="Please Enter Credit Limit"
                    value={iCreditLimit}
                    autoComplete="off"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].CreditLimitTab}
                    onChange={(e) => setiCreditLimit(e.target.value)}
                  />
                </div>
              )}
              {bIsCreditTerm && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Credit Term</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].CreditTermTab}
                    value={strCreditTerm}
                    onChange={(e) => setstrCreditTerm(e.target.value)}
                    placeholder="Enter CreditTerm"
                    name="strCreditTerm"
                  />
                </div>
              )}
              {bIsPriceGroup && (
                <div className="col-md-3 col-sm-12 mb-2">
                  <label>Price Group</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_selecrparem.isoptionShow4}
                    placeholder="Select Price Group"
                    select_value={(val) => setiPriceGrpId(val)}
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].PriceGrpTab}
                    btnname="Price Group"
                    btnshow={true}
                    show_popup={(e) => setisShowPriceGroup(!isShowPriceGroup)}
                    valueonly={true}
                    defaultval={iPriceGrpId}
                    displayName="strPGrpName"
                    disvalue="iPGrpId"
                    arrydata={listEntPriGrp}
                    disabled={false}
                    EmptVal="Price Group "
                  />
                </div>
              )}
            </div>
            {ExpImpOpen && (
              <ExportImport
                Close={(IsImported) => {
                  setExpImpOpen(!ExpImpOpen);
                  if (IsImported) ClearClick();
                }}
                iScrId={_ScrId}
              />
            )}
            {isShowPriceGroup && (
              <PriceGroupMaster Close={(e) => Load_PriceGroup()} />
            )}
            {bIsNewAddress && (
              <ShippingAddressPopup
                PartyId={iCustId}
                PartyName={strCustomerName}
                Close={(e) => setbIsNewAddress(!bIsNewAddress)}
                ScreentType="Sales"
                iScrId={_ScrId}
                Update={(add) => setbIsNewAddress(!bIsNewAddress)}
              />
            )}
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={_ScrId}
              />
            )}
            {IsCountry && (
              <CountryMaster Close={() => setIsCountry(!IsCountry)} />
            )}
            {IsState && <StateMaster Close={() => setIsState(!IsState)} />}
            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (PropertiesValidation()) {
                dispatch(
                  Show_MsgBox({
                    Button: SaveClick,
                    Question: "Do You Want to Save Customer ?",
                    isdialog: true,
                  })
                );
              }
            }}
            Export={(e) => setExpImpOpen(!ExpImpOpen)}
            Clear={ClearClick}
            Delete={(e) => {
              if (iCustId === 0) {
                AlertMessage("Please Select Customer", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Customer ?",
                    isdialog: true,
                  })
                );
              }
            }}
            ScrId={_ScrId}
            Iid={iCustId}
            SaveIndex={ListTabOrder[0].BtnSavtab}
            DeleteIndex={ListTabOrder[0].BtnDeletetab}
            CloseIndex={ListTabOrder[0].BtnClosetab}
            ClearIndex={ListTabOrder[0].BtnCleartab}
            ExportIndex={ListTabOrder[0].BtnExporttab}
            onKeyDown={(e) => TabIndex(e)}
          />
        </div>
      </div>
    </div>
  );
}
export default CustomerMaster;
