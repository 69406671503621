import React, { useEffect, useState } from "react";

import ReactTable from "../General/ReactTable";
import PrintLineCopy from "./PrintLineCopy";
import PrintTemplateCopy from "./PrintTemplateCopy";
import PrintTemplateCreation from "./PrintTemplateCreation";
import PrintScreen from "./PrintScreen";
import { userApp} from "../Admin/Utility";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import PrintImage from "./PrintImage";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import $ from "jquery";
import Print_DesignCaller from "../General/Print_DesignCaller";
function PrintMaster(props) {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const [iScrId, setiScrId] = useState(32);
  const [iFormatId, setiFormatId] = useState(0);
  const [iAreaId, setiAreaId] = useState(0);
  const [iDesignId, setiDesignId] = useState(0);
  const [arrScreenName, setarrScreenName] = useState([]);
  const [arrPrintFormate, setarrPrintFormate] = useState([]);
  const [arrPrintArea, setarrPrintArea] = useState([]);
  const [arrAreaColumn, setarrAreaColumn] = useState([]);
  const [arrColumDesign, setarrColumDesign] = useState([]);

  const [iselectedIndex, setiselectedIndex] = useState(0);

  const [strSearch, setstrSearch] = useState("");
  const [iLineAt, setiLineAt] = useState(0);
  const [iStartAt, setiStartAt] = useState(0);
  const [iWidth, setiWidth] = useState(0);
  const [iFontSize, setiFontSize] = useState(13);
  const [strFont, setstrFont] = useState("times");
  const [strWordWrab, setstrWordWrab] = useState("nowrap");
  const [strAlignment, setstrAlignment] = useState("left");
  const [strLableName, setstrLableName] = useState("");
  const [strFontSyle, setstrFontSyle] = useState("normal");
  const [strfontWeight, setstrfontWeight] = useState("normal");
  const [strPrefix, setstrPrefix] = useState("");
  const [strSuffix, setstrSuffix] = useState("");
  const [iInOrder, setiInOrder] = useState(1);
  const [strColor, setstrColor] = useState("black");
  const [strFieldName, setstrFieldName] = useState("");
  const [strDisplayName, setstrDisplayName] = useState("");
  const [strColumName, setstrColumName] = useState("");

  const [IsLineCopy, setIsLineCopy] = useState(false);
  const [IsTempCopy, setIsTempCopy] = useState(false);
  const [IsTemplateCreation, setIsTemplateCreation] = useState(false);
  const [IsPreViewOpen, setIsPreViewOpen] = useState(false);
  const [islblreadonly, setislblreadonly] = useState(false);
  const [isImageUploadOpen, setisImageUploadOpen] = useState(false);

  useEffect(() => {
    Load_PrintScreen();
  }, []);
  const Load_PrintScreen = async () => {
    try {
      await dispatch(setload_Screen(false));
      await userApp.get(`PrintMaster/Load_PrintScreens/${localStorage.Msdb}`).then(
        (Res) => {
          if (Res.data.strStatus === "Success") {
            setarrScreenName(Res.data.EntScreen);
            View_PrintTemplate(17);
          } else AlertMessage(Res.data.strMessage, "info");
        }
      );
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_PrintTemplate = async (ScrId) => {
    try {
      await dispatch(setload_Screen(true));
      await setiScrId(Number(ScrId));
      await userApp.get(
        `PrintMaster/View_PrintFormate/${Number(ScrId)}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrPrintFormate(Res.data.EntTemplate);
          setarrPrintArea(Res.data.EntColumtype);
          setarrAreaColumn([]);
          setarrColumDesign([]);
          setiFormatId(0);
          setiAreaId(0);
          ClearDesign();
        } else AlertMessage(Res.data.strMessage, "info");
      });
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_PrintFormate = (FormateId) => {
    setiFormatId(FormateId);
    setiAreaId(0);
    setarrAreaColumn([]);
    setarrColumDesign([]);
    ClearDesign();
  };
  const View_PrintColumns = async (AreaId) => {
    try {
      if (Number(iFormatId) === 0) {
        AlertMessage("Please Select Formate", "info");
      } else if (Number(AreaId) === 0) {
        AlertMessage("Please Select Area", "info");
      } else {
        await dispatch(setload_Screen(true));
        setiAreaId(Number(AreaId));
        const objSearch = {
          iScrId: iScrId,
          iFormateId: Number(iFormatId),
          iAreaId: Number(AreaId),
          strSearch: strSearch,
          strCompanyDB: localStorage.db,
        };
        await userApp.post("PrintMaster/View_FormateDesing/", objSearch).then(
          (Res) => {
            if (Res.data.strStatus === "Success") {
              setarrAreaColumn(Res.data.EntColumns);
              let EntDesing = Res.data.EntDesign;
              EntDesing = EntDesing.sort((a, b) => a.lineAt - b.lineAt);
              setarrColumDesign(EntDesing);
            } else AlertMessage(Res.data.strMessage, "info");
          }
        );
      }
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Add_NewDesign = (RowInfo, Index) => {
    try {
      ClearDesign();
      if (
        RowInfo.valueMember === "Label" ||
        RowInfo.valueMember === "AddLessHeaderLabel"
      )
        setislblreadonly(false);
      else setislblreadonly(true);
      setstrColumName(RowInfo.valueMember);
      setiselectedIndex(Index);
      setstrDisplayName(RowInfo.displayName);
      setstrFieldName(RowInfo.valueMember);
    } catch (e) {
      AlertMessage(e.message, "info");
    }
  };
  const ClearDesign = () => {
    setstrColumName("");
    setiDesignId(0);
    setiLineAt(0);
    setiStartAt(0);
    setiWidth(0);
    setiFontSize(13);
    setiInOrder(1);
    setstrFont("times");
    setstrWordWrab("nowrap");
    setstrAlignment("left");
    setstrFontSyle("normal");
    setstrfontWeight("normal");
    setstrColor("black");
    setstrLableName("");
    setstrPrefix("");
    setstrSuffix("");
    setstrDisplayName("");
    setstrFieldName("");
  };
  const Modify_Design = (RowInfo) => {
    ClearDesign();
    if (
      RowInfo.fieldName === "Label" ||
      RowInfo.fieldName === "AddLessHeaderLabel"
    )
      setislblreadonly(false);
    else setislblreadonly(true);
    setstrColumName(RowInfo.fieldName);
    setiDesignId(RowInfo.designId);
    setiLineAt(RowInfo.lineAt);
    setiStartAt(RowInfo.startAt);
    setiWidth(RowInfo.width);
    setiFontSize(RowInfo.fontSize);
    setstrFont(RowInfo.font);
    setstrWordWrab(RowInfo.wordWrap);
    setstrAlignment(RowInfo.alignment);
    setstrLableName(RowInfo.labelName);
    setstrfontWeight(RowInfo.fontWeight);
    setstrPrefix(RowInfo.prefix);
    setstrSuffix(RowInfo.suffix);
    setiInOrder(RowInfo.inOrderTo);
    setstrDisplayName(RowInfo.displayName);
    setstrFieldName(RowInfo.fieldName);
    setstrColor(RowInfo.color);
    setstrFontSyle(RowInfo.fontStyle);
  };
  const Save_ColDesign = async () => {
    try {
      dispatch(setload_Screen(true));
      const UpdateDesign = {
        ilbSelectedInd: Number(iselectedIndex),
        iDesignId: iDesignId,
        dLineAt: Number(iLineAt),
        dStartAt: Number(iStartAt),
        iWidth: Number(iWidth),
        iFontSize: Number(iFontSize),
        strLabel: strLableName,
        strPrefix: strPrefix,
        strSuffix: strSuffix,
        iInOrderTo: Number(iInOrder),
        IsLabelEnabled: strLableName.length > 0 ? true : false,
        strAlignMent: strAlignment,
        strFontStyle: strFontSyle,
        strFontWeight: strfontWeight,
        strFont: strFont,
        strFontColor: strColor,
        sttWrap: strWordWrab,
        iFormatNameId: Number(iFormatId),
        iPrintAreaId: Number(iAreaId),
        iScrId: Number(iScrId),
        strFieldName: strFieldName,
        strDisplayName: strDisplayName,
        strCompanyDB: localStorage.db,
      };
      userApp.post("PrintMaster/InsUpd_ColDesign/", UpdateDesign).then(
        async (Res) => {
          if (Res.data.strStatus === "Success") {
            await ClearDesign();
            await View_PrintColumns(iAreaId);
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
          dispatch(setload_Screen(false));
        }
      );
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "info");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const SearcPrintColumn = (strtxt) => {
    setstrSearch(strtxt);
    try {
      const objSearch = {
        iScrId: iScrId,
        iFormateId: Number(iFormatId),
        iAreaId: Number(iAreaId),
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
      };
      userApp.post("PrintMaster/View_FormateDesing/", objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setarrAreaColumn(Res.data.EntColumns);
          let EntDesing = Res.data.EntDesign;
          EntDesing = EntDesing.sort((a, b) => a.lineAt - b.lineAt);
          setarrColumDesign(EntDesing);
        } else AlertMessage(Res.data.strMessage, "info");
      });
    } catch (e) {
      AlertMessage(e.message, "info");
    }
  };
  const Delete_ColDesign = async (designId) => {
    try {
      await dispatch(setload_Screen(true));
      await userApp.post(
        `PrintMaster/Delete_ColDesign/${Number(designId)}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearDesign();
          View_PrintColumns(iAreaId);
        } else {
          AlertMessage(Res.data.strMessage, "info");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const DesignColumns = [
    {
      Header: "Delete",
      id: "sNo",
      minWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <i
          onClick={(e) => {
            dispatch(
              Show_MsgBox({
                Button: Delete_ColDesign,
                Question: "Do You Want to  Delete Design?",
                isdialog: true,
                Id: rowinfo.designId,
              })
            );
          }}
          className="far fa-trash-alt"
        ></i>
      ),
    },
    {
      Header: "Column Name",
      accessor: "displayName",
      subaccessor: "fieldName",
      minWidth: 250,
    },
    {
      Header: "Line At",
      accessor: "lineAt",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Start At",
      accessor: "startAt",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Order To",
      accessor: "inOrderTo",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Width",
      accessor: "width",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Label",
      accessor: "labelName",
      minWidth: 200,
      style: { justifyContent: "left" },
    },
    {
      Header: "Size",
      accessor: "fontSize",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Style",
      accessor: "fontStyle",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Weight",
      accessor: "fontWeight",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Wrap",
      accessor: "wordWrap",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Alignment",
      accessor: "alignment",
      style: { justifyContent: "center" },
      minWidth: 80,
    },
    {
      Header: "Prefix",
      accessor: "prefix",
      style: { justifyContent: "left" },
      minWidth: 80,
    },
    {
      Header: "Suffix",
      accessor: "suffix",
      style: { justifyContent: "left" },
      minWidth: 80,
    },
    {
      Header: "Color",
      accessor: "color",
      minWidth: 80,
      style: { justifyContent: "left" },
    },
    {
      Header: "Font",
      accessor: "font",
      minWidth: 80,
      style: { justifyContent: "left" },
    },
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  const arrFontStyle = ["normal", "italic", "oblique", "bold", "underline"];
  const arrFontFamily = [
    "courier",
    "helvetica",
    "symbol",
    "times",
    "zapfdingbats",
  ];
  const arrFontWeight = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "bold",
    "normal",
  ];
  const arrTextAlignment = ["center", "left", "right", "justify"];
  const arrWordWrab = ["nowrap", "wrap"];

  const PrintInvoice = async () => {
    try {
      if (iScrId === 0) {
        AlertMessage("Please Select Screen", "info");
      } else if (iFormatId === 0) {
        AlertMessage("Please Select Formate", "info");
      } else {
        var strScrType = "";
        if (iScrId === 41 || iScrId === 42 || iScrId === 43 || iScrId === 44)
          strScrType = "Accounts";
        else strScrType = "Transaction";

        let TempId = iFormatId > 0 ? iFormatId : 0;
        await Print_DesignCaller(
          iScrId,
          "Bill View",
          strScrType,
          0,
          TempId,
          "Print",
          dispatch,
          false
        );
      }
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="p-1 mt-1">
            <CustomizedSelectoption
              optionparem="isoptionShow"
              showoption={_parem.isoptionShow}
              placeholder="Select Screen"
              select_value={(val) => View_PrintTemplate(Number(val))}
              btnname=""
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={iScrId}
              displayName="strScrName"
              disvalue="iScrId"
              arrydata={arrScreenName}
              EmptVal="Screen Name"
            />
          </div>
          <div className="data-list">
            <div className="p-1">
              <CustomizedSelectoption
                optionparem="isoptionShow1"
                showoption={_parem.isoptionShow1}
                placeholder="Select Formate"
                select_value={(val) => View_PrintFormate(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iFormatId}
                displayName="strPrintTempName"
                disvalue="iPrintTempId"
                arrydata={arrPrintFormate}
                EmptVal="Formate"
                disabled={iScrId === 0 ? true : false}
              />
            </div>
            <div className="p-1">
              <CustomizedSelectoption
                optionparem="isoptionShow2"
                showoption={_parem.isoptionShow2}
                placeholder="Select Print Area"
                select_value={(val) => View_PrintColumns(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iAreaId}
                displayName="printAreaName"
                disvalue="printAreaId"
                arrydata={arrPrintArea}
                EmptVal="Print Area"
                disabled={iFormatId === 0 ? true : false}
              />
            </div>
            <div className="p-1">
              <div className="m-hsearch">
                <input
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder="Search Column"
                  value={strSearch}
                  onChange={(e) => SearcPrintColumn(e.target.value)}
                  autoFocus
                  style={{ height: "32px" }}
                />
                <i
                  className="fas fa-search"
                  onClick={(e) => SearcPrintColumn(strSearch)}
                ></i>
              </div>
            </div>
            {arrAreaColumn.map((dt, Index) => (
              <button
                key={Index}
                tabIndex={Index}
                onKeyDown={(e) => {
                  const arrlength = arrAreaColumn.length - 1;
                  if (e.key === "ArrowDown") {
                    if (Index === arrlength)
                      $(`.data-list [tabindex=${Index + 1}]`).focus();
                    else $(`.data-list [tabindex=${0}]`).focus();
                  }
                  if (e.key === "ArrowUp") {
                    if (Index === 0)
                      $(`.data-list [tabindex=${arrlength}]`).focus();
                    else $(`.data-list [tabindex=${Index - 1}]`).focus();
                  }
                  if (e.key === "Enter") {
                    Add_NewDesign(dt, Index);
                  }
                }}
              >
                <label onClick={(e) => Add_NewDesign(dt, Index)}>
                  {dt.displayName}
                  <sub className="danger ml-2">{dt.valueMember}</sub>
                </label>
              </button>
            ))}
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-4 col-sm-12 ">
                <label>Column Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={strColumName}
                  readOnly
                />
              </div>
              <div className="col-md-4 col-sm-12 ">
                <label>
                  Lable Name<span className="danger">*</span>
                </label>
                <input
                  id="LableName"
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Lable Name"
                  value={strLableName}
                  onChange={(e) => setstrLableName(e.target.value)}
                  disabled={islblreadonly}
                />
              </div>
              <div className="col-md-2 col-sm-4 ">
                <label>
                  Line At<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Height Line"
                  onClick={(e) => e.target.select()}
                  value={iLineAt}
                  onChange={(e) => setiLineAt(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-4 ">
                <label>
                  Start At<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Line Start"
                  onClick={(e) => e.target.select()}
                  value={iStartAt}
                  onChange={(e) => setiStartAt(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-4 ">
                <label>
                  Width<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter Line Width"
                  value={iWidth}
                  onChange={(e) => setiWidth(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = (0).toFixed(2);
                    }
                  }}
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>In Order</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  onClick={(e) => e.target.select()}
                  placeholder="Enter in Order To"
                  value={iInOrder}
                  onChange={(e) => setiInOrder(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>Prefix</label>
                <input
                  type="text"
                  aria-label="First name"
                  onClick={(e) => e.target.select()}
                  className="form-control"
                  placeholder="Enter Prefix Value"
                  value={strPrefix}
                  onChange={(e) => setstrPrefix(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>Suffix</label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Suffix Value"
                  onClick={(e) => e.target.select()}
                  value={strSuffix}
                  onChange={(e) => setstrSuffix(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 ">
                <label>
                  Color<span className="danger"></span>
                </label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow8"
                  showoption={_parem.isoptionShow8}
                  placeholder="Select Color"
                  select_value={(val) => setstrColor(val)}
                  defaultval={strColor}
                  arrydata={arrColor}
                  EmptVal="Color"
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>Font</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow7"
                  showoption={_parem.isoptionShow7}
                  placeholder="Select Font"
                  select_value={(val) => setstrFont(val)}
                  defaultval={strFont}
                  arrydata={arrFontFamily}
                  EmptVal="Font"
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>
                  Font Size<span className="danger">*</span>
                </label>
                <input
                  type="text"
                  onClick={(e) => e.target.select()}
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Font Size"
                  value={iFontSize}
                  onChange={(e) => setiFontSize(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>Alignment</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow6"
                  showoption={_parem.isoptionShow6}
                  placeholder="Select Text Align"
                  select_value={(val) => setstrAlignment(val)}
                  defaultval={strAlignment}
                  arrydata={arrTextAlignment}
                  EmptVal="Text Align"
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>Font Weight</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow4"
                  showoption={_parem.isoptionShow4}
                  placeholder="Select Font Weight"
                  select_value={(val) => setstrfontWeight(val)}
                  defaultval={strfontWeight}
                  arrydata={arrFontWeight}
                  EmptVal="Font Weight"
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>Font Style</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow3"
                  showoption={_parem.isoptionShow3}
                  placeholder="Select Font Style"
                  select_value={(val) => setstrFontSyle(val)}
                  defaultval={strFontSyle}
                  arrydata={arrFontStyle}
                  EmptVal="Font Style"
                />
              </div>
              <div className="col-md-2 col-sm-6 ">
                <label>Word Wrap</label>
                <CustomeIndexSelectOption
                  optionparem="isoptionShow5"
                  showoption={_parem.isoptionShow5}
                  placeholder="Select Word Wrap"
                  select_value={(val) => setstrWordWrab(val)}
                  defaultval={strWordWrab}
                  arrydata={arrWordWrab}
                  EmptVal="Word Wrap"
                />
              </div>
              <div className="table-card">
                <ReactTable
                  columns={DesignColumns}
                  data={arrColumDesign}
                  minRows={5}
                  row_click={(rowInfo, Index) => {}}
                  row_doubleclick={(rowInfo, Index) => {
                    Modify_Design(rowInfo);
                  }}
                  background={true}
                  className="option-list"
                />
              </div>
            </div>
          </div>
          {IsTempCopy && (
            <PrintTemplateCopy Close={(e) => setIsTempCopy(!IsTempCopy)} />
          )}
          {IsLineCopy && (
            <PrintLineCopy Close={(e) => setIsLineCopy(!IsLineCopy)} />
          )}
          {IsTemplateCreation && (
            <PrintTemplateCreation
              Close={(e) => setIsTemplateCreation(!IsTemplateCreation)}
            />
          )}

          {IsPreViewOpen && (
            <PrintScreen
              Close={(e) => setIsPreViewOpen(!IsPreViewOpen)}
              iScrId={iScrId}
              iFormateId={iFormatId}
            />
          )}
          {isImageUploadOpen && (
            <PrintImage
              Close={(e) => setisImageUploadOpen(!isImageUploadOpen)}
            />
          )}
          <Messagedialogbox />
          <div className="btn-section">
            <button className="btn-fabgreen" onClick={PrintInvoice}>
              Preview &nbsp; <i className="fas fa-print"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setIsTemplateCreation(!IsTemplateCreation)}
            >
              Template&nbsp;<i className="bx bx-plus"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setIsTempCopy(!IsTempCopy)}
            >
              Template Copy&nbsp;<i className="bx bx-copy"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => window.open(`/Others/PrintLineDesign`)}
            >
              Line&nbsp; <i className="fas fa-edit"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setIsLineCopy(!IsLineCopy)}
            >
              Line Copy&nbsp;<i className="bx bx-copy"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => setisImageUploadOpen(!isImageUploadOpen)}
            >
              Image&nbsp;
              <i className="fa fa-picture-o" aria-hidden="true"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (!islblreadonly && strLableName === "")
                  AlertMessage("Please Enter Lable Name", "info");
                else if (iLineAt === 0) {
                  AlertMessage("Line At Required", "info");
                } else if (iStartAt === 0) {
                  AlertMessage("Start At Required", "info");
                } else if (strColumName === "") {
                  AlertMessage("Please Select Field", "info");
                } else {
                  Save_ColDesign();
                }
              }}
            >
              <i className="fa-solid fa-save"></i>
              Save&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearDesign}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/Dashboard")}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintMaster;
