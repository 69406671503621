import React, { useEffect, useState } from "react";
import ExportImport from "./ExportImport";
import UOMMaster from "./UOMMaster";
import Button from "./MasterButton";
import MasterList from "./MasterListItem";
import { userApp } from "../Admin/Utility";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CategoryMaster from "./CategoryMaster";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import MasterCodeSetting from "./MasterCodeSetting";
function ProductMaster() {
  const dispatch = useDispatch();
  const _parem = useSelector((state) => state.SelectOption.parems);
  const iScrId = 1;
  const [ManualCode, setManualCode] = useState(true);
  const [IsOpen, setIsOpen] = useState(false);
  const [IsCatOpen, setIsCatOpen] = useState(false);
  const [bIsTcsShow, setbIsTcsShow] = useState(false);
  const [ExpImpOpen, setExpImpOpen] = useState(false);
  const [chkDecimalQtyYes, setchkDecimalQtyYes] = useState(true);
  const [chkStockMaintainYes, setchkStockMaintainYes] = useState(true);
  const [iProId, setiProId] = useState(0);
  const [strProCode, setstrProCode] = useState("");
  const [strProName, setstrProName] = useState("");
  const [strHSNCode, setstrHSNCode] = useState("");
  const [strPrintName, setstrPrintName] = useState("");
  const [strRemarks, setstrRemarks] = useState("");
  const [strUnitName, setstrUnitName] = useState("");
  const [strPurchaseUnitValue, setstrPurchaseUnitValue] = useState(0);
  const [strSalesUnitValue, setstrSalesUnitValue] = useState(0);
  const [dCostRate, setdCostRate] = useState(0);
  const [dPriceRate, setdPriceRate] = useState(0);
  const [dDiscount, setdDiscount] = useState(0);
  const [dMinMargin, setdMinMargin] = useState(0);
  const [iProTypeId, setiProTypeId] = useState(1);
  const [iCat1Id, setiCat1Id] = useState(0);
  const [iCat2Id, setiCat2Id] = useState(0);
  const [iCat3Id, setiCat3Id] = useState(0);
  const [iStatusId, setiStatusId] = useState(1);
  const [iProGenreId, setiProGenreId] = useState(1);
  const [iSGSTTaxId, setiSGSTTaxId] = useState(8);
  const [iCGSTTaxId, setiCGSTTaxId] = useState(16);
  const [iIGSTTaxId, setiIGSTTaxId] = useState(21);
  const [iTcsTaxId, setiTcsTaxId] = useState(41);
  const [iTaxTypeId, setiTaxTypeId] = useState(1);
  const [iProDimId, setiProDimId] = useState(0);
  const [iStoDimId, setiStoDimId] = useState(0);
  const [iTraDimId, setiTraDimId] = useState(0);
  const [CategoryType, setCategoryType] = useState(1);
  const [strConcatAttNames, setstrConcatAttNames] = useState("");
  const [iProductCount, setiProductCount] = useState(0);
  const [listFilter, setlistFilter] = useState([]);
  const [listCat1, setlistCat1] = useState([]);
  const [listCat2, setlistCat2] = useState([]);
  const [listCat3, setlistCat3] = useState([]);
  const [listProGenre, setlistProGenre] = useState([]);
  const [listStatus, setlistStatus] = useState([]);
  const [listCodeFormat, setlistCodeFormat] = useState([]);
  const [listSGST, setlistSGST] = useState([]);
  const [listCGST, setlistCGST] = useState([]);
  const [listIGST, setlistIGST] = useState([]);
  const [listTaxType, setlistTaxType] = useState([]);
  const [listProDim, setlistProDim] = useState([]);
  const [listStoDim, setlistStoDim] = useState([]);
  const [listTraDim, setlistTraDim] = useState([]);
  const [listProduct, setlistProduct] = useState([]);
  const [listProType, setlistProType] = useState([]);
  const [listUnit, setlistUnit] = useState([]);
  const [ListTcsTax, setListTcsTax] = useState([]);
  const [strProDescription1, setstrProDescription1] = useState("");
  const [strProDescription2, setstrProDescription2] = useState("");
  const [ListValidateProperties, setListValidateProperties] = useState([]);

  const [bIsShowPrintName, setbIsShowPrintName] = useState(false);
  const [bIsShowHsn, setbIsShowHsn] = useState(false);
  const [bIsShowRemark, setbIsShowRemark] = useState(false);
  const [bIsShowCatg1, setbIsShowCatg1] = useState(false);
  const [bIsShowCatg2, setbIsShowCatg2] = useState(false);
  const [bIsShowCatg3, setbIsShowCatg3] = useState(false);
  const [bIsShowStatus, setbIsShowStatus] = useState(false);
  const [bIsShowProTyp, setbIsShowProTyp] = useState(false);
  const [bIsShowProgen, setbIsShowProgen] = useState(false);
  const [bIsShowProDim, setbIsShowProDim] = useState(false);
  const [bIsShowStoDim, setbIsShowStoDim] = useState(false);
  const [bIsShowTracDim, setbIsShowTracDim] = useState(false);
  const [IsMasterCode, setIsMasterCode] = useState(false);

  useEffect(() => {
    Load_ProductMaster();
  }, []);
  const Load_ProductMaster = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await userApp
        .post(`ProductMaster/Load_ProductMaster`, objParems)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setlistFilter(Res.data.objEntCmbFilter);
            setlistCat1(Res.data.objEntCat1);
            setlistCat2(Res.data.objEntCat2);
            setlistCat3(Res.data.objEntCat3);
            setlistProType(Res.data.objEntProType);
            setlistProGenre(Res.data.objEntProGenre);
            setlistStatus(Res.data.objEntStatus);
            setlistCodeFormat(Res.data.objEntCodeFormat);
            setListValidateProperties(Res.data.Transtab);
            setManualCode(!Res.data.objEntCodeFormat[0].isManualCode);
            setlistTaxType(Res.data.objEntTaxType);
            setlistSGST(Res.data.objEntSGSTTax);
            setlistCGST(Res.data.objEntCGSTTax);
            setlistIGST(Res.data.objEntIGSTTax);
            setlistProDim(Res.data.objEntProDim);
            setlistStoDim(Res.data.objEntStoDim);
            setlistTraDim(Res.data.objEntTraDim);
            setlistProduct(Res.data.objEntProductList);
            setstrProCode(Res.data.objEntCodeFormat[0].strCode);
            setdCostRate((0).toFixed(2));
            setdPriceRate((0).toFixed(2));
            setdDiscount((0).toFixed(2));
            setdMinMargin((0).toFixed(2));
            var objEntField = Res.data.FieldPer;
            setbIsShowPrintName(objEntField[0].bIsVissible);
            setbIsShowHsn(objEntField[1].bIsVissible);
            setbIsShowRemark(objEntField[2].bIsVissible);
            setbIsShowCatg1(objEntField[3].bIsVissible);
            setbIsShowCatg2(objEntField[4].bIsVissible);
            setbIsShowCatg3(objEntField[5].bIsVissible);
            setbIsShowProTyp(objEntField[6].bIsVissible);
            setbIsShowProgen(objEntField[7].bIsVissible);
            setbIsShowStatus(objEntField[8].bIsVissible);
            setbIsShowProDim(objEntField[9].bIsVissible);
            setbIsShowStoDim(objEntField[10].bIsVissible);
            setbIsShowTracDim(objEntField[11].bIsVissible);
            if (Res.data.objUnit !== undefined) {
              setlistUnit(Res.data.objUnit);
              setstrUnitName(Res.data.strUnitName);
              CheckUnitValue(Res.data.objUnit);
            }
            TabOrderAssign(Res.data.Transtab);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const SaveClick = async () => {
    try {
      if (listUnit.length === 0) {
        AlertMessage("", "Please Select Unit", "error");
      } else {
        if (MinMarginValidation()) {
          await dispatch(setload_Screen(true));
          const listProdcutMaster = {
            iTitleTag: iScrId,
            iProId: iProId,
            iNumSeqNo: listCodeFormat[0].iNumSeqNo,
            strPrefix: listCodeFormat[0].strPrefix,
            strProCode: strProCode,
            strProName: strProName,
            strRemark: strRemarks,
            strPrintName: strPrintName,
            strCommodityCode: strHSNCode,
            iCat1Id: iCat1Id,
            iCat2Id: iCat2Id,
            iCat3Id: iCat3Id,
            iStatusId: iStatusId,
            iProTypeId: iProTypeId,
            iProGenreId: iProGenreId,
            iProDimId: Number(iProDimId),
            iStoDimId: Number(iStoDimId),
            iTraDimId: Number(iTraDimId),
            dPriceRate: Number(dPriceRate),
            dMinMargin: Number(dMinMargin),
            dCostRate: Number(dCostRate),
            dDiscount: Number(dDiscount),
            iTaxTypeId: Number(iTaxTypeId),
            iSGSTTaxId: Number(iSGSTTaxId),
            iCGSTTaxId: Number(iCGSTTaxId),
            iIGSTTaxId: Number(iIGSTTaxId),
            bQtyDecimal: chkDecimalQtyYes,
            bStockMaintain: chkStockMaintainYes,
            strConcatAttNames: strConcatAttNames,
            strAll: "",
            strAttNames: "",
            strCat1Name: "",
            strCat2Name: "",
            strCat3Name: "",
            strFilter: "",
            strFilterColumn: "",
            strMasterProCodeTag: "",
            strProCodeTag: "",
            strProDescription1: "",
            strProDescription2: "",
            strProGenreName: "",
            strProTypeName: "",
            strSearch: "",
            strStatusName: "",
            strUnitName: "",
          };
          const ProductDescription = [
            {
              iProDescId: 0,
              iProId: iProId,
              strProDescription1: strProDescription1,
              strProDescription2: strProDescription2,
            },
          ];
          const InserProduct = {
            objEntProMaster: listProdcutMaster,
            objEntUOM: listUnit,
            objEntProDesc: ProductDescription,
            strCompanyDB: localStorage.db,
          };
          await userApp
            .post("ProductMaster/InsUpd_Product/", InserProduct)
            .then((Res) => {
              if (Res.data.strStatus === "Success") {
                AlertMessage(Res.data.strMessage, "success");
                ClearClick();
              } else {
                AlertMessage(Res.data.strMessage, "error");
              }
            });
        } else {
          AlertMessage("Price Rate Less Then MinMargin Not Allowed", "error");
        }
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_Product = async (ProId) => {
    try {
      setiProId(ProId);
      await dispatch(setload_Screen(true));
      await userApp
        .get(`ProductMaster/View_Product/${ProId}/${localStorage.db}`)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setlistUnit(Res.data.objEntUOM);
            CheckUnitValue(Res.data.objEntUOM);
            setstrUnitName(Res.data.strUnitName);
            const EntPro = Res.data.objEntProDetail[0];
            setstrProCode(EntPro.strProCode);
            setstrProName(EntPro.strProName);
            setstrHSNCode(EntPro.strCommodityCode);
            setstrPrintName(EntPro.strPrintName);
            setstrRemarks(EntPro.strRemark);
            setiCat1Id(EntPro.iCat1Id);
            setiCat2Id(EntPro.iCat2Id);
            setiCat3Id(EntPro.iCat3Id);
            setdCostRate(EntPro.dCostRate.toFixed(2));
            setdPriceRate(EntPro.dPriceRate.toFixed(2));
            setdMinMargin(EntPro.dMinMargin.toFixed(2));
            setdDiscount(EntPro.dDiscount.toFixed(2));
            setchkDecimalQtyYes(EntPro.bQtyDecimal);
            setchkStockMaintainYes(EntPro.bStockMaintain);
            setiProGenreId(EntPro.iProGenreId);
            setiStatusId(EntPro.iStatusId);
            setiProTypeId(EntPro.iProTypeId);
            setiSGSTTaxId(EntPro.iSGSTTaxId);
            setiCGSTTaxId(EntPro.iCGSTTaxId);
            setiIGSTTaxId(EntPro.iIGSTTaxId);
            setiProDimId(EntPro.iProDimId);
            setiStoDimId(EntPro.iStoDimId);
            setiTraDimId(EntPro.iTraDimId);
            var AttName =
              EntPro.strConcatAttNames === null ? "" : EntPro.strConcatAttNames;
            setstrConcatAttNames(AttName);
            if (Res.data.objProDescription.length > 0) {
              setstrProDescription1(
                Res.data.objProDescription[0].strProDescription1
              );
              setstrProDescription2(
                Res.data.objProDescription[0].strProDescription2
              );
            }
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = () => {
    try {
      setstrPurchaseUnitValue(0);
      setstrSalesUnitValue(0);
      setchkDecimalQtyYes(true);
      setbIsTcsShow(false);
      setchkStockMaintainYes(true);
      setiProId(0);
      setlistUnit([]);
      setstrProName("");
      setstrHSNCode("");
      setstrPrintName("");
      setstrRemarks("");
      setstrUnitName("");
      setdCostRate((0).toFixed(2));
      setdPriceRate((0).toFixed(2));
      setdDiscount((0).toFixed(2));
      setdMinMargin((0).toFixed(2));
      setiProTypeId(1);
      setiCat1Id(0);
      setiCat2Id(0);
      setiCat3Id(0);
      setiStatusId(1);
      setiProGenreId(1);
      setiSGSTTaxId(8);
      setiCGSTTaxId(16);
      setiIGSTTaxId(21);
      setiTcsTaxId(21);
      setiTaxTypeId(1);
      setiProDimId(0);
      setiStoDimId(0);
      setiTraDimId(0);
      setstrConcatAttNames("");
      setiProductCount(0);
      Load_ProductMaster();
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const DeleteClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .post(`ProductMaster/Delete_Product/${iProId}/${localStorage.db}`)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            ClearClick();
            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Search_Product = async (strtxt) => {
    try {
      await userApp
        .post("ProductMaster/Search_Product", {
          strColName: "All",
          strSearch: strtxt,
          strCompanyDB: localStorage.db,
        })
        .then((Res) => {
          if (Res.data.strStatus === "Success")
            setlistProduct(Res.data.EntProduct);
          else AlertMessage(Res.data.strMessage, "error");
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxLevelChange = async (TaxLevel) => {
    try {
      await setiTaxTypeId(TaxLevel);
      await userApp
        .get(`TaxMaster/TaxLevelChange/${Number(TaxLevel)}/${localStorage.db}`)
        .then((Res) => {
          if (Number(TaxLevel) === 1) {
            setlistSGST(Res.data.SGSTTax);
            setlistCGST(Res.data.CGSTTax);
            setlistIGST(Res.data.IGSTTax);
            setbIsTcsShow(false);
          } else {
            setListTcsTax(Res.data.TcsTax);
            setbIsTcsShow(true);
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const TaxOnChange = (objtax) => {
    let dTaxPer = Number(objtax.dTaxPer);
    var TaxType = objtax.strTaxDesc.split(" ")[0];
    switch (TaxType) {
      case "SGST":
        setiSGSTTaxId(objtax.iTaxId);
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      case "CGST":
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer)[0].iTaxId
        );
        setiCGSTTaxId(objtax.iTaxId);
        setiIGSTTaxId(
          listIGST.filter((Data) => Data.dTaxPer === dTaxPer * 2)[0].iTaxId
        );
        break;
      default:
        setiIGSTTaxId(objtax.iTaxId);
        setiSGSTTaxId(
          listSGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        setiCGSTTaxId(
          listCGST.filter((Data) => Data.dTaxPer === dTaxPer / 2)[0].iTaxId
        );
        break;
    }
  };
  const [ListTabOrder, setListTabOrder] = useState([
    {
      ProCodeTab: "",
      ProNTab: "",
      HsnTab: "",
      Cat1tap: "",
      Cat2tap: "",
      Cat3tap: "",
      Unittab: "",
      PrintNtab: "",
      Remarktab: "",
      MinMartab: "",
      Disctab: "",
      PurRtab: "",
      SalRtab: "",
      Stutab: "",
      StockMtab: "",
      Decimtab: "",
      Protytab: "",
      Progentab: "",
      Taxtytab: "",
      Cgsttab: "",
      Igsttab: "",
      Sgsttab: "",
      ProductListTab: "",
      ProductFIlterTab: "",
      ProductSearchTab: "",
      BtnSavtab: "",
      BtnCleartab: "",
      BtnDeletetab: "",
      BtnClosetab: "",
      BtnExporttab: "",
    },
  ]);
  const TabOrderAssign = (EntProperties) => {
    let TabOrder = ListTabOrder;
    EntProperties = EntProperties.filter((Data) => Data.isTabStop === true);
    for (var i = 0; i < EntProperties.length; i++) {
      switch (EntProperties[i].strCtrlName) {
        case "txtProductCode":
          TabOrder[0].ProCodeTab = EntProperties[i].iTabIndex;
          break;
        case "txtProductName":
          TabOrder[0].ProNTab = EntProperties[i].iTabIndex;
          break;
        case "txtCommodity":
          TabOrder[0].HsnTab = EntProperties[i].iTabIndex;
          break;
        case "cbCategory1":
          TabOrder[0].Cat1tap = EntProperties[i].iTabIndex;
          break;
        case "cbCategory2":
          TabOrder[0].Cat2tap = EntProperties[i].iTabIndex;
          break;
        case "cbCategory3":
          TabOrder[0].Cat3tap = EntProperties[i].iTabIndex;
          break;
        case "txtUnit":
          TabOrder[0].Unittab = EntProperties[i].iTabIndex;
          break;
        case "txtPrintName":
          TabOrder[0].PrintNtab = EntProperties[i].iTabIndex;
          break;
        case "txtRemarks":
          TabOrder[0].Remarktab = EntProperties[i].iTabIndex;
          break;
        case "txtMinMargin":
          TabOrder[0].MinMartab = EntProperties[i].iTabIndex;
          break;
        case "txtDiscount":
          TabOrder[0].Disctab = EntProperties[i].iTabIndex;
          break;
        case "txtCostRate":
          TabOrder[0].PurRtab = EntProperties[i].iTabIndex;
          break;
        case "txtPriceRate":
          TabOrder[0].SalRtab = EntProperties[i].iTabIndex;
          break;
        case "cbStatus":
          TabOrder[0].Stutab = EntProperties[i].iTabIndex;
          break;
        case "chkStockMaintain":
          TabOrder[0].StockMtab = EntProperties[i].iTabIndex;
          break;
        case "chkQtyYes":
          TabOrder[0].Decimtab = EntProperties[i].iTabIndex;
          break;
        case "cbProType":
          TabOrder[0].Protytab = EntProperties[i].iTabIndex;
          break;
        case "cbProGenre":
          TabOrder[0].Progentab = EntProperties[i].iTabIndex;
          break;
        case "cbTaxType":
          TabOrder[0].Taxtytab = EntProperties[i].iTabIndex;
          break;
        case "cbCgst":
          TabOrder[0].Cgsttab = EntProperties[i].iTabIndex;
          break;
        case "cbIgst":
          TabOrder[0].Igsttab = EntProperties[i].iTabIndex;
          break;
        case "cbSgst":
          TabOrder[0].Sgsttab = EntProperties[i].iTabIndex;
          break;
        case "btnSave":
          TabOrder[0].BtnSavtab = EntProperties[i].iTabIndex;
          break;
        case "btnClear":
          TabOrder[0].BtnCleartab = EntProperties[i].iTabIndex;
          break;
        case "btnDelete":
          TabOrder[0].BtnDeletetab = EntProperties[i].iTabIndex;
          break;
        case "btnClose":
          TabOrder[0].BtnClosetab = EntProperties[i].iTabIndex;
          break;
        case "btnExportImport":
          TabOrder[0].BtnExporttab = EntProperties[i].iTabIndex;
          break;
        case "cbFilter":
          TabOrder[0].ProductFIlterTab = EntProperties[i].iTabIndex;
          break;
        case "txtSearch":
          TabOrder[0].ProductSearchTab = EntProperties[i].iTabIndex;
          break;
        case "lbProductName":
          TabOrder[0].ProductListTab = EntProperties[i].iTabIndex;
          break;
        default:
          break;
      }
    }
    var maxTab = EntProperties.reduce(
      (prev, current) => (prev.iTabIndex > current.iTabIndex ? prev : current),
      0
    ); //returns object
    sessionStorage.maxTab = maxTab.iTabIndex;
    setListTabOrder(TabOrder);
  };
  const TabIndex = (e) => {
    var maxTab = Number(sessionStorage.maxTab);
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < maxTab) {
        if (ActiveIndex + 1 === ListTabOrder[0].ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex + 1) + "']").select();
        }
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 0 && ActiveIndex <= maxTab) {
        if (ActiveIndex - 1 === ListTabOrder[0].ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
          $("[tabindex='" + (ActiveIndex - 1) + "']").select();
        }
      } else {
        if (maxTab - 1 === ListTabOrder[0].ProductListTab) {
          $(".react-table .rt-tbody .rt-tr:eq(0)").focus();
        } else {
          $("[tabindex='" + (maxTab - 1) + "']").focus();
          $("[tabindex='" + (maxTab - 1) + "']").select();
        }
      }
    }
  };
  const PropertiesValidation = () => {
    var ErrorMsg = true;
    let ValidateItem = ListValidateProperties.filter(
      (Data) => Data.isValidate === true
    );
    for (var i = 0; i < ValidateItem.length; i++) {
      switch (ValidateItem[i].strCtrlName) {
        case "txtProductName":
          ErrorMsg = strProName === "" ? false : true;
          break;
        case "txtPrintName":
          ErrorMsg = strPrintName === "" ? false : true;
          break;
        case "txtRemarks":
          ErrorMsg = strRemarks === "" ? false : true;
          break;
        case "cbCategory1":
          ErrorMsg = Number(iCat1Id) === 0 ? false : true;
          break;
        case "cbCategory2":
          ErrorMsg = Number(iCat2Id) === 0 ? false : true;
          break;
        case "cbCategory3":
          ErrorMsg = Number(iCat3Id) === 0 ? false : true;
          break;
        case "txtCommodity":
          ErrorMsg = strHSNCode === "" ? false : true;
          break;
        case "txtCostRate":
          ErrorMsg = Number(dCostRate) === 0 ? false : true;
          break;
        case "txtMinMargin":
          ErrorMsg = Number(dMinMargin) === 0 ? false : true;
          break;
        case "txtPriceRate":
          ErrorMsg = Number(dPriceRate) === 0 ? false : true;
          break;
        case "txtDiscount":
          ErrorMsg = Number(dDiscount) === 0 ? false : true;
          break;
        case "cbSgst":
          ErrorMsg = Number(iSGSTTaxId) === 0 ? false : true;
          break;
        case "cbIgst":
          ErrorMsg = Number(iIGSTTaxId) === 0 ? false : true;
          break;
        case "cbCgst":
          ErrorMsg = Number(iCGSTTaxId) === 0 ? false : true;
          break;
        case "cbTaxType":
          ErrorMsg = Number(iTaxTypeId) === 0 ? false : true;
          break;
        default:
          break;
      }
      if (ErrorMsg === false) {
        AlertMessage(ValidateItem[i].strErrorMsg, "error");
        return ErrorMsg;
      }
    }
    return ErrorMsg;
  };
  const GetUnitDetails = (DefaultUnit, UnitName) => {
    try {
      setIsOpen(!IsOpen);
      setlistUnit(DefaultUnit);
      setstrUnitName(UnitName);
      CheckUnitValue(DefaultUnit);
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const CheckUnitValue = (UnitList) => {
    var PurcahseValue = UnitList.filter(
      (Data) => Data.bPurchaseDefault === true
    );
    var SalesValue = UnitList.filter((Data) => Data.bSalesDefault === true);
    if (UnitList.length > 1) {
      userApp
        .get(
          "UnitMaster/GetUnit/" +
            PurcahseValue[0].iUnitId +
            "/" +
            localStorage.db
        )
        .then((Res) => {
          setstrPurchaseUnitValue(Res.data.dConvertValue);
        });
    }
    if (SalesValue.length > 0) {
      userApp
        .get(
          "UnitMaster/GetUnit/" + SalesValue[0].iUnitId + "/" + localStorage.db
        )
        .then((Res) => {
          setstrSalesUnitValue(Res.data.dConvertValue);
        });
    }
  };
  const MinMarginValidation = () => {
    var MinMarginCheck = true;
    var PurchaseRate = Number(dCostRate);
    var SalesRate = Number(dPriceRate);
    var MinMargin = Number(dMinMargin);
    if (PurchaseRate > 0 && SalesRate > 0) {
      MinMarginCheck = false;
      if (listUnit.length > 1) {
        PurchaseRate = PurchaseRate / Number(strPurchaseUnitValue);
        SalesRate = SalesRate / Number(strSalesUnitValue);
        MinMargin = PurchaseRate + (PurchaseRate * MinMargin) / 100;
        if (MinMargin <= SalesRate) MinMarginCheck = true;
      } else {
        PurchaseRate = PurchaseRate / Number(strSalesUnitValue);
        SalesRate = SalesRate / Number(strSalesUnitValue);
        MinMargin = PurchaseRate + (PurchaseRate * MinMargin) / 100;
        if (MinMargin <= SalesRate) MinMarginCheck = true;
      }
    }
    return MinMarginCheck;
  };
  const CategoryClose = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objParems = {
        iScrId: iScrId,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await userApp
        .post(`ProductMaster/Load_ProductMaster`, objParems)
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            setlistCat1(Res.data.objEntCat1);
            setlistCat2(Res.data.objEntCat2);
            setlistCat3(Res.data.objEntCat3);
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <MasterList
          ComBoxValue={listFilter}
          EntList={listProduct}
          GetDetails={View_Product}
          Count={iProductCount}
          Search={Search_Product}
          ScreenID={iScrId}
          accessor="strProName"
          accessorid="iProId"
          ListTab={ListTabOrder[0].ProductListTab}
          FilterTab={ListTabOrder[0].ProductFIlterTab}
          SearchTab={ListTabOrder[0].ProductSearchTab}
          onKeyDown={(e) => TabIndex(e)}
        />
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label>
                  Name <span className="danger">*</span>
                </label>
                <input
                  type="text"
                  aria-label="First name"
                  className="form-control"
                  placeholder="Enter Product Name"
                  name="strProName"
                  id="txtProductName"
                  autoComplete="off"
                  value={strProName}
                  onChange={(e) => setstrProName(e.target.value)}
                  autoFocus
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].ProNTab}
                  spellCheck={false}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label>
                  Code <span className="danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    id="txtProductCode"
                    name="strProCode"
                    autoComplete="off"
                    onKeyDown={(e) => TabIndex(e)}
                    value={strProCode}
                    tabIndex={ListTabOrder[0].ProCodeTab}
                    readOnly={ManualCode}
                    onChange={(e) => setstrProCode(e.target.value)}
                  />
                  <span
                    className="inv-no"
                    onClick={(e) => setIsMasterCode(!IsMasterCode)}
                  >
                    <i className="bx bx-cog"></i>
                  </span>
                </div>
              </div>
              {bIsShowHsn && (
                <div className="col-md-3 col-sm-6">
                  <label>Commodity/HSN</label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Commodity Details"
                    id="txtCommodity"
                    autoComplete="off"
                    name="strHSNCode"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].HsnTab}
                    value={strHSNCode}
                    onChange={(e) => setstrHSNCode(e.target.value)}
                  />
                </div>
              )}
              {bIsShowPrintName && (
                <div className="col-md-6 col-sm-12">
                  <label>Print Name </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    tabIndex={ListTabOrder[0].PrintNtab}
                    placeholder="Enter Print Name"
                    id="txtPrintName"
                    name="strPrintName"
                    autoComplete="off"
                    onKeyDown={(e) => TabIndex(e)}
                    value={strPrintName}
                    onInput={(e) => setstrPrintName(e.target.value)}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-6">
                <label>Unit Name </label>
                <div className="custom-select">
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Unit Name"
                    id="txtUnitName"
                    name="strUnitName"
                    autoComplete="off"
                    tabIndex={ListTabOrder[0].Unittab}
                    value={strUnitName}
                    onInput={(e) => setIsOpen(!IsOpen)}
                    onClick={() => setIsOpen(!IsOpen)}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: IsOpen ? "rotate(-180deg)" : "rotate(0deg)",
                      fontSize: "15px",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <label>Purchase Rate </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  id="txtcostRate"
                  name="dCostRate"
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].PurRtab}
                  value={dCostRate}
                  onKeyDown={(e) => TabIndex(e)}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdCostRate(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "")
                      setdCostRate(Number(0).toFixed(2));
                    else {
                      setdCostRate(Number(e.target.value).toFixed(2));
                      if (Number(dPriceRate) === 0)
                        setdPriceRate(Number(e.target.value).toFixed(2));
                    }
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-3">
                <label>Sales Rate </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  id="txtPriceRate"
                  autoComplete="off"
                  name="dPriceRate"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].SalRtab}
                  value={dPriceRate}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdPriceRate(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "")
                      setdPriceRate(Number(0).toFixed(2));
                    else setdPriceRate(Number(e.target.value).toFixed(2));
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-3">
                <label>Minimum Margin % </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  id="txtMinMargin"
                  name="dMinMargin"
                  autoComplete="off"
                  tabIndex={ListTabOrder[0].MinMartab}
                  onKeyDown={(e) => TabIndex(e)}
                  value={dMinMargin}
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdMinMargin(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "")
                      setdMinMargin(Number(0).toFixed(2));
                    else setdMinMargin(Number(e.target.value).toFixed(2));
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-3">
                <label>Discount % </label>
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  id="txtDiscRate"
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Disctab}
                  name="dDiscount"
                  value={dDiscount}
                  autoComplete="off"
                  onClick={(e) => e.target.select()}
                  onChange={(e) => setdDiscount(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === "")
                      setdDiscount(Number(0).toFixed(2));
                    else setdDiscount(Number(e.target.value).toFixed(2));
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-3">
                <label>Tax Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_parem.isoptionShow3}
                  placeholder="Select Tax Type"
                  taborder={ListTabOrder[0].Taxtytab}
                  keydown={(e) => TabIndex(e)}
                  select_value={(val) => TaxLevelChange(val)}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={iTaxTypeId}
                  displayName="strTaxLvlType"
                  disvalue="iTaxLvlId"
                  arrydata={listTaxType}
                  EmptVal="Tax Type "
                />
              </div>
              {!bIsTcsShow && (
                <div className="col-md-3 col-sm-3">
                  <label>SGST</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_parem.isoptionShow4}
                    placeholder="Select SGST Tax"
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iSGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={listSGST}
                    EmptVal="SGST "
                  />
                </div>
              )}
              {!bIsTcsShow && (
                <div className="col-md-3 col-sm-3">
                  <label>CGST</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_parem.isoptionShow5}
                    placeholder="Select CGST Tax"
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iCGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={listCGST}
                    EmptVal="CGST "
                  />
                </div>
              )}
              {!bIsTcsShow && (
                <div className="col-md-3 col-sm-3">
                  <label>IGST</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow6"
                    showoption={_parem.isoptionShow6}
                    placeholder="Select IGST Tax"
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => TaxOnChange(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={false}
                    defaultval={iIGSTTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={listIGST}
                    EmptVal="IGST "
                  />
                </div>
              )}
              {bIsTcsShow && (
                <div className="col-md-3 col-sm-3">
                  <label>TCS %</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow6"
                    showoption={_parem.isoptionShow6}
                    placeholder="Select TCS Tax"
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiTcsTaxId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iTcsTaxId}
                    displayName="strTaxDesc"
                    disvalue="iTaxId"
                    arrydata={ListTcsTax}
                    EmptVal="TCS "
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Stock Maintain</label>
                <br />
                <input
                  type="checkbox"
                  style={{
                    cursor: "pointer",
                    height: "15px",
                    width: "15px",
                  }}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].StockMtab}
                  checked={chkStockMaintainYes}
                  onChange={(e) => setchkStockMaintainYes(!chkStockMaintainYes)}
                />
              </div>
              <div className="col-md-3 col-sm-6 mt-2">
                <label>Decimal Qty</label>
                <br />
                <input
                  type="checkbox"
                  style={{
                    cursor: "pointer",
                    height: "15px",
                    width: "15px",
                  }}
                  onKeyDown={(e) => TabIndex(e)}
                  tabIndex={ListTabOrder[0].Decimtab}
                  checked={chkDecimalQtyYes}
                  onChange={(e) => setchkDecimalQtyYes(!chkDecimalQtyYes)}
                />
              </div>
              {bIsShowCatg1 && (
                <div className="col-md-3 col-sm-6">
                  <label>Category I</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_parem.isoptionShow}
                    placeholder="Select Cetegory"
                    taborder={ListTabOrder[0].Cat1tap}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCat1Id(val)}
                    btnname="Category 1"
                    btnshow={true}
                    show_popup={(e) => {
                      setIsCatOpen(!IsCatOpen);
                      setCategoryType("1");
                    }}
                    valueonly={true}
                    defaultval={iCat1Id}
                    displayName="strCatName"
                    disvalue="iCatId"
                    arrydata={listCat1}
                    EmptVal="Category 1 "
                  />
                </div>
              )}
              {bIsShowCatg2 && (
                <div className="col-md-3 col-sm-6">
                  <label>Category II</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_parem.isoptionShow1}
                    placeholder="Select Cetegory"
                    taborder={ListTabOrder[0].Cat2tap}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCat2Id(val)}
                    btnname="Category 2"
                    btnshow={true}
                    show_popup={(e) => {
                      setIsCatOpen(!IsCatOpen);
                      setCategoryType("2");
                    }}
                    valueonly={true}
                    defaultval={iCat2Id}
                    displayName="strCatName"
                    disvalue="iCatId"
                    arrydata={listCat2}
                    EmptVal="Category 2 "
                  />
                </div>
              )}
              {bIsShowCatg3 && (
                <div className="col-md-3 col-sm-6">
                  <label>Category III</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_parem.isoptionShow2}
                    placeholder="Select Cetegory"
                    taborder={ListTabOrder[0].Cat3tap}
                    keydown={(e) => TabIndex(e)}
                    select_value={(val) => setiCat3Id(val)}
                    btnname="Category 3"
                    btnshow={true}
                    show_popup={(e) => {
                      setIsCatOpen(!IsCatOpen);
                      setCategoryType("3");
                    }}
                    valueonly={true}
                    defaultval={iCat3Id}
                    displayName="strCatName"
                    disvalue="iCatId"
                    arrydata={listCat3}
                    EmptVal="Category 3 "
                  />
                </div>
              )}
              {bIsShowStatus && (
                <div className="col-md-3 col-sm-6">
                  <label>Status</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow7"
                    showoption={_parem.isoptionShow7}
                    placeholder="Select Status"
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].Stutab}
                    select_value={(val) => setiStatusId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStatusId}
                    displayName="strStatusName"
                    disvalue="iStatusId"
                    arrydata={listStatus}
                    EmptVal="Status "
                  />
                </div>
              )}
              {bIsShowProTyp && (
                <div className="col-md-3 col-sm-6">
                  <label>Product Type</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow8"
                    showoption={_parem.isoptionShow8}
                    placeholder="Select Product Type"
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].Protytab}
                    select_value={(val) => setiProTypeId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iProTypeId}
                    displayName="strProTypeName"
                    disvalue="iProTypeId"
                    arrydata={listProType}
                    EmptVal="Product Type "
                  />
                </div>
              )}
              {bIsShowProgen && (
                <div className="col-md-3 col-sm-6">
                  <label>Product Genre</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow9"
                    showoption={_parem.isoptionShow9}
                    placeholder="Select Product Genre"
                    keydown={(e) => TabIndex(e)}
                    taborder={ListTabOrder[0].Progentab}
                    select_value={(val) => setiProGenreId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iProGenreId}
                    displayName="strProGenreName"
                    disvalue="iProGenreId"
                    arrydata={listProGenre}
                    EmptVal="Product Genre "
                  />
                </div>
              )}
              {bIsShowRemark && (
                <div className="col-md-3 col-sm-6">
                  <label>Remarks </label>
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control"
                    placeholder="Enter Remarks"
                    id="txtRemarks"
                    name="strRemarks"
                    onKeyDown={(e) => TabIndex(e)}
                    tabIndex={ListTabOrder[0].Remarktab}
                    value={strRemarks}
                    onChange={(e) => setstrRemarks(e.target.value)}
                  />
                </div>
              )}
              {bIsShowProDim && (
                <div className="col-md-3 col-sm-6">
                  <label>Product Dimension</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow10"
                    showoption={_parem.isoptionShow10}
                    placeholder="Select Product Dimension"
                    select_value={(val) => setiProDimId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iProDimId}
                    displayName="strAttGroupName"
                    disvalue="iAttGroupId"
                    arrydata={listProDim}
                    EmptVal="Product Dimension "
                  />
                </div>
              )}
              {bIsShowStoDim && (
                <div className="col-md-3 col-sm-6">
                  <label>Storage Dimension</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow11"
                    showoption={_parem.isoptionShow11}
                    placeholder="Select Storage Dimension"
                    select_value={(val) => setiStoDimId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iStoDimId}
                    displayName="strAttGroupName"
                    disvalue="iAttGroupId"
                    arrydata={listStoDim}
                    EmptVal="Storage Dimension "
                  />
                </div>
              )}
              {bIsShowTracDim && (
                <div className="col-md-3 col-sm-6">
                  <label>Tracking Dimension</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow12"
                    showoption={_parem.isoptionShow12}
                    placeholder="Select Tracking Dimension"
                    select_value={(val) => setiTraDimId(val)}
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={iTraDimId}
                    displayName="strAttGroupName"
                    disvalue="iAttGroupId"
                    arrydata={listTraDim}
                    EmptVal="Tracking Dimension "
                  />
                </div>
              )}
            </div>
            {ExpImpOpen && (
              <ExportImport
                Close={(IsImported) => {
                  setExpImpOpen(!ExpImpOpen);
                  if (IsImported) ClearClick();
                }}
                iScrId={iScrId}
              />
            )}
            {IsOpen && (
              <UOMMaster
                Close={(e) => setIsOpen(!IsOpen)}
                GetDefautUnit={GetUnitDetails}
                ListUnit={listUnit}
                UnitName={strUnitName}
                ProId={iProId}
              />
            )}
            {IsCatOpen && (
              <CategoryMaster
                Close={(e) => {
                  setIsCatOpen(!IsCatOpen);
                  CategoryClose();
                }}
                Index={CategoryType}
              />
            )}
            {IsMasterCode && (
              <MasterCodeSetting
                Close={(e) => setIsMasterCode(!IsMasterCode)}
                iScrId={iScrId}
              />
            )}
            <Messagedialogbox />
          </div>
          <Button
            Save={(e) => {
              if (PropertiesValidation())
                if (iProId > 0) {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Update Product ?",
                      isdialog: true,
                    })
                  );
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Product ?",
                      isdialog: true,
                    })
                  );
                }
            }}
            SaveIndex={ListTabOrder[0].BtnSavtab}
            DeleteIndex={ListTabOrder[0].BtnDeletetab}
            CloseIndex={ListTabOrder[0].BtnClosetab}
            ClearIndex={ListTabOrder[0].BtnCleartab}
            ExportIndex={ListTabOrder[0].BtnExporttab}
            Export={() => setExpImpOpen(!ExpImpOpen)}
            onKeyDown={(e) => TabIndex(e)}
            Clear={ClearClick}
            ScrId={iScrId}
            Iid={iProId}
            Delete={(e) => {
              if (iProId === 0) {
                AlertMessage("Please Select Product", "info");
              } else {
                dispatch(
                  Show_MsgBox({
                    Button: DeleteClick,
                    Question: "Do You Want to Delete Product?",
                    isdialog: true,
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
export default ProductMaster;
