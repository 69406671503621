import { userApp} from "../Admin/Utility";

import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import PrintAccountsDesign from "./PrintAccounts";
import PrintInvoicDesign from "./PrintInvoice";
const Print_DesignCaller = async (
  ScrId,
  ScrName,
  ScrType,
  VoucherId,
  TempId,
  PrintType,
  dispatch,
  PrintShare
) => {
  var objEntPrint = undefined;
  try {
    VoucherId = VoucherId ? VoucherId : 0;
    await dispatch(setload_Screen(true));
    let URL = `PrintMaster/Print_Data/{0}/${ScrId}/${TempId}/${VoucherId}/${localStorage.db}`;
    if (ScrType === "Transaction") URL = URL.replace("{0}", "Transaction");
    else URL = URL.replace("{0}", "Accounts");
    await userApp.get(URL).then(async (Res) => {
      if (Res.data.strStatus === "Success") {
        if (ScrType === "Transaction")
          objEntPrint = await PrintInvoicDesign(
            Res.data,
            ScrId,
            PrintType,
            ScrName,
            PrintShare,
            dispatch
          );
        else
          objEntPrint = await PrintAccountsDesign(
            Res.data,
            ScrId,
            PrintType,
            ScrName,
            PrintShare,
            dispatch
          );
      } else
        await dispatch(
          Show_MsgBox({
            Message: Res.data.strMessage,
            Type: "info",
            isMsg: true,
          })
        );
    });
  } catch (e) {
    await dispatch(
      Show_MsgBox({
        Message: e.strMessage,
        Type: "info",
        isMsg: true,
      })
    );
  } finally {
    await dispatch(setload_Screen(false));
    if (PrintShare) return objEntPrint;
  }
};

export default Print_DesignCaller;
