import { useEffect, useState } from "react";
import SideMenu from "../General/SideMenu";
import { useDispatch } from "react-redux";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { userApp} from "../Admin/Utility";
import ReportTable from "./ReportTable";

function GeneralReports() {
  const dispatch = useDispatch();
  const [iRptId, setiRptId] = useState(0);
  const [iScrId, setiScrId] = useState(0);
  const [strPartyName, setstrPartyName] = useState("");
  const [iPartyid, setiPartyid] = useState(0);
  const [iTotalRows, setiTotalRows] = useState(0);
  const [RowPerPage, setRowPerPage] = useState(0);
  const [CurrPage, setCurrPage] = useState(1);
  const [strPartyScrColName, setstrPartyScrColName] = useState("");

  const [strFilterColName, setstrFilterColName] = useState("");
  const [strReportName, setstrReportName] = useState("General Reports");
  const [isPartyShow, setisPartyShow] = useState(false);
  const [iVenTypeId, setiVenTypeId] = useState(5);

  const [arrReportDesing, setarrReportDesing] = useState([]);
  const [arrReports, setarrReports] = useState([]);
  const [arrReportData, setarrReportData] = useState([]);
  const [arrSummarydata, setarrSummarydata] = useState([]);
  const [arrParty, setarrParty] = useState([]);

  const [strSearch, setstrSearch] = useState("");
  const [strcolSearch, setstrcolSearch] = useState("");
  const [isPartyoption, setisPartyoption] = useState(false);

  useEffect(() => {
    Load_Reports("", "");
  }, []);
  const Load_Reports = async (strtxt, Load) => {
    try {
      if (Load) await dispatch(setload_Screen(true));
      setstrSearch(strtxt);
      const objSearch = {
        iScrId: 0,
        iPartyId: 0,
        strColName: "Report",
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iClientId: Number(localStorage.ClientId),
        iUserId: Number(localStorage.UserId),
        iRoleId: Number(localStorage.RoleId),
      };
      await userApp.post(`Reports/Load_Reports/`, objSearch).then(async (Res) => {
        if (Res.data.strStatus === "Success") {
          await setarrReports(Res.data.objEntRpt);
        } else {
          await AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      await AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_Report = async (iId, strName) => {
    try {
      await dispatch(setload_Screen(true));
      setstrReportName(strName);
      await setiPartyid(0);
      await setiTotalRows(0);
      await setRowPerPage(0);
      await setstrPartyName("");
      setiRptId(Number(iId));
      if (!fnOpenParty(iId)) {
        setisPartyShow(false);
        const objSearch = {
          iRptId: Number(iId),
          iPartyId: iPartyid,
          strColName: "",
          strPartyName: strPartyName,
          strSearch: "",
          strCompanyDB: localStorage.db,
          iUserId: Number(localStorage.UserId),
          CurrtPage: 1,
        };
        await userApp.post(`Reports/Load_GeneralReports/`, objSearch).then(
          (Res) => {
            if (Res.data.strStatus === "Success") {
              let RptDesign = Res.data.EntDesign.sort(
                (a, b) => a.inOrderTo - b.inOrderTo
              );
              RptDesign = RptDesign.filter((rdt) => rdt.isShowInReport);
              setarrReportDesing(RptDesign);
              let RptData = JsontoArray(Res.data.EntReport);
              setarrReportData(RptData);
              setstrFilterColName(Res.data.strSearchCol);
              setiTotalRows(Res.data.TotalRows);
              setRowPerPage(Res.data.RowPerPage);
              if (Res.data.EntSummary !== undefined) {
                let Summary = JSON.parse(Res.data.EntSummary);
                if (Summary.length > 0) {
                  setarrSummarydata(Summary);
                }
              } else {
                setarrSummarydata([]);
              }
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          }
        );
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Search_Report = async (strtxt, strFilterCol) => {
    try {
      await dispatch(setload_Screen(true));
      const objSearch = {
        iRptId: Number(iRptId),
        iPartyId: iPartyid,
        strColName: strFilterCol,
        strPartyName: strPartyName,
        strSearch: strtxt,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
        CurrtPage: 1,
      };
      await userApp.post(`Reports/Load_GeneralReports/`, objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          let RptData = JsontoArray(Res.data.EntReport);
          setarrReportData(RptData);
          if (RptData.length > 0 && Res.data.EntSummary !== undefined) {
            let Summary = JSON.parse(Res.data.EntSummary);
            if (Summary.length > 0) {
              setarrSummarydata(Summary);
            }
          }
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const View_PageReport = async (Page) => {
    try {
      await dispatch(setload_Screen(true));
      setCurrPage(Page);
      const objSearch = {
        iRptId: Number(iRptId),
        iPartyId: iPartyid,
        strColName: strFilterColName,
        strPartyName: strPartyName,
        strSearch: "",
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
        CurrtPage: Page,
      };
      await userApp.post(`Reports/Load_GeneralReports/`, objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          let RptData = JsontoArray(Res.data.EntReport);
          setarrReportData(RptData);
          if (RptData.length > 0 && Res.data.EntSummary !== undefined) {
            let Summary = JSON.parse(Res.data.EntSummary);
            if (Summary.length > 0) {
              setarrSummarydata(Summary);
            }
          }
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const fnOpenParty = (RptId) => {
    let isOpen = false;
    let strColName = "";
    let ScrId = 0;
    let VenType = 0;
    switch (Number(RptId)) {
      case 94:
      case 96:
      case 97:
        strColName = "SuppName";
        ScrId = 3;
        VenType = 1;
        isOpen = true;
        break;
      case 95:
      case 100:
      case 101:
        strColName = "CustName";
        ScrId = 2;
        VenType = 4;
        isOpen = true;
        break;
      case 102:
        strColName = "VenName";
        ScrId = 50;
        VenType = 5;
        isOpen = true;
        break;
      default:
        break;
    }
    if (isOpen) {
      setstrPartyScrColName(strColName);
      setiScrId(ScrId);
      setiVenTypeId(VenType);
      setiRptId(Number(RptId));
      setstrFilterColName("");
      setiPartyid(0);
      setstrPartyName("");
      setstrcolSearch("");
      setarrReportData([]);
      setarrReportDesing([]);
      setarrReportData([]);
      setarrReportDesing([]);
      setarrParty([]);
      setisPartyShow(true);
      setisPartyoption(true);
      Search_Party("", strColName, ScrId, VenType);
    }
    return isOpen;
  };
  const View_VendoreOutstanding = async (objparty) => {
    try {
      await dispatch(setload_Screen(true));
      setisPartyoption(!isPartyoption);
      setiPartyid(Number(objparty.partyId));
      setstrPartyName(objparty.partyName);
      const objSearch = {
        iRptId: Number(iRptId),
        iPartyId: Number(objparty.partyId),
        strColName: strFilterColName,
        strPartyName: objparty.partyName,
        strSearch: strSearch,
        strCompanyDB: localStorage.db,
        strMasterDB: localStorage.Msdb,
        iUserId: Number(localStorage.UserId),
        CurrtPage: 1,
      };
      await userApp.post(`Reports/Load_GeneralReports/`, objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          let RptDesign = Res.data.EntDesign.sort(
            (a, b) => a.inOrderTo - b.inOrderTo
          );
          setarrReportDesing(RptDesign);
          let RptData = JsontoArray(Res.data.EntReport);
          setarrReportData(RptData);
          setstrFilterColName(Res.data.strSearchCol);
          if (RptData.length > 0 && Res.data.EntSummary !== undefined) {
            let Summary = JSON.parse(Res.data.EntSummary);
            if (Summary.length > 0) {
              setarrSummarydata(Summary);
            }
          }
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Msg, type) => {
    dispatch(Show_MsgBox({ Message: Msg, Type: type, isMsg: true }));
  };
  const Search_Party = async (SearchText, ColName, ScrId, VenType) => {
    try {
      setstrSearch(SearchText);
      const objSearch = {
        iScrId: ScrId !== undefined ? ScrId : iScrId,
        iPartyId: VenType !== undefined ? VenType : iVenTypeId,
        strColName: ColName !== undefined ? ColName : strPartyScrColName,
        strSearch: SearchText,
        strCompanyDB: localStorage.db,
        iUserId: Number(localStorage.UserId),
      };
      await userApp.post(`Transaction/Search_Party`, objSearch).then((res) => {
        if (res.data.strStatus === "success") {
          setarrParty(res.data.EntParty);
        } else
          dispatch(
            Show_MsgBox({
              Message: res.data.strMessage,
              Type: "info",
              isMsg: true,
            })
          );
      });
    } catch (err) {
      dispatch(Show_MsgBox({ Message: err, Type: "error", isMsg: true }));
    }
  };
  const JsontoArray = (DataArray) => {
    return DataArray !== null ? JSON.parse(DataArray) : [];
  };
  return (
    <>
      <SideMenu ScrId={101} />
      <ReportTable
        ReportData={arrReportData}
        ReportDesing={arrReportDesing}
        EntReports={arrReports}
        View_Report={View_Report}
        Load_Reports={Load_Reports}
        Search_Report={Search_Report}
        View_VendoreOutstanding={View_VendoreOutstanding}
        Search_Party={Search_Party}
        EntSum={arrSummarydata}
        iRptId={iRptId}
        strReportName={strReportName}
        EntParty={arrParty}
        IsPartyOption={isPartyoption}
        IsParty={isPartyShow}
        Party_OptionShow={(e) => setisPartyoption(e)}
        PartyName={strPartyName}
        strcolSearch={strcolSearch}
        FilterColName={strFilterColName}
        setstrFilterColName={(e) => setstrFilterColName(e)}
        IsAccounts={false}
        TotalRows={iTotalRows}
        RowPerPage={RowPerPage}
        View_PageReport={View_PageReport}
        CurrPage={CurrPage}
      />
    </>
  );
}

export default GeneralReports;
