import { useEffect, useState } from "react";
import { Show_MsgBox } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import { userApp} from "../Admin/Utility";
import PopupSearch from "../Transaction/PopupSearch";
function SearchLedger(props) {
  const dispatch = useDispatch();
  const [arrEntLedger, setarrEntLedger] = useState([]);
  const [arrEntTableCol, setarrEntTableCol] = useState([]);
  const [strSearch, setstrSearch] = useState("");
  const [strColName, setstrColName] = useState("JE");
  useEffect(() => {
    Load_Ledger(props.ColName);
  }, []);
  const Load_Ledger = async (ColName) => {
    try {
      setstrColName(ColName);
      const objSearch = {
        strSearch: "",
        strColName: ColName,
        strCompanyDB: localStorage.db,
      };
      await userApp.post(`ServiceMaster/Load_Ledger/`, objSearch).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setarrEntLedger(res.data.EntLedger);
            setarrEntTableCol(res.data.EntTablDesign);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const Search_Ledger = async (strtxt) => {
    try {
      setstrSearch(strtxt);
      const objSearch = {
        strSearch: strtxt,
        strColName: strColName,
        strCompanyDB: localStorage.db,
      };
      await userApp.post( `ServiceMaster/Search_Ledger/`, objSearch).then(
        (res) => {
          if (res.data.strStatus === "Success") {
            setarrEntLedger(res.data.EntLedger);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        }
      );
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <PopupSearch
        ScrName="Ledger"
        FilterCol={[]}
        FilterVal={""}
        FilterShow={false}
        strSearch={strSearch}
        Columns={arrEntTableCol}
        Data={arrEntLedger}
        UpdateFilterCol={(e) => {}}
        Search_Data={(e) => Search_Ledger(e)}
        View_Data={(e) => props.SelectLedger(e)}
        Close={() => props.Close()}
        IsParty={false}
      />
    </>
  );
}
export default SearchLedger;
