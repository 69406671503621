
import React, { useEffect, useState } from "react";
import Button from "../Master/MasterButton";
import MasterList from "../Master/MasterListItem";
import { userApp} from "../Admin/Utility";
import Messagedialogbox from "../General/Messagedialogbox";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function OtherHeadMaster() {
  const dispatch = useDispatch();
  const iScrId = 5;
  const [iLedId, setiLedId] = useState(0);
  const [strLedCode, setstrLedCode] = useState("");
  const [strLedName, setstrLedName] = useState("");
  const [strAliasName, setstrAliasName] = useState("");
  const [iAccGrpId, setiAccGrpId] = useState(1);
  const [iStatusId, setiStatusId] = useState(1);
  const [strRemarks, setstrRemarks] = useState("");
  const [dOpBal, setdOpBal] = useState(0);
  const [iBalTypeId, setiBalTypeId] = useState(3);
  const [strLedCodeTag, setstrLedCodeTag] = useState("Enter Ledger Code");
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("");
  const [strFilterColumn, setstrFilterColumn] = useState("");
  const [iNumSeqNo, setiNumSeqNo] = useState(0);
  const [strPrefix, setstrPrefix] = useState("");
  const [RecordCount, setRecordCount] = useState(0);

  const [ListLedger, setListLedger] = useState([]);
  const [ListFilter, setListFilter] = useState([]);
  const [ListStatus, setListStatus] = useState([]);
  const [ListAccGrp, setListAccGrp] = useState([]);
  const [ListBalType, setListBalType] = useState([]);

  useEffect(() => {
    return Load();
  }, []);

  const Load = () => {
    setiLedId(0);
    setstrLedCode("");
    setstrLedName("");
    setstrAliasName("");
    setiAccGrpId(1);
    setiStatusId(1);
    setstrRemarks("");
    setdOpBal(0);
    setiBalTypeId(3);
    setstrLedCodeTag("Enter Ledger Code");
    setstrSearch("");
    setstrFilter("");
    setstrFilterColumn("All");
    setiNumSeqNo(0);
    setstrPrefix("");
    dispatch(setload_Screen(true));
    userApp.get(
      
        "OtherHeaderMaster/GetDefaultData/" +
        iScrId +
        "/" +
        localStorage.db
    ).then((res) => {
      setListLedger(res.data.objLedger);
      setListFilter(res.data.objFilter);
      setstrLedCode(res.data.objCodeFormate);
      setListStatus(res.data.objStatus);
      setListAccGrp(res.data.objAccGrp);
      setListBalType(res.data.objBalTyp);
      setRecordCount(res.data.objLedger.length);
      dispatch(setload_Screen(false));
    });
  };

  const SaveClick = () => {
    const ObjInstUpd = {
      iLedId: iLedId,
      strLedCode: strLedCode,
      strLedName: strLedName,
      strAliasName: strAliasName,
      iAccGrpId: Number(iAccGrpId),
      iStatusId: Number(iStatusId),
      strRemarks: strRemarks,
      dOpBal: Number(dOpBal),
      iBalTypeId: Number(iBalTypeId),
      strLedCodeTag: strLedCodeTag,
      strSearch: strSearch,
      strFilter: strFilter,
      strFilterColumn: strFilterColumn,
      iNumSeqNo: Number(iNumSeqNo),
      strPrefix: strPrefix,
      strCompanyDB: localStorage.db,
    };
    userApp.post( "OtherHeaderMaster/InsertUpd/", ObjInstUpd).then(
      (res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
          Load();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      }
    );
  };

  const ClearClick = () => {
    Load();
  };

  const DeleteClick = () => {
    userApp.post(
      
        "OtherHeaderMaster/Delete/" +
        iLedId +
        "/" +
        iScrId +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        AlertMessage(res.data.strMessage, "success");
        Load();
      } else {
        AlertMessage(res.data.strMessage, "error");
      }
    });
  };

  const DeleteIconClick = (iLedId) => {
    userApp.post(
      
        "OtherHeaderMaster/Delete/" +
        iLedId +
        "/" +
        iScrId +
        "/" +
        localStorage.db
    ).then((res) => {
      if (res.data.strStatus === "Success") {
        AlertMessage(res.data.strMessage, "success");
        Load();
      } else {
        AlertMessage(res.data.strMessage, "error");
        Load();
      }
    });
  };

  const Search = (strtxt) => {
    strtxt = strtxt === "" ? "null" : strtxt;
    userApp.get(
      
        "OtherHeaderMaster/Search/" +
        strtxt +
        "/All/" +
        iScrId +
        "/" +
        localStorage.db
    ).then((res) => {
      setListLedger(res.data);
    });
  };

  const GetLedger = (iLedId) => {
    userApp.get(
      
        "OtherHeaderMaster/GetOtherHeader/" +
        iLedId +
        "/" +
        iScrId +
        "/" +
        localStorage.db
    ).then((res) => {
      setiLedId(res.data[0].iLedId);
      setstrLedCode(res.data[0].strLedCode);
      setstrLedName(res.data[0].strLedName);
      setstrAliasName(res.data[0].strAliasName);
      setiAccGrpId(res.data[0].iAccGrpId);
      setiStatusId(res.data[0].iStatusId);
      setstrRemarks(res.data[0].strRemarks);
      setdOpBal(res.data[0].dOpBal);
      setiBalTypeId(res.data[0].iBalTypeId);
      setstrLedCodeTag(res.data[0].strLedCodeTag);
      setstrSearch(res.data[0].strSearch);
      setstrFilter(res.data[0].strFilter);
      setstrFilterColumn(res.data[0].strFilterColumn);
      setiNumSeqNo(res.data[0].iNumSeqNo);
      setstrPrefix(res.data[0].strPrefix);
    });
  };

  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <div>
      <div className="Master-view">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-sm-12 order-sm-1 mt-3 ">
              <div className="fg-card shadow-base mb-3">
                <div className="container p-2" id="ClearInput">
                  <div className="row">
                    <div className="row">
                      <div className="col-md-3 col-sm-12 mb-2">
                        <label>
                          Code<span className="danger">*</span>
                        </label>
                        <input
                          type="text"
                          aria-label="First name"
                          className="form-control"
                          value={strLedCode}
                          onChange={(e) => setstrLedCode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 mb-2">
                        <label>Name</label>
                        <input
                          type="text"
                          aria-label="First name"
                          className="form-control"
                          placeholder="Enter Ledger Name"
                          value={strLedName}
                          onChange={(e) => setstrLedName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 mb-2">
                        <label>Alias</label>
                        <input
                          type="text"
                          aria-label="First name"
                          className="form-control"
                          placeholder="Enter Alias Name"
                          value={strAliasName}
                          onChange={(e) => setstrAliasName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 mb-2">
                        <label>Remarks</label>
                        <input
                          type="text"
                          aria-label="First name"
                          className="form-control"
                          placeholder="Enter Remarks"
                          value={strRemarks}
                          onChange={(e) => setstrRemarks(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-12 mb-2">
                        <label>A/c Group</label>
                        <br />
                        <div className="btn-group" style={{ width: "100%" }}>
                          <select
                            className="selectcus"
                            value={iAccGrpId}
                            onChange={(e) => setiAccGrpId(e.target.value)}
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "left",
                            }}
                          >
                            {ListAccGrp.map((AccGrp, Index) => (
                              <option key={Index} value={AccGrp.iAccGrpId}>
                                {AccGrp.strAccGrpName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12 mb-2">
                        <label>Status</label>
                        <br />
                        <div className="btn-group" style={{ width: "100%" }}>
                          <select
                            className="selectcus"
                            value={iStatusId}
                            onChange={(e) => setiStatusId(e.target.value)}
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "left",
                            }}
                          >
                            {ListStatus.map((Status, Index) => (
                              <option key={Index} value={Status.iStatusId}>
                                {Status.strStatusName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-12 mb-2">
                        <label>Opening Bal</label>
                        <input
                          type="number"
                          aria-label="First name"
                          className="form-control"
                          value={dOpBal}
                          onChange={(e) => setdOpBal(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3 col-sm-12 mb-2">
                        <label>Balance Type</label>
                        <br />
                        <div className="btn-group" style={{ width: "100%" }}>
                          <select
                            className="selectcus"
                            value={iBalTypeId}
                            onChange={(e) => setiBalTypeId(e.target.value)}
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "left",
                            }}
                          >
                            {ListBalType.map((BalTyp, Index) => (
                              <option key={Index} value={BalTyp.iBalTypeId}>
                                {BalTyp.strBalType}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Messagedialogbox />
              </div>
              <Button
                Save={(e) => {
                  if (strLedName === "") {
                    AlertMessage("Please Enter Name", "info");
                  } else if (iAccGrpId === 0) {
                    AlertMessage("Please Select Account Group", "info");
                  } else {
                    dispatch(
                      Show_MsgBox({
                        Button: SaveClick,
                        Question: "Do You Want to Save ?",
                        isdialog: true,
                      })
                    );
                  }
                }}
                Clear={ClearClick}
                Delete={(e) => {
                  if (iLedId === 0) {
                    AlertMessage("Please Select OtherHeaderMaster");
                  } else {
                    dispatch(
                      Show_MsgBox({
                        Button: DeleteClick,
                        Question: "Do You Want to Save ?",
                        isdialog: true,
                      })
                    );
                  }
                }}
                ScrId={iScrId}
              />
            </div>
            <MasterList
              ComBoxValue={ListFilter}
              EntList={ListLedger}
              GetDetails={GetLedger}
              Delete={DeleteIconClick}
              Count={RecordCount}
              Search={Search}
              ScreenID={iScrId}
              accessor="strLedName"
              accessorid="iLedId"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default OtherHeadMaster;
