
import React, { useEffect, useState } from "react";
import { userApp} from "../Admin/Utility";
import SearchBanks from "../Master/BankSearch";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import {
  Clear_Transaction,
  ShowHide_Popup,
} from "../ReduxStore/Transactionstates";
import { Load_Transaction } from "../Transaction/Transaction";

function PaymentPopup() {
  const dispatch = useDispatch();
  const _selecrparem = useSelector((state) => state.SelectOption.parems);
  const _ScrId = 41;
  const _Common = useSelector((store) => store.states.Common);
  const _HT = useSelector((store) => store.states.Header);

  const [IsBankOpen, setIsBankOpen] = useState(false);
  const [strTransNo, setstrTransNo] = useState("");
  const [strBankName, setstrBankName] = useState("");
  const [strVouchNo, setstrVouchNo] = useState("");
  const [dNetAmount, setdNetAmount] = useState(0);
  const [strAccNo, setstrAccNo] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [strVenName, setstrVenName] = useState("");
  const [strAccHolderName, setstrAccHolderName] = useState("");
  const [strChequeNo, setstrChequeNo] = useState("");
  const [dpChequeDate, setdpChequeDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [BNId, setBNId] = useState(0);
  const [iPayModeId, setiPayModeId] = useState(0);
  const [iBankAccId, setiBankAccId] = useState(0);
  const [ListNoFormat, setListNoFormat] = useState([]);
  const [ListPayMode, setListPayMode] = useState([]);
  const [ListVoucherDetails, setListVoucherDetails] = useState();
  const [IsCheckPayment, setIsCheckPayment] = useState(false);
  const [IsBankpayment, setIsBankpayment] = useState(false);
  const [strSearch, setstrSearch] = useState("");

  useEffect(() => {
    View_VoucherInfo();
  }, []);
  const View_VoucherInfo = () => {
    try {
      userApp.get(
        `Payment/View_VoucherInfo/${_ScrId}/${_Common.iScrId}/${localStorage.db}`
      ).then((Res) => {
        if (Res.data.strStatus === "Success") {
          setListNoFormat(Res.data.NoFormat);
          setstrTransNo(Res.data.NoFormat[0].strTransNo);
          setstrVouchNo(Res.data.NoFormat[0].strUserNo);
          setListVoucherDetails(Res.data.EntData);
          setstrVenName(Res.data.EntData.venName);
          setListPayMode(Res.data.PayMode);
          setdNetAmount(Res.data.EntData.netAmt.toFixed(2));
          switch (Res.data.EntData.bT_Id) {
            case 2: // Cash
              setiPayModeId(1);
              setIsCheckPayment(false);
              setIsBankpayment(false);
              ClearClick();
              break;
            default:
              let PayMode = 0;
              if (Res.data.EntData.bT_Id === 3) {
                //Cheque
                PayMode = 2;
              }
              if (Res.data.EntData.bT_Id === 4) {
                //Bank
                PayMode = 7;
              }
              PayMode_OnSelectionChange(PayMode, Res.data.EntData.venId);
              break;
          }
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const SaveClick = () => {
    try {
      dispatch(setload_Screen(true));
      const objInsUpdPay = {
        strPrefix: ListNoFormat[0].strPrefix,
        iNumSeqNo: ListNoFormat[0].iNumSeqNo,
        strCompCode: ListNoFormat[0].strCompCode,
        strFinanFrom: ListNoFormat[0].strFinanFrom,
        strPayNo: strVouchNo,
        strTransNo: strTransNo,
        dpPayDate: _HT.strVouchDate,
        iVenType: _Common.VenTypeId,
        iVenId: ListVoucherDetails.venId,
        iPayModeId: iPayModeId,
        dAmount: dNetAmount,
        iBankAccId: iBankAccId,
        strChequeNo: strChequeNo,
        dpChequeDate: iPayModeId === 2 ? dpChequeDate : _HT.strVouchDate,
        strAccNo: strAccNo,
        strAccHolderName: strAccHolderName,
        strPrintName: "",
        strRemark: strRemark,
        iBNId: BNId,
        iVouchId: ListVoucherDetails.vouchId,
        iPayId: 0,
        iCallerScrId: _Common.iScrId,
        strBankName: strBankName,
        strFilter: "",
        strFilterColumn: "All",
        strSearch: "",
        strVenName: strVenName,
        strCompanyDB: localStorage.db,
      };
      userApp.post( "Payment/InsertUpdate/", objInsUpdPay).then((Res) => {
        if (Res.data.strStatus === "Success") {
          AlertMessage(Res.data.strMessage, "success");
          Popup_Close();
        } else {
          AlertMessage(Res.data.strMessage, "error");
        }
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.message, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ClearClick = () => {
    setstrAccNo("");
    setstrChequeNo("");
    setBNId(0);
    setiBankAccId(0);
    setstrAccHolderName("");
    setstrBankName("");
    setdpChequeDate(new Date().toISOString().split("T")[0]);
  };
  const PayMode_OnSelectionChange = (PayMode, venId) => {
    setiPayModeId(Number(PayMode));
    ClearClick();
    if (Number(PayMode) === 1) {
      setIsCheckPayment(false);
      setIsBankpayment(false);
    }
    if (Number(PayMode) === 2) {
      setIsCheckPayment(true);
    }
    if (Number(PayMode) !== 2 || Number(PayMode) !== 1) {
      setIsBankpayment(true);
      setIsCheckPayment(false);
    }
    let ListBankDT = [];
    if (Number(PayMode) !== 1) {
      dispatch(setload_Screen(true));
      userApp.all([
        userApp.get( "Payment/LoadBankAccount/" + localStorage.db),
        userApp.get(
          
            "Payment/Get_PartyBankDetails/" +
            venId +
            "/" +
            _Common.VenTypeId +
            "/" +
            localStorage.db
        ),
      ]).then((Res) => {
        if (
          Res[0].data.strStatus === "Success" &&
          Res[1].data.strStatus === "Success"
        ) {
          ListBankDT = Res[0].data.objEntBank;
          if (Res[1].data.objEntBankDetails.length > 0) {
            setstrChequeNo("");
            setstrAccHolderName(
              Res[1].data.objEntBankDetails[0].strAccHolderName
            );
            setBNId(Res[1].data.objEntBankDetails[0].iBankNameId);
            setstrBankName(Res[1].data.objEntBankDetails[0].strBankName);
            setstrAccNo(Res[1].data.objEntBankDetails[0].strAccNo);
          }
          if (ListBankDT.length > 0) setiBankAccId(ListBankDT[0].BankAccId);
        } else {
          AlertMessage(
            Res[0].data.strMessage + Res[1].data.strMessage,
            "error"
          );
        }
        dispatch(setload_Screen(false));
      });
    }
    dispatch(setload_Screen(false));
  };
  const GetBankData = (BNId, BankName) => {
    setIsBankOpen(!IsBankOpen);
    setBNId(BNId);
    setstrBankName(BankName);
  };
  const Popup_Close = async () => {
    try {
      await dispatch(ShowHide_Popup("IsPayment"));
      await dispatch(Clear_Transaction());
      await Load_Transaction(_Common, dispatch);
    } catch (e) {
      AlertMessage(e.message, "error");
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>Payment</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={() => Popup_Close()}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <label>Supplier Name</label>
              <input
                type="text"
                className="form-control"
                value={strVenName}
                readOnly
              />
            </div>
            <div className="col-md-6 col-sm-12 mb-1">
              <label>Payment Mode </label>
              <CustomizedSelectoption
                optionparem="isoptionShow20"
                showoption={_selecrparem.isoptionShow20}
                placeholder="Select Payment Mode"
                select_value={(val) => {
                  PayMode_OnSelectionChange(val, ListVoucherDetails);
                }}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iPayModeId}
                displayName="strPaymentMode"
                disvalue="iPM_Id"
                arrydata={ListPayMode}
                EmptVal="Payment Mode"
                disabled={false}
              />
            </div>
            {IsBankpayment && (
              <div className="col-md-6 col-sm-12 mb-1">
                <label>Account No</label>
                <input
                  className="form-control"
                  type="text"
                  value={strAccNo}
                  placeholder="Enter Account No"
                  onChange={(e) => setstrAccNo(e.target.value)}
                />
              </div>
            )}
            {IsBankpayment && (
              <div className="col-md-6 col-sm-12 mb-1">
                <label>Acc/Holder Name</label>
                <input
                  className="form-control"
                  placeholder="Enter Acc/Holder Name"
                  type="text"
                  value={strAccHolderName}
                  onChange={(e) => setstrAccHolderName(e.target.value)}
                />
              </div>
            )}
            {IsBankpayment && (
              <div className="col-md-6 col-sm-12 mb-1">
                <label>Bank</label>
                <div className="custom-select">
                  <input
                    className="form-control"
                    type="text"
                    value={strBankName}
                    placeholder="Type Name to Select Bank"
                    onClick={(e) => setIsBankOpen(!IsBankOpen)}
                    onInput={(e) => setIsBankOpen(!IsBankOpen)}
                  />
                  <i
                    className="fa-solid fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "darkgreen",
                      transform: IsBankOpen
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    }}
                  ></i>
                </div>
              </div>
            )}
            {IsCheckPayment && (
              <div className="col-md-6 col-sm-12 mb-1">
                <label>Cheque No</label>
                <input
                  className="form-control"
                  placeholder="Enter Cheque No"
                  type="text"
                  value={strChequeNo}
                  onChange={(e) => setstrChequeNo(e.target.value)}
                />
              </div>
            )}
            {IsCheckPayment && (
              <div className="col-md-6 col-sm-12 mb-1">
                <label>Cheque Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dpChequeDate}
                  onChange={(e) => setdpChequeDate(e.target.value)}
                />
              </div>
            )}
            <div className="col-md-6 col-sm-12 mb-1">
              <label>Remark</label>
              <input
                className="form-control"
                type="text"
                value={strRemark}
                placeholder="Enter Remark"
                onChange={(e) => setstrRemark(e.target.value)}
              />
            </div>
            <div className="col-md-12 col-sm-12 mt-1 mb-2">
              <div className="net-amount">
                <h1>Total Amount</h1>
                <h2>
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "INR",
                  }).format(
                    Number(dNetAmount) > 0
                      ? Number(dNetAmount).toFixed(2)
                      : (0).toFixed(2)
                  )}
                </h2>
              </div>
            </div>
          </div>
          {IsBankOpen && (
            <SearchBanks
              Close={(e) => setIsBankOpen(!IsBankOpen)}
              GetData={GetBankData}
              BankName={strSearch}
            />
          )}
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <div
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(iPayModeId) === 2 && strChequeNo === "") {
                  AlertMessage("Cheque/DD Number is Invalid", "info");
                } else if (
                  (Number(iPayModeId) === 7 && strAccNo === "") ||
                  (Number(iPayModeId) === 7 && strAccHolderName === "") ||
                  (Number(iPayModeId) === 7 && BNId === 0)
                ) {
                  AlertMessage("Enter valid Account Detailes", "info");
                } else if (Number(dNetAmount) === 0) {
                  AlertMessage("Please Enter Amount", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: SaveClick,
                      Question: "Do You Want to Save Payment?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Add Payment&nbsp;<i className="fa fa-rupee"> </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentPopup;
