import React, { useEffect, useState } from "react";

import { userApp} from "../Admin/Utility";
import { useDispatch, useSelector } from "react-redux";
import {
  ShowHide_Popup,
  setRootCalculation,
} from "../ReduxStore/Transactionstates";

function RootCalculation() {
  const dispatch = useDispatch();
  const _Product = useSelector((store) => store.states.Product);

  const [dValue1, setdValue1] = useState(0);
  const [dValue2, setdValue2] = useState(0);
  const [dValue3, setdValue3] = useState(0);
  const [dConstantValue, setdConstantValue] = useState(0);
  const [dFinalValue, setdFinalValue] = useState(0);
  const [Operator1, setOperator1] = useState("+");
  const [Operator2, setOperator2] = useState("+");
  const [Operator3, setOperator3] = useState("+");

  useEffect(() => {
    setdValue1(Number(_Product.Calculation.dValue1.toFixed(2)));
    setdValue2(Number(_Product.Calculation.dValue2.toFixed(2)));
    setdValue3(Number(_Product.Calculation.dValue3.toFixed(2)));
    setdConstantValue(Number(_Product.Calculation.dConstantValue.toFixed(2)));
    setdFinalValue(Number(_Product.Calculation.dFinalValue.toFixed(2)));
  }, []);
  const ClearClick = () => {
    setdValue1(0);
    setdValue2(0);
    setdValue3(0);
    setdConstantValue(0);
    setdFinalValue(1);
    setOperator1("+");
    setOperator2("+");
    setOperator3("+");
  };
  const Calculate_RootValues = () => {
    userApp.post( "Transaction/Calculate_RootValues/", {
      dValue1: Number(dValue1),
      dValue2: Number(dValue2),
      dValue3: Number(dValue3),
      dConstantValue: Number(dConstantValue),
      dFinalValue: Number(dFinalValue),
      strOp1: Operator1,
      strOp2: Operator2,
      strOp3: Operator3,
    }).then((res) => {
      setdFinalValue(res.data);
    });
  };
  const Save_Calculation = () => {
    dispatch(
      setRootCalculation({
        dValue1: dValue1,
        dValue2: dValue2,
        dValue3: dValue3,
        dConstantValue: dConstantValue,
        dFinalValue: dFinalValue,
        strOp1: Operator1,
        strOp2: Operator2,
        strOp3: Operator3,
      })
    );
    Popup_Close();
  };
  const Popup_Close = () => {
    dispatch(ShowHide_Popup("IsProCal"));
  };
  return (
    <div className="popup-container">
      <div className="popup-content sub">
        <div className="popup-header">
          <h5>ROOT CALCULATION</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={Popup_Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body pt-2">
          <div className="row p-2">
            <div className="col-md-12 col-sm-12">
              <label>Value 1</label>
              <div className="d-flex">
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dValue1"
                  autoFocus
                  value={dValue1}
                  onChange={(e) => setdValue1(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
                <input
                  type="text"
                  aria-label="First name"
                  style={{
                    width: "30px",
                    marginLeft: "5px",
                  }}
                  className="form-control"
                  name="Operator1"
                  value={Operator1}
                  onChange={(e) => setOperator1(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <label>Value 2</label>
              <div className="d-flex">
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dValue2"
                  value={dValue2}
                  onChange={(e) => setdValue2(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
                <input
                  type="text"
                  aria-label="First name"
                  style={{
                    width: "30px",
                    marginLeft: "5px",
                  }}
                  className="form-control"
                  name="Operator2"
                  value={Operator2}
                  onChange={(e) => setOperator2(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <label>Value 3</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                name="dValue3"
                value={dValue3}
                onChange={(e) => setdValue3(e.target.value)}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-md-12 col-sm-12">
              <label>Constant Value</label>

              <div className="d-flex">
                <input
                  type="number"
                  aria-label="First name"
                  className="form-control"
                  name="dConstantValue"
                  value={dConstantValue}
                  onChange={(e) => setdConstantValue(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
                <input
                  type="text"
                  aria-label="First name"
                  style={{
                    width: "30px",
                    marginLeft: "5px",
                  }}
                  className="form-control"
                  name="Operator3"
                  value={Operator3}
                  onChange={(e) => setOperator3(e.target.value)}
                  onClick={(e) => e.target.select()}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <label>Final Value</label>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                name="dFinalValue"
                readOnly
                value={dFinalValue}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={Calculate_RootValues}>
              Calculate&nbsp;<i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={Save_Calculation}>
              Save&nbsp;<i className="bx bx-refresh"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RootCalculation;
