import React, { useEffect, useState } from "react";
import ReactTable from "../General/ReactTable";
import { userApp} from "../Admin/Utility";
import SideMenu from "../General/SideMenu";
import Messagedialogbox from "../General/Messagedialogbox";
import BarcodePreview from "./BarcodePreview";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSelectoption from "../General/CustomizedSelectoption";
import CustomeIndexSelectOption from "../General/CustomeIndexSelectOption";

function BarcodeGenerator(props) {
  const dispatch = useDispatch();
  const _selectparem = useSelector((state) => state.SelectOption.parems);

  const iScrId = 120;
  const [selected, setselected] = useState(0);

  const [iDesignId, setiDesignId] = useState(0);
  const [dpagewidth, setdpagewidth] = useState(0);
  const [dlblHeight, setdlblHeight] = useState(0);
  const [iFormateId, setiFormateId] = useState(1);
  const [dwidth, setdwidth] = useState(0);
  const [dbarcodeWidth, setdbarcodeWidth] = useState(0);
  const [dbarcodeHeight, setdbarcodeHeight] = useState(0);
  const [dHeight, setdHeight] = useState(0);
  const [dRowGap, setdRowGap] = useState(0);
  const [dColumGap, setdColumGap] = useState(0);
  const [iFontSize, setiFontSize] = useState(0);
  const [iNoColumn, setiNoColumn] = useState(0);
  const [strLineColor, setstrLineColor] = useState("black");
  const [strSearch, setstrSearch] = useState("");
  const [strFilter, setstrFilter] = useState("All");
  const [iTempId, setiTempId] = useState(0);
  const [strCodeFormate, setstrCodeFormate] = useState("code128");
  const [strformate, setstrformate] = useState("");
  const [isTempDefault, setisTempDefault] = useState(false);
  const [BarCodeView, setBarCodeView] = useState(false);
  const [ListProduct, setListProduct] = useState([]);
  const [ListComBoxValue, setListComBoxValue] = useState([]);
  const [ListBarcodeFormate, setListBarcodeFormate] = useState([]);
  const [ListBarocodeTemp, setListBarocodeTemp] = useState([]);

  useEffect(() => {
    Load_BarcodeProducts();
  }, []);
  const Load_BarcodeProducts = async () => {
    try {
      await dispatch(setload_Screen(true));
      const objSearch = {
        strColName: strFilter,
        strSearch: strSearch,
        strCompanyDB: localStorage.db,
      };
      await userApp.post("Barcode/Load_Barcode/", objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          let Product = [];
          Res.data.objEntProduct.map((Data, Index) => {
            const ProductObject = {
              iProId: Data.iProId,
              strProCode: Data.strProCode,
              strProName: Data.strProName,
              strCommodityCode: Data.strCommodityCode,
              strUnitName: Data.strUnitName,
              dPriceRate: Data.dPriceRate,
              strPrintName: Data.strPrintName,
              Qty: 15,
              SelectProduct: false,
              dDiscount: Data.dDiscount,
              Remarks: Data.strRemark,
              dRateWithTax: Data.dRateWithTax,
            };
            Product.push(ProductObject);
            return Data;
          });
          setListProduct(Product);
          setListComBoxValue(Res.data.objEntComboBox);
          setListBarcodeFormate(Res.data.objEntCodeFormate);
          setListBarocodeTemp(Res.data.objbarTemp);
          let barcodetemp = Res.data.objbarTemp.filter(
            (DT) => DT.isDefault === 1
          );
          if (barcodetemp.length > 0) {
            setiTempId(barcodetemp[0].bfid);
            setstrformate(barcodetemp[0].strformate);
            setisTempDefault(barcodetemp[0].isDefault === 1 ? true : false);
            setdwidth(barcodetemp[0].dWidth);
            setdHeight(barcodetemp[0].dheight);
            setdColumGap(barcodetemp[0].dcolumgap);
            setdRowGap(barcodetemp[0].drowgap);
            setiNoColumn(barcodetemp[0].inoofcopy);
            setdbarcodeHeight(barcodetemp[0].dbarcodeHeight);
            setdbarcodeWidth(barcodetemp[0].dbarcodeWidth);
            setdpagewidth(barcodetemp[0].dPageWidth);
            setdlblHeight(barcodetemp[0].dLblHeight);
          }
        } else AlertMessage(Res.data.strMessage, "error");
        dispatch(setload_Screen(false));
      });
    } catch (err) {
      AlertMessage(err, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const SearchProduct = async (SearchText, SearchFor) => {
    try {
      const objSearch = {
        strColName: strFilter,
        strSearch: strSearch,
        strCompanyDB: localStorage.db,
      };
      switch (SearchFor) {
        case "Filter":
          setstrFilter(SearchText);
          objSearch.strColName = SearchText;
          break;
        default:
          setstrSearch(SearchText);
          objSearch.strSearch = SearchText;
          break;
      }
      await userApp.post("Barcode/SearchProduct/", objSearch).then((Res) => {
        if (Res.data.strStatus === "Success") {
          let Product = [];
          Res.data.objEntProduct.map((Data, Index) => {
            const ProductObject = {
              iProId: Data.iProId,
              strProCode: Data.strProCode,
              strProName: Data.strProName,
              strCommodityCode: Data.strCommodityCode,
              strUnitName: Data.strUnitName,
              dPriceRate: Data.dPriceRate,
              strPrintName: Data.strPrintName,
              Qty: 15,
              SelectProduct: false,
              dDiscount: Data.dDiscount,
              Remarks: Data.strRemark,
              dRateWithTax: Data.dRateWithTax,
            };
            Product.push(ProductObject);
            return Data;
          });
          setListProduct(Product);
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const PrintClick = () => {
    let Product = ListProduct.filter((Data) => Data.SelectProduct === true);
    let QtyCheck = Product.filter((Data) => Data.Qty === 0);
    if (QtyCheck.length > 0) {
      AlertMessage("Please Enter No of Copy", "error");
    } else if (Product.length === 0) {
      AlertMessage("Please Select Product", "error");
    } else setBarCodeView(!BarCodeView);
  };
  const ClearClick = () => {
    setListProduct([]);
    setListComBoxValue([]);
    setselected(0);
    setiDesignId(0);
    setiFormateId(1);
    setdwidth(0);
    setdHeight(0);
    setdRowGap(0);
    setdColumGap(0);
    setdbarcodeHeight(0);
    setdbarcodeWidth(0);
    setiFontSize(0);
    setstrLineColor("black");
    Load_BarcodeProducts();
  };
  const SaveClick = async () => {
    try {
      await dispatch(setload_Screen(true));
      const InsUpdBCD = {
        bfid: Number(iTempId),
        strformate: strformate,
        dPageWidth: Number(dpagewidth),
        dLblHeight: Number(dlblHeight),
        dWidth: Number(dwidth),
        dheight: Number(dHeight),
        drowgap: Number(dRowGap),
        dcolumgap: Number(dColumGap),
        inoofcopy: Number(iNoColumn),
        isDefault: isTempDefault === true ? 1 : 0,
        dbarcodeWidth: Number(dbarcodeWidth),
        dbarcodeHeight: Number(dbarcodeHeight),
        strCompanyDB: localStorage.db,
      };
      await userApp.post("Barcode/InsUpdBarDesign", InsUpdBCD).then((Res) => {
        if (Res.data.strStatus === "Success") {
          ClearClick();
          AlertMessage(Res.data.strMessage, "success");
        } else AlertMessage(Res.data.strMessage, "error");
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const ProductColumn = [
    {
      Header: "Select",
      accessor: "SelectProduct",
      minWidth: 50,
      maxWidth: 50,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          defaultChecked={rowinfo.SelectProduct}
          onChange={(e) => (rowinfo.SelectProduct = !rowinfo.SelectProduct)}
        />
      ),
    },
    {
      Header: "ProName",
      accessor: "strProName",
      style: { justifyContent: "left" },
    },
    {
      Header: "Unit",
      minWidth: 100,
      maxWidth: 100,
      accessor: "strUnitName",
      style: { justifyContent: "center" },
    },
    {
      Header: "Price",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          onClick={(e) => e.target.select()}
          defaultValue={rowinfo.dPriceRate}
          onChange={(e) => (rowinfo.dPriceRate = e.target.value)}
        />
      ),
    },
    {
      Header: "MRP Rate",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          onClick={(e) => e.target.select()}
          defaultValue={rowinfo.dRateWithTax}
          onChange={(e) => (rowinfo.dRateWithTax = e.target.value)}
        />
      ),
    },
    {
      Header: "Discount",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          onClick={(e) => e.target.select()}
          defaultValue={rowinfo.dDiscount}
          onChange={(e) => (rowinfo.dDiscount = e.target.value)}
        />
      ),
    },
    {
      Header: "Copy",
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="number"
          className="form-control"
          onClick={(e) => e.target.select()}
          defaultValue={rowinfo.Qty}
          onChange={(e) => (rowinfo.Qty = e.target.value)}
        />
      ),
    },
  ];
  const Codeformat = [
    "pharmacode",
    "CODE39",
    "code128",
    "CODE128A",
    "EAN13",
    "UPC",
    "EAN8",
    "EAN5",
    "EAN2",
    "codabar",
  ];
  const arrColor = [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine	",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgrey",
    "darkgreen",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "grey",
    "green",
    "greenyellow",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgrey",
    "lightgreen",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ];
  return (
    <div>
      <SideMenu ScrId={iScrId} />
      <div className="Master-container">
        <div className="Master-card">
          <div className="row p-2">
            <div className="col-md-12 col-sm-12">
              <CustomizedSelectoption
                optionparem="isoptionShow"
                showoption={_selectparem.isoptionShow}
                placeholder="Select Formate"
                select_value={(val) => setiFormateId(Number(val))}
                btnname=""
                btnshow={false}
                show_popup={""}
                valueonly={true}
                defaultval={iFormateId}
                displayName="displayName"
                disvalue="iBarCoFId"
                arrydata={ListBarcodeFormate}
                EmptVal="Formate Name"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Page Width</label>
              <input
                className="form-control"
                type="number"
                onClick={(e) => e.target.select()}
                value={dpagewidth}
                onChange={(e) => setdpagewidth(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Label Height</label>
              <input
                className="form-control"
                type="number"
                onClick={(e) => e.target.select()}
                value={dlblHeight}
                onChange={(e) => setdlblHeight(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Barcode Width</label>
              <input
                className="form-control"
                type="number"
                onClick={(e) => e.target.select()}
                value={dwidth}
                onChange={(e) => setdwidth(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Barcode Height</label>
              <input
                className="form-control"
                type="number"
                value={dHeight}
                onClick={(e) => e.target.select()}
                onChange={(e) => setdHeight(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Row Gap</label>
              <input
                className="form-control"
                type="number"
                onClick={(e) => e.target.select()}
                value={dRowGap}
                onChange={(e) => setdRowGap(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Label Gap</label>
              <input
                className="form-control"
                type="number"
                onClick={(e) => e.target.select()}
                value={dColumGap}
                onChange={(e) => setdColumGap(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>Font Size</label>
              <input
                className="form-control"
                type="number"
                onClick={(e) => e.target.select()}
                value={iFontSize}
                onChange={(e) => setiFontSize(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>No Of Column</label>
              <input
                className="form-control"
                type="number"
                onClick={(e) => e.target.select()}
                value={iNoColumn}
                onChange={(e) => setiNoColumn(Number(e.target.value))}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>
                Barcode Line Color<span className="danger"></span>
              </label>
              <CustomeIndexSelectOption
                optionparem="isoptionShow1"
                showoption={_selectparem.isoptionShow1}
                placeholder="Select Color"
                select_value={(val) => setstrLineColor(val)}
                defaultval={strLineColor}
                arrydata={arrColor}
                EmptVal="Color"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label>
                Barcode Line Color<span className="danger"></span>
              </label>
              <CustomeIndexSelectOption
                optionparem="isoptionShow2"
                showoption={_selectparem.isoptionShow2}
                placeholder="Select Barcode Format"
                select_value={(val) => setstrCodeFormate(val)}
                defaultval={strCodeFormate}
                arrydata={Codeformat}
                EmptVal="Barcode Format"
              />
            </div>
          </div>
        </div>
        <div className="Master-card">
          <div className="Master-wrapper">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <input
                  className="form-control"
                  type="text"
                  value={strSearch}
                  onChange={(e) => SearchProduct(e.target.value, "Search")}
                  placeholder="Search Product"
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <CustomizedSelectoption
                  optionparem="isoptionShow3"
                  showoption={_selectparem.isoptionShow3}
                  placeholder="Select Filter Name"
                  select_value={(val) => SearchProduct(val, "Filter")}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={strFilter}
                  displayName="strDisplayName"
                  disvalue="strValueName"
                  arrydata={ListComBoxValue}
                  EmptVal="Filter Name"
                />
              </div>
            </div>
            <ReactTable
              columns={ProductColumn}
              data={ListProduct}
              minRows={5}
              row_click={(rowInfo, Index) => {}}
              row_doubleclick={(rowInfo, Index) => {}}
              background={true}
              className="full-table"
              containerclass="autotable"
            />

            <img id="Barcode" alt="" style={{ display: "none" }}></img>
            {BarCodeView && (
              <BarcodePreview
                ProductList={ListProduct}
                Template={ListBarocodeTemp.filter((DT) => DT.bfid === iTempId)}
                BarcodeFormate={strCodeFormate}
                Close={(e) => setBarCodeView(!BarCodeView)}
              />
            )}
            <Messagedialogbox />
          </div>
          <div className="btn-section">
            <button className="btn-fabgreen" onClick={PrintClick}>
              Barcode&nbsp;<i className="bx bx-barcode-reader"></i>
            </button>
            <button className="btn-fabgreen" onClick={SaveClick}>
              Save&nbsp; <i className="fa-solid fa-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={ClearClick}>
              Clear&nbsp; <i className="fa-solid fa-broom"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => window.open(`/Others/BarcodeSetting`)}
            >
              Setting&nbsp;<i className="bx bx-cog"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => (window.location.href = "/Dashboard")}
            >
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarcodeGenerator;
