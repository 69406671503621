import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
import Print_DesignCaller from "./Print_DesignCaller";
function WhatsApp(props) {
  const dispatch = useDispatch();
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [strWhatsappMessage, setstrWhatsappMessage] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [numberEmptyError, setnumberEmptyError] = useState(false);
  const [strDescription, setstrDescription] = useState("");
  useEffect(() => {
    let apiKey = "a718be3e8ac89a607d0f36c138ddc26eed61f7ae871049d8c22b8e2a";
    $.getJSON(`https://userApp.ipdata.co?api-key=${apiKey}`).then((data) => {
      setCountryCode("+" + data.calling_code);
    });
    getMessageInfo();
  }, []);
  const ConfigWhatsappMessage = async (BillInfo) => {
    try {
      var strCompanyName = localStorage.OrgName;
      var strCustomerName = BillInfo.PartyInfo[0].custName;
      var BillSummary = BillInfo.Summary[0];

      var Message = `Dear ${strCustomerName},

      Thank you so much for your recent purchase ! 
      Invoice No : ${BillInfo.FileName}.

      Qty : ${BillSummary.tQty},
      Goods Value : ₹${BillSummary.tGoodsValue},
      Discount Amount : ₹${BillSummary.tDiscAmt},
      Tax Amount : ₹${BillSummary.tTaxAmt},
      Round Off : ₹${BillSummary.roundOff},
      Bill Amount : ₹${BillSummary.netAmt},

      Sincerely,
      ${strCompanyName}`;

      setstrWhatsappMessage(Message);
    } catch (error) {
      console.error(error.message);
    }
  };
  const sentMessageClick = () => {
    if (PhoneNumber.length < 1) {
      setnumberEmptyError(true);
      setTimeout(() => setnumberEmptyError(false), 3000);
    } else {
      // Regex expression to remove all characters which are NOT alphanumeric
      let number = PhoneNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");
      number = CountryCode + number;
      var Message = `${strWhatsappMessage},
    Message : ${strDescription} `;
      // Appending the phone number to the URL
      let url = `https://web.whatsapp.com/send?phone=${number}`;
      // Appending the message to the URL by encoding it
      url += `&text=${Message}`;
      // url += `&document=PDF:${Pdffile}`;
      window.open(url);
    }
  };
  const getMessageInfo = async () => {
    try {
      var objEntPdf = await Print_DesignCaller(
        props.ScrId,
        props.ScrName,
        "Transaction",
        props.iVouchId,
        0,
        "PDF",
        dispatch
      );
      if (objEntPdf.PartyInfo[0].mobile1 === "Mobile No : ") {
        ConfigWhatsappMessage(objEntPdf);
      } else {
        let MobNum = objEntPdf.PartyInfo[0].mobile1.replace("Mobile No : ", "");
        setPhoneNumber(MobNum);
        ConfigWhatsappMessage(objEntPdf);
      }
    } catch (err) {
      dispatch(setload_Screen(false));
      AlertMessage(err.strMessage, "info");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h5>Sent WhatsApp Message</h5>
          <div className="icon-popup-btn-grp">
            <span className="icon-btn" onClick={props.Close}>
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            {numberEmptyError && (
              <div style={{ color: "red", fontSize: "13px" }}>
                Mobile number cannot be empty!
              </div>
            )}
            <div>
              <label style={{ color: "gray" }}>Phone Number</label>
              <div className="What_Phone">
                <span>{CountryCode}</span>
                <input
                  className="form-control"
                  type="number"
                  value={PhoneNumber}
                  placeholder="Please Enter Mobile Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label style={{ color: "gray" }}>Message</label>
              <textarea
                className="form-control"
                type="text"
                style={{ minHeight: "100px" }}
                placeholder="Please Leave Your Comment if Any Query"
                value={strDescription}
                onChange={(e) => setstrDescription(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={sentMessageClick}>
              Share WhatsApp<i className="bx bxl-whatsapp"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatsApp;
