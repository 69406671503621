import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userApp } from "../Admin/Utility";

import { Show_MsgBox, setload_Screen } from "../ReduxStore/Message";
import {
  ShowHide_Popup,
  Update_NoFormate,
} from "../ReduxStore/Transactionstates";
function VouchernoSetting(props) {
  const dispatch = useDispatch();
  const _Common = useSelector((store) => store.states.Common);
  const [isAutoNo, setisAutoNo] = useState(false);
  const [strNoFormat, setstrNoFormat] = useState("");
  const [iNextNo, setiNextNo] = useState("");
  const [iMaxDegit, setiMaxDegit] = useState(0);
  const [strInvNo, setstrInvNo] = useState("");
  const [isChanged, setisChanged] = useState(false);

  useEffect(() => {
    Load_VoucherNo();
  }, []);
  useEffect(() => {
    if (isAutoNo) View_VoucherNo(); // Update Invoice No if Auto Generated True
    else {
      setstrInvNo("");
      setstrNoFormat("");
      setiNextNo(0);
      setiMaxDegit(0);
    }
  }, [isAutoNo]);

  const Load_VoucherNo = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .post("ConfigurationSetting/View_VoucherNumber", {
          iScrId: props.Close ? props.iScrId : _Common.iScrId,
          strCompanyDB: localStorage.db,
        })
        .then((res) => {
          if (res.data.strStatus === "Success") {
            let VouchNo = res.data.EntVouch[0];
            setisAutoNo(!VouchNo.isManualNumber);
            if (!VouchNo.isManualNumber) {
              setstrInvNo(VouchNo.userNo);
              setstrNoFormat(VouchNo.userNoFormat);
              setiNextNo(VouchNo.nextNumber);
              setiMaxDegit(VouchNo.numSeqMaxDigit);
            }
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const View_VoucherNo = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .post("ConfigurationSetting/View_VoucherNumber", {
          iScrId: props.Close ? props.iScrId : _Common.iScrId,
          strCompanyDB: localStorage.db,
        })
        .then((res) => {
          if (res.data.strStatus === "Success") {
            let VouchNo = res.data.EntVouch[0];
            setstrInvNo(VouchNo.userNo);
            setstrNoFormat(VouchNo.userNoFormat);
            setiNextNo(VouchNo.nextNumber);
            setiMaxDegit(VouchNo.numSeqMaxDigit);
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const UpdateVoucherNo = async () => {
    try {
      if (strNoFormat.length > 0 && !strNoFormat.includes("#N"))
        AlertMessage("#N is Mandatory", "info");
      else {
        await dispatch(setload_Screen(true));
        await userApp
          .get(
            `ConfigurationSetting/View_NextNumber/${Number(
              props.Close ? props.iScrId : _Common.iScrId
            )}/${Number(iNextNo)}/${localStorage.db}`
          )
          .then(async (Res) => {
            if (Res.data.strStatus === "info") {
              AlertMessage(Res.data.strMessage, "success");
            } else {
              await userApp
                .post("ConfigurationSetting/Update_VoucherNumber", {
                  NumSeqMaxDigit: iMaxDegit,
                  NextNumber: iNextNo,
                  UserNoFormat: strNoFormat,
                  ScrId: props.Close ? props.iScrId : _Common.iScrId,
                  strCompanyDB: localStorage.db,
                  IsManualNumber: !isAutoNo,
                })
                .then((res) => {
                  if (res.data.strStatus === "Success") {
                    Load_VoucherNo();
                    AlertMessage(res.data.strMessage, "success");
                    setisChanged(true);
                  } else {
                    AlertMessage(res.data.strMessage, "error");
                  }
                });
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Popup_Close = () => {
    if (props.Close) props.Close();
    dispatch(ShowHide_Popup("IsVouchNo"));
  };
  const Load_NoFormat = async () => {
    try {
      await dispatch(setload_Screen(true));
      await userApp
        .get(
          `Transaction/Load_NoFormat/${Number(
            props.Close ? props.iScrId : _Common.iScrId
          )}/${localStorage.db}`
        )
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await dispatch(Update_NoFormate(res.data.NoFormat[0]));
            Popup_Close();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  return (
    <div className="popup-container">
      <div className="popup-content config-popup">
        <div className="popup-header">
          <h5>Voucher Number Configuration</h5>
          <div className="icon-popup-btn-grp">
            <span
              className="icon-btn"
              onClick={(e) => {
                if (isChanged) Load_NoFormat();
                else Popup_Close();
              }}
            >
              <i className="fa-regular fa-rectangle-xmark"></i>
            </span>
          </div>
        </div>
        <div className="popup-body">
          <div className="row">
            <p className="config-text">
              {!isAutoNo
                ? "Your invoice numbers are set on auto-generate mode to save your time. Are you sure about changing this setting?"
                : "You have selected manual invoice numbering. Do you want us to auto-generate it for you?"}
            </p>
            {isAutoNo && (
              <p className="config-text1">
                <strong>#C-Company Code </strong>
                <strong>#F-Financial Year</strong>
                <strong>#V-Bill Type Like Sales,Purchase</strong>
                <strong>#N-Bill No</strong>
              </p>
            )}
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="inv-check">
                  <input
                    type="checkbox"
                    checked={isAutoNo}
                    onChange={(e) => setisAutoNo(!isAutoNo)}
                  />
                  <label style={{ fontWeight: isAutoNo ? "bold" : "normal" }}>
                    Continue auto-generating invoice numbers
                  </label>
                </div>
              </div>
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Max Degit</label>
                  <input
                    type="number"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={iMaxDegit}
                    onChange={(e) => setiMaxDegit(e.target.value)}
                    placeholder="0"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Next No</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={iNextNo}
                    placeholder="000"
                    onChange={(e) => setiNextNo(e.target.value)}
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Inv Formate</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={strNoFormat}
                    onChange={(e) => setstrNoFormat(e.target.value)}
                    placeholder="Inv-"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              )}
              {isAutoNo && (
                <div className="col-sm-6 col-md-3">
                  <label>Inv No</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    spellCheck="off"
                    value={strInvNo}
                    readOnly
                  />
                </div>
              )}
              <div className="col-sm-12 col-md-12 ">
                <div className="inv-check">
                  <input
                    type="checkbox"
                    checked={!isAutoNo}
                    onChange={(e) => setisAutoNo(!isAutoNo)}
                  />
                  <label style={{ fontWeight: !isAutoNo ? "bold" : "normal" }}>
                    Enter invoice numbers manually
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            <button className="btn-fabgreen" onClick={(e) => UpdateVoucherNo()}>
              Save&nbsp; <i className="bx bxs-save"></i>
            </button>
            <button className="btn-fabgreen" onClick={Popup_Close}>
              Cancel&nbsp;<i className="bx bx-block"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VouchernoSetting;
